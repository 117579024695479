import * as d3 from "d3";
import * as d3ScaleChromatic from 'd3-scale-chromatic';;
import { vbSize } from "./ddd";
import { textEdit } from "./ddd";
import { parseTransform } from "./ddd";
import { toolTip2 } from "./ddd";
import { hoverPanel } from "./ddd";
import { stratData } from "../ddd/ddd.js"


//sData must be a d3 hierarchy.

export function treeMap(svg, sData, fData, sort, filters) {
    console.log("treemap DD");
    //console.log(sData);
    var keyLr = svg.select("#l-key");
    keyLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
    //Layers
    var gridLr = svg.select("#l-grid");
    gridLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
    var mapLr = svg.select("#l-map");
    mapLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
    var dataLr = svg.select("#l-data");
    dataLr.selectAll("*").remove();
    var copyLr = svg.select("#l-copy");
    copyLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
    var stageLr = svg.select("#l-stage");
    //Sizes
    var svgSize = vbSize(svg);
    var svgHeight = svgSize[0];
    var svgWidth = svgSize[1];
    var gcoords = parseTransform(dataLr.attr("transform")); //Grab the corrdinates of thedata layer which has a margin from top and left
    var gWidth = svgWidth - (gcoords[0] * 2); //50 the sides.
    var gHeight = svgHeight - (gcoords[1] + gcoords[0]); //100 - the top

    var bubSize = Math.min(12, (gWidth / fData.length) * 2);

    //Transitions
    var tIntro = d3.transition()
        .duration(2000)
        .ease(d3.easePolyInOut);

    var $Fmt = d3.format("$,");

    var formatNumber = d3.format("$.0f");

    function formatBillion(x) { return formatNumber(x / 1e9) + "B"; }

    function formatMillion(x) { return formatNumber(x / 1e6) + "M"; }

    function formatThousand(x) { return formatNumber(x / 1e3) + "k"; }

    function fmtAbbr(x) {
        var v = Math.abs(x);
        return (v >= .9995e9 ? formatBillion :
            v >= .9995e6 ? formatMillion :
            formatThousand)(x);
    }

    update(fData);
    //General Update Pattern
    function update(data) {


        var fader = function(color) { return d3.interpolateRgb(color, "#fff")(0.2); },
            color = d3.scaleOrdinal(d3ScaleChromatic.schemeSet1.map(fader)),
            format = d3.format(",d");

        var treemap = d3.treemap()
            .tile(d3.treemapResquarify)
            //.tile(d3.treemapBinary)
            //.tile(d3.treemapDice)
            //.tile(d3.treemapSlice)
            //.tile(d3.treemapSliceDice)
            .size([gWidth, gHeight])
            .round(false)
            .paddingInner(1)
            .paddingTop(1);

        treemap(sData);


        //now we draw stuff.

        //Labels - theese are special. - not abstract
        var classLabels = dataLr.selectAll(".bigLabel")
            .data(sData.children)
            .enter()
            .append("text")
            .text(function(d) {
                return d.data.key + ": " + fmtAbbr(d.value);
            })
            .attr("fill", function(d) {
                //console.log(d);
                return color(d.data.key);
            })
            .attr("transform", function(d, i) {
                console.log(d);
                var yOffset = d.y0;
                return "translate(" + d.x0 + "," + yOffset + ")" + "rotate(0)";
            })
            .style("font-weight", 600)
            .style("font-size", 12)
            .style("text-transform", "uppercase");

        ///OK setu up bub-system selection. But there will be problems.
        var bubs = dataLr.selectAll(".data-bub")
            .data(sData.leaves(), function(d, i) {
                //console.log(d);
                return d.data.key;
            });


        //Now enter. This is a struggle. If coming from another mode none will exist.
        //none the less bub group.
        var enter = bubs.enter().append("g")
            .attr("transform", function(d) {
                //console.log(d);
                var thisMarket = data.filter(function(f) {
                    return f.MARKET == d.data.key;
                });
                //d.share = parseFloat(thisMarket[0]["SHARE"]);
                //d.active = thisMarket[0]["ACTIVE"];
                d.boxW = d.x1 - d.x0;
                d.boxH = d.y1 - d.y0;
                //d.shareW = (parseInt(d.boxW * d.share));
                //d.offset = d.boxW - d.shareW;
                return "translate(" + d.x0 + "," + d.y0 + ")";
            })
            .classed("data-bub", true)
            .on("mouseover", function(d) {
                console.log(d);
            })
            .each(function(d) {
                var elem = d3.select(this);
                var txt = d.data.key + ": " + fmtAbbr(d.value);;
                //console.log(d.data.key);
                toolTip2(elem, d, 16, "", txt);
            });

        //The first time around there wont be any nodes in the exit, but no matter.
        bubs.exit()
            .transition().duration(750)
            .style("opacity", 0)
            .remove();

        bubs.exit()
            .select("rect")
            .transition().duration(750)
            .attr("width", function(d) { return 0; })
            .attr("height", function(d) { return 0; })
            .attr("x", function(d) {
                return d.boxW / 2;
            })
            .attr("y", function(d) {
                return d.boxH / 2;
            });



        //we draw the cells. 
        //there will have to be a place here where we animate and remove the bubbles.....
        enter.append("rect")
            .attr("id", function(d) { return d.data.key; })
            .attr("width", 0)
            .attr("height", 0)
            .attr("fill", function(d) {
                var classColor = d3.rgb(color(d.parent.parent.data.key));
                //console.log(classColor + " : " + classColor.darker);
                return classColor;
                //return classColor.darker(2);
                //return (d.active == "true" ? classColor : classColor.darker(1));
            })
            .attr("fill-opacity", .5)
            .transition()
            .duration(750)
            .attr("width", function(d) { return d.x1 - d.x0; })
            .attr("height", function(d) {
                //console.log(d.y1 - d.y0);
                return d.y1 - d.y0;
            });


        /*        enter.append("rect")
                    .attr("x", function(d) {
                        return d.offset;
                    })
                    .attr("width", function(d) { return d.shareW; })
                    .attr("height", function(d) { return d.y1 - d.y0; })
                    .attr("fill", function(d) {
                        var classColor = d3.rgb(color(d.parent.data.key));
                        //console.log(classColor + " : " + classColor.darker);
                        //return classColor.darker(2);
                        return (d.active == "true" ? classColor : classColor.darker(1));
                    })
                    .attr("fill-opacity", .75)*/


        enter.append("text")
            .attr("y", 8)
            .attr("x", 2)
            .style("fill", "white")
            .style("fill-opacity", .5)
            .text(function(d) {
                if (d.boxH < 10) {
                    return "";
                } else {
                    //return d.data.key + ": " + $Fmt(d.value);
                    return d.data.key + ": " + fmtAbbr(d.value);
                }
            })
            .style("font-size", function(d) {
                var boxWidth = d.x1 - d.x0;
                //console.log(boxWidth);
                var size = Math.min((boxWidth), (boxWidth - 8) / this.getComputedTextLength() * 20) + "px";
                //console.log(this.getComputedTextLength() * 24);
                //console.log(size);
                return 8;
            });

        /*        enter.append("text")
                    .attr("y", 8)
                    .attr("x", function(d) {
                        return d.offset + 4;
                    })
                    .style("fill", "white")
                    .style("fill-opacity", .5)
                    .text(function(d) {
                        if (d.boxH < 10) {
                            return "";
                        } else {
                            return fmtAbbr(d.value * d.share);
                        }
                    })
                    .style("font-size", function(d) {
                        var boxWidth = d.x1 - d.x0;
                        //console.log(boxWidth);
                        var size = Math.min((boxWidth), (boxWidth - 8) / this.getComputedTextLength() * 20) + "px";
                        //console.log(this.getComputedTextLength() * 24);
                        //console.log(size);
                        return 8;
                    });*/

        enter.append("title")
            .text(function(d) { return d.data.id + "\n" + format(d.value); });

        bubs = enter.merge(bubs);

        bubs.transition()
            .duration(750)
            .attr("transform", function(d) { return "translate(" + d.x0 + "," + d.y0 + ")"; })
            .select("rect")
            .attr("width", function(d) { return d.x1 - d.x0; })
            .attr("height", function(d) { return d.y1 - d.y0; });




        function sumByCount(d) {
            return d.children ? 0 : 1;
        }

        function sumBySize(d) {
            return d.size;
        }


        //formatAbbreviation(5000000000); // 5B
        //formatAbbreviation(5000000); // 5M
        //formatAbbreviation(5000); // 5k




        //////////previous






        /* var dump = gridLr.selectAll("*").remove();

         //I create and empty selection, and use the key function with an ID string
         //in my dsv data.
         var bubs = dataLr.selectAll(".data-bub")
             .data(data, function(d, i) {
                 return d.id || (d.id = ++i);
             });



         //remove any that dont need to be there.
         //The first time around there wont be any nodes in the exit, but no matter.
         bubs.exit()
             .transition(tIntro)
             .style("opacity", 0)
             .each(function(d) {
                 //Log out the removed elements.
                 //console.log(d.id);
             })
             .remove();

         var enter = bubs.enter() //add any new ones - a group and a circle within it.
             .append("g")
             .classed("data-bub", true)
             .attr("transform", function(d, i) {
                 ///console.log(i);
                 var y = gHeight / 2;
                 var x = gWidth / 2;
                 return "translate(" + x + "," + y + ")";
             })
             .on("click", function(d) {
                 console.log(d);
             })
             .each(function(d) {
                 var elem = d3.select(this);
                 var txt = d.id;
                 toolTip2(elem, d, 16, "MARKET");
             });

         enter.append("circle")
             .attr("r", bubSize)
             .classed("bub", true);


         //Now I should be able to run this transition that 
         //moves each group to a random location on my svg.
         //But nothing happens. Why is the update selection empty after merge?
         bubs = enter.merge(bubs);

         bubs.transition(tIntro)
             .attr("transform", function(d, i) {
                 ///console.log(i);
                 var y = Math.random() * gHeight;
                 var x = Math.random() * gWidth;
                 return "translate(" + x + "," + y + ")";
             });

         var bubBub = bubs.selectAll(".bub");

         bubBub.transition()
             .attr("r", bubSize);*/

    }

    if (stageLr.select("#headline").empty()) {
        var headline = stageLr.append("text")
            .attr("id", "headline")
            .attr("x", 50)
            .classed("gfx-head", true)
            .attr("y", function() {
                var textNode = d3.select(this);
                var textHeight = textNode.node().getBBox().height;
                return textHeight + 50;
            })
            .call(textEdit, "headline");


        stageLr.append('rect')
            .attr("height", svgHeight)
            .attr("width", svgWidth)
            .classed("box1", true);

    }

    stageLr.select("#headline").text(function(d) {
        var recCount = fData.length;
        //console.log(recCount);
        //return recCount + " total in the list";
        return recCount + " Projects in 2018";
    });







}