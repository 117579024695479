//bubMap
import * as d3 from 'd3';
import * as topojson from "topojson";

//other stuff
import { parseTransform } from "../ddd";
import { vbSize } from "../ddd";
import { textEdit } from "../ddd";

import { toolTip2 } from "../ddd";
import { hoverPanel } from "../ddd";

//require map data
const mapdata = require("../mapdata/admin0.json");
const usdata = require("../mapdata/us.json");
const geos = require("../mapdata/geos.json");


export function mapus(data) {
	console.log('US Map');
	console.log(data);

	//console.log(appData);
	//console.log(filters);
    var svg = data.svg;
    var fData = data.fData;
	var zoom = d3.zoom();
	var vizLr = svg.select("#l-viz");
	//Layers
	var gridLr = svg.select("#l-grid");
	gridLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var mapLr = svg.select("#l-map");
	mapLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var dataLr = svg.select("#l-data");
	var copyLr = svg.select("#l-copy");
	copyLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var stageLr = svg.select("#l-stage");

	//Sizes
	var svgSize = vbSize(svg);
    console.log(svgSize);
	var svgHeight = svgSize[0];
	var svgWidth = svgSize[1];
	var gcoords = parseTransform(vizLr.attr("transform")); //Grab the corrdinates of thedata layer which has a margin from top and left
	var gWidth = svgWidth - (gcoords[0] * 2); //50 the sides.
	var gHeight = svgHeight - (gcoords[1] + gcoords[0]); //100 - the top

	//this is needed for the map zoom
	var active = d3.select(null);

	//here the actual zoom handler.
	var zoom = d3.zoom()
		// no longer in d3 v4 - zoom initialises with zoomIdentity, so it's already at origin
		// .translate([0, 0])
		// .scale(1)
		.scaleExtent([1, 6])
		.on("zoom", zoomed);


	// vizLr.call(zoom);
	// vizLr.on("click", stopped, true);

	var colorScale = d3.scaleOrdinal(d3.schemeCategory10);


	var curK = d3.zoomTransform(vizLr.node()).k;

	console.log(curK);

	var bubSize = 4;

	//Transitions
	var tIntro = d3.transition()
		.duration(2000)
		.ease(d3.easePolyInOut);

	//Access the map shapes. Switch this to require.
	//var iso = topojson.feature(admin0, admin0.objects.layer1);
	var stateData = topojson.feature(usdata, usdata.objects.states);


	var proj = d3.geoAlbersUsa()
		.scale(1400)
	// 	.center([10, 0])
	// 	.rotate([-10, 0, 0]) //.rotate([-10, 0, 0]) to center russia
		.translate([svgWidth / 2, svgHeight / 2]);
	/*            .clipExtent([
	                [0, 0],
	                [gWidth, 600]
	            ]);*/

	//Now create a geoPath generator that uses the projection.
	var path = d3.geoPath()
		.projection(proj);


	update(fData);
	//General Update Pattern
	function update(data) {

		//Add graticule lines for the world map.
		// var graticule = d3.geoGraticule().step([30, 30]);

		// var lines = mapLr.append("path")
		// 	.datum(graticule)
		// 	.classed('graticule', true)
		// 	.attr("d", path);

		//this is where the zoom handler goes....
		var states = mapLr.selectAll(".state")
			.data(stateData.features)
			.enter().append("path")
			.classed("feature countries", true)
			.attr("d", path)
			.style("opacity", 0);
			// .on("click", clicked);



		mapLr.append("path")
			.datum(topojson.mesh(usdata, usdata.objects.states, function (a, b) { return a !== b; }))
			.attr("class", "mesh")
			.attr("d", path);

		states.transition().duration(1000).style("opacity", 1)

	}

	//headline et all
	if (stageLr.select("#headline").empty()) {
		var headline = stageLr.append("text")
			.attr("id", "headline")
			.attr("x", 50)
			.classed("gfx-head", true)
			.text(function (d) {
				//var recCount = fData.length;
				//console.log(recCount);
				//return recCount + " total in the list";
				return "A Map of the US";
			})
			.attr("y", function () {
				var textNode = d3.select(this);
				var textHeight = textNode.node().getBBox().height;
				return textHeight + 14;
			})
			.call(textEdit, "headline");


		stageLr.append('rect')
			.attr("height", svgHeight)
			.attr("width", svgWidth)
			.classed("box1", true);

	}

	// stageLr.select("#headline").text(function (d) {
	// 	var recCount = fData.length;
	// 	//console.log(recCount);
	// 	//return recCount + " total in the list";
	// 	return recCount + " RCG STUDIES";
	// });

	////map zoom
	function clicked(d) {

	//	console.log(this);
		//console.log("clicked");
		//console.log(d.properties.ISO3_CODE);

		if (active.node() === this) return reset();
		active.classed("active", false);
		active = d3.select(this).classed("active", true);

		// if (d.properties.ISO3_CODE == "USA") {
		// 	var bounds = [
		// 		[190, 150],
		// 		[250, 290]
		// 	];
		// } else if (d.properties.ISO3_CODE == "RUS") {
		// 	var bounds = [
		// 		[600, 50],
		// 		[1100, 200]
		// 	];
		// } else {
		// 	var bounds = path.bounds(d);
		// }




		// var dx = bounds[1][0] - bounds[0][0],
		// 	dy = bounds[1][1] - bounds[0][1],
		// 	x = (bounds[0][0] + bounds[1][0]) / 2,
		// 	y = (bounds[0][1] + bounds[1][1]) / 2,
		// 	scale = Math.max(1, Math.min(8, 0.9 / Math.max(dx / gWidth, dy / gHeight))),
		// 	translate = [gWidth / 2 - scale * x, gHeight / 2 - scale * y];

		// console.log(bounds);

		// vizLr.transition()
		// 	.duration(750)
		// 	.call(zoom.transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale))
		// 	.on("end", function (d) {
		// 		//var curK = d3.zoomTransform(vizLr.node()).k;
		// 		//bubSize = bubSize / curK;
		// 		//simulation.restart();
		// 		//simulation.alphaTarget(120);
		// 		//update(fData);
		// 	}) // updated for d3 v4

		// //console.log(zoom.transform);
		// //console.log(d3.zoomIdentity);
	}

	function reset() {
		console.log("reset");
		active.classed("active", false);
		active = d3.select(null);

		vizLr.transition()
			.duration(750)
			// .call( zoom.transform, d3.zoomIdentity.translate(0, 0).scale(1) ); // not in d3 v4
			.call(zoom.transform, d3.zoomIdentity.translate(50, 100).scale(1)); // updated for d3 v4
	}

	function zoomed() {
		console.log("zoomed");
		console.log(curK);

		//bubSize = bubSize / d3.event.transform.k;

		var cName = "",
			cISO = "";

		active.each(function (d) {
			cName = d.properties.name;
			cISO = d.properties.ISO_CODE;
		});

		// g.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")"); // not in d3 v4
		vizLr.attr("transform", d3.event.transform); // updated for d3 v4

		dataLr.selectAll(".bub")
			.transition()
			//.attr("r", bubSize / curK)
			.style("stroke-width", .5 / d3.event.transform.k + "px");

		mapLr.selectAll(".mesh").style("stroke-width", .5 / d3.event.transform.k + "px");

		stageLr.select("#headline").text(d => cName);

		//

		//update(fData);

	}

	// If the drag behavior prevents the default click,
	// also stop propagation so we don’t click-to-zoom.
	function stopped() {
		if (d3.event.defaultPrevented) d3.event.stopPropagation();
	}
}