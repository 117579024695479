//Pipe
import * as d3 from "d3";
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import { vbSize } from "./ddd";
import { textEdit } from "./ddd";
import { toolTip } from "./ddd";
import { parseTransform } from "./ddd";

export function pipe(svg, sData, fData, sort, filters) {

    console.log("pipe");
    //Layers
    var gridLr = svg.select("#l-grid");
    gridLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
    var mapLr = svg.select("#l-map");
    mapLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
    var dataLr = svg.select("#l-data");
    var copyLr = svg.select("#l-copy");
    var stageLr = svg.select("#l-stage");
    //Sizes
    var svgSize = vbSize(svg);
    var svgHeight = svgSize[0];
    var svgWidth = svgSize[1];
    var gcoords = parseTransform(dataLr.attr("transform")); //Grab the corrdinates of thedata layer which has a margin from top and left
    var gWidth = svgWidth - (gcoords[0] * 2); //50 the sides.
    var gHeight = svgHeight - (gcoords[1] + gcoords[0]); //100 - the top

    //This is currently hardcpded arbitraty array to match the names of the phases.
    var phases = ["Candidates", "Awaiting Trial", "In Trial", "Awaiting Implant", "Implanted"];
    var colorScale = d3.scaleOrdinal(d3.schemeCategory10);
    var formatValue = d3.format(",d");
    //A linear scale for the x axis.
    var x = d3.scaleLinear()
        .rangeRound([100, gWidth - 100]);
    //A point scale to place the labels.
    var pScale = d3.scalePoint()
        .domain(phases)
        .rangeRound([100, gWidth - 100])
        .round(true)
        .padding(0);
    //Transitions
    var tIntro = d3.transition()
        .duration(2000)
        .ease(d3.easePolyInOut);

    update(fData);
    //General Update Pattern
    function update(data) {

        //Set the domain of the sacle to the extent of some aspecrt of the data
        x.domain(d3.extent(data, function(d) { return d.PHASE; }));


        function gravity(alpha) {
            var nodes = data;
            for (var i = 0, n = nodes.length, node, k = alpha * 0.1; i < n; ++i) {
              node = nodes[i];
            //   node.vx -= node.x * k;
            //   node.vy -= node.y * k;
              node.y += gHeight * alpha;
              node.x += node.x * alpha
            }
          }

        //   function gravity(alpha) {
        //     return function(d) {
        //       d.y += (d.cy - d.y) * alpha;
        //       d.x += (d.cx - d.x) * alpha;
        //     };
        //   }

        //Now we creat ehe simulation with the filtered data.
        var simulation = d3.forceSimulation(data)
            //add the first foirce, and x – left right – force.
            // .force("x", d3.forceX(function(d) {
            //     //console.log(d);
            //     return x(d.PHASE);
            // }).strength(.5))
            //Trying a Many-body for gravity.
            .force("gravity", gravity(.25))
            //.force("y", d3.forceY(gHeight / 2).strength(.1))
            //.force("y", d3.forceY(gHeight / 2).strength(.5))
            //this is where you set the size i think
            //can you do it after?
            .force("collide", d3.forceCollide(11))
            .stop();

        //Why does he do this 120 times? Just because he assumes that is enough times?
        for (var i = 0; i < 120; ++i) simulation.tick();

        gridLr.append("g")
            .classed("axis axis--x upper gen-bold", true)
            .attr("transform", "translate(0," + gHeight / 2 + ")")
            .call(d3.axisBottom(pScale).ticks(20, ".0s"));

        var bubs = dataLr.selectAll(".data-bub")
            .data(data, function(d, i) {
                return d.id || (d.id = ++i);
            });

        //remove any that dont need to be there.
        //The first time around there wont be any nodes in the exit, but no matter.
        bubs.exit()
            .transition(tIntro)
            .style("opacity", 0)
            .each(function(d) {
                //Log out the removed elements.
                //console.log(d.id);
            })
            .remove();

        var enter = bubs.enter() //add any new ones - a group and a circle within it.
            .append("g")
            .classed("data-bub", true)
            .on("click", function(d) {
                console.log(d);
            });

        enter.append("circle")
            .attr("r", 20)
            .classed("bub", true)
            .each(function(d) {
                //Log out the new elements.
                //console.log(d.id);
            })

        //But nothing happens. Why is the update selection empty after merge?
        bubs = enter.merge(bubs);

        bubs.transition(tIntro)
            .attr("transform", function(d, i) {
                //console.log(d);
                var y = d.y;
                var x = d.x;
                return "translate(" + x + "," + y + ")";
            });


        bubs.select(".bub")
            .transition(tIntro)
            .style("fill", function(d) {
                return colorScale(d.PIPE);
            })
            .style("fill-opacity", .5);


        //THE WHOLE VORONOI THING
        /*  var voro = d3.voronoi()
              .size([svgWidth, svgHeight])
              .x(function(d) {
                  //console.log(d);
                  return d.x;
              })
              .y(function(d) {
                  return d.y;
              });


          var cells = dataLr.selectAll(".data-bub").data(d3.voronoi()
              .size([svgWidth, svgHeight])
              .x(function(d) {
                  //console.log(d);
                  return d.x;
              })
              .y(function(d) {
                  return d.y;
              })
              .polygons(data));

          console.log(cells);

          cells.append("path")
              .attr("d", function(d) { 
                  //console.log(d); 
                  return "M" + d.join("L") + "Z"; 
              })
              .classed("voro-path", true);

          cells.append("title")
              .text(function(d) { return d.data.id + "\n" + formatValue(d.data.value); });*/





        //headline et all
        if (stageLr.select("#headline").empty()) {
            var headline = stageLr.append("text")
                .attr("id", "headline")
                .attr("x", 50)
                .classed("gfx-head", true)
                .text(function(d) {
                    var recCount = fData.length;
                    //console.log(recCount);
                    //return recCount + " Projects in the pipeline";
                    return "";
                })
                .attr("y", function() {
                    var textNode = d3.select(this);
                    var textHeight = textNode.node().getBBox().height;
                    return textHeight + 14;
                })
                .call(textEdit, "headline");


            stageLr.append('rect')
                .attr("height", svgHeight)
                .attr("width", svgWidth)
                .classed("box1", true);

        }


    }


}