import * as d3 from "d3";
import { vbSize } from "./ddd";
import { textPog } from "./ddd";
import { toolTip } from "./ddd";
import { wrap } from "./ddd";
import { parseTransform } from "./ddd";

//OrgChart
export function org(svg, sData, fData, sort, filters) {
    console.log("org");
    //console.log(sData);
    //Layers
    var gridLr = svg.select("#l-grid");
    var dataLr = svg.select("#l-data");
    var copyLr = svg.select("#l-copy");
    var stageLr = svg.select("#l-stage");
    //Sizes
    var svgSize = vbSize(svg);
    var svgHeight = svgSize[0];
    var svgWidth = svgSize[1];
    var gcoords = parseTransform(dataLr.attr("transform")); //Grab the corrdinates of thedata layer which has a margin from top and left
    var gWidth = svgWidth - (gcoords[0] * 2); //50 the sides.
    var gHeight = svgHeight - (gcoords[1] + gcoords[0]); //100 - the top

    var bubs;

    var tree = d3.tree()
        .size([gWidth, gHeight]);

    update(sData);

    function update(root) {

        var orgData = tree(root);

        // adds each node as a group
        var bubs = dataLr.selectAll(".data-bub")
            .data(orgData.descendants(), function(d) {
                //console.log(d.id);
                return d.id || (d.id = ++i);
            });


        var exit = bubs.exit()
            .transition(tIntro)
            .style("opacity", 0)
            .each(function(d) {
                //Log out the removed elements.
                //console.log(d.id);
            })
            .remove();


        var enter = bubs.enter() //add any new ones - a group and a circle within it.
            .append("g")
            .classed("data-bub", true)
            .on("click", function(d) {
                console.log(d);
            });

        enter.append("circle")
            .attr("r", 20)
            .classed("bub", true);

        bubs = enter.merge(bubs);

        bubs.transition(tIntro)
            .attr("transform", function(d, i) {
                return "translate(" + d.x + "," + d.y + ")";
            });

        console.log(bubs);

        bubs.select(".bub")
            .attr("r", function(d) { return d.children ? 50 : 5; })
            .each(function(d) {
                var elem = d3.select(this);
                //var fmt = d3.format(formats.wholeFmt);
                var txt = d.data.NAME;
                toolTip(elem, txt, 16);

            });

        // adds the text to the node
        var labels = bubs.append("text")
            .attr("dy", ".35em")
            .classed("tiny-txt", true)
            .attr("y", function(d) { return d.children ? 0 : 0; })
            //.attr("x", "50px")
            .style("text-anchor", "middle")
            .text(function(d) {
                //console.log(d);
                return d.children ? d.data.NAME : "";
                //return d.data.NAME;
            })
            .call(wrap, 100);


        //now draw
        var links = gridLr.selectAll(".link")
            .data(orgData.descendants().slice(1));

        links.enter().append("path")
            .attr("class", "link")
            .attr("d", function(d) {
                return diagonal(d);
            }).merge(links);


        links.exit().remove();



    }


}


function diagonal(d) {
    return "M" + d.x + "," + d.y +
        "C" + d.x + "," + (d.y + d.parent.y) / 2 +
        " " + d.parent.x + "," + (d.y + d.parent.y) / 2 +
        " " + d.parent.x + "," + d.parent.y;
}

//Transitions
var tIntro = d3.transition()
    .duration(1500)
    .ease(d3.easeLinear);