//screatFilters.js takes a the results of a d3.dsv and creates filter buttons, and returns data for a viz.
import * as d3 from "d3";

export function sortData(data, sort) {
    //console.log(data);
    var nested = d3.nest()
        .key(function(d) {
            return d[sort]
        })
        .sortKeys(d3.ascending)
        .entries(data);
    return nested;
}
