export var admin0 = {
	"type": "Topology",
	"transform": {
		"scale": [0.026363588457261253, 0.013277811009964191],
		"translate": [-180, -85.5096335827304]
	},
	"arcs": [
		[
			[8359, 4756],
			[-1, 0],
			[0, 3],
			[1, 1],
			[-1, -4],
			[1, 0]
		],
		[
			[8127, 8792],
			[-1, 7]
		],
		[
			[8126, 8799],
			[11, 20]
		],
		[
			[8137, 8819],
			[-10, -27]
		],
		[
			[8176, 8879],
			[-2, -68]
		],
		[
			[8174, 8811],
			[-14, 17]
		],
		[
			[8160, 8828],
			[4, 8],
			[-7, 4],
			[-3, 8]
		],
		[
			[8154, 8848],
			[22, 31]
		],
		[
			[8160, 8828],
			[-6, 20]
		],
		[
			[6177, 8915],
			[9, -2],
			[-1, -15],
			[-9, 7],
			[1, 10]
		],
		[
			[9822, 8954],
			[1, 71],
			[-26, 23],
			[-13, 65]
		],
		[
			[9784, 9113],
			[51, 37],
			[40, -41],
			[-11, -59],
			[-42, -96]
		],
		[
			[10520, 8564],
			[-11, -35]
		],
		[
			[10509, 8529],
			[-45, 42],
			[-60, -54],
			[-28, -48],
			[-55, -6]
		],
		[
			[10321, 8463],
			[-17, 67]
		],
		[
			[10304, 8530],
			[39, 15],
			[25, 53],
			[36, 36],
			[72, -3],
			[11, -51],
			[33, -16]
		],
		[
			[9834, 8890],
			[-4, 49]
		],
		[
			[9830, 8939],
			[15, -43],
			[-11, -6]
		],
		[
			[9839, 8780],
			[-19, 16]
		],
		[
			[9820, 8796],
			[19, -16]
		],
		[
			[8175, 6788],
			[-44, 0]
		],
		[
			[8131, 6788],
			[30, 28],
			[14, -28]
		],
		[
			[9822, 8954],
			[8, -15]
		],
		[
			[9834, 8890],
			[-33, 0]
		],
		[
			[9801, 8890],
			[-72, 48],
			[-44, -64]
		],
		[
			[9685, 8874],
			[-64, 59],
			[-8, 110],
			[24, 44],
			[-58, 69],
			[42, 63]
		],
		[
			[9621, 9219],
			[36, 9]
		],
		[
			[9657, 9228],
			[52, -31],
			[9, -59],
			[66, -25]
		],
		[
			[12470, 9870],
			[-4, -16],
			[-21, -17],
			[-7, -12],
			[-6, 19],
			[23, 6],
			[15, 20]
		],
		[
			[11297, 7576],
			[-1, 0],
			[1, 2],
			[0, -2]
		],
		[
			[11514, 8382],
			[-2, -2],
			[0, 2],
			[2, 0]
		],
		[
			[11222, 7248],
			[-2, -1],
			[0, 1],
			[2, 0]
		],
		[
			[6747, 10151],
			[0, -5],
			[-3, -2],
			[-2, 6],
			[5, 1]
		],
		[
			[653, 6937],
			[8, -6],
			[-4, 0],
			[-4, 6]
		],
		[
			[4178, 7376],
			[-6, 4],
			[0, 9],
			[6, -13]
		],
		[
			[9669, 9244],
			[-12, -16]
		],
		[
			[9621, 9219],
			[-53, -10],
			[-40, -55],
			[18, -45],
			[-22, -57],
			[0, -47],
			[-42, 0],
			[13, -53],
			[-28, -19],
			[-10, -89],
			[-45, -9],
			[-33, -46],
			[-32, -18],
			[-6, -82],
			[-80, -36],
			[-63, 0],
			[-62, 36]
		],
		[
			[9136, 8689],
			[36, 74],
			[-5, 41],
			[-31, 7],
			[2, 54],
			[-17, 143],
			[20, 40],
			[10, 73]
		],
		[
			[9151, 9121],
			[52, -29],
			[26, 49],
			[51, 44],
			[4, 50],
			[35, 31],
			[33, -13]
		],
		[
			[9352, 9253],
			[47, -13]
		],
		[
			[9399, 9240],
			[66, 31],
			[24, -5],
			[20, 67],
			[34, -36],
			[-6, -66],
			[16, -29],
			[55, 59],
			[61, -17]
		],
		[
			[7738, 5620],
			[1, -158],
			[-76, 0],
			[0, -241],
			[53, -109]
		],
		[
			[7716, 5112],
			[-75, -29],
			[-97, 15],
			[-18, 33],
			[-159, 0],
			[-47, 30],
			[-47, -21]
		],
		[
			[7273, 5140],
			[-1, 104],
			[11, 23],
			[19, 161],
			[44, 90],
			[8, 93],
			[-33, 145],
			[15, 50],
			[-21, 109],
			[-22, 63],
			[27, 17]
		],
		[
			[7320, 5995],
			[136, 2],
			[7, -62],
			[30, -106],
			[70, 9],
			[5, 74],
			[39, -20],
			[48, 0],
			[5, -100],
			[-5, -61],
			[20, -99],
			[33, -28],
			[30, 16]
		],
		[
			[7324, 6088],
			[-34, -81]
		],
		[
			[7290, 6007],
			[-8, 56]
		],
		[
			[7282, 6063],
			[42, 25]
		],
		[
			[4438, 7815],
			[-3, -6],
			[-2, 1],
			[5, 5]
		],
		[
			[7589, 9645],
			[19, -51]
		],
		[
			[7608, 9594],
			[15, -77]
		],
		[
			[7623, 9517],
			[-37, -87]
		],
		[
			[7586, 9430],
			[-26, 73],
			[3, 89]
		],
		[
			[7563, 9592],
			[26, 53]
		],
		[
			[6892, 9641],
			[-9, -4],
			[-1, 12]
		],
		[
			[6882, 9649],
			[10, -8]
		],
		[
			[8961, 8370],
			[5, -49]
		],
		[
			[8966, 8321],
			[-33, -78],
			[-11, -93]
		],
		[
			[8922, 8150],
			[-100, 17],
			[-38, 100]
		],
		[
			[8784, 8267],
			[87, -15],
			[21, 17],
			[63, 133]
		],
		[
			[8955, 8402],
			[6, -32]
		],
		[
			[4452, 4764],
			[63, -118],
			[35, -14],
			[95, -109],
			[-21, -49],
			[-8, -86],
			[48, -17],
			[49, 2],
			[42, 85],
			[1, 55]
		],
		[
			[4756, 4513],
			[20, 6],
			[19, -57],
			[-9, -64],
			[-73, -85],
			[-71, -145]
		],
		[
			[4642, 4168],
			[-22, -150],
			[3, -66]
		],
		[
			[4623, 3952],
			[-11, -4],
			[-4, -102],
			[53, -72],
			[-10, -43],
			[28, -70],
			[-59, -117],
			[-112, -42],
			[-33, 5],
			[-12, -145],
			[-55, -23],
			[-45, 34],
			[-4, -94],
			[19, -39],
			[-28, -88],
			[4, -53],
			[-70, -73],
			[-19, -78],
			[70, -86],
			[-17, -52],
			[-56, -69],
			[-5, -59],
			[-43, -33],
			[-3, -96],
			[21, -59]
		],
		[
			[4232, 2494],
			[-60, 30],
			[-72, -2],
			[-14, 102],
			[-49, 30],
			[0, 56],
			[38, 56],
			[2, 66],
			[34, 91],
			[-4, 266],
			[-14, 19],
			[9, 202],
			[17, 99],
			[22, 28],
			[-14, 65],
			[7, 93],
			[26, 48],
			[4, 89],
			[15, 30],
			[-15, 168],
			[-13, 33],
			[27, 141],
			[8, 98],
			[20, 37],
			[27, 128],
			[-8, 75],
			[8, 55],
			[40, 32],
			[7, 93]
		],
		[
			[4280, 4722],
			[36, 77],
			[61, -31],
			[68, 15],
			[7, -19]
		],
		[
			[4225, 2305],
			[0, 169]
		],
		[
			[4225, 2474],
			[22, -66],
			[76, -81],
			[-14, -33],
			[-84, 11]
		],
		[
			[8593, 9368],
			[-15, -2]
		],
		[
			[8578, 9366],
			[-6, 34],
			[-46, 31]
		],
		[
			[8526, 9431],
			[-45, 47],
			[-5, 60]
		],
		[
			[8476, 9538],
			[58, 13]
		],
		[
			[8534, 9551],
			[13, -48],
			[47, -83],
			[-1, -52]
		],
		[
			[354, 5366],
			[0, -2],
			[-3, -7],
			[0, 5],
			[3, 4]
		],
		[
			[0, 81],
			[414, -18],
			[305, -62],
			[409, -1],
			[180, 45],
			[-172, 25],
			[-41, 10],
			[-38, 13],
			[-111, 192],
			[94, 45],
			[252, 20],
			[-194, 45],
			[-108, 62],
			[-130, 46],
			[-34, 70],
			[82, 58],
			[176, -22],
			[56, -27],
			[175, 52],
			[-22, 87],
			[230, 57],
			[91, 2],
			[71, 50],
			[221, -29],
			[461, 37],
			[156, -2],
			[383, -25],
			[109, 46],
			[21, 72],
			[74, 20],
			[67, -29],
			[289, 21],
			[243, -64],
			[75, 65],
			[156, -62],
			[325, 74],
			[24, 72],
			[-56, 167],
			[62, 47],
			[-27, 150],
			[63, 27],
			[6, 46],
			[59, 70],
			[100, 48],
			[-36, -107],
			[-43, -21],
			[-80, -115],
			[75, -61],
			[104, -199],
			[18, -132],
			[-21, -118],
			[-86, -67],
			[-251, -79],
			[81, -28],
			[-52, -96],
			[-178, 21],
			[-73, 89],
			[-264, -47],
			[30, -88],
			[244, -163],
			[130, -13],
			[462, 47],
			[38, 39],
			[177, 30],
			[45, 44],
			[178, 70],
			[93, -28],
			[101, -94],
			[-86, -87],
			[-213, -17],
			[-137, 56],
			[-229, -80],
			[-416, -30],
			[25, -46],
			[200, -35],
			[366, 6],
			[214, 50],
			[200, 4],
			[404, 114],
			[170, 11],
			[-9, 62],
			[-207, 21],
			[10, 81],
			[269, 108],
			[140, 52],
			[70, 81],
			[171, 86],
			[255, 82],
			[57, 96],
			[63, -49],
			[87, 38],
			[256, 4],
			[117, 68],
			[222, 12],
			[250, 1],
			[312, -22],
			[172, 42],
			[179, 27],
			[41, 50],
			[95, -59],
			[80, -14],
			[20, 64],
			[128, 73],
			[123, 34],
			[55, -8],
			[101, 38],
			[-8, 50],
			[75, 45],
			[131, -5],
			[127, -104],
			[141, -20],
			[31, 12],
			[206, -32],
			[43, -33],
			[-51, -69],
			[18, -115],
			[69, -44],
			[81, 69],
			[-15, 28],
			[201, 74],
			[12, 51],
			[66, 34],
			[107, 29],
			[58, 39],
			[52, -8],
			[83, 37],
			[67, -10],
			[75, 27],
			[216, -11],
			[100, 38],
			[92, 18],
			[95, -15],
			[116, -41],
			[71, -1],
			[31, 43],
			[89, 28],
			[172, -98],
			[258, 23],
			[60, 31],
			[68, -52],
			[61, -1],
			[17, 57],
			[204, 13],
			[271, -74],
			[43, 10],
			[188, -104],
			[93, -28],
			[60, 17],
			[238, -69],
			[78, -73],
			[62, -1],
			[185, -36],
			[130, -82],
			[-76, -122],
			[-43, -1],
			[-197, -97],
			[-1, -169],
			[135, -116],
			[-198, -39],
			[-46, -119],
			[30, -55],
			[299, -125],
			[29, -24],
			[283, -48],
			[107, -18],
			[4, -8],
			[-13636, -4]
		],
		[
			[4166, 1253],
			[62, -123],
			[3, -133],
			[-143, -27],
			[-133, 91],
			[172, 47],
			[1, 55],
			[-38, 75],
			[76, 15]
		],
		[
			[3887, 638],
			[69, -77],
			[-188, 18],
			[119, 59]
		],
		[
			[3095, 1032],
			[77, 4],
			[12, -58],
			[-234, 26],
			[145, 28]
		],
		[
			[9448, 2773],
			[13, -44],
			[-25, 5],
			[12, 39]
		],
		[
			[4482, 7733],
			[7, -9],
			[-8, -1],
			[1, 10]
		],
		[
			[12556, 4665],
			[53, -89],
			[29, -90],
			[-6, -92],
			[21, -82],
			[-18, -202],
			[-22, -113],
			[-38, -53],
			[-29, -141],
			[-23, -65],
			[-7, -123],
			[-18, -20],
			[-62, -9],
			[-41, -57],
			[-51, 10],
			[-19, 51],
			[-53, -77],
			[-44, 39],
			[-65, 21],
			[-35, 66],
			[-8, 92],
			[-38, 24],
			[0, 69],
			[-52, 77],
			[-27, -23],
			[-22, -55],
			[-59, 116],
			[-18, 72],
			[-65, 24],
			[-37, 35],
			[-84, -17],
			[-65, -45],
			[-40, 4],
			[-77, -68],
			[-14, -49],
			[-60, -17],
			[-31, 15],
			[-51, -8],
			[-81, -87],
			[-53, 5],
			[-57, 70],
			[27, 71],
			[1, 103],
			[-25, 95],
			[-3, 78],
			[-51, 212],
			[15, 76],
			[-24, 117],
			[14, 43],
			[-5, 71],
			[14, 54],
			[49, 20],
			[26, 42],
			[41, 31],
			[25, -5],
			[53, 55],
			[71, 26],
			[28, 80],
			[24, 33],
			[-9, 57],
			[24, 47],
			[24, -43],
			[43, 101],
			[-5, 32],
			[33, 73],
			[50, 31],
			[15, 33],
			[43, -70],
			[58, -25],
			[4, 29],
			[4, 83],
			[19, 61],
			[34, 45],
			[31, -15],
			[25, 57],
			[126, -70],
			[14, -58],
			[-39, -115],
			[48, -78],
			[41, -33],
			[57, -82],
			[33, -30],
			[30, 26],
			[29, 177],
			[-7, 83],
			[4, 103],
			[22, 108],
			[22, 10],
			[18, -76],
			[13, -136],
			[18, -51],
			[47, -35],
			[6, -115],
			[27, -89],
			[-5, -45],
			[17, -62],
			[39, -26],
			[41, -52],
			[25, -72],
			[19, -100],
			[42, -22],
			[7, -66]
		],
		[
			[12435, 3210],
			[-35, -54],
			[-35, 15],
			[-27, 60],
			[-20, 89],
			[0, 55],
			[63, -36],
			[71, 11],
			[-17, -140]
		],
		[
			[7470, 10101],
			[8, -45]
		],
		[
			[7478, 10056],
			[-39, -85]
		],
		[
			[7439, 9971],
			[-62, -36],
			[-30, 10]
		],
		[
			[7347, 9945],
			[-65, 39],
			[-58, -15]
		],
		[
			[7224, 9969],
			[-32, 16]
		],
		[
			[7192, 9985],
			[-3, 16]
		],
		[
			[7189, 10001],
			[1, 19]
		],
		[
			[7190, 10020],
			[26, -19],
			[52, 23],
			[85, 89]
		],
		[
			[7353, 10113],
			[43, 18],
			[74, -30]
		],
		[
			[8771, 9546],
			[15, -43],
			[0, -95],
			[-13, -52]
		],
		[
			[8773, 9356],
			[-60, -34],
			[-33, 13],
			[-32, 95],
			[-55, -62]
		],
		[
			[8534, 9551],
			[58, -18],
			[-3, 63]
		],
		[
			[8589, 9596],
			[50, -54],
			[77, 115]
		],
		[
			[8716, 9657],
			[55, -111]
		],
		[
			[8578, 9366],
			[-51, 59]
		],
		[
			[8527, 9425],
			[-1, 6]
		],
		[
			[7987, 6259],
			[10, -53],
			[-23, -69],
			[-30, -32]
		],
		[
			[7944, 6105],
			[-15, 128]
		],
		[
			[7929, 6233],
			[31, 0],
			[27, 26]
		],
		[
			[7057, 10262],
			[3, -46]
		],
		[
			[7060, 10216],
			[-12, -44]
		],
		[
			[7048, 10172],
			[-114, 89],
			[-10, 27]
		],
		[
			[6924, 10288],
			[31, 21]
		],
		[
			[6955, 10309],
			[33, -1]
		],
		[
			[6988, 10308],
			[2, 1]
		],
		[
			[6990, 10309],
			[46, -5],
			[21, -42]
		],
		[
			[6965, 7322],
			[8, -76],
			[-11, -63],
			[-28, -60],
			[-3, -203]
		],
		[
			[6931, 6920],
			[-41, -12]
		],
		[
			[6890, 6908],
			[0, 210],
			[-11, 75],
			[-22, 29],
			[5, 46]
		],
		[
			[6862, 7268],
			[57, 68]
		],
		[
			[6919, 7336],
			[17, 38],
			[29, -52]
		],
		[
			[6836, 7568],
			[-2, -34],
			[31, -114],
			[54, -84]
		],
		[
			[6862, 7268],
			[-39, 12]
		],
		[
			[6823, 7280],
			[-17, -14],
			[-86, 2],
			[6, -113]
		],
		[
			[6726, 7155],
			[-40, 35],
			[-30, -24],
			[-38, 60]
		],
		[
			[6618, 7226],
			[13, 114],
			[24, 14],
			[23, 102],
			[15, -23],
			[27, 66],
			[28, 7],
			[53, 70],
			[35, -8]
		],
		[
			[10341, 8095],
			[-17, -63]
		],
		[
			[10324, 8032],
			[-16, 96],
			[-37, 34],
			[-7, 47],
			[-11, -29],
			[0, -1],
			[-1, -81],
			[-7, -10],
			[-38, 5]
		],
		[
			[10207, 8093],
			[-13, 174],
			[-28, 32],
			[30, 55],
			[-27, 39],
			[38, 36],
			[30, -36],
			[22, -59],
			[62, 2],
			[-7, -77],
			[-28, -42],
			[15, -47],
			[26, 51],
			[14, -126]
		],
		[
			[7911, 9734],
			[-35, -88],
			[14, -44]
		],
		[
			[7890, 9602],
			[-62, -20]
		],
		[
			[7828, 9582],
			[-40, -36],
			[-41, 26],
			[-49, -18]
		],
		[
			[7698, 9554],
			[-21, 73]
		],
		[
			[7677, 9627],
			[22, 60],
			[-24, 52],
			[12, 32]
		],
		[
			[7687, 9771],
			[9, -29],
			[94, -16],
			[72, 38],
			[49, -30]
		],
		[
			[8747, 8415],
			[0, -28],
			[-5, 7],
			[0, 21],
			[5, 0]
		],
		[
			[3862, 8338],
			[18, -56],
			[-27, 10],
			[9, 46]
		],
		[
			[7549, 9819],
			[22, -60],
			[-13, -41]
		],
		[
			[7558, 9718],
			[-31, -73]
		],
		[
			[7527, 9645],
			[-30, 25]
		],
		[
			[7497, 9670],
			[-3, 4]
		],
		[
			[7494, 9674],
			[-51, 95],
			[-18, 71],
			[106, -4],
			[18, -17]
		],
		[
			[8034, 10364],
			[-49, -38],
			[-108, -6],
			[-60, 31],
			[-63, 0],
			[-30, -33]
		],
		[
			[7724, 10318],
			[11, 89],
			[-17, 97]
		],
		[
			[7718, 10504],
			[79, 29],
			[43, 62],
			[-3, 38]
		],
		[
			[7837, 10633],
			[58, 37]
		],
		[
			[7895, 10670],
			[46, -31],
			[48, 0],
			[27, -113],
			[54, -69],
			[-49, -9],
			[13, -84]
		],
		[
			[3478, 7833],
			[0, -139],
			[-24, -58]
		],
		[
			[3454, 7636],
			[-11, 0],
			[2, 145]
		],
		[
			[3445, 7781],
			[33, 52]
		],
		[
			[4373, 8877],
			[0, -2],
			[-3, -5],
			[0, 3],
			[3, 1],
			[0, 3]
		],
		[
			[4621, 4922],
			[-34, 65],
			[-34, 0],
			[-67, -26],
			[-20, -69],
			[-14, -128]
		],
		[
			[4280, 4722],
			[-28, -1],
			[-10, 115],
			[-22, 65],
			[12, 78],
			[-18, 28],
			[-21, 115]
		],
		[
			[4193, 5122],
			[18, 64],
			[-16, 77],
			[2, 63],
			[19, 44],
			[-4, 102],
			[11, 26],
			[-35, 117]
		],
		[
			[4188, 5615],
			[51, 1],
			[62, 78],
			[47, 14],
			[-1, -109],
			[15, -63],
			[23, -34],
			[50, -15],
			[47, -67],
			[52, -20],
			[11, -185],
			[64, -4],
			[3, -60],
			[23, -30],
			[10, -56],
			[-24, -143]
		],
		[
			[4685, 6587],
			[50, 47],
			[24, -18]
		],
		[
			[4759, 6616],
			[7, -13],
			[65, 17],
			[39, 136]
		],
		[
			[4870, 6756],
			[20, -25],
			[15, -130],
			[28, -35],
			[-28, -125],
			[38, -19],
			[48, -1],
			[69, -43],
			[2, -16],
			[66, -44],
			[19, -66],
			[41, 11],
			[62, -42],
			[63, 4],
			[26, -29],
			[64, -104],
			[33, -36],
			[46, -5],
			[26, -149],
			[-6, -90],
			[-13, -64],
			[-43, -99],
			[-16, -17],
			[-82, -238],
			[5, -148],
			[-12, -132],
			[-21, -66],
			[2, -70],
			[-52, -158],
			[3, -38],
			[-39, -42],
			[-2, -28],
			[-68, 3],
			[-31, -11],
			[-28, -44],
			[-55, -44],
			[-60, -108],
			[-10, -76],
			[5, -88],
			[-10, -59],
			[-38, -65],
			[-16, -72],
			[-36, 0],
			[-59, -216],
			[-23, -33]
		],
		[
			[4803, 3899],
			[11, 74],
			[-53, 93],
			[-29, 20],
			[-60, 88],
			[-30, -6]
		],
		[
			[4756, 4513],
			[9, 124],
			[-40, -1],
			[-16, 127],
			[-37, -3],
			[-44, 22],
			[5, 51],
			[-12, 89]
		],
		[
			[4188, 5615],
			[-39, -2],
			[-2, 109],
			[-24, -36],
			[-33, 0],
			[-7, 39],
			[-45, 84],
			[-16, 62],
			[30, 81],
			[13, 106],
			[40, 45],
			[37, 22],
			[33, -3]
		],
		[
			[4175, 6122],
			[20, 234],
			[-24, 71],
			[8, 142],
			[76, 5],
			[15, 27],
			[21, -69]
		],
		[
			[4291, 6532],
			[20, -35],
			[76, 45],
			[10, 44],
			[-5, 90],
			[7, 57],
			[45, -12],
			[16, 33],
			[33, 7],
			[31, 70]
		],
		[
			[4524, 6831],
			[29, -9],
			[14, -96],
			[-15, -66],
			[9, -81],
			[22, -38],
			[79, 51],
			[23, -5]
		],
		[
			[4572, 7433],
			[-7, -3],
			[0, 13],
			[7, -10]
		],
		[
			[11190, 6808],
			[-12, -64],
			[-23, 41]
		],
		[
			[11155, 6785],
			[35, 23]
		],
		[
			[10321, 8463],
			[-88, -11],
			[-33, 42]
		],
		[
			[10200, 8494],
			[33, 73],
			[71, -37]
		],
		[
			[6957, 2342],
			[2, -1],
			[-4, -3],
			[-1, 4],
			[3, 0]
		],
		[
			[7785, 5100],
			[35, -131],
			[60, -75],
			[10, -79],
			[40, -19],
			[11, -28]
		],
		[
			[7941, 4768],
			[-38, -28],
			[-44, -69],
			[-12, -57],
			[-37, -46],
			[-12, -59],
			[-34, -15],
			[-47, 42],
			[-67, -119],
			[-40, 6],
			[9, 47],
			[-18, 85],
			[-15, 21]
		],
		[
			[7586, 4576],
			[0, 207],
			[39, 0],
			[0, 277],
			[79, 24],
			[31, -14],
			[50, 30]
		],
		[
			[7696, 7263],
			[30, -78],
			[-8, -82],
			[28, -11]
		],
		[
			[7746, 7092],
			[36, -81],
			[47, -70],
			[6, -46],
			[24, -23],
			[10, -54]
		],
		[
			[7869, 6818],
			[-38, 3],
			[-33, 23],
			[-10, -26],
			[-72, -31],
			[-17, 17],
			[-27, -54],
			[-64, 23],
			[-29, 52],
			[-27, -15],
			[-15, -42],
			[-3, -66]
		],
		[
			[7534, 6702],
			[-43, 17],
			[-35, -17],
			[-15, -94]
		],
		[
			[7441, 6608],
			[-4, 51],
			[-36, 78],
			[-22, 101],
			[8, 73],
			[29, 95]
		],
		[
			[7416, 7006],
			[50, 2],
			[33, 34],
			[34, 3],
			[15, 71],
			[56, 15],
			[49, 94],
			[4, 34],
			[39, 4]
		],
		[
			[3677, 9626],
			[22, 36]
		],
		[
			[3699, 9662],
			[0, 11]
		],
		[
			[3699, 9673],
			[30, 35],
			[-2, 51],
			[28, 55],
			[42, 13],
			[-32, 69],
			[-125, 33],
			[-37, 95],
			[-52, 86],
			[-66, 20],
			[-54, -76]
		],
		[
			[3431, 10054],
			[-77, 4],
			[-53, 44],
			[-22, -7],
			[-62, 35],
			[-119, 0],
			[-166, 0],
			[-212, 0],
			[-138, 0],
			[-194, 0],
			[-217, 0]
		],
		[
			[2171, 10130],
			[-66, 61],
			[-3, 33],
			[-116, 63],
			[-9, 61],
			[10, 29],
			[-19, 37],
			[-15, 10]
		],
		[
			[1953, 10424],
			[-4, 20]
		],
		[
			[1949, 10444],
			[-55, 55],
			[-15, 55],
			[16, 96]
		],
		[
			[1895, 10650],
			[-69, 56]
		],
		[
			[1826, 10706],
			[0, 2]
		],
		[
			[1826, 10708],
			[-58, 133],
			[-80, 103],
			[-79, -42],
			[-80, 83],
			[-50, -2],
			[0, 352],
			[0, 350]
		],
		[
			[1479, 11685],
			[72, -10],
			[43, -32],
			[75, 8],
			[113, 29],
			[84, 39],
			[76, -26],
			[66, 31],
			[42, -57],
			[173, 27],
			[37, -24],
			[66, -16],
			[11, -9],
			[20, -9],
			[86, 0],
			[53, -32],
			[19, -66],
			[215, 7],
			[86, 83],
			[48, -64],
			[112, -26],
			[90, 4],
			[48, 61],
			[120, -38],
			[39, 54],
			[-34, 0],
			[3, 7],
			[9, 15],
			[3, 22],
			[-86, 55],
			[3, 89],
			[46, 52],
			[85, -44],
			[53, -92],
			[-35, -34],
			[69, -46],
			[48, 11],
			[11, 4],
			[11, -13],
			[22, -13],
			[-6, -72],
			[69, -49],
			[27, 56],
			[6, 44],
			[11, 10],
			[24, -4],
			[-11, 17],
			[7, 4],
			[9, 0],
			[-2, 5],
			[-24, 58],
			[39, 0],
			[112, -48],
			[4, -10],
			[-8, -2],
			[-2, -4],
			[12, -26],
			[-37, -24],
			[27, -101],
			[-74, -49],
			[-93, -13],
			[-56, -64],
			[-21, 0],
			[-68, 47],
			[24, -47],
			[77, -7],
			[2, -14],
			[-7, -8],
			[-5, -14],
			[-44, -54],
			[-59, -5],
			[-25, -69],
			[-64, -37],
			[-58, -77],
			[-33, -97],
			[-3, -87],
			[63, -28],
			[36, -128],
			[46, 18],
			[80, -31],
			[58, -68],
			[97, -54],
			[95, -14],
			[2, -160],
			[68, -118],
			[61, 37],
			[-9, 171],
			[-18, 45],
			[93, 71],
			[20, 48],
			[-21, 126],
			[-55, 51],
			[46, 89],
			[-31, 164],
			[25, 43],
			[73, -22],
			[67, 13],
			[130, -107],
			[31, -61],
			[-1, -71],
			[77, -79],
			[67, 44],
			[39, 116],
			[71, -116],
			[45, -152],
			[-7, -44],
			[114, -105],
			[37, 3],
			[20, -51],
			[60, -64],
			[6, -95],
			[-48, -49],
			[-56, -9],
			[-60, -80],
			[-168, 5],
			[-95, -23],
			[-17, -52],
			[-64, -41],
			[-28, -64],
			[-35, -63],
			[-20, -24],
			[-24, -6],
			[-49, -45],
			[-11, -28],
			[-5, -22],
			[-8, 1],
			[-57, -44]
		],
		[
			[3971, 9818],
			[-24, -35],
			[-119, -42],
			[-126, -130],
			[-25, 15]
		],
		[
			[4375, 11242],
			[-17, -69],
			[-41, -38],
			[-92, -5],
			[-116, 68],
			[11, 26],
			[-116, 82],
			[-90, -29],
			[-52, 32],
			[4, 15],
			[-2, 7],
			[6, 8],
			[25, 12],
			[145, 22],
			[-37, 49],
			[83, 75],
			[-27, 81],
			[-95, 54],
			[-35, -20],
			[-7, 28],
			[23, -3],
			[9, 6],
			[6, 12],
			[-79, 45],
			[-109, 17],
			[-66, -18],
			[-150, 22],
			[-91, 26],
			[20, 57],
			[-75, 55],
			[43, 101],
			[92, 43],
			[53, -41],
			[134, 30],
			[69, -91],
			[84, 18],
			[190, -82],
			[78, -55],
			[82, -26],
			[37, -38],
			[-27, -24],
			[9, -40],
			[-12, -6],
			[18, -10],
			[17, -40],
			[88, -33],
			[99, -132],
			[-41, -94],
			[-57, 16],
			[-18, 55],
			[-66, 34],
			[-2, -89],
			[12, -8],
			[2, -7],
			[7, -5],
			[58, -39],
			[16, -54]
		],
		[
			[4172, 12700],
			[335, -66],
			[-376, -186],
			[-141, -44],
			[-31, -86],
			[-138, -63],
			[41, -52],
			[-268, -18],
			[-151, 11],
			[79, 60],
			[-41, 33],
			[60, 85],
			[89, 30],
			[-85, 84],
			[-162, 87],
			[53, 33],
			[504, 87],
			[232, 5]
		],
		[
			[2481, 11966],
			[178, -58],
			[115, 53],
			[59, -39],
			[72, -165],
			[73, -34],
			[22, -33],
			[-41, -18],
			[10, -22],
			[-10, -14],
			[-39, -15],
			[-80, 8],
			[-2, 5],
			[5, 7],
			[-71, 14],
			[-13, -15],
			[-10, -4],
			[-83, -27],
			[-115, -13],
			[-36, 28],
			[6, 9],
			[0, 7],
			[-4, 13],
			[-88, 6],
			[-53, 56],
			[-24, 101],
			[-41, 22],
			[23, 63],
			[147, 65]
		],
		[
			[4283, 9835],
			[-28, 46],
			[2, 104],
			[-55, 30],
			[-30, -59],
			[-8, -56],
			[-50, -70],
			[-118, 0]
		],
		[
			[3996, 9830],
			[25, 17],
			[12, 14],
			[5, -2],
			[0, 10],
			[15, 39],
			[49, 47],
			[18, 2],
			[34, 24],
			[37, 69],
			[51, 54],
			[99, 46],
			[51, -29],
			[-27, -154],
			[39, -68],
			[90, -17],
			[14, -37],
			[-120, -69],
			[-45, -60],
			[-25, 22],
			[10, 106],
			[-45, -9]
		],
		[
			[2220, 12055],
			[155, -25],
			[79, -56],
			[-146, -65],
			[-51, -80],
			[-87, -35],
			[-113, 79],
			[75, 127],
			[88, 55]
		],
		[
			[3281, 12569],
			[102, -62],
			[119, -11],
			[53, -100],
			[-102, -69],
			[-115, 3],
			[-142, 90],
			[-40, 51],
			[125, 98]
		],
		[
			[3198, 12244],
			[284, -116],
			[269, 18],
			[65, -67],
			[-90, -32],
			[-226, 0],
			[-165, 28],
			[-1, 78],
			[-113, 27],
			[-23, 64]
		],
		[
			[2708, 12225],
			[29, -56],
			[93, -17],
			[-15, -50],
			[-275, -59],
			[-174, 67],
			[39, 63],
			[93, 26],
			[143, -5],
			[67, 31]
		],
		[
			[4706, 10032],
			[-34, -12],
			[-93, 36],
			[94, 243],
			[51, 11],
			[-40, -87],
			[48, -73],
			[74, -55],
			[-21, -63],
			[43, -3],
			[-20, -77],
			[-80, 47],
			[-22, 33]
		],
		[
			[3160, 11917],
			[7, -66],
			[-76, -40],
			[-82, 67],
			[24, 57],
			[-60, 41],
			[148, 31],
			[39, -90]
		],
		[
			[3585, 11405],
			[55, -56],
			[24, -2],
			[4, -12],
			[95, -68],
			[-106, -19],
			[-52, -50],
			[-54, 107],
			[9, 25],
			[-2, 4],
			[4, 14],
			[4, 42],
			[19, 15]
		],
		[
			[3281, 12026],
			[126, -20],
			[-80, -91],
			[-134, 12],
			[21, 86],
			[67, 13]
		],
		[
			[2413, 12281],
			[12, -70],
			[-84, -6],
			[-82, -54],
			[-76, 43],
			[126, 70],
			[104, 17]
		],
		[
			[3091, 12216],
			[44, -77],
			[-42, -51],
			[-150, 40],
			[12, 59],
			[136, 29]
		],
		[
			[2885, 12418],
			[151, -48],
			[-120, -28],
			[-31, 76]
		],
		[
			[2073, 10216],
			[21, -50],
			[1, -65],
			[-72, 94],
			[50, 21]
		],
		[
			[3776, 11996],
			[90, -7],
			[67, -64],
			[-139, 3],
			[-18, 68]
		],
		[
			[3112, 11705],
			[69, -46],
			[9, -11],
			[-1, -8],
			[-12, -42],
			[-113, 27],
			[-12, 13],
			[3, 10],
			[57, 57]
		],
		[
			[1949, 10444],
			[4, -20]
		],
		[
			[1953, 10424],
			[-4, 20]
		],
		[
			[7189, 10001],
			[-2, -7],
			[5, -9]
		],
		[
			[7224, 9969],
			[-19, -36],
			[-110, -33]
		],
		[
			[7095, 9900],
			[-14, 39],
			[34, 85]
		],
		[
			[7115, 10024],
			[38, 16],
			[37, -20]
		],
		[
			[4232, 2494],
			[-38, 10],
			[-43, -35],
			[-38, 13],
			[-50, -20],
			[-69, 196],
			[17, 51],
			[8, 82],
			[-26, 57],
			[-9, 70],
			[39, 52],
			[22, 84],
			[23, 144],
			[-1, 115],
			[-26, -21],
			[-18, 61],
			[27, 122],
			[-16, 161],
			[18, 6],
			[21, 117],
			[21, 65],
			[25, 176],
			[-10, 161],
			[16, 25],
			[0, 98],
			[21, 156],
			[4, 138],
			[16, 233],
			[2, 123],
			[-9, 124]
		],
		[
			[4159, 5058],
			[34, 64]
		],
		[
			[4225, 2305],
			[-78, 5],
			[24, 44],
			[-15, 93],
			[69, 27]
		],
		[
			[4025, 3294],
			[15, -28],
			[-12, -95],
			[-22, 13],
			[19, 110]
		],
		[
			[3979, 2760],
			[2, 19],
			[-2, -19]
		],
		[
			[11783, 9635],
			[-36, 0],
			[-61, -34],
			[4, -42],
			[-61, 16],
			[-18, -47],
			[-66, -75]
		],
		[
			[11545, 9453],
			[-41, -17],
			[-63, -53],
			[-4, 36],
			[29, 71],
			[-50, 24],
			[-18, -45],
			[-37, -25],
			[-19, -51],
			[-45, -3],
			[-11, -41],
			[49, -30],
			[15, -79],
			[66, 47],
			[43, -64],
			[-35, -21],
			[-57, -81],
			[-18, -56],
			[42, -42],
			[27, -192],
			[33, -58],
			[-27, -50],
			[32, -32],
			[-2, -47],
			[-84, -201],
			[-5, -63],
			[-25, -57],
			[-58, -93],
			[-62, -76],
			[-60, -16]
		],
		[
			[11160, 8138],
			[-7, -4]
		],
		[
			[11153, 8134],
			[-19, -21]
		],
		[
			[11134, 8113],
			[0, -1]
		],
		[
			[11134, 8112],
			[-37, -32],
			[-73, -31],
			[-3, -66],
			[-23, -15],
			[-6, 80],
			[-41, 28],
			[-26, -13]
		],
		[
			[10925, 8063],
			[-49, 35],
			[-8, 70],
			[-45, 35],
			[-51, -67],
			[-55, 19],
			[-15, -28]
		],
		[
			[10702, 8127],
			[-38, -63]
		],
		[
			[10664, 8064],
			[-34, -7],
			[-30, 50],
			[-34, 150],
			[-40, 24],
			[13, 62],
			[34, 81],
			[-1, 91],
			[-37, 71],
			[-15, -22]
		],
		[
			[10200, 8494],
			[-30, 45]
		],
		[
			[10170, 8539],
			[-79, 2],
			[-121, 136],
			[-32, 47],
			[-37, -9]
		],
		[
			[9901, 8715],
			[-30, 28]
		],
		[
			[9871, 8743],
			[-11, 22]
		],
		[
			[9860, 8765],
			[-21, 15]
		],
		[
			[9820, 8796],
			[-5, 50]
		],
		[
			[9815, 8846],
			[-11, 22]
		],
		[
			[9804, 8868],
			[-3, 22]
		],
		[
			[9669, 9244],
			[-4, 95],
			[-36, 34],
			[-8, 40]
		],
		[
			[9621, 9413],
			[45, 79],
			[62, -9],
			[21, 50],
			[52, 26],
			[70, 61]
		],
		[
			[9871, 9620],
			[22, 72],
			[-27, 141],
			[83, 37],
			[28, 125],
			[39, -18],
			[56, 7],
			[8, 99],
			[60, 55]
		],
		[
			[10140, 10138],
			[19, 6]
		],
		[
			[10159, 10144],
			[30, -76],
			[52, -22],
			[41, -99],
			[-15, -80],
			[39, -32],
			[70, -8],
			[68, -56],
			[40, -113],
			[118, -13],
			[87, -4],
			[78, -53],
			[41, -11],
			[69, 49],
			[106, 13],
			[34, 23],
			[59, 72],
			[-22, 46],
			[38, 56],
			[45, -26],
			[36, 48],
			[42, 6],
			[35, 64],
			[65, 34],
			[61, 11],
			[-52, 81],
			[-113, -5],
			[43, 145]
		],
		[
			[11254, 10194],
			[42, -25],
			[57, 45],
			[0, 38],
			[55, 113],
			[-21, 53],
			[26, 36],
			[113, 14],
			[66, -31],
			[37, -72],
			[36, -175],
			[76, -27],
			[42, -47],
			[14, -84],
			[58, 1],
			[19, 30],
			[63, 22],
			[3, -49],
			[-27, -57],
			[-6, -56],
			[-37, -92],
			[-35, 17],
			[-34, -41],
			[1, -133],
			[-19, -39]
		],
		[
			[11026, 7955],
			[-8, -100],
			[-34, -45],
			[-33, 24],
			[-1, 65],
			[38, 49],
			[38, 7]
		],
		[
			[6726, 7155],
			[7, -98],
			[-29, -117],
			[19, -79],
			[-13, -37]
		],
		[
			[6710, 6824],
			[-33, 11],
			[-71, -15],
			[-64, -52]
		],
		[
			[6542, 6768],
			[6, 96],
			[-46, 67],
			[4, 78]
		],
		[
			[6506, 7009],
			[32, 61],
			[-20, 88],
			[7, 49]
		],
		[
			[6525, 7207],
			[93, 19]
		],
		[
			[7441, 6608],
			[-16, -24],
			[-43, 21],
			[-50, -1]
		],
		[
			[7332, 6604],
			[-75, 0]
		],
		[
			[7257, 6604],
			[-57, 13]
		],
		[
			[7200, 6617],
			[7, 55],
			[-15, 52],
			[-42, 72]
		],
		[
			[7150, 6796],
			[13, 82],
			[49, 92],
			[45, -40],
			[18, 44],
			[18, 102],
			[19, 22],
			[31, 148],
			[37, 59],
			[4, 50],
			[-22, 70]
		],
		[
			[7362, 7425],
			[27, -34],
			[13, -80],
			[0, -77],
			[-43, -67],
			[46, -78],
			[11, -83]
		],
		[
			[7869, 6818],
			[29, -51],
			[53, 24],
			[46, -88]
		],
		[
			[7997, 6703],
			[-4, -78],
			[22, -22],
			[-49, -100],
			[-11, -57],
			[-5, -110]
		],
		[
			[7950, 6336],
			[-28, -77],
			[7, -26]
		],
		[
			[7944, 6105],
			[11, -161],
			[23, -42],
			[16, -78]
		],
		[
			[7994, 5824],
			[-67, -21],
			[-17, -68],
			[4, -88],
			[-10, -79],
			[26, -61],
			[28, 17],
			[-8, -79],
			[-24, -12],
			[-24, 72],
			[-151, 80],
			[-13, 35]
		],
		[
			[7320, 5995],
			[-30, 12]
		],
		[
			[7324, 6088],
			[12, -15],
			[33, 42],
			[14, -46],
			[58, 117],
			[2, 94],
			[23, 65],
			[33, 51],
			[15, 216],
			[20, 90]
		],
		[
			[7282, 6063],
			[-29, 77]
		],
		[
			[7253, 6140],
			[33, 76],
			[-17, 42],
			[53, 6],
			[40, -10],
			[11, 44],
			[3, 101],
			[-22, 56],
			[23, 47],
			[-8, 39],
			[-37, -9],
			[0, 72]
		],
		[
			[766, 4845],
			[3, -1],
			[0, -6],
			[-3, 2],
			[0, 5]
		],
		[
			[4122, 7332],
			[-24, -15],
			[-35, -90],
			[-14, -78],
			[33, -82],
			[-4, -63],
			[18, -35],
			[73, -2],
			[25, -66],
			[74, 6],
			[-16, -127],
			[22, -85],
			[-10, -43],
			[16, -35],
			[11, -85]
		],
		[
			[4175, 6122],
			[-29, 35],
			[25, 76],
			[-36, 42],
			[-52, -23],
			[-30, 16],
			[-17, 76],
			[-32, 57],
			[-33, 32]
		],
		[
			[3971, 6433],
			[-29, 33],
			[-29, -8],
			[-74, 89]
		],
		[
			[3839, 6547],
			[16, 90],
			[17, 2],
			[27, 77],
			[-10, 46],
			[6, 171],
			[-22, 51]
		],
		[
			[3873, 6984],
			[26, 66],
			[-6, 43]
		],
		[
			[3893, 7093],
			[13, -31],
			[41, 87],
			[22, 95],
			[46, 50],
			[33, -4],
			[61, 89],
			[13, -47]
		],
		[
			[8471, 5585],
			[2, -20],
			[5, -21],
			[-9, 4],
			[0, 34],
			[2, 3]
		],
		[
			[5927, 7595],
			[11, -24],
			[-8, -6],
			[-5, 10],
			[2, 20]
		],
		[
			[3653, 7264],
			[11, -46],
			[32, -57]
		],
		[
			[3696, 7161],
			[-14, -7],
			[1, -109]
		],
		[
			[3683, 7045],
			[-26, 31],
			[-3, 47],
			[-47, 64],
			[-29, -2],
			[-1, 89]
		],
		[
			[3577, 7274],
			[76, -10]
		],
		[
			[3716, 8186],
			[53, -6],
			[71, -53],
			[41, -47],
			[103, -80],
			[-7, -62],
			[-41, 8],
			[-86, 80],
			[-77, 76],
			[-46, 10],
			[-6, 37],
			[-32, 1],
			[-24, -38],
			[-24, 37],
			[75, 37]
		],
		[
			[10838, 5651],
			[-3, -7],
			[0, 8],
			[3, -1]
		],
		[
			[3741, 7901],
			[9, -8],
			[-9, -2],
			[0, 10]
		],
		[
			[8139, 9129],
			[-49, -76],
			[-10, 50],
			[59, 26]
		],
		[
			[7389, 10271],
			[108, -44],
			[45, -56]
		],
		[
			[7542, 10171],
			[-72, -70]
		],
		[
			[7353, 10113],
			[-40, 43],
			[-22, 58],
			[98, 57]
		],
		[
			[7156, 10576],
			[28, -7]
		],
		[
			[7184, 10569],
			[65, -47],
			[53, 21],
			[67, -59]
		],
		[
			[7369, 10484],
			[20, -213]
		],
		[
			[7115, 10024],
			[23, 103],
			[-68, 39]
		],
		[
			[7070, 10166],
			[-10, 50]
		],
		[
			[7057, 10262],
			[-3, 76],
			[42, 36],
			[5, 76]
		],
		[
			[7101, 10450],
			[5, 33],
			[62, 16],
			[-12, 77]
		],
		[
			[8469, 7303],
			[-12, -35]
		],
		[
			[8457, 7268],
			[-43, -3],
			[0, 59],
			[22, 55]
		],
		[
			[8436, 7379],
			[27, 18]
		],
		[
			[8463, 7397],
			[6, -94]
		],
		[
			[4497, 7617],
			[6, -4],
			[1, -25],
			[-6, 0],
			[-1, 29]
		],
		[
			[7156, 10576],
			[-18, 64],
			[17, 102],
			[62, -12],
			[6, -51],
			[-30, -59],
			[5, -44],
			[-14, -7]
		],
		[
			[4107, 7798],
			[-2, 126]
		],
		[
			[4105, 7924],
			[29, 17],
			[67, -69],
			[-25, -41],
			[-69, -33]
		],
		[
			[7156, 9223],
			[-15, -77],
			[0, -96],
			[-27, -45],
			[9, -64],
			[49, -84],
			[18, -139]
		],
		[
			[7190, 8718],
			[14, -112],
			[-2, -168],
			[-18, -24],
			[24, -94],
			[60, -53],
			[15, -56]
		],
		[
			[7283, 8211],
			[-172, -201],
			[-63, -106],
			[-60, -23]
		],
		[
			[6988, 7881],
			[-33, -13],
			[-4, 65],
			[-56, 35],
			[-23, 63],
			[-130, 167],
			[-98, 125]
		],
		[
			[6644, 8323],
			[-145, 173]
		],
		[
			[6499, 8496],
			[0, 28]
		],
		[
			[6499, 8524],
			[0, 78],
			[41, 50],
			[69, 18],
			[54, 70],
			[26, 13],
			[0, 70],
			[28, 37],
			[64, -4],
			[-17, 91],
			[2, 62],
			[-22, 74]
		],
		[
			[6744, 9083],
			[79, 52],
			[56, 58],
			[59, 20],
			[69, 5],
			[20, -19],
			[77, 33],
			[52, -9]
		],
		[
			[3971, 6433],
			[-8, -109],
			[-42, -79],
			[-44, -29],
			[-21, -35],
			[-10, -81],
			[-25, -34],
			[-34, 50],
			[-3, 65]
		],
		[
			[3784, 6181],
			[7, 2],
			[-7, 51],
			[-24, 31],
			[15, 145],
			[19, 91],
			[45, 46]
		],
		[
			[8127, 8792],
			[24, -131]
		],
		[
			[8151, 8661],
			[-19, -90],
			[-31, -34],
			[16, -93],
			[57, -188],
			[5, -73]
		],
		[
			[8179, 8183],
			[-26, -21],
			[-33, -64]
		],
		[
			[8120, 8098],
			[-34, 0]
		],
		[
			[8086, 8098],
			[-71, -2],
			[-239, 0]
		],
		[
			[7776, 8096],
			[0, 543],
			[-11, 72],
			[17, 112]
		],
		[
			[7782, 8823],
			[83, -21],
			[76, -37],
			[38, 48],
			[29, 8],
			[38, -41],
			[39, -3],
			[41, 22]
		],
		[
			[8436, 7379],
			[-55, 122],
			[-30, 33],
			[-38, 9],
			[-26, -17],
			[-21, 36],
			[-15, -54],
			[-37, 6]
		],
		[
			[8214, 7514],
			[-3, 49],
			[20, 156],
			[48, 43],
			[12, 34]
		],
		[
			[8291, 7796],
			[34, -187],
			[26, -41],
			[38, -27],
			[47, -107],
			[27, -37]
		],
		[
			[6499, 8496],
			[0, -98],
			[-127, 0],
			[0, -190],
			[-38, -34],
			[0, -127],
			[-149, 0],
			[-4, -42]
		],
		[
			[6181, 8005],
			[4, 80],
			[16, 36],
			[29, 123],
			[33, 55],
			[15, 110],
			[37, 49],
			[14, 66]
		],
		[
			[6329, 8524],
			[170, 0]
		],
		[
			[6760, 9705],
			[46, -43],
			[76, -13]
		],
		[
			[6892, 9641],
			[55, -5]
		],
		[
			[6947, 9636],
			[2, -40],
			[-42, -46],
			[-42, -19],
			[-49, -116],
			[20, -57],
			[-87, -148],
			[-89, -5],
			[-61, -40],
			[-23, 66],
			[-31, 14]
		],
		[
			[6545, 9245],
			[0, 1]
		],
		[
			[6545, 9246],
			[-1, 11]
		],
		[
			[6544, 9257],
			[19, 121],
			[14, 221],
			[-84, -2]
		],
		[
			[6493, 9597],
			[-19, 76],
			[55, 64],
			[124, -29],
			[107, -3]
		],
		[
			[7892, 10919],
			[-24, -52],
			[-4, -93]
		],
		[
			[7864, 10774],
			[-84, 41],
			[-29, -16]
		],
		[
			[7751, 10799],
			[-33, 101],
			[84, 26],
			[90, -7]
		],
		[
			[8191, 6788],
			[-3, 53],
			[-28, 23],
			[-4, 61],
			[-31, 45],
			[-39, 103],
			[37, 16],
			[-1, 66]
		],
		[
			[8122, 7155],
			[6, 83],
			[26, 23],
			[3, 63],
			[22, 65],
			[20, 6],
			[15, 119]
		],
		[
			[8457, 7268],
			[-10, -30],
			[23, -75],
			[27, -45],
			[113, -75],
			[37, 0],
			[-113, -233],
			[-37, 3],
			[-79, -73]
		],
		[
			[8418, 6740],
			[-44, 22],
			[-48, -61],
			[-57, 20],
			[-44, 54],
			[-34, 13]
		],
		[
			[7743, 11397],
			[-17, 160],
			[-119, 84]
		],
		[
			[7607, 11641],
			[99, -33],
			[100, 30],
			[-3, 15],
			[7, 35],
			[79, 31],
			[51, -45],
			[-19, -20],
			[0, -8],
			[4, -5]
		],
		[
			[7925, 11641],
			[-11, -65],
			[53, -40],
			[-38, -52],
			[40, -79],
			[-18, -65],
			[1, -13],
			[33, -64],
			[41, -85],
			[-89, -123],
			[-54, -55]
		],
		[
			[7883, 11000],
			[-181, -48],
			[-19, 22],
			[-42, 31],
			[7, 75],
			[-21, 80],
			[17, 38],
			[150, 134],
			[-4, 12],
			[-7, 34],
			[-40, 19]
		],
		[
			[13587, 5136],
			[14, -59],
			[-25, -12],
			[-20, 45],
			[31, 26]
		],
		[
			[4594, 2577],
			[42, -24],
			[-49, -37],
			[7, 61]
		],
		[
			[7048, 10172],
			[22, -6]
		],
		[
			[7095, 9900],
			[-2, -128],
			[19, -34]
		],
		[
			[7112, 9738],
			[-53, -53],
			[-81, 35],
			[-32, -32],
			[1, -52]
		],
		[
			[6760, 9705],
			[14, 29],
			[13, 171],
			[-35, 77],
			[-93, 71],
			[3, 54],
			[49, 14],
			[49, -20],
			[23, 62],
			[100, 51],
			[4, 57],
			[37, 17]
		],
		[
			[6557, 11132],
			[5, -9],
			[-9, 0],
			[4, 9]
		],
		[
			[12828, 6966],
			[5, -8],
			[-7, -5],
			[2, 13]
		],
		[
			[7253, 6140],
			[-72, 155],
			[-18, 83],
			[16, 27],
			[3, 81],
			[13, 34]
		],
		[
			[7195, 6520],
			[62, -5],
			[0, 89]
		],
		[
			[6700, 10858],
			[-18, -80],
			[78, -9],
			[-38, -105],
			[44, -37],
			[19, -72],
			[34, -36],
			[27, -91],
			[36, -4],
			[5, -60],
			[-47, -97],
			[-143, -15],
			[-11, -30],
			[-57, 5],
			[39, 69],
			[-8, 42],
			[11, 112],
			[41, 51],
			[-61, 115],
			[-36, 24],
			[-22, 74],
			[11, 55],
			[49, 80],
			[47, 9]
		],
		[
			[6591, 10514],
			[-73, 27],
			[35, 47]
		],
		[
			[6553, 10588],
			[42, 11],
			[23, -69],
			[-27, -16]
		],
		[
			[6731, 10169],
			[-1, -6],
			[-3, 3],
			[4, 3]
		],
		[
			[8476, 9538],
			[-25, 34],
			[-48, -4]
		],
		[
			[8403, 9568],
			[-4, 90],
			[-53, 50]
		],
		[
			[8346, 9708],
			[109, -17],
			[34, -31],
			[75, -21],
			[25, -43]
		],
		[
			[6823, 7280],
			[17, -63],
			[15, -148],
			[-7, -115],
			[25, -53]
		],
		[
			[6873, 6901],
			[-125, -104],
			[-38, 27]
		],
		[
			[6395, 7375],
			[28, -39],
			[36, 9],
			[21, 35],
			[45, -173]
		],
		[
			[6506, 7009],
			[-23, -26],
			[-15, 89],
			[-30, 8]
		],
		[
			[6438, 7080],
			[-36, 113],
			[-46, -9],
			[-33, -64]
		],
		[
			[6323, 7120],
			[-61, 153]
		],
		[
			[6262, 7273],
			[9, 33],
			[37, 16],
			[0, 73]
		],
		[
			[6308, 7395],
			[52, -28],
			[35, 8]
		],
		[
			[6192, 7422],
			[7, 42]
		],
		[
			[6199, 7464],
			[30, -33],
			[-37, -9]
		],
		[
			[6262, 7273],
			[-2, -7],
			[-22, 61],
			[-44, 42]
		],
		[
			[6194, 7369],
			[58, 26],
			[56, 0]
		],
		[
			[7195, 6520],
			[5, 97]
		],
		[
			[7828, 9582],
			[-12, -73]
		],
		[
			[7816, 9509],
			[-38, 20],
			[-37, -23],
			[-43, -86],
			[-6, -59],
			[17, -61],
			[-5, -90],
			[-27, -8],
			[-31, 66],
			[-33, 113],
			[-27, 49]
		],
		[
			[7623, 9517],
			[75, 37]
		],
		[
			[4489, 7361],
			[1, -14],
			[-5, -3],
			[1, 14],
			[3, 3]
		],
		[
			[5139, 10960],
			[-49, 55],
			[-80, 7],
			[-91, 122],
			[-11, 58],
			[-55, 75],
			[-3, 32],
			[19, 18],
			[-14, 5],
			[-11, 9],
			[-42, 64],
			[-21, 86],
			[33, 95],
			[72, 37],
			[3, 10],
			[-1, 7],
			[1, 8],
			[23, 67],
			[-69, 0],
			[-10, 124],
			[-89, 42],
			[-27, 125],
			[-137, 149],
			[-158, 37],
			[-120, -32],
			[-72, 11],
			[-110, 70],
			[83, 32],
			[-136, 50],
			[302, 107],
			[-96, 59],
			[232, 114],
			[256, 40],
			[56, -27],
			[344, 31],
			[-55, 54],
			[571, 35],
			[339, -78],
			[3, -83],
			[216, 28],
			[153, -34],
			[-147, -58],
			[-227, -196],
			[62, -82],
			[31, -183],
			[-131, -93],
			[-76, 22],
			[-4, -85],
			[77, -38],
			[33, -54],
			[-228, -19],
			[186, -65],
			[-49, -50],
			[-11, 2],
			[-10, -12],
			[-13, -5],
			[-3, -8],
			[-7, -4],
			[-14, -12],
			[-184, -62],
			[-60, 1],
			[-72, -100],
			[-84, -63],
			[-81, -23],
			[-57, 0],
			[-4, -30],
			[-30, -8],
			[-5, -4],
			[-7, 0],
			[0, -4],
			[-7, -5],
			[23, -64],
			[-61, -129],
			[-37, -174],
			[-52, -4]
		],
		[
			[4776, 11735],
			[79, -36],
			[-59, -45],
			[-54, 35],
			[34, 46]
		],
		[
			[3454, 7636],
			[27, -12]
		],
		[
			[3481, 7624],
			[-36, -49],
			[-6, -49]
		],
		[
			[3439, 7526],
			[-31, -52]
		],
		[
			[3408, 7474],
			[-43, 16],
			[-37, 44]
		],
		[
			[3328, 7534],
			[2, 55],
			[19, 62],
			[47, 0],
			[-23, 61],
			[4, 69],
			[68, 0]
		],
		[
			[4759, 6616],
			[21, 83],
			[-19, 110],
			[18, 55]
		],
		[
			[4779, 6864],
			[40, -13],
			[46, -68],
			[5, -27]
		],
		[
			[12324, 7464],
			[1, -5],
			[-6, -8],
			[-1, -11],
			[-4, 5],
			[10, 19]
		],
		[
			[4661, 6880],
			[-35, -127],
			[52, -159],
			[7, -7]
		],
		[
			[4524, 6831],
			[-24, 55],
			[5, 50],
			[36, 40],
			[-17, 32],
			[28, 77]
		],
		[
			[4552, 7085],
			[34, -40],
			[75, -165]
		],
		[
			[11160, 8138],
			[7, -9],
			[-9, -10],
			[-5, 15]
		],
		[
			[9610, 2448],
			[11, -3],
			[-3, -12],
			[-7, 6],
			[-1, 9]
		],
		[
			[3674, 7570],
			[-51, -29],
			[-16, 14],
			[-33, -70],
			[-37, -8],
			[-22, -59]
		],
		[
			[3515, 7418],
			[-19, 32]
		],
		[
			[3496, 7450],
			[-57, 76]
		],
		[
			[3481, 7624],
			[49, 2],
			[73, 18],
			[27, -13],
			[44, -61]
		],
		[
			[7458, 9940],
			[27, -40],
			[59, -1]
		],
		[
			[7544, 9899],
			[5, -80]
		],
		[
			[7494, 9674],
			[-59, 42],
			[-33, 53],
			[-11, 68],
			[-48, 28]
		],
		[
			[7343, 9865],
			[60, -4],
			[18, 57],
			[37, 22]
		],
		[
			[7527, 9645],
			[3, -10]
		],
		[
			[7530, 9635],
			[-33, 35]
		],
		[
			[4107, 7798],
			[-30, 57],
			[-24, 87],
			[52, -18]
		],
		[
			[7667, 10085],
			[30, -33]
		],
		[
			[7697, 10052],
			[-22, -15],
			[-44, -109],
			[-35, -15]
		],
		[
			[7596, 9913],
			[-52, -14]
		],
		[
			[7458, 9940],
			[-19, 31]
		],
		[
			[7478, 10056],
			[24, -21],
			[81, 30],
			[35, 34],
			[49, -14]
		],
		[
			[10991, 6365],
			[-3, -2],
			[-22, 38],
			[-11, 101],
			[19, 85],
			[13, 9]
		],
		[
			[10987, 6596],
			[0, -32],
			[35, -59],
			[59, 20],
			[14, 34],
			[42, -27],
			[36, 16],
			[35, 119],
			[8, 86],
			[18, 18],
			[54, -17]
		],
		[
			[11288, 6754],
			[-6, -62],
			[26, -80],
			[-7, -36],
			[31, -45],
			[-38, -34],
			[-12, -124],
			[-34, -44],
			[2, -54],
			[-23, -106],
			[-49, -44],
			[-8, 52],
			[-57, 24],
			[-17, -19],
			[-47, 38],
			[-38, -7],
			[-20, 152]
		],
		[
			[10565, 6736],
			[56, -74],
			[26, -62],
			[55, -56],
			[13, -47],
			[45, -31],
			[3, -101],
			[23, -2],
			[20, -96],
			[25, -8],
			[21, -63],
			[-13, -199],
			[-62, 32],
			[-67, 108],
			[-55, 127],
			[-22, 111],
			[-22, 63],
			[-23, 22],
			[-25, 129],
			[-31, 31],
			[-33, 101],
			[-47, 69],
			[-5, 76],
			[37, -33],
			[41, 2],
			[40, -99]
		],
		[
			[12176, 6243],
			[0, -280],
			[0, -210]
		],
		[
			[12176, 5753],
			[-71, 119],
			[-12, 57],
			[-42, 118],
			[-69, 55],
			[-26, 3],
			[-61, 66],
			[-26, -39],
			[-7, 96],
			[-38, 98],
			[-18, 51],
			[38, 35],
			[66, -27],
			[11, -45],
			[-4, -73],
			[31, -78],
			[36, 27],
			[18, 57],
			[57, 56],
			[69, -66],
			[48, -20]
		],
		[
			[11569, 6572],
			[-3, -58],
			[-25, -45],
			[-45, 9],
			[-102, -3],
			[-12, -83],
			[38, -60],
			[15, 41],
			[28, 9],
			[6, -56],
			[-25, -51],
			[25, -71],
			[-7, -33],
			[27, -63],
			[-54, -20],
			[5, 47],
			[-46, 64],
			[-2, -174],
			[-34, -7],
			[7, 98],
			[-29, 105],
			[18, 130],
			[16, 49],
			[13, 95],
			[33, 47],
			[23, -22],
			[87, -18],
			[43, 70]
		],
		[
			[10961, 5859],
			[-23, -8],
			[-69, 30],
			[-39, 42],
			[23, 65],
			[47, 3],
			[35, -21],
			[12, -39],
			[70, -15],
			[10, 38],
			[65, -31],
			[33, -69],
			[43, 0],
			[-3, -73],
			[-39, 34],
			[-25, -11],
			[-58, 12],
			[-47, 34],
			[-35, 9]
		],
		[
			[11682, 6605],
			[1, -64],
			[26, -21],
			[-37, -57],
			[-12, 57],
			[22, 85]
		],
		[
			[11741, 6230],
			[41, -27],
			[-7, -36],
			[-34, 25],
			[-43, -10],
			[-9, 43],
			[52, 5]
		],
		[
			[11302, 5830],
			[41, -43],
			[-76, -34],
			[-11, 34],
			[46, 43]
		],
		[
			[11488, 5833],
			[-1, -41],
			[-70, -27],
			[-14, 53],
			[70, -26],
			[15, 41]
		],
		[
			[11567, 5765],
			[6, -37]
		],
		[
			[11573, 5728],
			[-29, -55],
			[-11, 63]
		],
		[
			[11533, 5736],
			[16, 13]
		],
		[
			[11549, 5749],
			[18, 16]
		],
		[
			[11930, 6021],
			[5, -11]
		],
		[
			[11935, 6010],
			[2, -2]
		],
		[
			[11937, 6008],
			[-7, -13],
			[-4, -8],
			[4, 34]
		],
		[
			[11937, 6008],
			[-2, 2]
		],
		[
			[11930, 6021],
			[7, -13]
		],
		[
			[6662, 10530],
			[-4, -12],
			[-10, 6],
			[7, 10],
			[7, -4]
		],
		[
			[9804, 8868],
			[11, -22]
		],
		[
			[9860, 8765],
			[11, -22]
		],
		[
			[9901, 8715],
			[-25, -36],
			[-9, -68],
			[100, -100],
			[50, 1],
			[67, -71],
			[83, -14],
			[3, 112]
		],
		[
			[10509, 8529],
			[-73, -85],
			[2, -40],
			[-38, -168],
			[-28, -13],
			[-31, -128]
		],
		[
			[10207, 8093],
			[-1, -2],
			[-2, -1],
			[-72, -30],
			[-3, -61],
			[-24, -56],
			[-58, -56],
			[-30, -70],
			[-104, -148],
			[-15, -45],
			[-27, -6],
			[-7, -42],
			[11, -134],
			[-22, -127],
			[4, -101],
			[-18, 3],
			[-17, -79],
			[-27, -23],
			[-6, -45],
			[-28, -19],
			[-29, 59],
			[-33, 192],
			[-19, 42],
			[-23, 145],
			[-42, 151],
			[-20, 165],
			[-11, 129],
			[10, 71],
			[-32, 31],
			[-56, -32],
			[-53, 89],
			[-1, 68],
			[-25, 31],
			[-8, 50]
		],
		[
			[9419, 8242],
			[17, 27],
			[47, -9],
			[41, 40],
			[-38, 96],
			[3, 44],
			[-22, 47],
			[30, 63],
			[15, -22],
			[44, 18],
			[39, 81],
			[16, 68],
			[41, 77],
			[2, 58],
			[31, 44]
		],
		[
			[6591, 10514],
			[9, -85],
			[-12, -55],
			[-116, -57],
			[-34, 33],
			[38, 94],
			[-26, 53],
			[42, 29],
			[21, 68],
			[40, -6]
		],
		[
			[8773, 9356],
			[26, -60],
			[58, -45],
			[28, 0],
			[46, 57],
			[67, 14],
			[10, -26],
			[71, -28],
			[38, -70],
			[30, 1],
			[4, -78]
		],
		[
			[9136, 8689],
			[36, -92],
			[37, -28],
			[0, -76],
			[15, -46],
			[-49, -30],
			[-10, -80]
		],
		[
			[9165, 8337],
			[-164, 47],
			[-13, 90],
			[-32, 11],
			[-48, -47],
			[-38, 14],
			[-53, 70],
			[-40, 22],
			[-18, 86],
			[-38, 85],
			[-55, -17]
		],
		[
			[8666, 8698],
			[-30, 77],
			[7, 59],
			[-17, 52],
			[-34, 33],
			[-40, 82],
			[8, 61],
			[20, 28],
			[-31, 68],
			[-23, 79]
		],
		[
			[8526, 9237],
			[-28, 170],
			[29, 18]
		],
		[
			[8666, 8698],
			[-19, 2]
		],
		[
			[8647, 8700],
			[-31, -1],
			[-22, -67]
		],
		[
			[8594, 8632],
			[-70, 7],
			[-101, 143],
			[-62, 64],
			[-47, 15]
		],
		[
			[8314, 8861],
			[-15, 93]
		],
		[
			[8299, 8954],
			[85, 78],
			[15, 158],
			[36, 44]
		],
		[
			[8435, 9234],
			[16, 22],
			[75, -19]
		],
		[
			[6214, 11450],
			[95, -73],
			[6, -37],
			[-3, -5],
			[-55, -53],
			[-112, -58],
			[-84, 1],
			[-71, 59],
			[-17, 37],
			[-28, 1],
			[2, 14],
			[11, -2],
			[6, 4],
			[31, 27],
			[-90, 18],
			[67, 63],
			[48, -41],
			[181, 19],
			[13, 26]
		],
		[
			[8179, 8944],
			[1, -42]
		],
		[
			[8180, 8902],
			[-4, -23]
		],
		[
			[8174, 8811],
			[-20, -146]
		],
		[
			[8154, 8665],
			[-3, -4]
		],
		[
			[8137, 8819],
			[23, 114]
		],
		[
			[8160, 8933],
			[19, 11]
		],
		[
			[7347, 9945],
			[2, -71]
		],
		[
			[7349, 9874],
			[-24, 13],
			[-37, -38],
			[21, -95],
			[36, -34],
			[13, -64],
			[75, -93],
			[78, -62],
			[-42, -15],
			[-26, -117],
			[-17, 76],
			[-39, 59],
			[-81, 58],
			[-77, 113],
			[-13, 68],
			[-56, 43],
			[-48, -48]
		],
		[
			[7298, 9747],
			[3, 0],
			[1, 7],
			[-4, -4],
			[0, -3]
		],
		[
			[7418, 9324],
			[-19, -123],
			[-50, 36],
			[0, 63],
			[69, 24]
		],
		[
			[7178, 9547],
			[22, -55],
			[-10, -103],
			[-45, 7],
			[4, 78],
			[-14, 34],
			[43, 39]
		],
		[
			[3876, 7835],
			[30, -8],
			[33, -38],
			[-61, -5],
			[-2, 51]
		],
		[
			[8314, 8861],
			[-83, -48],
			[38, -76],
			[-48, -47],
			[-26, -53],
			[-42, 13]
		],
		[
			[8153, 8650],
			[1, 15]
		],
		[
			[8180, 8902],
			[45, -28],
			[74, 80]
		],
		[
			[12176, 9564],
			[15, -25],
			[25, -125],
			[-55, -227],
			[-8, -96],
			[-48, 1],
			[-35, -45],
			[-51, 17],
			[-42, -102],
			[-26, 30],
			[-14, 68],
			[-78, -45],
			[-46, 17],
			[64, 89],
			[100, -8],
			[32, 86],
			[74, 55],
			[28, 49],
			[30, 127],
			[10, 117],
			[25, 17]
		],
		[
			[12212, 9869],
			[53, -89],
			[54, -31],
			[12, -67],
			[-33, -4],
			[-48, -66],
			[-52, 38],
			[-44, -71],
			[-22, 74],
			[61, 50],
			[19, 166]
		],
		[
			[11790, 8994],
			[35, -28],
			[10, -54],
			[-26, -108],
			[-44, 45],
			[19, 49],
			[-39, 23],
			[-2, 33],
			[47, 40]
		],
		[
			[11917, 9027],
			[5, -59],
			[-73, -18],
			[15, 51],
			[53, 26]
		],
		[
			[9871, 9620],
			[-41, 40],
			[-87, 18],
			[-62, -12],
			[-29, 26],
			[-39, -51],
			[-60, 25],
			[-34, -43]
		],
		[
			[9519, 9623],
			[-73, -69],
			[-91, -7],
			[-23, 52],
			[2, 76],
			[-41, 62],
			[-113, -21],
			[-34, 57],
			[-96, 101],
			[-98, -44],
			[0, -278]
		],
		[
			[8952, 9552],
			[-28, 7],
			[-40, 70],
			[-48, -18],
			[-65, -65]
		],
		[
			[8716, 9657],
			[-41, 154],
			[50, 83],
			[-75, 143],
			[-33, 0],
			[-25, 51],
			[20, 58],
			[-5, 48],
			[132, 112],
			[7, 24],
			[106, -10],
			[88, -71],
			[39, 38],
			[117, -37],
			[62, 16],
			[-52, 89],
			[39, 27],
			[8, 131],
			[49, -6],
			[213, 73],
			[27, 35],
			[72, -13],
			[15, -66],
			[67, -22],
			[48, -38],
			[51, 38],
			[38, -9],
			[63, -77],
			[67, -159],
			[24, 35],
			[36, -42],
			[85, 9],
			[51, -94],
			[61, -5],
			[20, -34]
		],
		[
			[8418, 6740],
			[-35, -88],
			[0, -274],
			[21, -63]
		],
		[
			[8404, 6315],
			[-53, -84],
			[-1, -40],
			[-36, -103]
		],
		[
			[8314, 6088],
			[-53, 76],
			[-4, 45],
			[-143, 156]
		],
		[
			[8114, 6365],
			[-1, 84],
			[43, 118],
			[-22, 149],
			[-17, 41]
		],
		[
			[8117, 6757],
			[14, 31]
		],
		[
			[8175, 6788],
			[16, 0]
		],
		[
			[9621, 9413],
			[-72, -11],
			[-10, 21],
			[-82, -5],
			[7, 40],
			[56, 12]
		],
		[
			[9520, 9470],
			[27, -6],
			[19, 75],
			[-54, 3],
			[-22, 31],
			[29, 50]
		],
		[
			[10789, 7225],
			[-51, 38],
			[-7, 54]
		],
		[
			[10731, 7317],
			[-20, 145],
			[43, 64],
			[63, -6]
		],
		[
			[10817, 7520],
			[23, -19],
			[29, 39],
			[38, 6]
		],
		[
			[10907, 7546],
			[0, -176],
			[-67, -55],
			[0, -44],
			[-51, -46]
		],
		[
			[844, 6594],
			[4, -3],
			[0, -12],
			[-3, -4],
			[-1, 19]
		],
		[
			[4445, 7752],
			[4, -6],
			[-2, -4],
			[-2, 10]
		],
		[
			[11697, 9349],
			[37, -105],
			[9, -91],
			[-25, -74],
			[-91, -27],
			[-4, 170],
			[9, 68]
		],
		[
			[11632, 9290],
			[65, 59]
		],
		[
			[8665, 8589],
			[-37, 36],
			[-34, 7]
		],
		[
			[8647, 8700],
			[18, -111]
		],
		[
			[10702, 8127],
			[37, -114],
			[52, -9],
			[20, -50],
			[-36, -64],
			[60, -95],
			[34, -75],
			[6, -42],
			[27, -39],
			[5, -93]
		],
		[
			[10817, 7520],
			[18, 99],
			[-34, 66],
			[1, 65],
			[-31, 71],
			[-36, -28],
			[-35, 18],
			[-32, -45],
			[-8, 64],
			[6, 68],
			[-30, 30],
			[-12, 44]
		],
		[
			[10624, 7972],
			[40, 92]
		],
		[
			[8160, 8933],
			[33, 116]
		],
		[
			[8193, 9049],
			[24, -34],
			[-38, -71]
		],
		[
			[6542, 6768],
			[-68, 59],
			[-35, 70],
			[-47, 65]
		],
		[
			[6392, 6962],
			[33, 64],
			[13, 54]
		],
		[
			[7776, 8096],
			[0, -150],
			[-38, 0],
			[0, -38]
		],
		[
			[7738, 7908],
			[-303, 297],
			[-39, -33]
		],
		[
			[7396, 8172],
			[-29, -27],
			[-31, 44],
			[-53, 22]
		],
		[
			[7190, 8718],
			[29, 50],
			[-2, 59],
			[48, 53],
			[2, 58]
		],
		[
			[7267, 8938],
			[45, -29],
			[23, 10],
			[70, -40],
			[16, -69],
			[97, -51],
			[18, -29],
			[35, 0],
			[22, 58],
			[-10, 58],
			[17, 36],
			[50, 39],
			[54, -23],
			[7, -32],
			[65, -20],
			[6, -23]
		],
		[
			[4518, 7497],
			[1, -13],
			[-3, -12],
			[-5, 15],
			[7, 10]
		],
		[
			[9870, 7180],
			[23, -39],
			[34, -118],
			[0, -95],
			[-48, -40],
			[-23, 74],
			[-2, 78],
			[16, 140]
		],
		[
			[7866, 4156],
			[-10, 43],
			[24, 60],
			[34, 29],
			[31, -59],
			[-52, -98],
			[-27, 25]
		],
		[
			[7718, 10504],
			[-26, 30]
		],
		[
			[7692, 10534],
			[4, 34],
			[-62, 32]
		],
		[
			[7634, 10600],
			[-7, 62]
		],
		[
			[7627, 10662],
			[36, 27],
			[138, -22],
			[36, -34]
		],
		[
			[7864, 10774],
			[31, -104]
		],
		[
			[7627, 10662],
			[23, 114],
			[34, 5],
			[37, -50],
			[30, 68]
		],
		[
			[11134, 8113],
			[2, -2],
			[-2, 0],
			[0, 1]
		],
		[
			[6329, 8524],
			[63, 51],
			[40, 56],
			[30, 80],
			[-8, 93],
			[22, 88],
			[37, 62],
			[60, 59],
			[30, 121],
			[59, -47],
			[26, 12],
			[56, -16]
		],
		[
			[7898, 9865],
			[-5, 115],
			[-56, 94]
		],
		[
			[7837, 10074],
			[44, 16],
			[54, -36],
			[21, -85],
			[-58, -104]
		],
		[
			[8695, 5540],
			[26, -82],
			[10, -116],
			[12, -57],
			[-25, -66],
			[-16, -83],
			[-3, -83],
			[-18, -86],
			[-36, -220],
			[-13, -99],
			[-22, -91],
			[-69, -46],
			[-44, 46],
			[-13, 50],
			[4, 67],
			[-21, 89],
			[12, 73],
			[34, 94],
			[-19, 192],
			[33, 95],
			[56, 39],
			[56, 83],
			[21, 73],
			[18, 21],
			[2, 72],
			[15, 35]
		],
		[
			[3143, 8394],
			[-21, -98],
			[-4, -184],
			[17, -47],
			[8, -69],
			[27, -60],
			[17, -72],
			[45, -29],
			[13, -29],
			[116, 58],
			[34, 72],
			[4, 81],
			[25, 26],
			[58, 25],
			[52, -15],
			[-1, -44],
			[-33, -93],
			[-1, -89],
			[-21, 6]
		],
		[
			[3328, 7534],
			[-35, 73],
			[-63, 70],
			[-19, -31],
			[-43, -28],
			[-50, 25],
			[-39, 45],
			[-36, 12],
			[-47, 40],
			[-31, 49],
			[-63, 30],
			[-17, 42],
			[-42, 38],
			[-24, 77],
			[17, 63],
			[-24, 110],
			[-43, 93],
			[-38, 53],
			[0, 28],
			[-53, 52],
			[0, 73],
			[-45, 59],
			[-42, 76],
			[-40, 111],
			[-11, 94],
			[-68, 47],
			[6, -120],
			[69, -134],
			[3, -43],
			[46, -86],
			[10, -70],
			[72, -186],
			[-35, 19],
			[-70, 97],
			[0, 85],
			[-87, 94],
			[16, 93],
			[-63, 97],
			[-14, 78],
			[-40, 131]
		],
		[
			[2385, 8890],
			[88, -3],
			[142, -88],
			[108, 0],
			[0, 35],
			[64, 0],
			[61, -89],
			[16, -73],
			[52, -50],
			[18, 57],
			[46, 4],
			[25, -40],
			[19, -74],
			[30, -58],
			[15, -81],
			[74, -36]
		],
		[
			[13323, 6971],
			[-1, 0],
			[-4, 4],
			[-3, 3],
			[0, 1],
			[5, -7],
			[3, -1]
		],
		[
			[7608, 9594],
			[69, 33]
		],
		[
			[6988, 7881],
			[0, -162],
			[-14, -97],
			[-13, -26],
			[-84, -7],
			[-41, -21]
		],
		[
			[6395, 7375],
			[-8, 73],
			[-18, 25],
			[-5, 78]
		],
		[
			[6364, 7551],
			[19, 58],
			[27, -21],
			[63, 19],
			[146, 0],
			[-16, 288],
			[-15, 255],
			[-10, 173],
			[66, 0]
		],
		[
			[7373, 9151],
			[7, -9],
			[-1, -5],
			[-7, 5],
			[1, 9]
		],
		[
			[10371, 7943],
			[-2, 7],
			[-32, 37],
			[-13, 45]
		],
		[
			[10624, 7972],
			[-77, -52],
			[-19, -87],
			[14, -72],
			[20, -38],
			[8, -57],
			[-13, -117],
			[33, -75],
			[0, -66],
			[17, -84],
			[-34, -102]
		],
		[
			[10573, 7222],
			[-6, 211],
			[-28, 117],
			[-9, 133],
			[-20, 55],
			[-34, -49],
			[-4, -17],
			[-30, -25],
			[-17, -12],
			[-1, 5],
			[-23, 8],
			[15, 119],
			[-15, 86],
			[-19, 19],
			[-11, 71]
		],
		[
			[7558, 9718],
			[40, -45],
			[-9, -28]
		],
		[
			[7563, 9592],
			[-33, 43]
		],
		[
			[10159, 10144],
			[152, 117],
			[94, -13],
			[13, -40],
			[96, -19],
			[36, 25],
			[-11, 67],
			[42, 87],
			[40, -30],
			[77, -28],
			[10, -60],
			[52, -34],
			[66, 26],
			[96, -47],
			[24, -39],
			[83, -14],
			[87, 34],
			[49, 51],
			[39, -28],
			[50, -5]
		],
		[
			[12359, 7589],
			[-3, -11],
			[-2, 1],
			[1, 8],
			[4, 2]
		],
		[
			[8075, 4417],
			[-29, 2]
		],
		[
			[8046, 4419],
			[-5, 67]
		],
		[
			[8041, 4486],
			[1, 111],
			[-27, 155]
		],
		[
			[8015, 4752],
			[41, 82],
			[26, 117],
			[-11, 31],
			[9, 66],
			[-2, 134],
			[-97, 82]
		],
		[
			[7981, 5264],
			[-7, 46],
			[115, 76]
		],
		[
			[8089, 5386],
			[22, -42],
			[21, 12],
			[7, -67],
			[-7, -73],
			[54, 17],
			[2, 102],
			[-49, 123],
			[-8, 63],
			[23, 48]
		],
		[
			[8154, 5569],
			[33, 12],
			[16, -24],
			[84, 22],
			[72, 70]
		],
		[
			[8359, 5649],
			[10, -169],
			[-4, -57],
			[12, -100],
			[-40, -115],
			[-27, -48],
			[-74, -62],
			[-33, -81],
			[-53, -72],
			[-8, -42],
			[18, -41],
			[18, -148],
			[-3, -89],
			[-15, -38],
			[-67, -51],
			[-29, -53],
			[11, -66]
		],
		[
			[6364, 7551],
			[-49, 104],
			[-32, 39],
			[-72, -9],
			[-10, -36]
		],
		[
			[6201, 7649],
			[18, 122],
			[-12, 133],
			[7, 58],
			[-33, 43]
		],
		[
			[4470, 7695],
			[-3, 4],
			[2, 8],
			[1, -7],
			[0, -5]
		],
		[
			[4512, 7557],
			[9, -28],
			[-10, 0],
			[1, 28]
		],
		[
			[9015, 4934],
			[5, -17],
			[-5, -20],
			[-12, 0],
			[2, 24],
			[10, 13]
		],
		[
			[8089, 5386],
			[-19, 31],
			[23, 77],
			[-1, 131],
			[15, 19],
			[-29, 88]
		],
		[
			[8078, 5732],
			[41, -7],
			[18, -36],
			[4, -85],
			[13, -35]
		],
		[
			[10987, 6596],
			[8, -26],
			[41, -16],
			[15, 63],
			[11, 38],
			[48, 18],
			[45, 112]
		],
		[
			[11190, 6808],
			[2, -7],
			[4, 9],
			[15, 49],
			[49, 91],
			[34, -26],
			[53, -99],
			[-59, -71]
		],
		[
			[10700, 6910],
			[38, -63],
			[15, -64],
			[-1, -124],
			[26, -72],
			[-18, -39]
		],
		[
			[10760, 6548],
			[-83, 95],
			[-29, 88],
			[-23, 193]
		],
		[
			[10625, 6924],
			[56, -38],
			[19, 24]
		],
		[
			[8538, 5488],
			[5, -9],
			[-3, -12],
			[-2, 21]
		],
		[
			[7716, 5112],
			[56, 9],
			[13, -21]
		],
		[
			[7586, 4576],
			[0, -277],
			[-34, -41],
			[-101, 26]
		],
		[
			[7451, 4284],
			[-44, 98],
			[-12, 75],
			[-3, 94],
			[-16, 77],
			[-3, 135],
			[-38, 111],
			[-7, 41],
			[-56, 183],
			[1, 42]
		],
		[
			[13049, 4927],
			[111, -150],
			[-6, -24],
			[-58, 62],
			[-34, 61],
			[-13, 51]
		],
		[
			[7396, 8172],
			[9, -114],
			[30, -86],
			[-10, -30],
			[-9, -230],
			[-43, -88],
			[-34, -93],
			[6, -58]
		],
		[
			[7345, 7473],
			[-45, -48],
			[-41, 22],
			[-92, -39],
			[-42, 37],
			[-34, -27],
			[-19, 50],
			[-35, 19],
			[-53, -32],
			[-18, -71],
			[-1, -62]
		],
		[
			[13202, 4257],
			[1, -3],
			[-1, -3],
			[-2, 2],
			[2, 4]
		],
		[
			[7345, 7473],
			[17, -48]
		],
		[
			[7150, 6796],
			[-9, -14],
			[-82, -20],
			[-16, 21],
			[-29, 107],
			[-21, 30],
			[-62, 0]
		],
		[
			[3674, 7570],
			[-17, -130],
			[-4, -176]
		],
		[
			[3577, 7274],
			[-32, 53],
			[-30, 91]
		],
		[
			[385, 4999],
			[-4, 4],
			[3, 9],
			[4, -8],
			[-3, -5]
		],
		[
			[6990, 10309],
			[-12, 17],
			[29, 102],
			[74, 38],
			[20, -16]
		],
		[
			[6955, 10309],
			[33, -1]
		],
		[
			[7997, 11696],
			[-56, -36],
			[-4, -14],
			[-12, -5]
		],
		[
			[7607, 11641],
			[-145, -85],
			[-12, -67],
			[-71, -67],
			[0, -64],
			[-7, -14],
			[-10, -12],
			[-17, -28],
			[-63, -98],
			[19, -243],
			[-48, -72]
		],
		[
			[7253, 10891],
			[-26, 16],
			[-90, -91],
			[-60, 2],
			[-41, 47],
			[26, 103],
			[-25, 23],
			[8, 20],
			[-21, 98],
			[154, 110],
			[17, 30],
			[59, 73],
			[13, 10],
			[11, 12],
			[9, 11],
			[44, 112],
			[123, 131],
			[42, 30],
			[-5, 9],
			[12, 9],
			[51, 47],
			[144, 34],
			[172, 67],
			[136, -60],
			[-52, -41],
			[43, 3]
		],
		[
			[13160, 6399],
			[-2, 0],
			[2, 4],
			[0, -4]
		],
		[
			[13151, 2995],
			[4, 36],
			[59, 95],
			[47, 30],
			[59, 84],
			[40, 129],
			[52, -21],
			[26, -52],
			[-53, -102],
			[-19, -57],
			[-40, -32],
			[-19, -90],
			[-39, -73],
			[-51, -12],
			[-67, 30],
			[1, 35]
		],
		[
			[13390, 3848],
			[6, -33],
			[42, -28],
			[20, -123],
			[69, -74],
			[65, -44],
			[-49, -58],
			[-1, -40],
			[-36, -106],
			[-24, -33],
			[-28, 26],
			[21, 71],
			[-57, 76],
			[33, 40],
			[9, 71],
			[-17, 73],
			[-47, 116],
			[-6, 66]
		],
		[
			[8966, 8321],
			[31, -79],
			[53, -23],
			[46, -106],
			[-51, -141],
			[-29, -46],
			[6, -54],
			[-39, -17],
			[-17, -66],
			[-36, -8],
			[-12, -57],
			[-41, -5],
			[-34, -25]
		],
		[
			[8843, 7694],
			[-43, 178]
		],
		[
			[8800, 7872],
			[114, 74],
			[26, 150],
			[-18, 54]
		],
		[
			[8955, 8402],
			[6, -32]
		],
		[
			[9419, 8242],
			[-32, -2],
			[-5, 66],
			[-33, 52],
			[-113, -20],
			[-71, -1]
		],
		[
			[3873, 6984],
			[-27, 113],
			[-43, 9],
			[-28, -49],
			[1, -71],
			[-74, 82],
			[-19, -23]
		],
		[
			[3696, 7161],
			[12, -42],
			[34, -18],
			[65, 64],
			[61, -29],
			[25, -43]
		],
		[
			[1961, 4608],
			[0, -6],
			[-2, 1],
			[0, 3],
			[2, 2]
		],
		[
			[4159, 5058],
			[-39, 72],
			[-41, 52],
			[-53, 43],
			[-49, 56],
			[-29, 55],
			[-11, 79],
			[-38, 148],
			[-16, 24],
			[-33, 161],
			[-56, 184],
			[-43, 50],
			[-5, 137],
			[38, 62]
		],
		[
			[11411, 7844],
			[40, -29],
			[24, -87],
			[-12, -65],
			[-31, -64],
			[13, -93],
			[29, 14],
			[55, -42],
			[-29, -26],
			[-23, 38],
			[-49, -26],
			[-45, 89],
			[-7, 79],
			[19, 27],
			[1, 117],
			[15, 68]
		],
		[
			[11586, 7178],
			[33, -66],
			[8, -129],
			[-44, -30],
			[13, -45],
			[-11, -48],
			[-48, 48],
			[-5, 108],
			[-44, -12],
			[8, 77],
			[41, -25],
			[27, 63],
			[25, 0],
			[-3, 59]
		],
		[
			[11502, 7268],
			[-5, -147],
			[-27, 50],
			[32, 97]
		],
		[
			[11567, 7388],
			[14, -9],
			[12, -85],
			[-49, 72],
			[23, 22]
		],
		[
			[11454, 7339],
			[42, -53],
			[-43, -59],
			[1, 112]
		],
		[
			[11574, 7205],
			[-28, 72],
			[23, 20],
			[5, -92]
		],
		[
			[11935, 7014],
			[0, -10],
			[-6, -3],
			[1, 11],
			[5, 2]
		],
		[
			[12176, 6243],
			[57, -47],
			[77, -43],
			[48, -80],
			[-3, -41],
			[69, -47],
			[-22, -53],
			[6, -48],
			[39, -51],
			[4, -41],
			[62, -108],
			[-6, -22],
			[-80, 26],
			[-58, 142],
			[-75, 24],
			[-56, -117],
			[-62, 16]
		],
		[
			[12601, 6124],
			[-3, -83],
			[-64, -72],
			[-52, 8],
			[-4, 52],
			[74, -2],
			[22, 96],
			[27, 1]
		],
		[
			[7578, 10541],
			[114, -7]
		],
		[
			[7724, 10318],
			[15, -78],
			[-52, -71],
			[-4, -32]
		],
		[
			[7683, 10137],
			[-141, 34]
		],
		[
			[7369, 10484],
			[20, 25],
			[133, 62],
			[56, -30]
		],
		[
			[4283, 7835],
			[42, -4],
			[-7, -40],
			[-39, 7],
			[4, 37]
		],
		[
			[11783, 9635],
			[1, -11]
		],
		[
			[11784, 9624],
			[-34, -39],
			[0, -62],
			[-91, -118],
			[38, -56]
		],
		[
			[11632, 9290],
			[-62, 5],
			[-7, 32],
			[22, 74],
			[-40, 52]
		],
		[
			[6544, 9257],
			[1, -11]
		],
		[
			[6545, 9245],
			[-44, -8],
			[-6, 80],
			[-27, 38],
			[28, 146],
			[-3, 96]
		],
		[
			[5851, 9294],
			[8, -7],
			[8, 4],
			[6, -6],
			[-13, -6],
			[-12, 8],
			[3, 7]
		],
		[
			[1158, 5122],
			[6, -18],
			[-10, 0],
			[4, 18]
		],
		[
			[8773, 8286],
			[-18, 17]
		],
		[
			[8755, 8303],
			[9, 98],
			[21, -19],
			[-12, -96]
		],
		[
			[8931, 4869],
			[15, -23],
			[-19, -8],
			[4, 31]
		],
		[
			[7898, 9865],
			[56, -20]
		],
		[
			[7954, 9845],
			[-40, -68],
			[-3, -43]
		],
		[
			[7687, 9771],
			[-62, 82],
			[-29, 60]
		],
		[
			[7697, 10052],
			[75, -17],
			[65, 39]
		],
		[
			[0, 11338],
			[13650, -3],
			[-89, -44],
			[44, -32],
			[37, -98],
			[-18, -29],
			[-78, 18],
			[-118, -54],
			[-75, -74],
			[-79, -58],
			[-60, 41],
			[-52, -23],
			[-134, -34],
			[-5, -43],
			[-51, -86],
			[50, -32],
			[-59, -163],
			[15, -53],
			[-82, -55],
			[0, -48],
			[-59, -36],
			[4, -56],
			[-24, -47],
			[-52, -26],
			[-37, 294],
			[17, 112],
			[55, 84],
			[86, 90],
			[160, 167],
			[16, 111],
			[-39, -11],
			[-34, -66],
			[-66, -69],
			[-58, 88],
			[-65, -11],
			[-100, -111],
			[-29, -94],
			[-49, -11],
			[-54, 50],
			[-63, 15],
			[-48, -29],
			[-196, -2],
			[-95, -79],
			[-9, -29],
			[-101, -127],
			[-101, -103],
			[61, -20],
			[-2, -61],
			[69, 1],
			[47, 32],
			[60, -74],
			[-36, -191],
			[5, -86],
			[-14, -85],
			[-34, -47],
			[-44, -119],
			[-94, -155],
			[-19, -50],
			[-75, -61],
			[-54, 47],
			[-41, -79]
		],
		[
			[8346, 9708],
			[-47, 67],
			[-50, 32],
			[5, 71],
			[48, 95],
			[-25, 16]
		],
		[
			[8277, 9989],
			[25, 56],
			[33, -2],
			[12, 123],
			[-100, 71],
			[-68, -2],
			[-19, 62],
			[-49, 87],
			[-77, -20]
		],
		[
			[7892, 10919],
			[41, 51],
			[-50, 30]
		],
		[
			[7997, 11696],
			[47, 11],
			[27, -36],
			[90, -21],
			[15, 3],
			[27, -10],
			[178, -104],
			[9, -67],
			[-46, -43],
			[-80, -12],
			[-89, 22],
			[-36, -24],
			[-7, -52],
			[10, -15],
			[2, -7],
			[3, -1],
			[-2, -6],
			[2, -35],
			[102, -56],
			[23, 17],
			[-61, 64],
			[63, -1],
			[91, -23],
			[-3, 18],
			[-6, 17],
			[-7, 5],
			[-9, 43],
			[148, 118],
			[17, 81],
			[48, 17],
			[46, -50],
			[-66, -29],
			[41, -48],
			[65, 14],
			[4, 44],
			[209, 98],
			[19, -52],
			[130, 25],
			[56, 33],
			[44, -39],
			[40, 35],
			[0, 12],
			[-6, 6],
			[-7, -2],
			[0, 9],
			[-18, 24],
			[107, 10],
			[53, -17],
			[22, -17],
			[12, -4],
			[15, -6],
			[37, -17],
			[71, -47],
			[22, 42],
			[-23, 11],
			[-4, 7],
			[-11, 13],
			[-46, 55],
			[2, 98],
			[59, 43],
			[42, 77],
			[115, -9],
			[-30, -94],
			[37, -46],
			[-6, -77],
			[2, -30],
			[-9, -18],
			[0, -10],
			[3, -13],
			[40, -32],
			[-52, -102],
			[94, 39],
			[-15, 54],
			[11, 30],
			[-28, 24],
			[-3, 10],
			[-10, 41],
			[30, 61],
			[-51, 62],
			[246, 72],
			[164, -142],
			[-5, 87],
			[-104, 59],
			[-13, 86],
			[250, 25],
			[-24, 45],
			[103, 75],
			[175, 47],
			[130, -11],
			[259, 134],
			[111, -70],
			[172, -8],
			[71, -38],
			[9, -67],
			[-119, -98],
			[113, -37],
			[68, 15],
			[122, -39],
			[182, -20],
			[43, 63],
			[192, -56],
			[-36, -84],
			[99, -89],
			[89, 56],
			[152, -9],
			[93, 14],
			[19, 71],
			[137, 15],
			[222, -50],
			[98, -89],
			[166, 20],
			[68, -12],
			[52, -43],
			[-15, -37],
			[48, -18],
			[228, -12],
			[30, 22],
			[21, -17],
			[-3, -19],
			[5, -5],
			[48, -34],
			[55, 22],
			[-7, 9],
			[1, 8],
			[-13, 24],
			[16, 37],
			[85, -19],
			[109, 2],
			[87, -31],
			[38, -17],
			[-13633, -18]
		],
		[
			[0, 11636],
			[0, -298]
		],
		[
			[0, 11636],
			[180, -101],
			[44, -43],
			[90, -9],
			[57, -51],
			[-15, -51],
			[-59, -13],
			[2, -29],
			[-11, -10],
			[-28, -50],
			[-103, 56],
			[3, 4],
			[-41, 42],
			[-91, -5],
			[-28, -38]
		],
		[
			[9397, 12239],
			[-26, -70],
			[-191, -47],
			[-103, -58],
			[-99, -110],
			[-106, 42],
			[77, 105],
			[196, 82],
			[113, 3],
			[139, 53]
		],
		[
			[12243, 10533],
			[21, -114],
			[-7, -38],
			[43, -231],
			[-50, -12],
			[-15, -107],
			[23, -81],
			[-50, 0],
			[13, 102],
			[2, 240],
			[-24, 89],
			[6, 79],
			[38, 73]
		],
		[
			[8921, 11966],
			[51, -21],
			[-45, -77],
			[76, -111],
			[-126, 9],
			[-92, 62],
			[59, 120],
			[77, 18]
		],
		[
			[12104, 12174],
			[188, -25],
			[26, -50],
			[-216, -37],
			[-79, 45],
			[81, 67]
		],
		[
			[10516, 12477],
			[105, -31],
			[-17, -71],
			[-177, 19],
			[-56, 39],
			[145, 44]
		],
		[
			[10715, 12421],
			[90, -84],
			[-180, 0],
			[90, 84]
		],
		[
			[10468, 12558],
			[50, -76],
			[-173, -7],
			[18, 61],
			[105, 22]
		],
		[
			[7578, 10541],
			[8, 39],
			[48, 20]
		],
		[
			[7984, 6361],
			[16, -77],
			[-13, -25]
		],
		[
			[7950, 6336],
			[34, 25]
		],
		[
			[8665, 8589],
			[16, -70],
			[43, -59],
			[0, -53],
			[31, -104]
		],
		[
			[8773, 8286],
			[11, -19]
		],
		[
			[8800, 7872],
			[-109, -30],
			[-35, -34],
			[-28, -79],
			[-18, -13],
			[-68, 37],
			[-73, 4],
			[-20, -81]
		],
		[
			[8449, 7676],
			[-16, 77],
			[-23, 38],
			[-29, 117],
			[-63, 109],
			[-12, 145],
			[-19, 68],
			[-30, 40],
			[-37, 130],
			[-63, 158],
			[-4, 92]
		],
		[
			[6194, 7369],
			[-2, 53]
		],
		[
			[6199, 7464],
			[-29, 86],
			[31, 99]
		],
		[
			[10760, 6548],
			[5, 2],
			[6, -6],
			[-6, -9],
			[-5, 13]
		],
		[
			[5397, 2375],
			[36, -9],
			[-2, -28],
			[-34, 37]
		],
		[
			[6611, 5242],
			[3, -4],
			[-4, -5],
			[-1, 3],
			[2, 6]
		],
		[
			[7445, 12471],
			[199, -94],
			[-98, -27],
			[-62, -112],
			[-127, 33],
			[-125, 159],
			[111, -4],
			[102, 45]
		],
		[
			[7698, 12498],
			[158, -40],
			[-76, -43],
			[-165, 2],
			[-93, 35],
			[176, 46]
		],
		[
			[12762, 5943],
			[24, -27],
			[-6, -20],
			[-18, 47]
		],
		[
			[6392, 6962],
			[-35, 33],
			[-34, 79],
			[0, 46]
		],
		[
			[3496, 7450],
			[-24, -20],
			[-64, 44]
		],
		[
			[8469, 7303],
			[43, -81],
			[53, 37],
			[25, -15],
			[36, 37],
			[28, -3],
			[73, 28],
			[27, 36],
			[13, -98],
			[-12, -98],
			[-61, -199],
			[-9, -52],
			[-39, -121],
			[-78, -158],
			[-49, -55],
			[-79, -149],
			[-36, -97]
		],
		[
			[4688, 9986],
			[6, -1],
			[-4, -14],
			[-2, 15]
		],
		[
			[7081, 6472],
			[4, -9],
			[-9, -21],
			[-2, 22],
			[7, 8]
		],
		[
			[4661, 6880],
			[55, 10],
			[55, -7],
			[8, -19]
		],
		[
			[7683, 10137],
			[-16, -52]
		],
		[
			[7343, 9865],
			[6, 9]
		],
		[
			[7743, 11397],
			[-56, -3],
			[-26, -28],
			[-16, 0],
			[-1, -30],
			[-4, 0],
			[-6, -6],
			[-4, -11],
			[16, -26],
			[-30, -43],
			[-106, -88],
			[-35, -87],
			[3, -47],
			[70, -79],
			[-38, -69],
			[-40, -26],
			[-14, -115],
			[-27, -75],
			[-37, 5],
			[-27, -58],
			[-45, 1],
			[-23, 114],
			[-38, 110],
			[-6, 55]
		],
		[
			[8046, 4419],
			[-37, -28],
			[-13, 49],
			[23, 63],
			[22, -17]
		],
		[
			[8592, 5735],
			[-2, -5],
			[-3, 1],
			[-5, -4],
			[0, 3],
			[5, 1],
			[5, 4]
		],
		[
			[8193, 9049],
			[-3, 97]
		],
		[
			[8190, 9146],
			[31, 68],
			[55, 5],
			[40, -18],
			[58, 35],
			[61, -2]
		],
		[
			[4086, 8085],
			[3, -4],
			[-3, -3],
			[0, 7]
		],
		[
			[7738, 7908],
			[0, -284],
			[-33, 0],
			[-40, -146],
			[4, -77],
			[17, -54],
			[10, -84]
		],
		[
			[6890, 6908],
			[-17, -7]
		],
		[
			[10731, 7317],
			[-43, 80],
			[-36, 3],
			[-21, 57],
			[-9, -99],
			[-34, -139],
			[8, -87],
			[22, -6],
			[31, -159],
			[30, -12],
			[21, -45]
		],
		[
			[10625, 6924],
			[-40, 111],
			[-27, 21],
			[-4, 63],
			[19, 103]
		],
		[
			[9399, 9240],
			[23, 77],
			[-37, 98],
			[41, 3],
			[27, 76],
			[51, 34],
			[16, -58]
		],
		[
			[310, 5747],
			[-1, 0],
			[0, 2],
			[1, -2]
		],
		[
			[8952, 9552],
			[39, -5],
			[-1, 49],
			[49, 60],
			[57, -29],
			[25, -82],
			[44, 2],
			[33, -98],
			[106, -116],
			[35, -20],
			[13, -60]
		],
		[
			[11567, 5765],
			[7, 25],
			[55, 11],
			[-56, -73]
		],
		[
			[11533, 5736],
			[16, 13]
		],
		[
			[179, 4849],
			[4, -4],
			[1, 4],
			[3, 0],
			[-3, -11],
			[-5, 11]
		],
		[
			[4513, 7256],
			[0, -52],
			[-16, 12],
			[16, 40]
		],
		[
			[7156, 9223],
			[39, 29],
			[25, -34],
			[11, -80],
			[19, -44],
			[-41, -96],
			[58, -60]
		],
		[
			[8190, 9146],
			[-51, 64],
			[-34, -47],
			[-32, -10],
			[-69, 63],
			[-48, -53],
			[-87, 83],
			[-26, 71],
			[10, 50],
			[-35, 74],
			[23, 42],
			[80, 0],
			[12, 62],
			[79, -10],
			[81, 69],
			[98, -20],
			[18, -37],
			[74, -26],
			[67, 0],
			[53, 47]
		],
		[
			[7890, 9602],
			[14, -69],
			[-63, -45],
			[-25, 21]
		],
		[
			[13605, 5879],
			[1, -3],
			[-1, 0],
			[0, 3]
		],
		[
			[11439, 8345],
			[14, -13],
			[-24, -161],
			[-26, -51],
			[-21, 58],
			[2, 40],
			[34, 107],
			[21, 20]
		],
		[
			[8314, 6088],
			[-15, -105],
			[29, -79],
			[-10, -95],
			[19, -116],
			[22, -44]
		],
		[
			[8078, 5732],
			[-37, 25],
			[-47, 67]
		],
		[
			[7984, 6361],
			[130, 4]
		],
		[
			[7997, 6703],
			[101, 20],
			[19, 34]
		],
		[
			[8277, 9989],
			[-94, -41],
			[-36, -31],
			[28, -67],
			[-59, -66],
			[-16, 53],
			[-37, 27],
			[-22, 90],
			[-48, -11],
			[-39, -98]
		],
		[
			[4803, 3899],
			[-17, -50],
			[-42, -42],
			[-50, 4],
			[-33, 34],
			[-29, -2],
			[-20, 42],
			[11, 67]
		],
		[
			[3971, 9818],
			[25, 12]
		],
		[
			[4283, 9835],
			[4, -21],
			[-85, -64],
			[-39, -19],
			[-22, -60],
			[16, -96],
			[-96, -27],
			[-27, -20],
			[-19, -98],
			[-28, -40],
			[-23, -100],
			[-28, 4],
			[19, -171],
			[-28, -15],
			[-16, -52],
			[-45, -63],
			[-27, -9],
			[-15, -46],
			[-49, -58],
			[-36, -96],
			[7, -102],
			[27, -98],
			[19, -126],
			[-16, -121],
			[-25, -5],
			[-9, 52],
			[-36, 76],
			[-22, 79],
			[9, 74],
			[-39, 78],
			[-23, 16],
			[-39, -32],
			[-9, 33],
			[-61, 29],
			[-72, -9],
			[-30, -29],
			[-33, -65],
			[-34, 45],
			[-56, 2],
			[-68, -25],
			[-15, -37],
			[-68, -70],
			[-19, -76],
			[16, -79]
		],
		[
			[2385, 8890],
			[-9, 48],
			[-45, 65],
			[-74, 32],
			[-15, 60],
			[-39, 90],
			[-47, 152],
			[-22, 34],
			[-4, 69],
			[-20, 33],
			[12, 87],
			[-19, 106],
			[24, 184],
			[-2, 123],
			[-29, 94],
			[65, -6],
			[20, 24],
			[0, 3],
			[-10, 42]
		],
		[
			[3431, 10054],
			[-44, -27],
			[-18, -59],
			[29, -21],
			[68, 50],
			[49, -55],
			[71, 13],
			[30, -47],
			[-90, -22],
			[-11, -72],
			[-22, -134],
			[15, -99],
			[32, 9],
			[16, 95],
			[-11, 42],
			[11, 79],
			[55, 81],
			[50, -34],
			[8, -101],
			[24, -10],
			[6, -69]
		],
		[
			[3699, 9673],
			[0, -11]
		],
		[
			[3699, 9662],
			[-22, -36]
		],
		[
			[3677, 9626],
			[-14, -37],
			[35, -33],
			[84, 50],
			[105, 90],
			[49, 24],
			[-6, 41],
			[24, 27],
			[17, 30]
		],
		[
			[1826, 10708],
			[-22, 0],
			[-78, 127],
			[-84, -9],
			[-58, 61],
			[-176, 79],
			[-38, -7],
			[-118, 70],
			[-37, -2],
			[-112, -125],
			[-37, 39],
			[-84, -34],
			[30, -35],
			[-40, -56],
			[-75, -52],
			[-19, -39],
			[-56, -32],
			[-6, -35],
			[-55, -14],
			[-86, -59],
			[-2, 50],
			[57, 14],
			[115, 130],
			[10, 66],
			[-51, 30],
			[-94, -9],
			[-35, 95],
			[-47, -17],
			[-73, 53],
			[15, 28],
			[-44, 56],
			[64, 120],
			[53, -11],
			[87, 52],
			[-14, 88],
			[-54, -30],
			[-94, -7],
			[-52, 14],
			[-3, 22],
			[-7, 6],
			[-8, 8],
			[11, 1],
			[-55, 34],
			[139, 76],
			[83, 9],
			[-157, 132],
			[6, 25],
			[76, 13],
			[18, 9],
			[17, 18],
			[37, 70],
			[208, 80],
			[161, -57],
			[101, -2],
			[175, -40],
			[78, 10],
			[83, -36]
		],
		[
			[1895, 10650],
			[-73, 4],
			[4, 52]
		],
		[
			[1013, 10808],
			[37, -43],
			[-76, -39],
			[-13, 46],
			[52, 36]
		],
		[
			[1774, 10603],
			[-2, 66],
			[2, -66]
		],
		[
			[918, 7966],
			[38, -57],
			[-33, -44],
			[-15, 64],
			[10, 37]
		],
		[
			[4507, 7448],
			[1, -18],
			[-5, 4],
			[4, 14]
		],
		[
			[4122, 7332],
			[-24, -33],
			[14, -52],
			[-20, -68],
			[30, -55],
			[12, 49],
			[-20, 62],
			[5, 31],
			[68, 37],
			[30, -1],
			[25, -71],
			[74, 11],
			[43, -46],
			[49, 47],
			[33, 3],
			[19, -55],
			[59, -38],
			[5, -66],
			[28, -2]
		],
		[
			[4378, 7830],
			[0, -3],
			[-4, -2],
			[1, 5],
			[3, 0]
		],
		[
			[4371, 7779],
			[-3, -7],
			[-2, 6],
			[5, 1]
		],
		[
			[10925, 8063],
			[-48, -46],
			[-6, -49],
			[-25, -28],
			[-12, -66],
			[34, -103],
			[53, -99],
			[34, -73],
			[25, -187],
			[-18, -115],
			[-71, -76],
			[-23, -59],
			[-66, -52],
			[3, 63],
			[-16, 52]
		],
		[
			[13147, 5336],
			[16, -22],
			[7, -40],
			[-23, 7],
			[0, 55]
		],
		[
			[71, 5364],
			[-1, -3],
			[-2, 5],
			[2, 0],
			[1, -2]
		],
		[
			[289, 5429],
			[-5, -29],
			[-11, 23],
			[16, 6]
		],
		[
			[8227, 8098],
			[-107, 0]
		],
		[
			[8179, 8183],
			[48, -85]
		],
		[
			[8120, 8098],
			[-19, -21],
			[-15, 21]
		],
		[
			[666, 6883],
			[0, -2],
			[-1, 0],
			[1, 2]
		],
		[
			[8843, 7694],
			[-26, -17],
			[-9, -60],
			[-41, -35],
			[-76, -48],
			[-16, -36],
			[-28, 0],
			[-49, -47],
			[-39, -6],
			[-22, -37],
			[-43, -19],
			[-18, 16],
			[-29, 207],
			[2, 64]
		],
		[
			[8075, 4417],
			[-19, -129],
			[-45, -74],
			[-45, -133],
			[-96, -150],
			[-100, -66],
			[-87, 14],
			[-110, -59],
			[-51, 48],
			[-16, 87],
			[18, 39],
			[-9, 68],
			[-21, 49],
			[-28, 138],
			[-15, 35]
		],
		[
			[7941, 4768],
			[74, -16]
		],
		[
			[7981, 5264],
			[-58, -25],
			[-6, -46],
			[-34, -30],
			[-43, -84],
			[-55, 21]
		],
		[
			[8227, 8098],
			[19, -230],
			[45, -72]
		],
		[
			[8122, 7155],
			[-6, 50],
			[-27, 51],
			[0, 103],
			[-33, -36],
			[3, -52],
			[-44, -95],
			[-18, -3],
			[-31, 43],
			[-39, -48]
		],
		[
			[7927, 7168],
			[0, 37],
			[-43, 0],
			[3, -42]
		],
		[
			[7887, 7163],
			[-62, 3],
			[-19, 60],
			[-26, -9],
			[-34, -125]
		],
		[
			[7887, 7163],
			[40, 5]
		],
		[
			[4486, 7673],
			[7, -24],
			[-5, -7],
			[-4, 23],
			[2, 8]
		],
		[
			[4437, 7800],
			[-4, 0]
		],
		[
			[4433, 7800],
			[4, 5],
			[0, -5]
		],
		[
			[4442, 7791],
			[3, -2],
			[-1, -2],
			[-2, 4]
		],
		[
			[4209, 7363],
			[7, -6],
			[1, -11],
			[-9, 11],
			[1, 6]
		],
		[
			[4237, 7361],
			[3, 0],
			[-1, -16],
			[-3, 13],
			[1, 3]
		],
		[
			[4437, 7800],
			[0, -3],
			[-4, 3]
		]
	],
	"objects": {
		"layer1": {
			"type": "GeometryCollection",
			"geometries": [{
				"arcs": [
					[0]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 4,
					"ISO3_CODE": "",
					"STATUS": "FR Territory",
					"CAPITAL": "",
					"Terr_ID": 77,
					"Terr_Name": "Europa Island",
					"Color_Code": "FRA",
					"Shape_Leng": 0.297900944883,
					"Shape_Area": 0.0019042911357,
					"name": "Europa Island"
				}
			}, {
				"arcs": [
					[1, 2, 3]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 8,
					"ISO3_CODE": "ISR",
					"STATUS": "Gaza",
					"CAPITAL": "",
					"Terr_ID": 88,
					"Terr_Name": "Gaza Strip",
					"Color_Code": "XXX",
					"Shape_Leng": 1.07269521636,
					"Shape_Area": 0.0343743279441,
					"name": "Gaza Strip"
				}
			}, {
				"arcs": [
					[4, 5, 6, 7]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 9,
					"ISO3_CODE": "",
					"STATUS": "Occupied Palestinan Territory",
					"CAPITAL": "",
					"Terr_ID": 265,
					"Terr_Name": "West Bank",
					"Color_Code": "XXX",
					"Shape_Leng": 4.25197760622,
					"Shape_Area": 0.551527865081,
					"name": "West Bank"
				}
			}, {
				"arcs": [
					[-7, 8]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 10,
					"ISO3_CODE": "ISR",
					"STATUS": "Occupied Palestinian Territory",
					"CAPITAL": "",
					"Terr_ID": 265,
					"Terr_Name": "West Bank",
					"Color_Code": "XXX",
					"Shape_Leng": 1.47402748207,
					"Shape_Area": 0.00759860750465,
					"name": "West Bank"
				}
			}, {
				"arcs": [
					[9]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 11,
					"ISO3_CODE": "",
					"STATUS": "PT Territory",
					"CAPITAL": "Funchal",
					"Terr_ID": 147,
					"Terr_Name": "Madeira Islands",
					"Color_Code": "PRT",
					"Shape_Leng": 2.24340582755,
					"Shape_Area": 0.0786454725773,
					"name": "Madeira Islands"
				}
			}, {
				"arcs": [
					[10, 11]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 12,
					"ISO3_CODE": "CHN",
					"STATUS": "Sovereignty unsettled",
					"CAPITAL": "",
					"Terr_ID": 0,
					"Terr_Name": "Aksai Chin",
					"Color_Code": "XXX",
					"Shape_Leng": 10.7740064237,
					"Shape_Area": 3.02895020965,
					"name": "Sint Maarten"
				}
			}, {
				"arcs": [
					[12, 13, 14, 15]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 13,
					"ISO3_CODE": "IND",
					"STATUS": "Sovereignty unsettled",
					"CAPITAL": "",
					"Terr_ID": 0,
					"Terr_Name": "Arunachal Pradesh",
					"Color_Code": "IND",
					"Shape_Leng": 18.8129403122,
					"Shape_Area": 6.24267640863,
					"name": "Sint Maarten"
				}
			}, {
				"arcs": [
					[16, 17]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 14,
					"ISO3_CODE": "CHN",
					"STATUS": "Sovereignty unsettled",
					"CAPITAL": "",
					"Terr_ID": 0,
					"Terr_Name": "China/India",
					"Color_Code": "XXX",
					"Shape_Leng": 6.08207859862,
					"Shape_Area": 0.3364503133,
					"name": "Sint Maarten"
				}
			}, {
				"arcs": [
					[20, 21]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 15,
					"ISO3_CODE": "KEN",
					"STATUS": "Sovereignty unsettled",
					"CAPITAL": "",
					"Terr_ID": 0,
					"Terr_Name": "Ilemi triangle",
					"Color_Code": "KEN",
					"Shape_Leng": 2.83804077841,
					"Shape_Area": 0.25909821485,
					"name": "Sint Maarten"
				}
			}, {
				"arcs": [
					[-11, 22, -17, 23, 24, 25, 26, 27]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 16,
					"ISO3_CODE": "PAK",
					"STATUS": "Sovereignty unsettled",
					"CAPITAL": "",
					"Terr_ID": 0,
					"Terr_Name": "Jammu and Kashmir",
					"Color_Code": "XXX",
					"Shape_Leng": 28.4272840714,
					"Shape_Area": 18.2758739479,
					"name": "Sint Maarten"
				}
			}, {
				"arcs": [
					[28]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 17,
					"ISO3_CODE": "",
					"STATUS": "Sovereignty unsettled",
					"CAPITAL": "",
					"Terr_ID": 132,
					"Terr_Name": "Kuril islands",
					"Color_Code": "RUS",
					"Shape_Leng": 12.9051092716,
					"Shape_Area": 0.580705588035,
					"name": "Kuril islands"
				}
			}, {
				"arcs": [
					[29]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 19,
					"ISO3_CODE": "",
					"STATUS": "Sovereignty unsettled",
					"CAPITAL": "",
					"Terr_ID": 213,
					"Terr_Name": "Scarborough Reef",
					"Color_Code": "XXX",
					"Shape_Leng": 0.24876932906,
					"Shape_Area": 0.000742467782659,
					"name": "Scarborough Reef"
				}
			}, {
				"arcs": [
					[30]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 20,
					"ISO3_CODE": "",
					"STATUS": "Sovereignty unsettled",
					"CAPITAL": "",
					"Terr_ID": 215,
					"Terr_Name": "Senkaku Islands",
					"Color_Code": "XXX",
					"Shape_Leng": 0.121157002439,
					"Shape_Area": 0.000462687263138,
					"name": "Senkaku Islands"
				}
			}, {
				"arcs": [
					[31]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 21,
					"ISO3_CODE": "",
					"STATUS": "Sovereignty unsettled",
					"CAPITAL": "",
					"Terr_ID": 227,
					"Terr_Name": "Spratly Islands",
					"Color_Code": "XXX",
					"Shape_Leng": 0.475216566037,
					"Shape_Area": 0.0012907242095,
					"name": "Spratly Islands"
				}
			}, {
				"arcs": [
					[32]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 22,
					"ISO3_CODE": "",
					"STATUS": "UK Territory",
					"CAPITAL": "St. Helier",
					"Terr_ID": 124,
					"Terr_Name": "Jersey",
					"Color_Code": "GBR",
					"Shape_Leng": 0.732756127974,
					"Shape_Area": 0.0147933628916,
					"name": "Jersey"
				}
			}, {
				"arcs": [
					[33]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 25,
					"ISO3_CODE": "",
					"STATUS": "US Territory",
					"CAPITAL": "",
					"Terr_ID": 130,
					"Terr_Name": "Kingman Reef",
					"Color_Code": "USA",
					"Shape_Leng": 2.60593203371,
					"Shape_Area": 0.02678639945,
					"name": "Kingman Reef"
				}
			}, {
				"arcs": [
					[34]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 27,
					"ISO3_CODE": "ABW",
					"STATUS": "NL Self-Governing Territory",
					"CAPITAL": "Oranjestad",
					"Terr_ID": 12,
					"Terr_Name": "Aruba",
					"Color_Code": "NLD",
					"Shape_Leng": 0.690777422497,
					"Shape_Area": 0.0151030953054,
					"name": "Aruba"
				}
			}, {
				"arcs": [
					[35, -27, 36, 37, 38, 39, 40]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 28,
					"ISO3_CODE": "AFG",
					"STATUS": "Member State",
					"CAPITAL": "Kabul",
					"Terr_ID": 1,
					"Terr_Name": "Afghanistan",
					"Color_Code": "AFG",
					"Shape_Leng": 58.9880016899,
					"Shape_Area": 62.5548856972,
					"name": "Afghanistan"
				}
			}, {
				"arcs": [
					[
						[41, 42, 43, 44]
					],
					[
						[45, 46, 47]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 29,
					"ISO3_CODE": "AGO",
					"STATUS": "Member State",
					"CAPITAL": "Luanda",
					"Terr_ID": 6,
					"Terr_Name": "Angola",
					"Color_Code": "AGO",
					"Shape_Leng": 68.3402471828,
					"Shape_Area": 103.762210702,
					"name": "Angola"
				}
			}, {
				"arcs": [
					[48]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 30,
					"ISO3_CODE": "AIA",
					"STATUS": "UK Non-Self-Governing Territory",
					"CAPITAL": "The Valley",
					"Terr_ID": 7,
					"Terr_Name": "Anguilla",
					"Color_Code": "GBR",
					"Shape_Leng": 0.719137131662,
					"Shape_Area": 0.00705306923163,
					"name": "Anguilla"
				}
			}, {
				"arcs": [
					[49, 50, 51, 52, 53]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 31,
					"ISO3_CODE": "ALB",
					"STATUS": "Member State",
					"CAPITAL": "Tirana",
					"Terr_ID": 2,
					"Terr_Name": "Albania",
					"Color_Code": "ALB",
					"Shape_Leng": 13.4942867625,
					"Shape_Area": 3.07636469059,
					"name": "Albania"
				}
			}, {
				"arcs": [
					[54, 55]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 32,
					"ISO3_CODE": "AND",
					"STATUS": "Member State",
					"CAPITAL": "Andorra la Vella",
					"Terr_ID": 5,
					"Terr_Name": "Andorra",
					"Color_Code": "AND",
					"Shape_Leng": 1.06056994309,
					"Shape_Area": 0.0520217009,
					"name": "Andorra"
				}
			}, {
				"arcs": [
					[56, 57, 58, 59, 60]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 34,
					"ISO3_CODE": "ARE",
					"STATUS": "Member State",
					"CAPITAL": "Abu Dhabi",
					"Terr_ID": 254,
					"Terr_Name": "United Arab Emirates",
					"Color_Code": "ARE",
					"Shape_Leng": 27.959178941,
					"Shape_Area": 6.28057952073,
					"name": "U.A.E."
				}
			}, {
				"arcs": [
					[
						[61, 62, 63, 64, 65, 66]
					],
					[
						[67, 68]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 35,
					"ISO3_CODE": "ARG",
					"STATUS": "Member State",
					"CAPITAL": "Buenos Aires",
					"Terr_ID": 10,
					"Terr_Name": "Argentina",
					"Color_Code": "ARG",
					"Shape_Leng": 162.88788538,
					"Shape_Area": 278.362386041,
					"name": "Argentina"
				}
			}, {
				"arcs": [
					[69, 70, 71, 72, 73]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 36,
					"ISO3_CODE": "ARM",
					"STATUS": "Member State",
					"CAPITAL": "Yerevan",
					"Terr_ID": 11,
					"Terr_Name": "Armenia",
					"Color_Code": "ARM",
					"Shape_Leng": 14.2764228128,
					"Shape_Area": 3.13783139271,
					"name": "Armenia"
				}
			}, {
				"arcs": [
					[74]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 37,
					"ISO3_CODE": "ASM",
					"STATUS": "US Non-Self-Governing Territory",
					"CAPITAL": "Pago Pago",
					"Terr_ID": 4,
					"Terr_Name": "American Samoa",
					"Color_Code": "USA",
					"Shape_Leng": 1.54651929528,
					"Shape_Area": 0.0176699225597,
					"name": "American Samoa"
				}
			}, {
				"arcs": [
					[
						[75]
					],
					[
						[76]
					],
					[
						[77]
					],
					[
						[78]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 38,
					"ISO3_CODE": "ATA",
					"STATUS": "",
					"CAPITAL": "",
					"Terr_ID": 8,
					"Terr_Name": "Antarctica",
					"Color_Code": "ATA",
					"Shape_Leng": 1648.31962413,
					"Shape_Area": 6041.96578326,
					"name": "Antarctica"
				}
			}, {
				"arcs": [
					[79]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 39,
					"ISO3_CODE": "ATF",
					"STATUS": "FR Territory",
					"CAPITAL": "",
					"Terr_ID": 85,
					"Terr_Name": "French Southern and Antarctic Territories",
					"Color_Code": "FRA",
					"Shape_Leng": 30.8110395804,
					"Shape_Area": 0.932210112882,
					"name": "French Southern and Antarctic Territories"
				}
			}, {
				"arcs": [
					[80]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 40,
					"ISO3_CODE": "ATG",
					"STATUS": "Member State",
					"CAPITAL": "St John's",
					"Terr_ID": 9,
					"Terr_Name": "Antigua and Barbuda",
					"Color_Code": "ATG",
					"Shape_Leng": 2.30101149884,
					"Shape_Area": 0.0376638969299,
					"name": "Antigua and Barbuda"
				}
			}, {
				"arcs": [
					[
						[81]
					],
					[
						[82]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 41,
					"ISO3_CODE": "AUS",
					"STATUS": "Member State",
					"CAPITAL": "Canberra",
					"Terr_ID": 14,
					"Terr_Name": "Australia",
					"Color_Code": "AUS",
					"Shape_Leng": 503.294671757,
					"Shape_Area": 695.562249626,
					"name": "Australia"
				}
			}, {
				"arcs": [
					[83, 84, 85, 86, 87, 88, 89, 90, 91]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 42,
					"ISO3_CODE": "AUT",
					"STATUS": "Member State",
					"CAPITAL": "Vienna",
					"Terr_ID": 15,
					"Terr_Name": "Austria",
					"Color_Code": "AUT",
					"Shape_Leng": 26.0290865642,
					"Shape_Area": 10.0407879439,
					"name": "Austria"
				}
			}, {
				"arcs": [
					[
						[92, 93, -74, 94, 95, 96]
					],
					[
						[97, 98, -71]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 43,
					"ISO3_CODE": "AZE",
					"STATUS": "Member State",
					"CAPITAL": "Baku",
					"Terr_ID": 16,
					"Terr_Name": "Azerbaijan",
					"Color_Code": "AZE",
					"Shape_Leng": 31.4018834761,
					"Shape_Area": 17.4551199829,
					"name": "Azerbaijan"
				}
			}, {
				"arcs": [
					[99, 100, 101]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 44,
					"ISO3_CODE": "BDI",
					"STATUS": "Member State",
					"CAPITAL": "Bujumbura",
					"Terr_ID": 41,
					"Terr_Name": "Burundi",
					"Color_Code": "BDI",
					"Shape_Leng": 9.11845939547,
					"Shape_Area": 2.18565171949,
					"name": "Burundi"
				}
			}, {
				"arcs": [
					[102, 103, 104, 105, 106, 107, 108]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 45,
					"ISO3_CODE": "BEL",
					"STATUS": "Member State",
					"CAPITAL": "Brussels",
					"Terr_ID": 25,
					"Terr_Name": "Belgium",
					"Color_Code": "BEL",
					"Shape_Leng": 14.8181038984,
					"Shape_Area": 3.89763866651,
					"name": "Belgium"
				}
			}, {
				"arcs": [
					[109, 110, 111, 112, 113]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 46,
					"ISO3_CODE": "BEN",
					"STATUS": "Member State",
					"CAPITAL": "Porto-Novo (constituti",
					"Terr_ID": 27,
					"Terr_Name": "Benin",
					"Color_Code": "BEN",
					"Shape_Leng": 19.258058571,
					"Shape_Area": 9.52588096547,
					"name": "Benin"
				}
			}, {
				"arcs": [
					[114, -113, 115, 116, 117, 118]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 47,
					"ISO3_CODE": "BFA",
					"STATUS": "Member State",
					"CAPITAL": "Ouagadougou",
					"Terr_ID": 40,
					"Terr_Name": "Burkina Faso",
					"Color_Code": "BFA",
					"Shape_Leng": 31.655680047,
					"Shape_Area": 22.7119697348,
					"name": "Burkina Faso"
				}
			}, {
				"arcs": [
					[119, 120, 121]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 48,
					"ISO3_CODE": "BGD",
					"STATUS": "Member State",
					"CAPITAL": "Dhaka",
					"Terr_ID": 21,
					"Terr_Name": "Bangladesh",
					"Color_Code": "BGD",
					"Shape_Leng": 94.8760508383,
					"Shape_Area": 12.1001221935,
					"name": "Bangladesh"
				}
			}, {
				"arcs": [
					[122, 123, 124, 125, 126, 127]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 49,
					"ISO3_CODE": "BGR",
					"STATUS": "Member State",
					"CAPITAL": "Sofia",
					"Terr_ID": 39,
					"Terr_Name": "Bulgaria",
					"Color_Code": "BGR",
					"Shape_Leng": 23.3481433091,
					"Shape_Area": 12.2416448496,
					"name": "Bulgaria"
				}
			}, {
				"arcs": [
					[128]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 50,
					"ISO3_CODE": "BHR",
					"STATUS": "Member State",
					"CAPITAL": "Manama",
					"Terr_ID": 19,
					"Terr_Name": "Bahrain",
					"Color_Code": "BHR",
					"Shape_Leng": 2.71261946852,
					"Shape_Area": 0.060715627428,
					"name": "Bahrain"
				}
			}, {
				"arcs": [
					[129]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 51,
					"ISO3_CODE": "BHS",
					"STATUS": "Member State",
					"CAPITAL": "Nassau",
					"Terr_ID": 18,
					"Terr_Name": "Bahamas",
					"Color_Code": "BHS",
					"Shape_Leng": 65.0116470231,
					"Shape_Area": 1.18657543428,
					"name": "Bahamas"
				}
			}, {
				"arcs": [
					[130, 131, 132, 133, 134]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 52,
					"ISO3_CODE": "BIH",
					"STATUS": "Member State",
					"CAPITAL": "Sarajevo",
					"Terr_ID": 32,
					"Terr_Name": "Bosnia and Herzegovina",
					"Color_Code": "BIH",
					"Shape_Leng": 15.8500487536,
					"Shape_Area": 5.75577038514,
					"name": "Bosnia and Herzegovina"
				}
			}, {
				"arcs": [
					[135, 136, 137, 138, 139]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 53,
					"ISO3_CODE": "BLR",
					"STATUS": "Member State",
					"CAPITAL": "Minsk",
					"Terr_ID": 24,
					"Terr_Name": "Belarus",
					"Color_Code": "BLR",
					"Shape_Leng": 36.6272975768,
					"Shape_Area": 28.1405066408,
					"name": "Belarus"
				}
			}, {
				"arcs": [
					[140, 141, 142]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 54,
					"ISO3_CODE": "BLZ",
					"STATUS": "Member State",
					"CAPITAL": "Belmopan",
					"Terr_ID": 26,
					"Terr_Name": "Belize",
					"Color_Code": "BLZ",
					"Shape_Leng": 12.7546160701,
					"Shape_Area": 1.88752547694,
					"name": "Belize"
				}
			}, {
				"arcs": [
					[143]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 55,
					"ISO3_CODE": "BMU",
					"STATUS": "UK Non-Self-Governing Territory",
					"CAPITAL": "Hamilton",
					"Terr_ID": 28,
					"Terr_Name": "Bermuda",
					"Color_Code": "GBR",
					"Shape_Leng": 1.07722376746,
					"Shape_Area": 0.00605152136602,
					"name": "Bermuda"
				}
			}, {
				"arcs": [
					[144, -67, 145, 146, 147]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 56,
					"ISO3_CODE": "BOL",
					"STATUS": "Member State",
					"CAPITAL": "La Paz  (adm.)",
					"Terr_ID": 31,
					"Terr_Name": "Bolivia",
					"Color_Code": "BOL",
					"Shape_Leng": 58.6607526074,
					"Shape_Area": 92.0060610671,
					"name": "Bolivia"
				}
			}, {
				"arcs": [
					[148, 149, 150, 151, -63, 152, -148, 153, 154, 155, 156]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 57,
					"ISO3_CODE": "BRA",
					"STATUS": "Member State",
					"CAPITAL": "Bras�lia",
					"Terr_ID": 35,
					"Terr_Name": "Brazil",
					"Color_Code": "BRA",
					"Shape_Leng": 306.576423571,
					"Shape_Area": 708.060894381,
					"name": "Brazil"
				}
			}, {
				"arcs": [
					[157]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 58,
					"ISO3_CODE": "BRB",
					"STATUS": "Member State",
					"CAPITAL": "Bridgetown",
					"Terr_ID": 22,
					"Terr_Name": "Barbados",
					"Color_Code": "BRB",
					"Shape_Leng": 0.824962989845,
					"Shape_Area": 0.0366313254616,
					"name": "Barbados"
				}
			}, {
				"arcs": [
					[158, 159]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 59,
					"ISO3_CODE": "BRN",
					"STATUS": "Member State",
					"CAPITAL": "Bandar Seri Begawan",
					"Terr_ID": 38,
					"Terr_Name": "Brunei Darussalam",
					"Color_Code": "BRN",
					"Shape_Leng": 6.10852818499,
					"Shape_Area": 0.480693654934,
					"name": "Brunei Darussalam"
				}
			}, {
				"arcs": [
					[-15, 160, 161]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 60,
					"ISO3_CODE": "BTN",
					"STATUS": "Member State",
					"CAPITAL": "Thimphu",
					"Terr_ID": 29,
					"Terr_Name": "Bhutan",
					"Color_Code": "BTN",
					"Shape_Leng": 10.5518504089,
					"Shape_Area": 3.43740086915,
					"name": "Bhutan"
				}
			}, {
				"arcs": [
					[162]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 61,
					"ISO3_CODE": "BVT",
					"STATUS": "NO Territory",
					"CAPITAL": "",
					"Terr_ID": 34,
					"Terr_Name": "Bouvet Island",
					"Color_Code": "NOR",
					"Shape_Leng": 0.366430871054,
					"Shape_Area": 0.00674068615456,
					"name": "Bouvet Island"
				}
			}, {
				"arcs": [
					[163, 164, 165]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 62,
					"ISO3_CODE": "BWA",
					"STATUS": "Member State",
					"CAPITAL": "Gaborone",
					"Terr_ID": 33,
					"Terr_Name": "Botswana",
					"Color_Code": "BWA",
					"Shape_Leng": 37.8859067916,
					"Shape_Area": 50.672384244,
					"name": "Botswana"
				}
			}, {
				"arcs": [
					[166, 167, 168, 169, 170, 171]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 63,
					"ISO3_CODE": "CAF",
					"STATUS": "Member State",
					"CAPITAL": "Bangui",
					"Terr_ID": 47,
					"Terr_Name": "Central African Republic",
					"Color_Code": "CAF",
					"Shape_Leng": 49.6180208115,
					"Shape_Area": 50.680333115,
					"name": "C.A.R."
				}
			}, {
				"arcs": [
					[
						[172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183]
					],
					[
						[184]
					],
					[
						[185]
					],
					[
						[186]
					],
					[
						[187, 188]
					],
					[
						[189]
					],
					[
						[190]
					],
					[
						[191]
					],
					[
						[192]
					],
					[
						[193]
					],
					[
						[194]
					],
					[
						[195]
					],
					[
						[196]
					],
					[
						[197]
					],
					[
						[198]
					],
					[
						[199]
					],
					[
						[200]
					],
					[
						[201]
					],
					[
						[202]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 64,
					"ISO3_CODE": "CAN",
					"STATUS": "Member State",
					"CAPITAL": "Ottawa",
					"Terr_ID": 44,
					"Terr_Name": "Canada",
					"Color_Code": "CAN",
					"Shape_Leng": 3692.20457203,
					"Shape_Area": 1687.61167176,
					"name": "Canada"
				}
			}, {
				"arcs": [
					[-90, 205, -88, 206, 207, 208]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 66,
					"ISO3_CODE": "CHE",
					"STATUS": "Member State",
					"CAPITAL": "Bern",
					"Terr_ID": 234,
					"Terr_Name": "Switzerland",
					"Color_Code": "CHE",
					"Shape_Leng": 18.1093757907,
					"Shape_Area": 4.8622631423,
					"name": "Switzerland"
				}
			}, {
				"arcs": [
					[
						[-66, 209, 210, -146]
					],
					[
						[-68, 211]
					],
					[
						[212]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 67,
					"ISO3_CODE": "CHL",
					"STATUS": "Member State",
					"CAPITAL": "Santiago",
					"Terr_ID": 49,
					"Terr_Name": "Chile",
					"Color_Code": "CHL",
					"Shape_Leng": 686.342402062,
					"Shape_Area": 78.7655336332,
					"name": "Chile"
				}
			}, {
				"arcs": [
					[
						[214, 215, 216, 217, 218, 219, 220, 221, 222, -16, -162, 223, 224, 225, 226, 227, -20, 228, 229, 230, -24, -18, -23, -12, -28, -36, 231, 232, 233, 234, 235, 236]
					],
					[
						[237]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 68,
					"ISO3_CODE": "CHN",
					"STATUS": "Member State",
					"CAPITAL": "Beijing",
					"Terr_ID": 50,
					"Terr_Name": "China",
					"Color_Code": "CHN",
					"Shape_Leng": 441.521157248,
					"Shape_Area": 948.284020905,
					"name": "China"
				}
			}, {
				"arcs": [
					[-118, 238, 239, 240, 241, 242]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 69,
					"ISO3_CODE": "CIV",
					"STATUS": "Member State",
					"CAPITAL": "Yamoussoukro",
					"Terr_ID": 59,
					"Terr_Name": "C�te d'Ivoire",
					"Color_Code": "CIV",
					"Shape_Leng": 33.5260774434,
					"Shape_Area": 26.3948663299,
					"name": "C�te d'Ivoire"
				}
			}, {
				"arcs": [
					[-171, 243, 244, 245, 246, 247, 248]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 70,
					"ISO3_CODE": "CMR",
					"STATUS": "Member State",
					"CAPITAL": "Yaound�",
					"Terr_ID": 43,
					"Terr_Name": "Cameroon",
					"Color_Code": "CMR",
					"Shape_Leng": 48.9678053408,
					"Shape_Area": 38.0376412591,
					"name": "Cameroon"
				}
			}, {
				"arcs": [
					[249, 250, 251, -101, 252, 253, -45, 254, -46, 255, -169]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 71,
					"ISO3_CODE": "COD",
					"STATUS": "Member State",
					"CAPITAL": "Kinshasa",
					"Terr_ID": 65,
					"Terr_Name": "Democratic Republic of the Congo",
					"Color_Code": "COD",
					"Shape_Leng": 95.4612420277,
					"Shape_Area": 190.018737767,
					"name": "D.R.C."
				}
			}, {
				"arcs": [
					[-256, -48, 256, 257, -244, -170]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 72,
					"ISO3_CODE": "COG",
					"STATUS": "Member State",
					"CAPITAL": "Brazzaville",
					"Terr_ID": 56,
					"Terr_Name": "Congo",
					"Color_Code": "COG",
					"Shape_Leng": 42.5287053316,
					"Shape_Area": 27.6994468414,
					"name": "Congo"
				}
			}, {
				"arcs": [
					[258]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 73,
					"ISO3_CODE": "COK",
					"STATUS": "NZ Territory",
					"CAPITAL": "Avarua",
					"Terr_ID": 57,
					"Terr_Name": "Cook Islands",
					"Color_Code": "NZL",
					"Shape_Leng": 3.10802678516,
					"Shape_Area": 0.0214327680851,
					"name": "Cook Islands"
				}
			}, {
				"arcs": [
					[259, -155, 260, 261, 262, 263, 264]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 74,
					"ISO3_CODE": "COL",
					"STATUS": "Member State",
					"CAPITAL": "Bogot�",
					"Terr_ID": 54,
					"Terr_Name": "Colombia",
					"Color_Code": "COL",
					"Shape_Leng": 89.7880276706,
					"Shape_Area": 92.7961852116,
					"name": "Colombia"
				}
			}, {
				"arcs": [
					[265]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 75,
					"ISO3_CODE": "COM",
					"STATUS": "Member State",
					"CAPITAL": "Moroni",
					"Terr_ID": 55,
					"Terr_Name": "Comoros",
					"Color_Code": "COM",
					"Shape_Leng": 3.56659796822,
					"Shape_Area": 0.138678717387,
					"name": "Comoros"
				}
			}, {
				"arcs": [
					[266]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 76,
					"ISO3_CODE": "CPV",
					"STATUS": "Member State",
					"CAPITAL": "Praia",
					"Terr_ID": 45,
					"Terr_Name": "Cape Verde",
					"Color_Code": "CPV",
					"Shape_Leng": 9.07623089864,
					"Shape_Area": 0.34241155975,
					"name": "Cape Verde"
				}
			}, {
				"arcs": [
					[267, 268, 269, 270]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 77,
					"ISO3_CODE": "CRI",
					"STATUS": "Member State",
					"CAPITAL": "San Jos�",
					"Terr_ID": 58,
					"Terr_Name": "Costa Rica",
					"Color_Code": "CRI",
					"Shape_Leng": 21.8361112452,
					"Shape_Area": 4.22386367679,
					"name": "Costa Rica"
				}
			}, {
				"arcs": [
					[271]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 78,
					"ISO3_CODE": "CUB",
					"STATUS": "Member State",
					"CAPITAL": "Havana",
					"Terr_ID": 61,
					"Terr_Name": "Cuba",
					"Color_Code": "CUB",
					"Shape_Leng": 86.8262424002,
					"Shape_Area": 9.71419117523,
					"name": "Cuba"
				}
			}, {
				"arcs": [
					[272]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 79,
					"ISO3_CODE": "CXR",
					"STATUS": "AU Territory",
					"CAPITAL": "The Settlement",
					"Terr_ID": 51,
					"Terr_Name": "Christmas Island",
					"Color_Code": "AUS",
					"Shape_Leng": 0.644466196153,
					"Shape_Area": 0.0113287901172,
					"name": "Christmas Island"
				}
			}, {
				"arcs": [
					[273]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 80,
					"ISO3_CODE": "CYM",
					"STATUS": "UK Non-Self-Governing Territory",
					"CAPITAL": "George Town",
					"Terr_ID": 46,
					"Terr_Name": "Cayman Islands",
					"Color_Code": "GBR",
					"Shape_Leng": 1.80672057001,
					"Shape_Area": 0.0238872321711,
					"name": "Cayman Islands"
				}
			}, {
				"arcs": [
					[274]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 81,
					"ISO3_CODE": "CYP",
					"STATUS": "Member State",
					"CAPITAL": "Nicosia",
					"Terr_ID": 62,
					"Terr_Name": "Cyprus",
					"Color_Code": "CYP",
					"Shape_Leng": 6.62812601417,
					"Shape_Area": 0.916136187158,
					"name": "Cyprus"
				}
			}, {
				"arcs": [
					[275, 276, -92, 277]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 82,
					"ISO3_CODE": "CZE",
					"STATUS": "Member State",
					"CAPITAL": "Prague",
					"Terr_ID": 63,
					"Terr_Name": "Czech Republic",
					"Color_Code": "CZE",
					"Shape_Leng": 22.6020588282,
					"Shape_Area": 9.8348219043,
					"name": "Czech Republic"
				}
			}, {
				"arcs": [
					[278, 279, 280, -278, -91, -209, 281, 282, -103, 283, 284]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 83,
					"ISO3_CODE": "DEU",
					"STATUS": "Member State",
					"CAPITAL": "Berlin",
					"Terr_ID": 90,
					"Terr_Name": "Germany",
					"Color_Code": "DEU",
					"Shape_Leng": 79.7534054451,
					"Shape_Area": 45.8756542927,
					"name": "Germany"
				}
			}, {
				"arcs": [
					[285, 286, 287, 288]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 84,
					"ISO3_CODE": "DJI",
					"STATUS": "Member State",
					"CAPITAL": "Djibouti",
					"Terr_ID": 67,
					"Terr_Name": "Djibouti",
					"Color_Code": "DJI",
					"Shape_Leng": 8.24366918881,
					"Shape_Area": 1.79825339902,
					"name": "Djibouti"
				}
			}, {
				"arcs": [
					[289]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 85,
					"ISO3_CODE": "DMA",
					"STATUS": "Member State",
					"CAPITAL": "Roseau",
					"Terr_ID": 68,
					"Terr_Name": "Dominica",
					"Color_Code": "DMA",
					"Shape_Leng": 1.20614326621,
					"Shape_Area": 0.064553548911,
					"name": "Dominica"
				}
			}, {
				"arcs": [
					[-279, 290]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 86,
					"ISO3_CODE": "DNK",
					"STATUS": "Member State",
					"CAPITAL": "Copenhagen",
					"Terr_ID": 66,
					"Terr_Name": "Denmark",
					"Color_Code": "DNK",
					"Shape_Leng": 61.0013031968,
					"Shape_Area": 6.39801893827,
					"name": "Denmark"
				}
			}, {
				"arcs": [
					[291, 292]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 87,
					"ISO3_CODE": "DOM",
					"STATUS": "Member State",
					"CAPITAL": "Santo Domingo",
					"Terr_ID": 69,
					"Terr_Name": "Dominican Republic",
					"Color_Code": "DOM",
					"Shape_Leng": 16.2831360019,
					"Shape_Area": 4.13175759631,
					"name": "Dominican Republic"
				}
			}, {
				"arcs": [
					[293, 294, 295, 296, 297, 298, 299, 300]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 88,
					"ISO3_CODE": "DZA",
					"STATUS": "Member State",
					"CAPITAL": "Algiers",
					"Terr_ID": 3,
					"Terr_Name": "Algeria",
					"Color_Code": "DZA",
					"Shape_Leng": 75.6492185868,
					"Shape_Area": 212.749397979,
					"name": "Algeria"
				}
			}, {
				"arcs": [
					[-262, 301, 302]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 89,
					"ISO3_CODE": "ECU",
					"STATUS": "Member State",
					"CAPITAL": "Quito",
					"Terr_ID": 70,
					"Terr_Name": "Ecuador",
					"Color_Code": "ECU",
					"Shape_Leng": 47.1115348615,
					"Shape_Area": 20.8269823404,
					"name": "Ecuador"
				}
			}, {
				"arcs": [
					[-2, 303, 304, 305, 306, 307, 308, 309]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 90,
					"ISO3_CODE": "EGY",
					"STATUS": "Member State",
					"CAPITAL": "Cairo",
					"Terr_ID": 71,
					"Terr_Name": "Egypt",
					"Color_Code": "EGY",
					"Shape_Leng": 67.253388981,
					"Shape_Area": 88.9692171295,
					"name": "Egypt"
				}
			}, {
				"arcs": [
					[-288, 310, 311, 312]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 91,
					"ISO3_CODE": "ERI",
					"STATUS": "Member State",
					"CAPITAL": "Asmara",
					"Terr_ID": 74,
					"Terr_Name": "Eritrea",
					"Color_Code": "ERI",
					"Shape_Leng": 40.7458151426,
					"Shape_Area": 10.1385430396,
					"name": "Eritrea"
				}
			}, {
				"arcs": [
					[-299, 313, 314, 315]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 92,
					"ISO3_CODE": "ESH",
					"STATUS": "Non-Self-Governing Territory",
					"CAPITAL": "",
					"Terr_ID": 266,
					"Terr_Name": "Western Sahara",
					"Color_Code": "XXX",
					"Shape_Leng": 29.4963068934,
					"Shape_Area": 23.9737771394,
					"name": "Western Sahara"
				}
			}, {
				"arcs": [
					[316, -55, 317, 318, 319, 320, 321, 322]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 93,
					"ISO3_CODE": "ESP",
					"STATUS": "Member State",
					"CAPITAL": "Madrid",
					"Terr_ID": 226,
					"Terr_Name": "Spain",
					"Color_Code": "ESP",
					"Shape_Leng": 80.3582940815,
					"Shape_Area": 53.5643903803,
					"name": "Spain"
				}
			}, {
				"arcs": [
					[323, 324, 325]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 94,
					"ISO3_CODE": "EST",
					"STATUS": "Member State",
					"CAPITAL": "Tallinn",
					"Terr_ID": 75,
					"Terr_Name": "Estonia",
					"Color_Code": "EST",
					"Shape_Leng": 34.3733037333,
					"Shape_Area": 7.03326361425,
					"name": "Estonia"
				}
			}, {
				"arcs": [
					[326, 327, -311, -287, 328, 329]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 95,
					"ISO3_CODE": "ETH",
					"STATUS": "Member State",
					"CAPITAL": "Addis-Ababa",
					"Terr_ID": 76,
					"Terr_Name": "Ethiopia",
					"Color_Code": "ETH",
					"Shape_Leng": 50.3802400902,
					"Shape_Area": 92.8692593197,
					"name": "Ethiopia"
				}
			}, {
				"arcs": [
					[330, 331, 332, 333]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 96,
					"ISO3_CODE": "FIN",
					"STATUS": "Member State",
					"CAPITAL": "Helsinki",
					"Terr_ID": 81,
					"Terr_Name": "Finland",
					"Color_Code": "FIN",
					"Shape_Leng": 169.959265597,
					"Shape_Area": 62.9126727062,
					"name": "Finland"
				}
			}, {
				"arcs": [
					[334]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 97,
					"ISO3_CODE": "FJI",
					"STATUS": "Member State",
					"CAPITAL": "Suva",
					"Terr_ID": 80,
					"Terr_Name": "Fiji",
					"Color_Code": "FJI",
					"Shape_Leng": 35.5798120885,
					"Shape_Area": 1.55606576559,
					"name": "Fiji"
				}
			}, {
				"arcs": [
					[335]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 98,
					"ISO3_CODE": "FLK",
					"STATUS": "UK Non-Self-Governing Territory",
					"CAPITAL": "Stanley",
					"Terr_ID": 78,
					"Terr_Name": "Falkland Islands (Malvinas)",
					"Color_Code": "GBR",
					"Shape_Leng": 55.1210406011,
					"Shape_Area": 1.56620622061,
					"name": "Falkland Islands (Malvinas)"
				}
			}, {
				"arcs": [
					[336, -282, -208, 337, 338, -318, -56, -317, 339, -105]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 99,
					"ISO3_CODE": "FRA",
					"STATUS": "Member State",
					"CAPITAL": "Paris",
					"Terr_ID": 82,
					"Terr_Name": "France",
					"Color_Code": "FRA",
					"Shape_Leng": 91.487702602,
					"Shape_Area": 64.4312552668,
					"name": "France"
				}
			}, {
				"arcs": [
					[340]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 100,
					"ISO3_CODE": "FRO",
					"STATUS": "DK Territory",
					"CAPITAL": "T�rshavn",
					"Terr_ID": 79,
					"Terr_Name": "Faroe Islands",
					"Color_Code": "DNK",
					"Shape_Leng": 12.5905015322,
					"Shape_Area": 0.243134962176,
					"name": "Faroe Islands"
				}
			}, {
				"arcs": [
					[341]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 101,
					"ISO3_CODE": "FSM",
					"STATUS": "Member State",
					"CAPITAL": "Palikir",
					"Terr_ID": 159,
					"Terr_Name": "Micronesia (Federated States of)",
					"Color_Code": "FSM",
					"Shape_Leng": 6.27504647077,
					"Shape_Area": 0.056700561572,
					"name": "Micronesia (Federated States of)"
				}
			}, {
				"arcs": [
					[-258, 342, 343, -245]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 102,
					"ISO3_CODE": "GAB",
					"STATUS": "Member State",
					"CAPITAL": "Libreville",
					"Terr_ID": 86,
					"Terr_Name": "Gabon",
					"Color_Code": "GAB",
					"Shape_Leng": 32.0607317325,
					"Shape_Area": 21.51298517,
					"name": "Gabon"
				}
			}, {
				"arcs": [
					[
						[344]
					],
					[
						[345, 346]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 103,
					"ISO3_CODE": "GBR",
					"STATUS": "Member State",
					"CAPITAL": "London",
					"Terr_ID": 251,
					"Terr_Name": "U.K. of Great Britain and Northern Ireland",
					"Color_Code": "GBR",
					"Shape_Leng": 212.390018007,
					"Shape_Area": 33.6234245143,
					"name": "United Kingdom"
				}
			}, {
				"arcs": [
					[347]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 104,
					"ISO3_CODE": "GBR",
					"STATUS": "UK Territory",
					"CAPITAL": "",
					"Terr_ID": 101,
					"Terr_Name": "Guernsey",
					"Color_Code": "GBR",
					"Shape_Leng": 0.946706827483,
					"Shape_Area": 0.00994164875024,
					"name": "Guernsey"
				}
			}, {
				"arcs": [
					[-95, -73, 348, 349, 350]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 105,
					"ISO3_CODE": "GEO",
					"STATUS": "Member State",
					"CAPITAL": "Tbilisi",
					"Terr_ID": 89,
					"Terr_Name": "Georgia",
					"Color_Code": "GEO",
					"Shape_Leng": 19.9114134261,
					"Shape_Area": 7.60482789266,
					"name": "Georgia"
				}
			}, {
				"arcs": [
					[351, 352, -239, -117]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 106,
					"ISO3_CODE": "GHA",
					"STATUS": "Member State",
					"CAPITAL": "Accra",
					"Terr_ID": 91,
					"Terr_Name": "Ghana",
					"Color_Code": "GHA",
					"Shape_Leng": 25.212004045,
					"Shape_Area": 19.6087553208,
					"name": "Ghana"
				}
			}, {
				"arcs": [
					[353, -242, 354, 355, 356, 357, 358]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 108,
					"ISO3_CODE": "GIN",
					"STATUS": "Member State",
					"CAPITAL": "Conakry",
					"Terr_ID": 102,
					"Terr_Name": "Guinea",
					"Color_Code": "GIN",
					"Shape_Leng": 40.1969922253,
					"Shape_Area": 20.2170192797,
					"name": "Guinea"
				}
			}, {
				"arcs": [
					[359, 360]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 110,
					"ISO3_CODE": "GMB",
					"STATUS": "Member State",
					"CAPITAL": "Banjul",
					"Terr_ID": 87,
					"Terr_Name": "Gambia",
					"Color_Code": "GMB",
					"Shape_Leng": 10.3644462327,
					"Shape_Area": 0.891125501627,
					"name": "Gambia"
				}
			}, {
				"arcs": [
					[-358, 361, 362]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 111,
					"ISO3_CODE": "GNB",
					"STATUS": "Member State",
					"CAPITAL": "Bissau",
					"Terr_ID": 103,
					"Terr_Name": "Guinea-Bissau",
					"Color_Code": "GNB",
					"Shape_Leng": 27.6976306184,
					"Shape_Area": 2.82625581367,
					"name": "Guinea-Bissau"
				}
			}, {
				"arcs": [
					[-344, 363, -246]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 112,
					"ISO3_CODE": "GNQ",
					"STATUS": "Member State",
					"CAPITAL": "Malabo",
					"Terr_ID": 73,
					"Terr_Name": "Equatorial Guinea",
					"Color_Code": "GNQ",
					"Shape_Leng": 8.98811968153,
					"Shape_Area": 2.19354226997,
					"name": "Equatorial Guinea"
				}
			}, {
				"arcs": [
					[364, 365, -52, 366, -125]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 113,
					"ISO3_CODE": "GRC",
					"STATUS": "Member State",
					"CAPITAL": "Athens",
					"Terr_ID": 94,
					"Terr_Name": "Greece",
					"Color_Code": "GRC",
					"Shape_Leng": 132.144120102,
					"Shape_Area": 13.7972437789,
					"name": "Greece"
				}
			}, {
				"arcs": [
					[367]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 114,
					"ISO3_CODE": "GRD",
					"STATUS": "Member State",
					"CAPITAL": "Saint George's",
					"Terr_ID": 96,
					"Terr_Name": "Grenada",
					"Color_Code": "GRD",
					"Shape_Leng": 0.940315928672,
					"Shape_Area": 0.0268981267191,
					"name": "Grenada"
				}
			}, {
				"arcs": [
					[
						[368]
					],
					[
						[369]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 115,
					"ISO3_CODE": "GRL",
					"STATUS": "DK Self-Governing Territory",
					"CAPITAL": "Nuuk",
					"Terr_ID": 95,
					"Terr_Name": "Greenland",
					"Color_Code": "DNK",
					"Shape_Leng": 1358.62154402,
					"Shape_Area": 662.838484285,
					"name": "Greenland"
				}
			}, {
				"arcs": [
					[-142, 370, 371, 372, 373, 374]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 116,
					"ISO3_CODE": "GTM",
					"STATUS": "Member State",
					"CAPITAL": "Guatemala",
					"Terr_ID": 100,
					"Terr_Name": "Guatemala",
					"Color_Code": "GTM",
					"Shape_Leng": 18.7341414399,
					"Shape_Area": 9.19959905491,
					"name": "Guatemala"
				}
			}, {
				"arcs": [
					[-150, 375, 376]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 117,
					"ISO3_CODE": "GUF",
					"STATUS": "FR Territory",
					"CAPITAL": "Cayenne",
					"Terr_ID": 83,
					"Terr_Name": "French Guiana",
					"Color_Code": "FRA",
					"Shape_Leng": 13.9634875873,
					"Shape_Area": 6.7916080772,
					"name": "French Guiana"
				}
			}, {
				"arcs": [
					[377]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 118,
					"ISO3_CODE": "GUM",
					"STATUS": "US Non-Self-Governing Territory",
					"CAPITAL": "Agana",
					"Terr_ID": 98,
					"Terr_Name": "Guam",
					"Color_Code": "USA",
					"Shape_Leng": 1.34853411859,
					"Shape_Area": 0.0465734788797,
					"name": "Guam"
				}
			}, {
				"arcs": [
					[378, -157, 379, 380]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 119,
					"ISO3_CODE": "GUY",
					"STATUS": "Member State",
					"CAPITAL": "Georgetown",
					"Terr_ID": 104,
					"Terr_Name": "Guyana",
					"Color_Code": "GUY",
					"Shape_Leng": 31.9267924808,
					"Shape_Area": 17.246887871,
					"name": "Guyana"
				}
			}, {
				"arcs": [
					[381, -217]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 120,
					"ISO3_CODE": "HKG",
					"STATUS": "CN Special Administrative Region",
					"CAPITAL": "Hong Kong",
					"Terr_ID": 109,
					"Terr_Name": "Hong Kong",
					"Color_Code": "CHN",
					"Shape_Leng": 6.49360908404,
					"Shape_Area": 0.0947540120375,
					"name": "Hong Kong"
				}
			}, {
				"arcs": [
					[382]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 121,
					"ISO3_CODE": "HMD",
					"STATUS": "AU Territory",
					"CAPITAL": "",
					"Terr_ID": 106,
					"Terr_Name": "Heard Island and McDonald Islands",
					"Color_Code": "HMD",
					"Shape_Leng": 1.6238566708,
					"Shape_Area": 0.0509904005216,
					"name": "Heard Island and McDonald Islands"
				}
			}, {
				"arcs": [
					[383, 384, 385, -372, 386]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 122,
					"ISO3_CODE": "HND",
					"STATUS": "Member State",
					"CAPITAL": "Tegucigalpa",
					"Terr_ID": 108,
					"Terr_Name": "Honduras",
					"Color_Code": "HND",
					"Shape_Leng": 25.5790835809,
					"Shape_Area": 9.46697830394,
					"name": "Honduras"
				}
			}, {
				"arcs": [
					[
						[387, 388, -135, 389, 390]
					],
					[
						[-133, 391, 392]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 123,
					"ISO3_CODE": "HRV",
					"STATUS": "Member State",
					"CAPITAL": "Zagreb",
					"Terr_ID": 60,
					"Terr_Name": "Croatia",
					"Color_Code": "HRV",
					"Shape_Leng": 65.038425218,
					"Shape_Area": 6.46258795546,
					"name": "Croatia"
				}
			}, {
				"arcs": [
					[-292, 393]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 124,
					"ISO3_CODE": "HTI",
					"STATUS": "Member State",
					"CAPITAL": "Port-au-Prince",
					"Terr_ID": 105,
					"Terr_Name": "Haiti",
					"Color_Code": "HTI",
					"Shape_Leng": 18.8591819211,
					"Shape_Area": 2.32178747222,
					"name": "Haiti"
				}
			}, {
				"arcs": [
					[394, 395, 396, -388, 397, -85, 398]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 125,
					"ISO3_CODE": "HUN",
					"STATUS": "Member State",
					"CAPITAL": "Budapest",
					"Terr_ID": 111,
					"Terr_Name": "Hungary",
					"Color_Code": "HUN",
					"Shape_Leng": 21.4245255439,
					"Shape_Area": 11.0478721305,
					"name": "Hungary"
				}
			}, {
				"arcs": [
					[
						[399, 400, 401]
					],
					[
						[402]
					],
					[
						[403, 404]
					],
					[
						[405]
					],
					[
						[406]
					],
					[
						[407]
					],
					[
						[408]
					],
					[
						[409]
					],
					[
						[410]
					],
					[
						[411, 412, 413, 414]
					],
					[
						[415, 416, 417]
					],
					[
						[418, -416, 419]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 126,
					"ISO3_CODE": "IDN",
					"STATUS": "Member State",
					"CAPITAL": "Jakarta",
					"Terr_ID": 114,
					"Terr_Name": "Indonesia",
					"Color_Code": "IDN",
					"Shape_Leng": 722.03477726,
					"Shape_Area": 153.794908405,
					"name": "Indonesia"
				}
			}, {
				"arcs": [
					[420]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 127,
					"ISO3_CODE": "IMN",
					"STATUS": "UK territory",
					"CAPITAL": "",
					"Terr_ID": 118,
					"Terr_Name": "Isle of Man",
					"Color_Code": "GBR",
					"Shape_Leng": 1.71773174069,
					"Shape_Area": 0.0790904282403,
					"name": "Isle of Man"
				}
			}, {
				"arcs": [
					[-231, 421, -229, -19, -228, 422, -226, 423, -224, -161, -14, 424, -122, 425, 426, -25]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 128,
					"ISO3_CODE": "IND",
					"STATUS": "Member State",
					"CAPITAL": "New Delhi",
					"Terr_ID": 113,
					"Terr_Name": "India",
					"Color_Code": "IND",
					"Shape_Leng": 211.565854633,
					"Shape_Area": 262.222938268,
					"name": "India"
				}
			}, {
				"arcs": [
					[-346, 427]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 130,
					"ISO3_CODE": "IRL",
					"STATUS": "Member State",
					"CAPITAL": "Dublin",
					"Terr_ID": 117,
					"Terr_Name": "Ireland",
					"Color_Code": "IRL",
					"Shape_Leng": 74.1991293381,
					"Shape_Area": 9.38354611765,
					"name": "Ireland"
				}
			}, {
				"arcs": [
					[-70, -94, 428, -38, 429, 430, 431, 432, -98]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 131,
					"ISO3_CODE": "IRN",
					"STATUS": "Member State",
					"CAPITAL": "Tehran",
					"Terr_ID": 115,
					"Terr_Name": "Iran (Islamic Republic of)",
					"Color_Code": "IRN",
					"Shape_Leng": 98.3817626273,
					"Shape_Area": 161.666022399,
					"name": "Iran"
				}
			}, {
				"arcs": [
					[-432, 433, 434, 435, 436, 437, 438]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 132,
					"ISO3_CODE": "IRQ",
					"STATUS": "Member State",
					"CAPITAL": "Baghdad",
					"Terr_ID": 116,
					"Terr_Name": "Iraq",
					"Color_Code": "IRQ",
					"Shape_Leng": 37.4439667697,
					"Shape_Area": 42.1032062567,
					"name": "Iraq"
				}
			}, {
				"arcs": [
					[439]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 133,
					"ISO3_CODE": "ISL",
					"STATUS": "Member State",
					"CAPITAL": "Reykjav�k",
					"Terr_ID": 112,
					"Terr_Name": "Iceland",
					"Color_Code": "ISL",
					"Shape_Leng": 98.519490666,
					"Shape_Area": 19.4944912105,
					"name": "Iceland"
				}
			}, {
				"arcs": [
					[440, 441, -8, -9, -6, 442, 443, -304, -4, 444, 445]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 134,
					"ISO3_CODE": "ISR",
					"STATUS": "Member State",
					"CAPITAL": "",
					"Terr_ID": 119,
					"Terr_Name": "Israel",
					"Color_Code": "ISR",
					"Shape_Leng": 11.8294700937,
					"Shape_Area": 1.96433873368,
					"name": "Israel"
				}
			}, {
				"arcs": [
					[
						[446, 447, -338, -207, -87],
						[448]
					],
					[
						[449]
					],
					[
						[450]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 135,
					"ISO3_CODE": "ITA",
					"STATUS": "Member State",
					"CAPITAL": "Rome",
					"Terr_ID": 120,
					"Terr_Name": "Italy",
					"Color_Code": "ITA",
					"Shape_Leng": 91.3604440402,
					"Shape_Area": 33.2250714175,
					"name": "Italy"
				}
			}, {
				"arcs": [
					[451]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 136,
					"ISO3_CODE": "JAM",
					"STATUS": "Member State",
					"CAPITAL": "Kingston",
					"Terr_ID": 121,
					"Terr_Name": "Jamaica",
					"Color_Code": "JAM",
					"Shape_Leng": 6.98660316049,
					"Shape_Area": 0.941417740196,
					"name": "Jamaica"
				}
			}, {
				"arcs": [
					[452, 453, -443, -5, -442, 454, -437]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 137,
					"ISO3_CODE": "JOR",
					"STATUS": "Member State",
					"CAPITAL": "Amman",
					"Terr_ID": 126,
					"Terr_Name": "Jordan",
					"Color_Code": "JOR",
					"Shape_Leng": 16.9779239804,
					"Shape_Area": 8.43445757415,
					"name": "Jordan"
				}
			}, {
				"arcs": [
					[
						[455]
					],
					[
						[456]
					],
					[
						[457]
					],
					[
						[458]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 138,
					"ISO3_CODE": "JPN",
					"STATUS": "Member State",
					"CAPITAL": "Tokyo",
					"Terr_ID": 122,
					"Terr_Name": "Japan",
					"Color_Code": "JPN",
					"Shape_Leng": 219.204607342,
					"Shape_Area": 38.1809703314,
					"name": "Japan"
				}
			}, {
				"arcs": [
					[-234, 459, 460, 461, -97, 462]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 140,
					"ISO3_CODE": "KAZ",
					"STATUS": "Member State",
					"CAPITAL": "Astana",
					"Terr_ID": 128,
					"Terr_Name": "Kazakhstan",
					"Color_Code": "KAZ",
					"Shape_Leng": 148.158014393,
					"Shape_Area": 343.064396045,
					"name": "Kazakhstan"
				}
			}, {
				"arcs": [
					[463, 464, 465, 466, 467, -21, 468, -330]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 141,
					"ISO3_CODE": "KEN",
					"STATUS": "Member State",
					"CAPITAL": "Nairobi",
					"Terr_ID": 129,
					"Terr_Name": "Kenya",
					"Color_Code": "KEN",
					"Shape_Leng": 40.4191927627,
					"Shape_Area": 47.3751907211,
					"name": "Kenya"
				}
			}, {
				"arcs": [
					[-233, 469, 470, -460]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 142,
					"ISO3_CODE": "KGZ",
					"STATUS": "Member State",
					"CAPITAL": "Bishkek",
					"Terr_ID": 134,
					"Terr_Name": "Kyrgyzstan",
					"Color_Code": "KGZ",
					"Shape_Leng": 43.2762475804,
					"Shape_Area": 21.4710302899,
					"name": "Kyrgyzstan"
				}
			}, {
				"arcs": [
					[471, 472, 473, 474]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 143,
					"ISO3_CODE": "KHM",
					"STATUS": "Member State",
					"CAPITAL": "Phnom Penh",
					"Terr_ID": 42,
					"Terr_Name": "Cambodia",
					"Color_Code": "KHM",
					"Shape_Leng": 29.2090975348,
					"Shape_Area": 15.1016265382,
					"name": "Cambodia"
				}
			}, {
				"arcs": [
					[475]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 144,
					"ISO3_CODE": "KIR",
					"STATUS": "Member State",
					"CAPITAL": "Tarawa",
					"Terr_ID": 131,
					"Terr_Name": "Kiribati",
					"Color_Code": "KIR",
					"Shape_Leng": 14.20294091,
					"Shape_Area": 0.0758923137121,
					"name": "Kiribati"
				}
			}, {
				"arcs": [
					[476]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 145,
					"ISO3_CODE": "KNA",
					"STATUS": "Member State",
					"CAPITAL": "Basseterre",
					"Terr_ID": 205,
					"Terr_Name": "Saint Kitts and Nevis",
					"Color_Code": "KNA",
					"Shape_Leng": 1.05427379232,
					"Shape_Area": 0.0234343795966,
					"name": "St. Kitts and Nevis"
				}
			}, {
				"arcs": [
					[477, 478]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 146,
					"ISO3_CODE": "KOR",
					"STATUS": "Member State",
					"CAPITAL": "Seoul",
					"Terr_ID": 199,
					"Terr_Name": "Republic of Korea",
					"Color_Code": "KOR",
					"Shape_Leng": 83.5114619741,
					"Shape_Area": 9.95422093036,
					"name": "Korea"
				}
			}, {
				"arcs": [
					[479, -435, 480]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 147,
					"ISO3_CODE": "KWT",
					"STATUS": "Member State",
					"CAPITAL": "Kuwait",
					"Terr_ID": 133,
					"Terr_Name": "Kuwait",
					"Color_Code": "KWT",
					"Shape_Leng": 10.3927507745,
					"Shape_Area": 1.61648868604,
					"name": "Kuwait"
				}
			}, {
				"arcs": [
					[481, -474, 482, 483, -222]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 148,
					"ISO3_CODE": "LAO",
					"STATUS": "Member State",
					"CAPITAL": "Vientiane",
					"Terr_ID": 135,
					"Terr_Name": "Lao People's Democratic Republic",
					"Color_Code": "LAO",
					"Shape_Leng": 45.0941014624,
					"Shape_Area": 19.7232205953,
					"name": "Loas"
				}
			}, {
				"arcs": [
					[-446, 484, 485]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 149,
					"ISO3_CODE": "LBN",
					"STATUS": "Member State",
					"CAPITAL": "Beirut",
					"Terr_ID": 137,
					"Terr_Name": "Lebanon",
					"Color_Code": "LBN",
					"Shape_Leng": 6.72167078498,
					"Shape_Area": 0.988214024659,
					"name": "Lebanon"
				}
			}, {
				"arcs": [
					[-241, 486, 487, -355]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 150,
					"ISO3_CODE": "LBR",
					"STATUS": "Member State",
					"CAPITAL": "Monrovia",
					"Terr_ID": 140,
					"Terr_Name": "Liberia",
					"Color_Code": "LBR",
					"Shape_Leng": 20.1402360183,
					"Shape_Area": 7.84264535788,
					"name": "Liberia"
				}
			}, {
				"arcs": [
					[-309, 488, 489, 490, -295, 491, 492]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 151,
					"ISO3_CODE": "LBY",
					"STATUS": "Member State",
					"CAPITAL": "Tripoli",
					"Terr_ID": 141,
					"Terr_Name": "Libya",
					"Color_Code": "LBY",
					"Shape_Leng": 58.8235404063,
					"Shape_Area": 147.693664079,
					"name": "Libya"
				}
			}, {
				"arcs": [
					[493]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 152,
					"ISO3_CODE": "LCA",
					"STATUS": "Member State",
					"CAPITAL": "Castries",
					"Terr_ID": 206,
					"Terr_Name": "Saint Lucia",
					"Color_Code": "LCA",
					"Shape_Leng": 1.06574744215,
					"Shape_Area": 0.0516298164366,
					"name": "St. Lucia"
				}
			}, {
				"arcs": [
					[-206, -89]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 153,
					"ISO3_CODE": "LIE",
					"STATUS": "Member State",
					"CAPITAL": "Vaduz",
					"Terr_ID": 142,
					"Terr_Name": "Liechtenstein",
					"Color_Code": "LIE",
					"Shape_Leng": 0.677663325586,
					"Shape_Area": 0.0179004684,
					"name": "Liechtenstein"
				}
			}, {
				"arcs": [
					[494]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 154,
					"ISO3_CODE": "LKA",
					"STATUS": "Member State",
					"CAPITAL": "Sri Jayewardenepura Ko",
					"Terr_ID": 228,
					"Terr_Name": "Sri Lanka",
					"Color_Code": "LKA",
					"Shape_Leng": 21.9956539875,
					"Shape_Area": 5.42328165012,
					"name": "Sri Lanka"
				}
			}, {
				"arcs": [
					[495]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 155,
					"ISO3_CODE": "LSO",
					"STATUS": "Member State",
					"CAPITAL": "Maseru",
					"Terr_ID": 138,
					"Terr_Name": "Lesotho",
					"Color_Code": "LSO",
					"Shape_Leng": 8.39371149554,
					"Shape_Area": 2.8356673621,
					"name": "Lesotho"
				}
			}, {
				"arcs": [
					[-138, 496, 497, 498, 499]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 156,
					"ISO3_CODE": "LTU",
					"STATUS": "Member State",
					"CAPITAL": "Vilnius",
					"Terr_ID": 143,
					"Terr_Name": "Lithuania",
					"Color_Code": "LTU",
					"Shape_Leng": 19.0652983444,
					"Shape_Area": 9.19293512139,
					"name": "Lithuania"
				}
			}, {
				"arcs": [
					[-283, -337, -104]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 157,
					"ISO3_CODE": "LUX",
					"STATUS": "Member State",
					"CAPITAL": "Luxembourg",
					"Terr_ID": 144,
					"Terr_Name": "Luxembourg",
					"Color_Code": "LUX",
					"Shape_Leng": 3.38574205358,
					"Shape_Area": 0.32713597455,
					"name": "Luxembourg"
				}
			}, {
				"arcs": [
					[500, -139, -500, 501, -325]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 158,
					"ISO3_CODE": "LVA",
					"STATUS": "Member State",
					"CAPITAL": "Riga",
					"Terr_ID": 136,
					"Terr_Name": "Latvia",
					"Color_Code": "LVA",
					"Shape_Leng": 20.8708435942,
					"Shape_Area": 9.50345295366,
					"name": "Latvia"
				}
			}, {
				"arcs": [
					[-219, 502]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 159,
					"ISO3_CODE": "MAC",
					"STATUS": "CN Special Administrative Region",
					"CAPITAL": "Macau",
					"Terr_ID": 145,
					"Terr_Name": "Macau",
					"Color_Code": "CHN",
					"Shape_Leng": 0.315927498916,
					"Shape_Area": 0.00147950021099,
					"name": "Macau"
				}
			}, {
				"arcs": [
					[-300, -316, 503]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 160,
					"ISO3_CODE": "MAR",
					"STATUS": "Member State",
					"CAPITAL": "Rabat",
					"Terr_ID": 166,
					"Terr_Name": "Morocco",
					"Color_Code": "MAR",
					"Shape_Leng": 39.4891243195,
					"Shape_Area": 39.5401142185,
					"name": "Morocco"
				}
			}, {
				"arcs": [
					[504, 505]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 162,
					"ISO3_CODE": "MDA",
					"STATUS": "Member State",
					"CAPITAL": "Chisinau",
					"Terr_ID": 161,
					"Terr_Name": "Moldova, Republic of",
					"Color_Code": "MDA",
					"Shape_Leng": 17.453761808,
					"Shape_Area": 4.01274791615,
					"name": "Moldova"
				}
			}, {
				"arcs": [
					[506]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 163,
					"ISO3_CODE": "MDG",
					"STATUS": "Member State",
					"CAPITAL": "Antananarivo",
					"Terr_ID": 146,
					"Terr_Name": "Madagascar",
					"Color_Code": "MDG",
					"Shape_Leng": 69.3436904431,
					"Shape_Area": 50.950881305,
					"name": "Madagascar"
				}
			}, {
				"arcs": [
					[507, -143, -375, 508, 509]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 165,
					"ISO3_CODE": "MEX",
					"STATUS": "Member State",
					"CAPITAL": "M�xico",
					"Terr_ID": 158,
					"Terr_Name": "Mexico",
					"Color_Code": "MEX",
					"Shape_Leng": 192.562457087,
					"Shape_Area": 174.229538618,
					"name": "Mexico"
				}
			}, {
				"arcs": [
					[510]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 166,
					"ISO3_CODE": "MHL",
					"STATUS": "Member State",
					"CAPITAL": "Majuro",
					"Terr_ID": 153,
					"Terr_Name": "Marshall Islands",
					"Color_Code": "MHL",
					"Shape_Leng": 9.40567215158,
					"Shape_Area": 0.0163459007503,
					"name": "Marshall Islands"
				}
			}, {
				"arcs": [
					[-126, -367, -51, 511]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 168,
					"ISO3_CODE": "MKD",
					"STATUS": "Member State",
					"CAPITAL": "Skopje",
					"Terr_ID": 239,
					"Terr_Name": "The former Yugoslav Republic of Macedonia",
					"Color_Code": "MKD",
					"Shape_Leng": 9.01177602169,
					"Shape_Area": 2.74584098815,
					"name": "Macedonia"
				}
			}, {
				"arcs": [
					[-297, 512, -119, -243, -354, 513, 514]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 169,
					"ISO3_CODE": "MLI",
					"STATUS": "Member State",
					"CAPITAL": "Bamako",
					"Terr_ID": 151,
					"Terr_Name": "Mali",
					"Color_Code": "MLI",
					"Shape_Leng": 69.7023089695,
					"Shape_Area": 106.577197622,
					"name": "Mali"
				}
			}, {
				"arcs": [
					[515]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 170,
					"ISO3_CODE": "MLT",
					"STATUS": "Member State",
					"CAPITAL": "Valletta",
					"Terr_ID": 152,
					"Terr_Name": "Malta",
					"Color_Code": "MLT",
					"Shape_Leng": 1.3200921826,
					"Shape_Area": 0.0314471929472,
					"name": "Malta"
				}
			}, {
				"arcs": [
					[516, -120, -425, -13, -223, -484, 517, 518]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 171,
					"ISO3_CODE": "MMR",
					"STATUS": "Member State",
					"CAPITAL": "Yangon",
					"Terr_ID": 168,
					"Terr_Name": "Myanmar",
					"Color_Code": "MMR",
					"Shape_Leng": 177.308440781,
					"Shape_Area": 58.1128363456,
					"name": "Myanmar"
				}
			}, {
				"arcs": [
					[519, -54, 520, -392, -132]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 172,
					"ISO3_CODE": "MNE",
					"STATUS": "Member State",
					"CAPITAL": "Podgorica",
					"Terr_ID": 164,
					"Terr_Name": "Montenegro",
					"Color_Code": "MNE",
					"Shape_Leng": 9.41421287652,
					"Shape_Area": 1.51554207538,
					"name": "Montenegro"
				}
			}, {
				"arcs": [
					[-236, 521]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 173,
					"ISO3_CODE": "MNG",
					"STATUS": "Member State",
					"CAPITAL": "Ulaanbaatar",
					"Terr_ID": 163,
					"Terr_Name": "Mongolia",
					"Color_Code": "MNG",
					"Shape_Leng": 88.179339222,
					"Shape_Area": 184.639227275,
					"name": "Mongolia"
				}
			}, {
				"arcs": [
					[522]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 174,
					"ISO3_CODE": "MNP",
					"STATUS": "US Territory",
					"CAPITAL": "Saipan",
					"Terr_ID": 182,
					"Terr_Name": "Northern Mariana Islands",
					"Color_Code": "USA",
					"Shape_Leng": 2.96336445145,
					"Shape_Area": 0.0400079028853,
					"name": "Northern Mariana Islands"
				}
			}, {
				"arcs": [
					[523, 524, 525, 526, 527, 528, 529, 530]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 175,
					"ISO3_CODE": "MOZ",
					"STATUS": "Member State",
					"CAPITAL": "Maputo",
					"Terr_ID": 167,
					"Terr_Name": "Mozambique",
					"Color_Code": "MOZ",
					"Shape_Leng": 96.2895359845,
					"Shape_Area": 67.0113276336,
					"name": "Mozambique"
				}
			}, {
				"arcs": [
					[-515, 531, 532, -314, -298]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 176,
					"ISO3_CODE": "MRT",
					"STATUS": "Member State",
					"CAPITAL": "Nouakchott",
					"Terr_ID": 155,
					"Terr_Name": "Mauritania",
					"Color_Code": "MRT",
					"Shape_Leng": 57.0938199224,
					"Shape_Area": 89.9112242896,
					"name": "Mauritania"
				}
			}, {
				"arcs": [
					[533]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 177,
					"ISO3_CODE": "MSR",
					"STATUS": "UK Non-Self-Governing Territory",
					"CAPITAL": "Plymouth",
					"Terr_ID": 165,
					"Terr_Name": "Montserrat",
					"Color_Code": "GBR",
					"Shape_Leng": 0.389800857246,
					"Shape_Area": 0.0086864391404,
					"name": "Montserrat"
				}
			}, {
				"arcs": [
					[534]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 178,
					"ISO3_CODE": "MTQ",
					"STATUS": "FR Territory",
					"CAPITAL": "Fort-de-France",
					"Terr_ID": 154,
					"Terr_Name": "Martinique",
					"Color_Code": "FRA",
					"Shape_Leng": 2.38251176207,
					"Shape_Area": 0.0958344302193,
					"name": "Martinique"
				}
			}, {
				"arcs": [
					[535]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 179,
					"ISO3_CODE": "MUS",
					"STATUS": "Member State",
					"CAPITAL": "Port Louis",
					"Terr_ID": 156,
					"Terr_Name": "Mauritius",
					"Color_Code": "MUS",
					"Shape_Leng": 3.08916720969,
					"Shape_Area": 0.174200737204,
					"name": "Mauritius"
				}
			}, {
				"arcs": [
					[-529, 536, 537]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 180,
					"ISO3_CODE": "MWI",
					"STATUS": "Member State",
					"CAPITAL": "Lilongwe",
					"Terr_ID": 148,
					"Terr_Name": "Malawi",
					"Color_Code": "MWI",
					"Shape_Leng": 26.9998996911,
					"Shape_Area": 9.90556682772,
					"name": "Malawi"
				}
			}, {
				"arcs": [
					[
						[-401, 538, -159, 539]
					],
					[
						[540, 541, 542]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 181,
					"ISO3_CODE": "MYS",
					"STATUS": "Member State",
					"CAPITAL": "Kuala Lumpur",
					"Terr_ID": 149,
					"Terr_Name": "Malaysia",
					"Color_Code": "MYS",
					"Shape_Leng": 87.9969479076,
					"Shape_Area": 26.8571264104,
					"name": "Malaysia"
				}
			}, {
				"arcs": [
					[543]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 182,
					"ISO3_CODE": "MYT",
					"STATUS": "FR Territory",
					"CAPITAL": "Mamoudzou",
					"Terr_ID": 157,
					"Terr_Name": "Mayotte",
					"Color_Code": "FRA",
					"Shape_Leng": 1.85563898887,
					"Shape_Area": 0.0321540067036,
					"name": "Mayotte"
				}
			}, {
				"arcs": [
					[544, -166, 545, 546, -43]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 183,
					"ISO3_CODE": "NAM",
					"STATUS": "Member State",
					"CAPITAL": "Windhoek",
					"Terr_ID": 169,
					"Terr_Name": "Namibia",
					"Color_Code": "NAM",
					"Shape_Leng": 52.758489176,
					"Shape_Area": 72.2916308497,
					"name": "Namibia"
				}
			}, {
				"arcs": [
					[547]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 184,
					"ISO3_CODE": "NCL",
					"STATUS": "FR Non-Self-Governing Territory",
					"CAPITAL": "Noum�a",
					"Terr_ID": 175,
					"Terr_Name": "New Caledonia",
					"Color_Code": "FRA",
					"Shape_Leng": 24.6370384576,
					"Shape_Area": 1.64695473729,
					"name": "New Caledonia"
				}
			}, {
				"arcs": [
					[548, 549, -114, -115, -513, -296, -491]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 185,
					"ISO3_CODE": "NER",
					"STATUS": "Member State",
					"CAPITAL": "Niamey",
					"Terr_ID": 178,
					"Terr_Name": "Niger",
					"Color_Code": "NER",
					"Shape_Leng": 53.3817737989,
					"Shape_Area": 100.679977014,
					"name": "Niger"
				}
			}, {
				"arcs": [
					[550]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 186,
					"ISO3_CODE": "NFK",
					"STATUS": "AU Territory",
					"CAPITAL": "Kingston",
					"Terr_ID": 181,
					"Terr_Name": "Norfolk Island",
					"Color_Code": "AUS",
					"Shape_Leng": 0.332922126637,
					"Shape_Area": 0.00351404086721,
					"name": "Norfolk Island"
				}
			}, {
				"arcs": [
					[551, -248, 552, -110, -550]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 187,
					"ISO3_CODE": "NGA",
					"STATUS": "Member State",
					"CAPITAL": "Abuja",
					"Terr_ID": 179,
					"Terr_Name": "Nigeria",
					"Color_Code": "NGA",
					"Shape_Leng": 48.0857211107,
					"Shape_Area": 75.0368333853,
					"name": "Nigeria"
				}
			}, {
				"arcs": [
					[553, -271, 554, -384]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 188,
					"ISO3_CODE": "NIC",
					"STATUS": "Member State",
					"CAPITAL": "Managua",
					"Terr_ID": 177,
					"Terr_Name": "Nicaragua",
					"Color_Code": "NIC",
					"Shape_Leng": 21.4075029589,
					"Shape_Area": 10.7443797113,
					"name": "Nicaragua"
				}
			}, {
				"arcs": [
					[555]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 189,
					"ISO3_CODE": "NIU",
					"STATUS": "NZ Territory",
					"CAPITAL": "Alofi",
					"Terr_ID": 180,
					"Terr_Name": "Niue",
					"Color_Code": "NZL",
					"Shape_Leng": 0.602520460662,
					"Shape_Area": 0.0228456820354,
					"name": "Niue"
				}
			}, {
				"arcs": [
					[-284, -109, 556]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 190,
					"ISO3_CODE": "NLD",
					"STATUS": "Member State",
					"CAPITAL": "Amsterdam",
					"Terr_ID": 173,
					"Terr_Name": "Netherlands",
					"Color_Code": "NLD",
					"Shape_Leng": 31.8915424579,
					"Shape_Area": 4.63276492872,
					"name": "Netherlands"
				}
			}, {
				"arcs": [
					[558, -332, 559, 560]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 191,
					"ISO3_CODE": "NOR",
					"STATUS": "Member State",
					"CAPITAL": "Oslo",
					"Terr_ID": 183,
					"Terr_Name": "Norway",
					"Color_Code": "NOR",
					"Shape_Leng": 563.663496803,
					"Shape_Area": 60.6113704331,
					"name": "Norway"
				}
			}, {
				"arcs": [
					[-424, -225]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 192,
					"ISO3_CODE": "NPL",
					"STATUS": "Member State",
					"CAPITAL": "Kathmandu",
					"Terr_ID": 172,
					"Terr_Name": "Nepal",
					"Color_Code": "NPL",
					"Shape_Leng": 28.9355062897,
					"Shape_Area": 13.5597471957,
					"name": "Nepal"
				}
			}, {
				"arcs": [
					[561]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 193,
					"ISO3_CODE": "NRU",
					"STATUS": "Member State",
					"CAPITAL": "",
					"Terr_ID": 170,
					"Terr_Name": "Nauru",
					"Color_Code": "NRU",
					"Shape_Leng": 0.144695006929,
					"Shape_Area": 0.00148362830834,
					"name": "Nauru"
				}
			}, {
				"arcs": [
					[
						[562]
					],
					[
						[563]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 194,
					"ISO3_CODE": "NZL",
					"STATUS": "Member State",
					"CAPITAL": "Wellington",
					"Terr_ID": 176,
					"Terr_Name": "New Zealand",
					"Color_Code": "NZL",
					"Shape_Leng": 127.692516424,
					"Shape_Area": 29.3423190781,
					"name": "New Zealand"
				}
			}, {
				"arcs": [
					[564, 565, 566, -58]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 195,
					"ISO3_CODE": "OMN",
					"STATUS": "Member State",
					"CAPITAL": "Muscat",
					"Terr_ID": 184,
					"Terr_Name": "Oman",
					"Color_Code": "OMN",
					"Shape_Leng": 38.385371498,
					"Shape_Area": 26.7321984095,
					"name": "Oman"
				}
			}, {
				"arcs": [
					[-26, -427, 568, -430, -37]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 196,
					"ISO3_CODE": "PAK",
					"STATUS": "Member State",
					"CAPITAL": "Islamabad",
					"Terr_ID": 185,
					"Terr_Name": "Pakistan",
					"Color_Code": "PAK",
					"Shape_Leng": 84.2477787557,
					"Shape_Area": 73.9237843553,
					"name": "Pakistan"
				}
			}, {
				"arcs": [
					[-264, 569, -269, 570]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 197,
					"ISO3_CODE": "PAN",
					"STATUS": "Member State",
					"CAPITAL": "Panam�",
					"Terr_ID": 188,
					"Terr_Name": "Panama",
					"Color_Code": "PAN",
					"Shape_Leng": 46.264084607,
					"Shape_Area": 6.15921187912,
					"name": "Panama"
				}
			}, {
				"arcs": [
					[571]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 198,
					"ISO3_CODE": "PCN",
					"STATUS": "UK Non-Self-Governing Territory",
					"CAPITAL": "Adamstown",
					"Terr_ID": 194,
					"Terr_Name": "Pitcairn Islands",
					"Color_Code": "GBR",
					"Shape_Leng": 0.465969192104,
					"Shape_Area": 0.00434864396667,
					"name": "Pitcairn Islands"
				}
			}, {
				"arcs": [
					[-154, -147, -211, 572, -302, -261]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 199,
					"ISO3_CODE": "PER",
					"STATUS": "Member State",
					"CAPITAL": "Lima",
					"Terr_ID": 192,
					"Terr_Name": "Peru",
					"Color_Code": "PER",
					"Shape_Leng": 82.0651653263,
					"Shape_Area": 106.547616209,
					"name": "Peru"
				}
			}, {
				"arcs": [
					[
						[573]
					],
					[
						[574]
					],
					[
						[575]
					],
					[
						[576]
					],
					[
						[577]
					],
					[
						[578]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 200,
					"ISO3_CODE": "PHL",
					"STATUS": "Member State",
					"CAPITAL": "Manila",
					"Terr_ID": 193,
					"Terr_Name": "Philippines",
					"Color_Code": "PHL",
					"Shape_Leng": 241.1600524,
					"Shape_Area": 24.6011351526,
					"name": "Philippines"
				}
			}, {
				"arcs": [
					[579]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 201,
					"ISO3_CODE": "PLW",
					"STATUS": "Member State",
					"CAPITAL": "Koror",
					"Terr_ID": 186,
					"Terr_Name": "Palau",
					"Color_Code": "PLW",
					"Shape_Leng": 2.99023624793,
					"Shape_Area": 0.0376611256099,
					"name": "Palau"
				}
			}, {
				"arcs": [
					[
						[-404, 580]
					],
					[
						[581]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 202,
					"ISO3_CODE": "PNG",
					"STATUS": "Member State",
					"CAPITAL": "Port Moresby",
					"Terr_ID": 189,
					"Terr_Name": "Papua New Guinea",
					"Color_Code": "PNG",
					"Shape_Leng": 162.616653713,
					"Shape_Area": 37.8055900578,
					"name": "Papua New Guinea"
				}
			}, {
				"arcs": [
					[582, -497, -137, 583, 584, -276, -281, 585]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 203,
					"ISO3_CODE": "POL",
					"STATUS": "Member State",
					"CAPITAL": "Warsaw",
					"Terr_ID": 195,
					"Terr_Name": "Poland",
					"Color_Code": "POL",
					"Shape_Leng": 42.7118113378,
					"Shape_Area": 40.9483734702,
					"name": "Poland"
				}
			}, {
				"arcs": [
					[586]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 204,
					"ISO3_CODE": "PRI",
					"STATUS": "US Territory",
					"CAPITAL": "San Juan",
					"Terr_ID": 197,
					"Terr_Name": "Puerto Rico",
					"Color_Code": "USA",
					"Shape_Leng": 7.28158447672,
					"Shape_Area": 0.770336389004,
					"name": "Puerto Rico"
				}
			}, {
				"arcs": [
					[587, 588, -479, 589, -215]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 205,
					"ISO3_CODE": "PRK",
					"STATUS": "Member State",
					"CAPITAL": "Pyongyang",
					"Terr_ID": 64,
					"Terr_Name": "Dem People's Republic of Korea",
					"Color_Code": "PRK",
					"Shape_Leng": 46.4811776952,
					"Shape_Area": 12.9458062729,
					"name": "D.P.R.K"
				}
			}, {
				"arcs": [
					[590, -320, 591, -322]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 206,
					"ISO3_CODE": "PRT",
					"STATUS": "Member State",
					"CAPITAL": "Lisbon",
					"Terr_ID": 196,
					"Terr_Name": "Portugal",
					"Color_Code": "PRT",
					"Shape_Leng": 27.0997686902,
					"Shape_Area": 9.32079083599,
					"name": "Portugal"
				}
			}, {
				"arcs": [
					[592]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 207,
					"ISO3_CODE": "PRT",
					"STATUS": "PT Territory",
					"CAPITAL": "Ponta Delgada",
					"Terr_ID": 17,
					"Terr_Name": "Azores Islands",
					"Color_Code": "PRT",
					"Shape_Leng": 7.41991380775,
					"Shape_Area": 0.248328627302,
					"name": "Azores Islands"
				}
			}, {
				"arcs": [
					[-153, -62, -145]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 208,
					"ISO3_CODE": "PRY",
					"STATUS": "Member State",
					"CAPITAL": "Asunci�n",
					"Terr_ID": 191,
					"Terr_Name": "Paraguay",
					"Color_Code": "PRY",
					"Shape_Leng": 35.5716599229,
					"Shape_Area": 35.2573788877,
					"name": "Paraguay"
				}
			}, {
				"arcs": [
					[593]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 209,
					"ISO3_CODE": "PYF",
					"STATUS": "FR Territory",
					"CAPITAL": "Papeete",
					"Terr_ID": 84,
					"Terr_Name": "French Polynesia",
					"Color_Code": "FRA",
					"Shape_Leng": 36.9161910601,
					"Shape_Area": 0.315080954858,
					"name": "French Polynesia"
				}
			}, {
				"arcs": [
					[594, 595]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 210,
					"ISO3_CODE": "QAT",
					"STATUS": "Member State",
					"CAPITAL": "Doha",
					"Terr_ID": 198,
					"Terr_Name": "Qatar",
					"Color_Code": "QAT",
					"Shape_Leng": 8.27137356878,
					"Shape_Area": 1.03563764594,
					"name": "Qatar"
				}
			}, {
				"arcs": [
					[596]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 211,
					"ISO3_CODE": "REU",
					"STATUS": "FR Territory",
					"CAPITAL": "Saint-Denis",
					"Terr_ID": 200,
					"Terr_Name": "R�union",
					"Color_Code": "FRA",
					"Shape_Leng": 1.93711118823,
					"Shape_Area": 0.22092059471,
					"name": "Riunion"
				}
			}, {
				"arcs": [
					[597, 598, -128, 599, -396, 600, -505]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 212,
					"ISO3_CODE": "ROU",
					"STATUS": "Member State",
					"CAPITAL": "Bucharest",
					"Terr_ID": 201,
					"Terr_Name": "Romania",
					"Color_Code": "ROU",
					"Shape_Leng": 31.6464740226,
					"Shape_Area": 27.5842585241,
					"name": "Romania"
				}
			}, {
				"arcs": [
					[
						[601, -588, -237, -522, -235, -463, -96, -351, 602, 603, -140, -501, -324, 604, -333, -559, 605, 606]
					],
					[
						[607, -607]
					],
					[
						[608]
					],
					[
						[609]
					],
					[
						[610]
					],
					[
						[611]
					],
					[
						[612]
					],
					[
						[613]
					],
					[
						[614]
					],
					[
						[-583, 615, -498]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 213,
					"ISO3_CODE": "RUS",
					"STATUS": "Member State",
					"CAPITAL": "Moskva",
					"Terr_ID": 202,
					"Terr_Name": "Russian Federation",
					"Color_Code": "RUS",
					"Shape_Leng": 1953.09633229,
					"Shape_Area": 2931.11336628,
					"name": "Russia"
				}
			}, {
				"arcs": [
					[616, -102, -252, 617]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 214,
					"ISO3_CODE": "RWA",
					"STATUS": "Member State",
					"CAPITAL": "Kigali",
					"Terr_ID": 203,
					"Terr_Name": "Rwanda",
					"Color_Code": "RWA",
					"Shape_Leng": 8.12700348553,
					"Shape_Area": 2.06385242025,
					"name": "Rwanda"
				}
			}, {
				"arcs": [
					[-436, -480, 618, -595, 619, -59, -567, 620, 621, -453]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 215,
					"ISO3_CODE": "SAU",
					"STATUS": "Member State",
					"CAPITAL": "Riyadh",
					"Terr_ID": 212,
					"Terr_Name": "Saudi Arabia",
					"Color_Code": "SAU",
					"Shape_Leng": 102.521255932,
					"Shape_Area": 171.354538601,
					"name": "Saudi Arabia"
				}
			}, {
				"arcs": [
					[-514, -359, -363, 622, -361, 623, -532]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 216,
					"ISO3_CODE": "SEN",
					"STATUS": "Member State",
					"CAPITAL": "Dakar",
					"Terr_ID": 214,
					"Terr_Name": "Senegal",
					"Color_Code": "SEN",
					"Shape_Leng": 31.5936908559,
					"Shape_Area": 16.5163579367,
					"name": "Senegal"
				}
			}, {
				"arcs": [
					[624]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 217,
					"ISO3_CODE": "SGP",
					"STATUS": "Member State",
					"CAPITAL": "Singapore",
					"Terr_ID": 219,
					"Terr_Name": "Singapore",
					"Color_Code": "SGP",
					"Shape_Leng": 2.03842432597,
					"Shape_Area": 0.0482709362483,
					"name": "Singapore"
				}
			}, {
				"arcs": [
					[625]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 218,
					"ISO3_CODE": "SGS",
					"STATUS": "UK Territory",
					"CAPITAL": "",
					"Terr_ID": 225,
					"Terr_Name": "South Georgia and the South Sandwich Islands",
					"Color_Code": "GBR",
					"Shape_Leng": 15.225151827,
					"Shape_Area": 0.540472572558,
					"name": "South Georgia and the South Sandwich Islands"
				}
			}, {
				"arcs": [
					[626]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 219,
					"ISO3_CODE": "SHN",
					"STATUS": "UK Non-Self-Governing Territory",
					"CAPITAL": "",
					"Terr_ID": 204,
					"Terr_Name": "Saint Helena",
					"Color_Code": "GBR",
					"Shape_Leng": 1.88754725945,
					"Shape_Area": 0.0379102205675,
					"name": "St. Helena"
				}
			}, {
				"arcs": [
					[
						[627]
					],
					[
						[628]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 220,
					"ISO3_CODE": "SJM",
					"STATUS": "NO Territory",
					"CAPITAL": "Longyearbyen",
					"Terr_ID": 231,
					"Terr_Name": "Svalbard and Jan Mayen Islands",
					"Color_Code": "NOR",
					"Shape_Leng": 190.019805851,
					"Shape_Area": 25.8002572614,
					"name": "Svalbard and Jan Mayen Islands"
				}
			}, {
				"arcs": [
					[629]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 221,
					"ISO3_CODE": "SLB",
					"STATUS": "Member State",
					"CAPITAL": "Honiara",
					"Terr_ID": 222,
					"Terr_Name": "Solomon islands",
					"Color_Code": "SLB",
					"Shape_Leng": 66.6518105739,
					"Shape_Area": 2.36156271026,
					"name": "Solomon islands"
				}
			}, {
				"arcs": [
					[-488, 630, -356]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 222,
					"ISO3_CODE": "SLE",
					"STATUS": "Member State",
					"CAPITAL": "Freetown",
					"Terr_ID": 218,
					"Terr_Name": "Sierra Leone",
					"Color_Code": "SLE",
					"Shape_Leng": 18.9961368903,
					"Shape_Area": 5.93630555572,
					"name": "Sierra Leone"
				}
			}, {
				"arcs": [
					[-386, 631, -373]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 223,
					"ISO3_CODE": "SLV",
					"STATUS": "Member State",
					"CAPITAL": "San Salvador",
					"Terr_ID": 72,
					"Terr_Name": "El Salvador",
					"Color_Code": "SLV",
					"Shape_Leng": 8.47373837314,
					"Shape_Area": 1.74396228251,
					"name": "El Salvador"
				}
			}, {
				"arcs": [
					[-449]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 224,
					"ISO3_CODE": "SMR",
					"STATUS": "Member State",
					"CAPITAL": "San Marino",
					"Terr_ID": 210,
					"Terr_Name": "San Marino",
					"Color_Code": "SMR",
					"Shape_Leng": 0.357606728766,
					"Shape_Area": 0.00673116925,
					"name": "San Marino"
				}
			}, {
				"arcs": [
					[-464, -329, -286, 632]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 225,
					"ISO3_CODE": "SOM",
					"STATUS": "Member State",
					"CAPITAL": "Mogadishu",
					"Terr_ID": 223,
					"Terr_Name": "Somalia",
					"Color_Code": "SOM",
					"Shape_Leng": 53.3548883171,
					"Shape_Area": 51.8061110088,
					"name": "Somalia"
				}
			}, {
				"arcs": [
					[633]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 226,
					"ISO3_CODE": "SPM",
					"STATUS": "FR Territory",
					"CAPITAL": "Saint-Pierre",
					"Terr_ID": 207,
					"Terr_Name": "Saint Pierre et Miquelon",
					"Color_Code": "FRA",
					"Shape_Leng": 1.72568202005,
					"Shape_Area": 0.0269381416319,
					"name": "St. Pierre et Miquelon"
				}
			}, {
				"arcs": [
					[-600, -127, -512, -50, -520, -131, -389, -397]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 227,
					"ISO3_CODE": "SRB",
					"STATUS": "Member State",
					"CAPITAL": "Belgrade",
					"Terr_ID": 216,
					"Terr_Name": "Serbia",
					"Color_Code": "SRB",
					"Shape_Leng": 24.9490317312,
					"Shape_Area": 9.93482528958,
					"name": "Serbia"
				}
			}, {
				"arcs": [
					[634]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 228,
					"ISO3_CODE": "STP",
					"STATUS": "Member State",
					"CAPITAL": "S?o Tom�",
					"Terr_ID": 211,
					"Terr_Name": "Sao Tome and Principe",
					"Color_Code": "STP",
					"Shape_Leng": 2.03983242691,
					"Shape_Area": 0.0819441933461,
					"name": "Sao Tome and Principe"
				}
			}, {
				"arcs": [
					[-376, -149, -379, 635]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 229,
					"ISO3_CODE": "SUR",
					"STATUS": "Member State",
					"CAPITAL": "Paramaribo",
					"Terr_ID": 230,
					"Terr_Name": "Suriname",
					"Color_Code": "SUR",
					"Shape_Leng": 19.4485169569,
					"Shape_Area": 11.8005425388,
					"name": "Suriname"
				}
			}, {
				"arcs": [
					[636, -399, -84, -277, -585]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 230,
					"ISO3_CODE": "SVK",
					"STATUS": "Member State",
					"CAPITAL": "Bratislava",
					"Terr_ID": 220,
					"Terr_Name": "Slovakia",
					"Color_Code": "SVK",
					"Shape_Leng": 16.1247013028,
					"Shape_Area": 5.99578710525,
					"name": "Slovakia"
				}
			}, {
				"arcs": [
					[-391, 637, -447, -86, -398]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 231,
					"ISO3_CODE": "SVN",
					"STATUS": "Member State",
					"CAPITAL": "Ljubljana",
					"Terr_ID": 221,
					"Terr_Name": "Slovenia",
					"Color_Code": "SVN",
					"Shape_Leng": 11.720025245,
					"Shape_Area": 2.3653457037,
					"name": "Slovenia"
				}
			}, {
				"arcs": [
					[638, -560, -331]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 232,
					"ISO3_CODE": "SWE",
					"STATUS": "Member State",
					"CAPITAL": "Stockholm",
					"Terr_ID": 233,
					"Terr_Name": "Sweden",
					"Color_Code": "SWE",
					"Shape_Leng": 192.235967647,
					"Shape_Area": 79.0945637121,
					"name": "Sweden"
				}
			}, {
				"arcs": [
					[-525, 639]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 233,
					"ISO3_CODE": "SWZ",
					"STATUS": "Member State",
					"CAPITAL": "Mbabane (adm.)",
					"Terr_ID": 232,
					"Terr_Name": "Swaziland",
					"Color_Code": "SWZ",
					"Shape_Leng": 5.12855758088,
					"Shape_Area": 1.5662385121,
					"name": "Swaziland"
				}
			}, {
				"arcs": [
					[640]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 234,
					"ISO3_CODE": "SYC",
					"STATUS": "Member State",
					"CAPITAL": "Victoria",
					"Terr_ID": 217,
					"Terr_Name": "Seychelles",
					"Color_Code": "SYC",
					"Shape_Leng": 5.44366560971,
					"Shape_Area": 0.0389479415563,
					"name": "Seychelles"
				}
			}, {
				"arcs": [
					[-438, -455, -441, -486, 641, 642]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 235,
					"ISO3_CODE": "SYR",
					"STATUS": "Member State",
					"CAPITAL": "Damascus",
					"Terr_ID": 235,
					"Terr_Name": "Syrian Arab Republic",
					"Color_Code": "SYR",
					"Shape_Leng": 24.4891023493,
					"Shape_Area": 18.58324377,
					"name": "Syria"
				}
			}, {
				"arcs": [
					[643]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 236,
					"ISO3_CODE": "TCA",
					"STATUS": "UK Non-Self-Governing Territory",
					"CAPITAL": "Cockburn Town",
					"Terr_ID": 249,
					"Terr_Name": "Turks and Caicos islands",
					"Color_Code": "GBR",
					"Shape_Leng": 5.63953597616,
					"Shape_Area": 0.0476991819879,
					"name": "Turks and Caicos"
				}
			}, {
				"arcs": [
					[644, -172, -249, -552, -549, -490]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 237,
					"ISO3_CODE": "TCD",
					"STATUS": "Member State",
					"CAPITAL": "N'Djamena",
					"Terr_ID": 48,
					"Terr_Name": "Chad",
					"Color_Code": "TCD",
					"Shape_Leng": 55.9561364794,
					"Shape_Area": 106.690108734,
					"name": "Chad"
				}
			}, {
				"arcs": [
					[-112, 645, -352, -116]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 238,
					"ISO3_CODE": "TGO",
					"STATUS": "Member State",
					"CAPITAL": "Lom�",
					"Terr_ID": 241,
					"Terr_Name": "Togo",
					"Color_Code": "TGO",
					"Shape_Leng": 16.0804062673,
					"Shape_Area": 4.66678650161,
					"name": "Togo"
				}
			}, {
				"arcs": [
					[-483, -473, 646, -543, 647, -518]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 239,
					"ISO3_CODE": "THA",
					"STATUS": "Member State",
					"CAPITAL": "Bangkok",
					"Terr_ID": 238,
					"Terr_Name": "Thailand",
					"Color_Code": "THA",
					"Shape_Leng": 94.7037077628,
					"Shape_Area": 43.4036363235,
					"name": "Thailand"
				}
			}, {
				"arcs": [
					[-470, -232, -41, 648]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 240,
					"ISO3_CODE": "TJK",
					"STATUS": "Member State",
					"CAPITAL": "Dushanbe",
					"Terr_ID": 237,
					"Terr_Name": "Tajikistan",
					"Color_Code": "TJK",
					"Shape_Leng": 40.097194764,
					"Shape_Area": 14.6719042992,
					"name": "Tajikistan"
				}
			}, {
				"arcs": [
					[649]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 241,
					"ISO3_CODE": "TKL",
					"STATUS": "NZ Non-Self-Governing Territory",
					"CAPITAL": "",
					"Terr_ID": 242,
					"Terr_Name": "Tokelau",
					"Color_Code": "GBR",
					"Shape_Leng": 0.290606697029,
					"Shape_Area": 0.00165031930971,
					"name": "Tokelau"
				}
			}, {
				"arcs": [
					[-39, -429, -93, -462, 650]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 242,
					"ISO3_CODE": "TKM",
					"STATUS": "Member State",
					"CAPITAL": "Ashgabat",
					"Terr_ID": 248,
					"Terr_Name": "Turkmenistan",
					"Color_Code": "TKM",
					"Shape_Leng": 48.2290257925,
					"Shape_Area": 57.9088354748,
					"name": "Turkmenistan"
				}
			}, {
				"arcs": [
					[-412, 651]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 243,
					"ISO3_CODE": "TLS",
					"STATUS": "Member State",
					"CAPITAL": "Dili",
					"Terr_ID": 240,
					"Terr_Name": "Timor-Leste",
					"Color_Code": "TLS",
					"Shape_Leng": 9.42177035729,
					"Shape_Area": 1.22397214323,
					"name": "Timor-Leste"
				}
			}, {
				"arcs": [
					[653]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 244,
					"ISO3_CODE": "TON",
					"STATUS": "Member State",
					"CAPITAL": "Nuku'alofa",
					"Terr_ID": 243,
					"Terr_Name": "Tonga",
					"Color_Code": "TON",
					"Shape_Leng": 6.2271341688,
					"Shape_Area": 0.057997885553,
					"name": "Tonga"
				}
			}, {
				"arcs": [
					[654]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 245,
					"ISO3_CODE": "TTO",
					"STATUS": "Member State",
					"CAPITAL": "Port of Spain",
					"Terr_ID": 244,
					"Terr_Name": "Trinidad and Tobago",
					"Color_Code": "TTO",
					"Shape_Leng": 5.15463754312,
					"Shape_Area": 0.427864943009,
					"name": "Trinidad and Tobago"
				}
			}, {
				"arcs": [
					[-492, -294, 655]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 246,
					"ISO3_CODE": "TUN",
					"STATUS": "Member State",
					"CAPITAL": "Tunis",
					"Terr_ID": 246,
					"Terr_Name": "Tunisia",
					"Color_Code": "TUN",
					"Shape_Leng": 30.3149834088,
					"Shape_Area": 15.2000317195,
					"name": "Tunisia"
				}
			}, {
				"arcs": [
					[
						[-349, -72, -99, -433, -439, -643, 656]
					],
					[
						[657, -365, -124]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 247,
					"ISO3_CODE": "TUR",
					"STATUS": "Member State",
					"CAPITAL": "Ankara",
					"Terr_ID": 247,
					"Terr_Name": "Turkey",
					"Color_Code": "TUR",
					"Shape_Leng": 97.5742858021,
					"Shape_Area": 81.3293434918,
					"name": "Turkey"
				}
			}, {
				"arcs": [
					[658]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 248,
					"ISO3_CODE": "TUV",
					"STATUS": "Member State",
					"CAPITAL": "Funafuti",
					"Terr_ID": 250,
					"Terr_Name": "Tuvalu",
					"Color_Code": "TUV",
					"Shape_Leng": 1.07071023835,
					"Shape_Area": 0.00245698121385,
					"name": "Tuvalu"
				}
			}, {
				"arcs": [
					[659]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 249,
					"ISO3_CODE": "TWN",
					"STATUS": "CN Province",
					"CAPITAL": "Taipei",
					"Terr_ID": 236,
					"Terr_Name": "Taiwan",
					"Color_Code": "CHN",
					"Shape_Leng": 13.7517021086,
					"Shape_Area": 3.19982096794,
					"name": "Taiwan"
				}
			}, {
				"arcs": [
					[-466, 660, -530, -538, 661, -253, -100, -617, 662]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 250,
					"ISO3_CODE": "TZA",
					"STATUS": "Member State",
					"CAPITAL": "Dodoma",
					"Terr_ID": 255,
					"Terr_Name": "United Republic of Tanzania",
					"Color_Code": "TZA",
					"Shape_Leng": 59.6541685352,
					"Shape_Area": 76.9343472127,
					"name": "Tanzania"
				}
			}, {
				"arcs": [
					[-663, -618, -251, 663, -467]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 251,
					"ISO3_CODE": "UGA",
					"STATUS": "Member State",
					"CAPITAL": "Kampala",
					"Terr_ID": 252,
					"Terr_Name": "Uganda",
					"Color_Code": "UGA",
					"Shape_Leng": 23.2675091562,
					"Shape_Area": 19.6298003093,
					"name": "Uganda"
				}
			}, {
				"arcs": [
					[664, -598, -506, -601, -395, -637, -584, -136, -604]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 252,
					"ISO3_CODE": "UKR",
					"STATUS": "Member State",
					"CAPITAL": "Ky�v",
					"Terr_ID": 253,
					"Terr_Name": "Ukraine",
					"Color_Code": "UKR",
					"Shape_Leng": 91.379340589,
					"Shape_Area": 73.842561106,
					"name": "Ukraine"
				}
			}, {
				"arcs": [
					[665, -64, -152]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 254,
					"ISO3_CODE": "URY",
					"STATUS": "Member State",
					"CAPITAL": "Montevideo",
					"Terr_ID": 258,
					"Terr_Name": "Uruguay",
					"Color_Code": "URY",
					"Shape_Leng": 21.7102709931,
					"Shape_Area": 17.1534921239,
					"name": "Uruguay"
				}
			}, {
				"arcs": [
					[
						[666, -188, 667, -510, 668, -176, 669, 670, 671, 672]
					],
					[
						[-182, 673]
					],
					[
						[-180, 674]
					],
					[
						[675]
					],
					[
						[677]
					]
				],
				"type": "MultiPolygon",
				"properties": {
					"OBJECTID": 255,
					"ISO3_CODE": "USA",
					"STATUS": "Member State",
					"CAPITAL": "Washington, D.C.",
					"Terr_ID": 256,
					"Terr_Name": "United States of America",
					"Color_Code": "USA",
					"Shape_Leng": 1246.4936097,
					"Shape_Area": 1101.12490132,
					"name": "United States"
				}
			}, {
				"arcs": [
					[-471, -649, -40, -651, -461]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 256,
					"ISO3_CODE": "UZB",
					"STATUS": "Member State",
					"CAPITAL": "Tashkent",
					"Terr_ID": 259,
					"Terr_Name": "Uzbekistan",
					"Color_Code": "UZB",
					"Shape_Leng": 67.4218107843,
					"Shape_Area": 48.6957499186,
					"name": "Uzbekistan"
				}
			}, {
				"type": null,
				"properties": {
					"OBJECTID": 257,
					"ISO3_CODE": "VAT",
					"STATUS": "The City of Vatican",
					"CAPITAL": "",
					"Terr_ID": 107,
					"Terr_Name": "Holy See",
					"Color_Code": "VAT",
					"Shape_Leng": 0.0297435344486,
					"Shape_Area": 0.0000539858500001,
					"name": "Holy See"
				}
			}, {
				"arcs": [
					[678]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 258,
					"ISO3_CODE": "VCT",
					"STATUS": "Member State",
					"CAPITAL": "Kingstown",
					"Terr_ID": 208,
					"Terr_Name": "Saint Vincent and the Grenadines",
					"Color_Code": "VCT",
					"Shape_Leng": 2.08025366103,
					"Shape_Area": 0.0376133883214,
					"name": "St. Vincent and the Grenadines"
				}
			}, {
				"arcs": [
					[-380, -156, -260, 679]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 259,
					"ISO3_CODE": "VEN",
					"STATUS": "Member State",
					"CAPITAL": "Caracas",
					"Terr_ID": 261,
					"Terr_Name": "Venezuela",
					"Color_Code": "VEN",
					"Shape_Leng": 94.0296415628,
					"Shape_Area": 74.7856338614,
					"name": "Venezuela"
				}
			}, {
				"arcs": [
					[680]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 260,
					"ISO3_CODE": "VGB",
					"STATUS": "UK Non-Self-Governing Territory",
					"CAPITAL": "Road Town",
					"Terr_ID": 37,
					"Terr_Name": "British Virgin Islands",
					"Color_Code": "GBR",
					"Shape_Leng": 2.31943051477,
					"Shape_Area": 0.0145291088039,
					"name": "B.V.I."
				}
			}, {
				"arcs": [
					[681]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 261,
					"ISO3_CODE": "VIR",
					"STATUS": "US Non-Self-Governing Territory",
					"CAPITAL": "Charlotte Amalie",
					"Terr_ID": 257,
					"Terr_Name": "United States Virgin Islands",
					"Color_Code": "USA",
					"Shape_Leng": 2.35670428022,
					"Shape_Area": 0.0314797981573,
					"name": "U.S. Virgin Islands"
				}
			}, {
				"arcs": [
					[682, -475, -482, -221]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 262,
					"ISO3_CODE": "VNM",
					"STATUS": "Member State",
					"CAPITAL": "Hanoi",
					"Terr_ID": 262,
					"Terr_Name": "Viet Nam",
					"Color_Code": "VNM",
					"Shape_Leng": 92.4963170504,
					"Shape_Area": 27.8335090758,
					"name": "Viet Nam"
				}
			}, {
				"arcs": [
					[683]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 263,
					"ISO3_CODE": "VUT",
					"STATUS": "Member State",
					"CAPITAL": "Port-Vila",
					"Terr_ID": 260,
					"Terr_Name": "Vanuatu",
					"Color_Code": "VUT",
					"Shape_Leng": 26.0025473239,
					"Shape_Area": 1.03404343181,
					"name": "Vanuatu"
				}
			}, {
				"arcs": [
					[684]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 265,
					"ISO3_CODE": "WLF",
					"STATUS": "FR Territory",
					"CAPITAL": "Mata-Utu",
					"Terr_ID": 264,
					"Terr_Name": "Wallis and Futuna",
					"Color_Code": "FRA",
					"Shape_Leng": 1.01872214355,
					"Shape_Area": 0.0146333970685,
					"name": "Wallis and Futuna"
				}
			}, {
				"arcs": [
					[685]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 266,
					"ISO3_CODE": "WSM",
					"STATUS": "Member State",
					"CAPITAL": "Apia",
					"Terr_ID": 209,
					"Terr_Name": "Samoa",
					"Color_Code": "WSM",
					"Shape_Leng": 3.79340142709,
					"Shape_Area": 0.240451208105,
					"name": "Samoa"
				}
			}, {
				"arcs": [
					[686, -306, 687]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 267,
					"ISO3_CODE": "SDN",
					"STATUS": "Sovereignty unsettled",
					"CAPITAL": "",
					"Terr_ID": 0,
					"Terr_Name": "Hala'ib triangle",
					"Color_Code": "SDN",
					"Shape_Leng": 9.55226988199,
					"Shape_Area": 1.56343746302,
					"name": "Sint Maarten"
				}
			}, {
				"arcs": [
					[-307, 688]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 268,
					"ISO3_CODE": "EGY",
					"STATUS": "Sovereignty unsettled",
					"CAPITAL": "",
					"Terr_ID": 0,
					"Terr_Name": "Ma'tan al-Sarra",
					"Color_Code": "EGY",
					"Shape_Leng": 2.06262812158,
					"Shape_Area": 0.173918914222,
					"name": "Sint Maarten"
				}
			}, {
				"arcs": [
					[689]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 270,
					"ISO3_CODE": "XXX",
					"STATUS": "US Territory",
					"CAPITAL": "",
					"Terr_ID": 187,
					"Terr_Name": "Palmyra Atoll",
					"Color_Code": "USA",
					"Shape_Leng": 0.175752982665,
					"Shape_Area": 0.00069575075374,
					"name": "Palmyra Atoll"
				}
			}, {
				"arcs": [
					[690, -621, -566]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 271,
					"ISO3_CODE": "YEM",
					"STATUS": "Member State",
					"CAPITAL": "Sanaa",
					"Terr_ID": 267,
					"Terr_Name": "Yemen",
					"Color_Code": "YEM",
					"Shape_Leng": 43.1484291449,
					"Shape_Area": 38.2885227408,
					"name": "Yemen"
				}
			}, {
				"arcs": [
					[-526, -640, -524, 691, -546, -165, 692],
					[-496]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 272,
					"ISO3_CODE": "ZAF",
					"STATUS": "Member State",
					"CAPITAL": "Pretoria (Adm.)",
					"Terr_ID": 224,
					"Terr_Name": "South Africa",
					"Color_Code": "ZAF",
					"Shape_Leng": 79.8522064703,
					"Shape_Area": 113.164987757,
					"name": "South Africa"
				}
			}, {
				"arcs": [
					[-537, -528, 693, -545, -42, -254, -662]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 273,
					"ISO3_CODE": "ZMB",
					"STATUS": "Member State",
					"CAPITAL": "Lusaka",
					"Terr_ID": 268,
					"Terr_Name": "Zambia",
					"Color_Code": "ZMB",
					"Shape_Leng": 54.1965705996,
					"Shape_Area": 62.7442807716,
					"name": "Zambia"
				}
			}, {
				"arcs": [
					[-527, -693, -164, -694]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 274,
					"ISO3_CODE": "ZWE",
					"STATUS": "Member State",
					"CAPITAL": "Harare",
					"Terr_ID": 269,
					"Terr_Name": "Zimbabwe",
					"Color_Code": "ZWE",
					"Shape_Leng": 28.1832453073,
					"Shape_Area": 33.5306894643,
					"name": "Zimbabwe"
				}
			}, {
				"arcs": [
					[-689, -687, 694, -312, -328, 695, 696, 697, -167, -645, -489, -308]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 275,
					"ISO3_CODE": "SDN",
					"STATUS": "Member State",
					"CAPITAL": "Khartoum",
					"Terr_ID": 229,
					"Terr_Name": "Sudan",
					"Color_Code": "SDN",
					"Shape_Leng": 74.411742789,
					"Shape_Area": 155.828657232,
					"name": "Sudan"
				}
			}, {
				"arcs": [
					[-327, -469, -22, -468, -664, -250, -168, -698, 698, -696]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 276,
					"ISO3_CODE": "SSD",
					"STATUS": "Member State",
					"CAPITAL": "Juba",
					"Terr_ID": 270,
					"Terr_Name": "South Sudan",
					"Color_Code": "SSD",
					"Shape_Leng": 46.9250502942,
					"Shape_Area": 51.5990196091,
					"name": "South Sudan"
				}
			}, {
				"arcs": [
					[-697, -699]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 277,
					"ISO3_CODE": "SSD",
					"STATUS": "Sovereignty unsettled",
					"CAPITAL": "",
					"Terr_ID": 0,
					"Terr_Name": "Abyei",
					"Color_Code": "SSD",
					"Shape_Leng": 3.68666474539,
					"Shape_Area": 0.865847846725,
					"name": "Sint Maarten"
				}
			}, {
				"arcs": [
					[699]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 278,
					"ISO3_CODE": "GLP",
					"STATUS": "FR Territory",
					"CAPITAL": "Basse-Terre",
					"Terr_ID": 97,
					"Terr_Name": "Guadeloupe",
					"Color_Code": "FRA",
					"Shape_Leng": 3.65083002376,
					"Shape_Area": 0.141416442751,
					"name": "St. Martin"
				}
			}, {
				"arcs": [
					[700, 701]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 281,
					"ISO3_CODE": "MAF",
					"STATUS": "FR Territory",
					"CAPITAL": "Marigot",
					"Terr_ID": 97,
					"Terr_Name": "Saint Martin",
					"Color_Code": "FRA",
					"Shape_Leng": 0.471258321939,
					"Shape_Area": 0.00466901484034,
					"name": "St. Martin"
				}
			}, {
				"arcs": [
					[702]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 283,
					"ISO3_CODE": "BLM",
					"STATUS": "FR Territory",
					"CAPITAL": "Gustavia",
					"Terr_ID": 97,
					"Terr_Name": "Saint Barth�lemy",
					"Color_Code": "FRA",
					"Shape_Leng": 0.247875629791,
					"Shape_Area": 0.00184800140185,
					"name": "St. Martin"
				}
			}, {
				"arcs": [
					[703]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 290,
					"ISO3_CODE": "CUW",
					"STATUS": "NL Territory",
					"CAPITAL": "Willemstad",
					"Terr_ID": 0,
					"Terr_Name": "Cura�ao",
					"Color_Code": "CUW",
					"Shape_Leng": 1.32133758264,
					"Shape_Area": 0.0371499565261,
					"name": "Sint Maarten"
				}
			}, {
				"arcs": [
					[704]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 291,
					"ISO3_CODE": "BES",
					"STATUS": "NL Territory",
					"CAPITAL": "",
					"Terr_ID": 0,
					"Terr_Name": "Bonaire, Sint Eustatius and Saba",
					"Color_Code": "NLD",
					"Shape_Leng": 1.40817451842,
					"Shape_Area": 0.0270118232623,
					"name": "Sint Maarten"
				}
			}, {
				"arcs": [
					[705, -701]
				],
				"type": "Polygon",
				"properties": {
					"OBJECTID": 292,
					"ISO3_CODE": "SXM",
					"STATUS": "NL Territory",
					"CAPITAL": "Philipsburg",
					"Terr_ID": 0,
					"Terr_Name": "Sint Maarten",
					"Color_Code": "SXM",
					"Shape_Leng": 0.346042843454,
					"Shape_Area": 0.00353185943796,
					"name": "Sint Maarten"
				}
			}]
		}
	}
};
