//bubMap
import * as d3 from 'd3';
import { geoArmadillo } from 'd3-geo-projection';
import * as topojson from "topojson";

//other stuff
import { parseTransform } from "./ddd";
import { vbSize } from "./ddd";
import { textEdit } from "./ddd";
import { toolTip } from "./ddd";
import { cokeGeos } from "../ddd/mapdata/geos.js";
import { circlePack } from "../ddd/circlePack.js";

//require map data
const mapdata = require("../ddd/mapdata/admin0.json");

var isoLookup = {};
//This just finds cokes short names.
cokeGeos.forEach(function(d) {
    isoLookup[d.ISO3] = d.COKE_SPELLING;
})
//sort, filters

export function bubMap(svg, sData, fData, sort, filters) {
    console.log('bubmap');
    //console.log(mapdata);
    //Layers
    var gridLr = svg.select("#l-grid");
    gridLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
    var mapLr = svg.select("#l-map");
    mapLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
    var dataLr = svg.select("#l-data");
    var copyLr = svg.select("#l-copy");
    var stageLr = svg.select("#l-stage");
    //Sizes
    var svgSize = vbSize(svg);
    var svgHeight = svgSize[0];
    var svgWidth = svgSize[1];
    var gcoords = parseTransform(dataLr.attr("transform")); //Grab the corrdinates of thedata layer which has a margin from top and left
    var gWidth = svgWidth - (gcoords[0] * 2); //50 the sides.
    var gHeight = svgHeight - (gcoords[1] + gcoords[0]); //100 - the top

    var margin = { top: 100, right: 50, bottom: 50, left: 50 };

    var colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    //Transitions
    var tIntro = d3.transition()
        .duration(2000)
        .ease(d3.easePolyInOut);

    //cut out entries with no ISO.
    fData = fData.filter(function(p) {
        return p.ISO != "";
    });

    update(fData);
    //General Update Pattern
    function update(data) {
        //Access the map shapes. Switch this to require.
        //var iso = topojson.feature(admin0, admin0.objects.layer1);
        var isoMap = topojson.feature(mapdata, mapdata.objects.layer1);
        //Create a mercator propjection
        var proj = d3.geoMercator()
            .scale(200)
            .center([10, 0])
            .rotate([-10, 0, 0]) //.rotate([-10, 0, 0]) to center russia
            .translate([svgWidth / 2, svgHeight / 2]);
        /*            .clipExtent([
                        [0, 0],
                        [gWidth, 600]
                    ]);*/

        //Now create a geoPath generator that uses the projection.
        var path = d3.geoPath()
            .projection(proj);


        //Now we creat the simulation with the filtered data.
        var simulation = d3.forceSimulation(data)
            //add the first foirce, and x – left right – force.
            .force("x", d3.forceX(function(d) {
                //console.log(d);
                var thePath = isoMap.features;
                thePath = thePath.filter(function(p) {
                    //return p.properties.ISO3_CODE == d.ISO && p.properties.STATUS == "Member State";
                    return p.properties.ISO3_CODE == d.ISO;
                });
                //console.log(thePath)
                thePath = thePath[0];
                var x = path.centroid(thePath)[0];
                var y = path.centroid(thePath)[1];
                //return "translate(" + x + ", " + y + ")";

                return x;
            }).strength(.5))
            .force("y", d3.forceY(function(d) {
                //console.log(d);
                var thePath = isoMap.features;
                thePath = thePath.filter(function(p) {
                    return p.properties.ISO3_CODE == d.ISO;
                });
                //console.log(thePath)
                thePath = thePath[0];
                var x = path.centroid(thePath)[0];
                var y = path.centroid(thePath)[1];
                //return "translate(" + x + ", " + y + ")";

                return y;
            }).strength(.5))
            //this is where you set the size i think
            //can you do it after?
            .force("collide", d3.forceCollide(20))
            .stop();

        //Why does he do this 120 times? Just because he assumes that is enough times?
        for (var i = 0; i < 120; ++i) simulation.tick();

        //Add graticule lines for the world map.
        var graticule = d3.geoGraticule().step([30, 30]);
        var lines = mapLr.append("path")
            .datum(graticule)
            .classed('graticule', true)
            .attr("d", path);

        //Now draw the countries
        var countries = mapLr.selectAll(".countries")
            .data(isoMap.features)
            .enter().append("path")
            .classed("countries", true)
            .classed("highlight-poly", function(d) {
                var color = false;
                var thisISO = d.properties.ISO3_CODE;
                return color;
            })
            .attr("d", path)
            .style("opacity", 0)
            .on("click", function(d) {
                console.log(d);
            });

        countries.transition().duration(1000).style("opacity", 1)

        var bubs = dataLr.selectAll(".data-bub")
            .data(data, function(d, i) {
                return d.id || (d.id = ++i);
            });


        //remove any that dont need to be there.
        gridLr.selectAll("*").remove();

        //The first time around there wont be any nodes in the exit, but no matter.
        bubs.exit()
            .transition().duration(3000)
            .style("opacity", 0)
            .each(function(d) {
                //Log out the removed elements.
                console.log(d);
            })
            .remove();

        var enter = bubs.enter() //add any new ones - a group and a circle within it.
            .append("g")
            .classed("data-bub", true)
            .on("click", function(d) {
                console.log(d);
            });

        enter.append("circle")
            .attr("r", 20)
            .classed("bub", true)
            .each(function(d) {
                //Log out the new elements.
                console.log(d.id);
            })

        //But nothing happens. Why is the update selection empty after merge?
        bubs = enter.merge(bubs);

        bubs.transition(tIntro)
            .attr("transform", function(d, i) {
                //console.log(d);
                var y = d.y;
                var x = d.x;
                return "translate(" + x + "," + y + ")";
            })


        bubs.select(".bub")
            .transition(tIntro)
            .style("fill", function(d) {
                return colorScale(d.ISO);
            })
            .style("fill-opacity", .5);


        //make a scale for the bubbles.
        //var bubScale = d3.
        //the scale for circle radius
        /*        var countryMax = d3.max(isoSort, function(d) {
                    return d.count;
                })
        */
        /*        var radius = d3.scaleSqrt()
                    .domain([0, countryMax])
                    .range([0, (mapW * .05)]);*/

        /*        var dataBubs = svg.selectAll(".data-bub")
                    .data(data)
                    .append("g")
                    .attr("transform", function(d) {
                        console.log(d)
                        var thePath = isoMap.features;
                        thePath = thePath.filter(function(p) {
                            return p.properties.ISO3_CODE == d.key && p.properties.STATUS == "Member State";
                        });
                        console.log(thePath)
                        thePath = thePath[0];
                        var x = path.centroid(thePath)[0];
                        var y = path.centroid(thePath)[1];
                        return "translate(" + x + ", " + y + ")";
                    });
        */
        //Add Circle packing to map, maybe make an option
        /*        var packs = dataBubs.each(function(d) {
                    var elem = d3.select(this);
                    //console.log(elem);
                    var thePath = iso.features;
                    thePath = thePath.filter(function(p) {
                        return p.properties.ISO3_CODE == d.key && p.properties.STATUS == "Member State";
                    });
                    thePath = thePath[0];
                    var loc = path.centroid(thePath);
                    var x = loc[0] - radius(d.count);
                    var y = loc[1] - radius(d.count);
                    loc = [x, y]
                    var circSize = radius(d.count) * 2;

                    var root = d3.hierarchy(d, function(d) {
                            //console.log("doing it");
                            return d.values;
                        })
                        .count()


                    circlePack(elem, [circSize, circSize], loc, root);
                })*/

    }

    //headline et all
    if (stageLr.select("#headline").empty()) {
        var headline = stageLr.append("text")
            .attr("id", "headline")
            .attr("x", 50)
            .classed("gfx-head", true)
            .text(function(d) {
                var recCount = fData.length;
                //console.log(recCount);
                //return recCount + " Projects in the pipeline";
                return "";
            })
            .attr("y", function() {
                var textNode = d3.select(this);
                var textHeight = textNode.node().getBBox().height;
                return textHeight + 14;
            })
            .call(textEdit, "headline");


        stageLr.append('rect')
            .attr("height", svgHeight)
            .attr("width", svgWidth)
            .classed("box1", true);

    }

}