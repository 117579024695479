import * as d3 from "d3";
// this is to use an editable text object...Used in the pipeline project.

export function textEdit(d) {
    //The first argument here is a d3 selection.
    //Why did I name the second argument field?
    //console.log("textEdit", arguments);
    //console.log(d);

    d.on("mouseover", function() {
            d.classed("hoverchange", true);
        })
        .on("mouseout", function() {
            d.classed("hoverchange", false);
        })
        .on("click", function(d) {
            var el = d3.select(this);
            var p = this.parentNode;
            var p_el = d3.select(p);
            /*            console.log("This is this:");
                        console.log(this)
                        console.log("This is arguments:");
                        console.log(arguments);
                        console.log("This is d:");
                        console.log(d);
            */

            // inject a HTML form to edit the content here...

            // bug in the getBBox logic here, but don't know what I've done wrong here;
            // anyhow, the coordinates are completely off & wrong. :-((
            var xy = el.node().getBBox();
            console.log(xy);
            var p_xy = p_el.node().getBBox();
            //console.log(p);
            console.log(p_xy);
            var inpWidth = 300;
            var inpHeight = xy.height;

            xy.x -= p_xy.x;
            xy.y -= p_xy.y;


            var fobject = p_el.append("foreignObject");


            //still something not right for sure.. Maybe cause its not in a group - the text box
            var form = fobject
                .attr("x", xy.x)
                .attr("y", function() {
                    return xy.y + inpHeight;
                })
                .attr("width", inpWidth)
                .attr("height", inpHeight)
                .append("xhtml:form")
                .classed("form-control", true);

            var inp = form.append("input")
                .style("width", function() {
                    return (inpWidth - 30) + "px";
                })
                .on("blur", function() {
                    var txt = inp.node().value.toUpperCase();
                    if (txt != "") {
                        el.text(txt);
                    } else {
                        el.text("HEADLINE");
                    }
                    p_el.select("foreignObject").remove();
                })
                .on("keypress", function() {
                    var e = d3.event;
                    var txt = inp.node().value.toUpperCase();
                    console.log(txt.length);
                    if (e.keyCode == 13) {
                        if (typeof(e.cancelBubble) !== 'undefined') // IE
                            e.cancelBubble = true;
                        if (e.stopPropagation)
                            e.stopPropagation();
                        e.preventDefault();


                        if (txt != "") {
                            el.text(txt);
                        } else {
                            el.text("HEADLINE");
                        }
                        //For whatever reason this is producing an error..It thinks its already gone
                        var fo = p_el.select("foreignObject");
                        //console.log(fo.empty());
                        fo.remove();

                    }
                });

            inp.node().focus();
        });
}
