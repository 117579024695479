export function svg2pdf_dr(gfx) {

    //console.log(gfx);

    createAndDownloadDoc("fy7y3aaqKvz668kXt4EK", {
        test: false, // test documents are free, but watermarked
        type: "pdf",
        document_content: gfx, // use this page's HTML
        // document_content: "<h1>Hello world!</h1>",               // or supply HTML directly
        // document_url: "http://example.com/your-page",            // or use a URL
        // javascript: true,                                        // enable JavaScript processing
        prince_options: {
           //media: "screen",                                       // use screen styles instead of print styles
         }
    });


    function createAndDownloadDoc(api_key, doc_attrs) {
        var makeFormElement = function(name, value) {
            var element = document.createElement("textarea");
            element.name = name;
            element.value = value;
            return element;
        }

        var form = document.createElement("form")
        form.action = "https://docraptor.com/docs"
        form.method = "post"
        form.style.display = "none"

        form.appendChild(makeFormElement("user_credentials", api_key))

        for (var key in doc_attrs) {
            if (key == "prince_options") {
                for (var option in doc_attrs.prince_options) {
                    form.appendChild(makeFormElement("doc[prince_options][" + option + "]", doc_attrs.prince_options[option]))
                }
            } else {
                form.appendChild(makeFormElement("doc[" + key + "]", doc_attrs[key]))
            }
        }

        document.body.appendChild(form);
        form.submit()
    }













    /*  console.log(gfx);
      // this function is based on code found:
      // http://www.filamentgroup.com/lab/jquery_plugin_for_requesting_ajax_like_file_downloads/
      // to easily make a form and POST it
      var download = function(url, data, method) {
          //url and data options required
          if (url && data) {
              jQuery('<form style="display: none" id="dr_submission" action="' + url + '" method="' + (method || 'post') + '">' + '</form>').appendTo('body');
              //credentials
              jQuery('form#dr_submission').append('<textarea name="user_credentials"></textarea>');
              jQuery('form#dr_submission textarea[name=user_credentials]').val(data.user_credentials);

              //doc values
              for (var key in data.doc) {
                  jQuery('form#dr_submission').append('<textarea name="doc[' + key + ']"></textarea>');
                  jQuery('form#dr_submission textarea[name="doc[' + key + ']"]').val(data.doc[key]);
              }

              //submit the form
              if (confirm("press ok")) { jQuery('form#dr_submission').submit().remove(); }
          };
      };

      // setup the string represeting the html we want to submit
      var content = 'This is a sample PDF created using jQuery. Find more examples of what you can do with DocRaptor <a href="https://docraptor.com/">https://docraptor.com/</a> or contact support@docraptor.com with any questions.';

      var data = {
          doc: {
              test: true,
              type: 'pdf',
              document_content: gfx
          },
          user_credentials: "fy7y3aaqKvz668kXt4EK"
      };

      var call_download = function() {
          // this drops a form on the page and submits, which will result in a download dialog popping up
          download("http://docraptor.com/docs", data);
      };

      call_download();*/




}