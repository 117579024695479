//nA hover panel in the SVG.

import * as d3 from "d3";
import { wrap } from "../ddd";
import { cap1 } from "../ddd";
import { titleCase } from "../ddd";
import { truncate } from "../ddd";
import { vbSize } from "../ddd";



var dragGroup = d3.drag()
.on("start", dragstarted)
.on("drag", dragged)
.on("end", dragended);

function dragstarted(event, d) {
  d3.select(this).raise().attr("stroke", "black")
}

function dragged(event, d) {
  //d3.select(this).attr("cx", d.x = event.x).attr("cy", d.y = event.y);
  d3.select(this).attr("transform", "translate(" + event.x + "," + event.y + ")")


}

function dragended(event, d) {
  d3.select(this).attr("stroke", null);
}



//variables data, 
//details (coordinates for placement), 
//p (d3 selected svg element to attach to), 
//fs (font size)


export function legend(keyColors, svg, fs) {
    
    //console.log(copyLr.empty());
    var svgSize = vbSize(svg);
    var svgHeight = svgSize[0];
    var svgWidth = svgSize[1];

    var details = { "x": 50, "y": 670, "height": 40, "width": 1180 };
    var x = details.x;
    var y = details.y;
    var h = details.height;
    var w = details.width;
    var marg = 6;
    var leadSpace = 5;
    var lines = 2;
    var lineHeight = leadSpace + fs;
    var legendHeight = Object.keys(keyColors).length * fs + (marg * 2);
    

    var viewBox = svg.attr("viewBox");
    var ssv = d3.dsvFormat(" ");
    var vbInfo = ssv.parse(viewBox);
    var vbOffset = [+vbInfo.columns[0] + +vbInfo.columns[2] - x, +vbInfo.columns[1] + x];

    var keyLr = svg.select("#l-key").call(dragGroup);
    keyLr.attr("transform", "translate(" + vbOffset[0] + "," + vbOffset[1] + ")");


    
    update(keyColors);

    function update(data) {

        //console.log(data);

        var legendWidth = 25;

        var keyMap = new Map(Object.entries(data));

    
        var keyItems = keyLr.selectAll(".key-items")
            .data(keyMap);
        //3. remove any that dont need to be there with the exit selection
        var exit = keyItems.exit().remove();

        //4. Add any new nodes with the enter selection.
        var enter = keyItems.enter()
            .append("g")
            .classed("key-items", true)
            .attr("transform", function(d, i) {
                //console.log(i);
                var y = (fs * i);
                //legendHeight = y;
                var x = 0;
                return "translate(" + x + "," + y + ")";
            })
            .on("click", function(e, d, i) {
                //console.log(d);
            });

        //Legend dot
        enter.append("circle")
            .attr("r", fs / 2)
            .classed("key-dot", true)
            .style("fill", function(d) {
                //console.log(d);
                return d[1];
            })
            .each(function(d) {
                //Log out the new elements.
                //console.log(d);
            })
        
        //Text label
        enter.append("text")
            .style("text-anchor", "end")
            .style("font-size", fs)
            .style("fill", "Black")
            .attr("dy", ".35em")
            .attr("x", -fs)
            .text(function(d) {
                //return d[0];
                return d[0];
            });


            
        enter.insert("line", ":first-child")
            .attr("x1", 0)
            .attr("x2", function() {
                var itemGroup = this.parentNode;
                var textNode = d3.select(itemGroup).select("text").node().getComputedTextLength();
                legendWidth = textNode > legendWidth ? textNode : legendWidth;
                //console.log(legendWidth);
                return -textNode - fs;
            })
            .attr("y1", 0)
            .attr("y2", 0)
            .style("stroke-width", function() {
                return fs + 1;
            })
            .style("stroke-opacity", .5)
            .style("stroke", function() {
                return "white";
            })
            .classed("round-line", true);

        //5. Merge the update and enter selection.
        keyItems = enter.merge(keyItems);


        keyItems.select(".key-dot")
            .transition()
            .style("fill-opacity", 1);

        keyItems.select("text")
            .text(function(d) {
                return d[0];
            });

        keyItems.select("line")
            .transition()
            .attr("x2", function() {
                var itemGroup = this.parentNode;
                var textNode = d3.select(itemGroup).select("text").node().getComputedTextLength();
                return -textNode - fs;
            });

//Maybe add legend box later
        // keyLr.selectAll(".legend-box").remove()

        // keyLr.insert('rect', ":first-child")
        // .attr("height", legendHeight)
        // .attr("width", function() {
        //     console.log(legendWidth);
        //     legendWidth = legendWidth + (marg * 2) + fs;
        //     return legendWidth;
        // })
        // .attr("x", d => (legendWidth * -1) + (fs/2))
        // .attr("y", d => (marg + (fs/2)) * -1 )
        // .attr("rx", fs)
        // .classed("legend-box", true);



    }
  

}