//suss.js parses tab deliminted data and return an object with the data and other useful things
import * as d3 from "d3";
import { cokeGeos } from "./coke/cokeGeos";


export function sussOrg(data) {
    //console.log("suss : " + data);
    var returns = {};

    if (!data) {
        returns.msg = "Please paste valid data in the input field above";
    } else {
        //parse TSV format
        var parsedData = d3.tsvParse(data);
        parsedData.forEach(function(d, i) {
            d.id = d.NAME;
            //d.PCT_BRIX = -d.PCT_BRIX;
            //d.ID = d.ID.toString();
            //d.ASMET = d.ASMET.toUpperCase();
        });
        //List the columns in the data
        var columns = parsedData.columns;
        //Find out how many columns total.
        var colCount = +columns.length;
        //Find total number of records
        var recordCount = +parsedData.length;
        returns.recordCount = recordCount;
        returns.colCount = colCount;
        returns.data = parsedData;

        if (colCount > 3) {
            //console.log('min columns reached');
            returns.msg = recordCount + " entries | " + colCount + " columns. Now Viz It!";
            returns.success = true;
        } else {
            returns.success = false;
            returns.msg = 'Vital columns missing from the data. Please paste in the proper format';
        }

    }

    return returns;
}






////
