//TO SEND TO CLOUD CONVERT TO GET A PNG
export function svg2png_cc(ready_content, filename, filesize) {
    var fh = (+filesize[0]) * 4;
    var fw = (+filesize[1]) * 4;
    var pxSize = fh + "x" + fw;
    console.log("SVG>PNG with CloudConvert: " + filename);

    var cloud_convert_api = "6JhzPgAJwv6nyB7RxyqrKumo84Q60m0hJlBCo_HNyqBQ_xnkXZXv2TYrTUggFV5fDGYriyhEzVAqWMCdWLo9cg"; ///DDD API key

    var request = $.ajax({
        url: "https://api.cloudconvert.com/process",
        method: "POST",
        data: {
            "apikey": cloud_convert_api,
            "inputformat": "raw",
            "outputformat": "png"
        },
        dataType: "jsonp"
    }).done(function(msg) {
        console.log(msg);
        var request2 = $.ajax({
            url: msg.url,
            method: "POST",
            data: {
                "wait": true,
                "input": "raw",
                "inputformat": "svg",
                "file": ready_content,
                "filename": filename,
                "converteroptions": {
                    "resize": null,
                    //this number can be doubled if 4k is desired.
                    "density": "192"
                },
                "outputformat": "png"
            }
        }).done(function(msg2) {
            console.log(msg2);
            var link = document.createElement('a');
            link.setAttribute('href', msg2.output.url);
            link.click();
        }).fail(function(jqXHR, textStatus) {
            console.log("Request failed: " + jqXHR.responseJSON.message);
        });

    }).fail(function(jqXHR, textStatus) {
        console.log("Request failed: " + jqXHR.responseJSON.message);
    });

}
