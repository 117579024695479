//ISO MAP
import * as d3 from 'd3';
import { geoArmadillo } from 'd3-geo-projection';
import * as topojson from "topojson";
//data
import { admin0 } from "../ddd/mapdata/admin0.js";
//other stuff
import { vbSize } from "./ddd";
import { textEdit } from "./ddd";
import { toolTip } from "./ddd";
import { cokeGeos } from "../ddd/coke/cokeGeos.js";
import { circlePack } from "../ddd/circlePack.js";

var isoLookup = {};

cokeGeos.forEach(function(d) {
    isoLookup[d.ISO3] = d.COKE_SPELLING;
})
//sort, filters

export function isoMap(svg, sData, fData) {
    //console.log('--<MAPMODE>--');

    //The iso map should have several modes...
    //choro - options colorsceheme, 
    //which - just colors countires that are present
    //bubbles
    //circle pack

    //console.log(controls);


    //sort/nest this raw data based on ISO code.
    var isoSort = d3.nest()
        .key(function(d) {
            return d.ISO3;
        })
        .sortKeys(d3.ascending)
        .entries(fData);

    var margin = { top: 100, right: 50, bottom: 50, left: 50 };
    //SETUP USING CUSTOM VIEWBOX SNIFFER
    var svgSize = vbSize(svg);
    var svgHeight = svgSize[0];
    var svgWidth = svgSize[1];
    //upperleft point of the scatter
    var xPoint = svgWidth - margin.left;
    var yPoint = svgHeight - margin.top;
    //Set the height/width of the actual chart
    var mapW = svgWidth - margin.right,
        mapH = svgHeight - margin.top,
        botRight = svgHeight - margin.bottom;

    var colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    drawMap(fData);

    function drawMap(data) {
        svg.selectAll("*").remove();
        //console.log(sort);
        //console.log(data);
        //console.log(d3.values(isoSort));

        isoSort.forEach(function(d) {
            d.count = d.values.length;
        })

        //console.log(isoSort);

        //lets get the shapes from the data.
        var iso = topojson.feature(admin0, admin0.objects.layer1);
        //console.log(iso); //This is a fewature collectin.

        var proj = d3.geoMercator()
            .scale(250)
            .center([0, 30])
            .rotate([-10, 0, 0])
            .translate([svgWidth / 2, svgHeight / 2])
            .clipExtent([
                [margin.left, margin.top],
                [mapW, botRight]
            ]);

        //Now create a geoPath generator.
        var path = d3.geoPath()
            .projection(proj);


        var graticule = d3.geoGraticule().step([30, 30]);
        //what is path. datum
        var lines = svg.append("path")
            .datum(graticule)
            .classed('graticule', true)
            .attr("d", path);

        //Now draw them.
        var countries = svg.selectAll(".countries")
            .data(iso.features)
            .enter().append("path")
            .classed("countries", true)
            .classed("highlight-poly", function(d) {
                var color = false;
                var thisISO = d.properties.ISO3_CODE;
                isoSort.forEach(function(d) {
                    //console.log(d.count);
                    if (d.key == thisISO) {
                        //console.log("match");
                        color = true;
                    }
                })
                return color;
            })
            .attr("d", path);


        //make a scale for the bubbles.
        //var bubScale = d3.
        //the scale for circle radius
        var countryMax = d3.max(isoSort, function(d) {
            return d.count;
        })

        var radius = d3.scaleSqrt()
            .domain([0, countryMax])
            .range([0, (mapW * .05)]);


        // to make bubbles, we just need bubbles. but to do anything smart we need a group,
        //but the structure of that group should be used uniformley from the scatter to the 


        /* function drawBub() {
         drawDash();


         d3.csv("data/epop.csv", function(epop) {
             var pos = 50;
             // we first sort the data
             epop.sort(function(a, b) {
                 return b[2007] - a[2007];
             });

             // then we create the marks, which we put in an initial position
             var bubbles = graphic.selectAll(".bubbles").data(epop).enter()
                 .append("g")
                 //.attr("transform", "translate(0,0)")
                 .attr("transform", function(d) {
                     var c = d.ISO3;
                     var t = d3.transform(d3.select('.lAdmin0.' + c).attr("transform"));
                     var x = t.translate[0];
                     var y = t.translate[1];
                     return "translate(" + x + ", " + y + ")";
                     //findC(c);
                     //return 0;
                 })
                 .attr("class", "bubbles");;

             bubbles.append("circle")
                 .attr("cx", 0)
                 .attr("cy", 0)
                 .attr("r", 0)
                 .attr("class", "bub");

             bubbles.append("circle")
                 .attr("r", .25)
                 .attr("class", "ring");

             bubbles.append("circle")
                 .attr("r", 0)
                 .attr("class", "bubCentroid");

             bubbles.append("rect")
                 .attr("height", 0)
                 .attr("width", 0)
                 .attr("x", "0")
                 .attr("y", "0")
                 .attr("class", "bubPoint");

             bubbles.append("text")
                 .text(function(d) {
                     return d.Terr_Name;
                 })
                 .attr("x", "3")
                 .attr("y", "0")
                 .attr("dy", ".35em")
                 .attr("class", "bubLabel");

         })*/



        //Who is bub. the problem is its got to be a group. But that is good.
        //What should the main containter for bubbles to get passed around be called?

        var dataBubs = svg.selectAll(".dataBubs")
            .data(isoSort).enter()
            .append("g")
            .attr("transform", function(d) {
                var thePath = iso.features;
                thePath = thePath.filter(function(p) {
                    return p.properties.ISO3_CODE == d.key && p.properties.STATUS == "Member State";
                });
                thePath = thePath[0];
                var x = path.centroid(thePath)[0];
                var y = path.centroid(thePath)[1];
                return "translate(" + x + ", " + y + ")";
            })
            .attr("class", "dataBubs");

/*
        var bubbles = dataBubs.append("circle")
            .classed("bub", true)
            .attr("r", function(d) {
                return radius(d.count);

            })
            .each(function(d) {
                var elem = d3.select(this);
                //console.log(elem);
                var thePath = iso.features;
                thePath = thePath.filter(function(p) {
                    return p.properties.ISO3_CODE == d.key && p.properties.STATUS == "Member State";
                });
                thePath = thePath[0];
                var loc = path.centroid(thePath);
                var circSize = radius(d.count) * 2;
                toolTip(elem, isoLookup[d.key], 16);
            });*/

        var packs = dataBubs.each(function(d) {
            var elem = d3.select(this);
            //console.log(elem);
            var thePath = iso.features;
            thePath = thePath.filter(function(p) {
                return p.properties.ISO3_CODE == d.key && p.properties.STATUS == "Member State";
            });
            thePath = thePath[0];
            var loc = path.centroid(thePath);
            var x = loc[0] - radius(d.count);
            var y = loc[1] - radius(d.count);
            loc = [x, y]
            var circSize = radius(d.count) * 2;
            
            var root = d3.hierarchy(d, function(d) {
                    //console.log("doing it");
                    return d.values;
                })
                .count()


            circlePack(elem, [circSize, circSize], loc, root);
        })

    }


    //HEADLINE AND WHATNOT
    var stage = svg.append("g");

    stage.append('rect')
        .attr("height", svgHeight)
        .attr("width", svgWidth)
        .classed("box1", true);

    var headline = stage.append("text")
        .attr("x", 50)
        .attr("id", "headline")
        .classed("gfx-head", true)
        .text("Global Research")
        .attr("y", function() {
            var textNode = d3.select(this);
            var textHeight = textNode.node().getBBox().height;
            return textHeight;
        })
        .call(textEdit);

}