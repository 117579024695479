import * as d3 from "d3";
import * as d3ScaleChromatic from 'd3-scale-chromatic';;
import { vbSize } from "./ddd";
import { textEdit } from "./ddd";
import { parseTransform } from "./ddd";
import { toolTip2 } from "./ddd";
import { hoverPanel } from "./ddd";
//import defaultExport from "d3-3d";
//import * as d33d from "d3-3d";
import * as d33d from "d3-3d";


export function scatter3d(svg, sData, fData, sort, filters) {
	var zoom = d3.zoom();
	var vizLr = svg.select("#l-viz");
	var scatterLayer = svg.select("#scatterGroup");
	scatterLayer.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	//Layers
	var gridLr = svg.select("#l-grid");
	gridLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var mapLr = svg.select("#l-map");
	mapLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var dataLr = svg.select("#l-data");
	var copyLr = svg.select("#l-copy");
	copyLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var stageLr = svg.select("#l-stage");
	var thekey = svg.select("#l-key");
	thekey.transition().duration(1000).style("opacity", 1);

	//Sizes
	var svgSize = vbSize(svg);
	var svgHeight = svgSize[0];
	var svgWidth = svgSize[1];
	var gcoords = parseTransform(vizLr.attr("transform")); //Grab the corrdinates of thedata layer which has a margin from top and left
	var gWidth = svgWidth - (gcoords[0] * 2); //50 the sides.
	var gHeight = svgHeight - (gcoords[1] + gcoords[0]); //100 - the top

    //var bubSize = Math.min(12, (gWidth / fData.length) * 2);
    var bubSize = 26;

	//Transitions
	var tIntro = d3.transition()
		.duration(2000)
		.ease(d3.easePolyInOut);



//////////////////////////////////////////////////////////
	//3D scatter setup.
	var origin = [svgWidth/2, svgHeight/2];
	var j = 10;
	var scale = 30;
	var scatter = [];
	var yLine = [];
	var xGrid = [];
	var beta = 0; 
	var alpha = 0; 
	var key = function(d){ return d.id; }
	var startAngle = Math.PI/8;

	//add drag listers on the svg
	svg.call(d3.drag().on('drag', dragged).on('start', dragStart).on('end', dragEnd)).append('g');

	//A color scheme
	var color = d3.scaleOrdinal(d3.schemeCategory10);

	//some variables related to mouse interaction
	var mx, my, mouseX, mouseY;


	//Scatter Scales
	//It seems that all units are equal so well need a scale to normalize
	var xScale = d3.scaleLinear().range([0, 10]).domain([0, d3.max(fData, function(d) {
		return d.x;
	})]);

	var yScale = d3.scaleLinear().range([0, -10]).domain([0, d3.max(fData, function(d) {
		return d.y;
	})]);

	var zScale = d3.scaleLinear().range([0, 10]).domain([0, d3.max(fData, function(d) {
		return d.z;
	})]);

//d3.max(fData, function(d) {return d.num_atom_stereocenters})

// y.domain([0, d3.max(data, function(cat) {
//     return d3.max(cat.values, function(def) {
//         return def.value;
//     });
// })]);


	//The actual grid
	var grid3d = d33d._3d()
		.shape('GRID', 20)
		.origin(origin)
		.rotateY( startAngle)
		.rotateX(-startAngle)
		.scale(scale);

    var point3d = d33d._3d()
        .x(function(d){ return d.x; })
        .y(function(d){ return d.y; })
        .z(function(d){ return d.z; })
        .origin(origin)
        .rotateY( startAngle)
        .rotateX(-startAngle)
        .scale(scale);

    var yScale3d = d33d._3d()
        .shape('LINE_STRIP')
        .origin(origin)
        .rotateY( startAngle)
        .rotateX(-startAngle)
        .scale(scale);

    function processData(data, tt){

        /* ----------- GRID ----------- */

        var xGrid = svg.selectAll('path.grid').data(data[0], key);

        xGrid.enter()
            .append('path')
            .attr('class', '_3d grid')
            .merge(xGrid)
            .attr('stroke', 'black')
            .attr('stroke-width', 0.3)
            .attr('fill', function(d){ return d.ccw ? 'lightgrey' : '#717171'; })
            .attr('fill-opacity', 0.9)
            .attr('d', grid3d.draw);

        xGrid.exit().remove();

        /* ----------- POINTS ----------- */

        var points = svg.selectAll('circle').data(data[1], key);

        points.enter()
            .append('circle')
            .attr('class', '_3d')
            .attr('opacity', 0)
            .attr('cx', posPointX)
            .attr('cy', posPointY)
            .merge(points)
            .transition().duration(tt)
            .attr('r', 3)
            .attr('stroke', function(d){ 
				//console.log(d);
				return d3.color(color(d.Source)).darker(3); 
			})
            .attr('fill', function(d){ return color(d.Source); })
            .attr('opacity', 1)
            .attr('cx', posPointX)
            .attr('cy', posPointY);

        points.exit().remove();

        /* ----------- y-Scale ----------- */

        var yScale = svg.selectAll('path.yScale').data(data[2]);

        yScale
            .enter()
            .append('path')
            .attr('class', '_3d yScale')
            .merge(yScale)
            .attr('stroke', 'black')
            .attr('stroke-width', .5)
            .attr('d', yScale3d.draw);

        yScale.exit().remove();

         /* ----------- y-Scale Text ----------- */

        var yText = svg.selectAll('text.yText').data(data[2][0]);

        yText.enter()
            .append('text')
            .attr('class', '_3d yText')
            .attr('dx', '.3em')
            .merge(yText)
            .each(function(d){
                d.centroid = {x: d.rotated.x, y: d.rotated.y, z: d.rotated.z};
            })
            .attr('x', function(d){ return d.projected.x; })
            .attr('y', function(d){ return d.projected.y; })
            .text(function(d){ return d[1] <= 0 ? d[1] : ''; });

        yText.exit().remove();

        d3.selectAll('._3d').sort(d33d._3d().sort);
    }

    function posPointX(d){
        return d.projected.x;
    }

    function posPointY(d){
        return d.projected.y;
    }

	function init(){
        var cnt = 0;
        xGrid = [];
		scatter = [];
		yLine = [];


        for(var z = -j; z < j; z++){
            for(var x = -j; x < j; x++){
                xGrid.push([x, 1, z]);
                //scatter.push({x: x, y: d3.randomUniform(0, -10)(), z: z, id: 'point_' + cnt++});
            }
        }

		fData.forEach(function(d){
            console.log("X: " + xScale(+d.x));
            console.log("Y: " + yScale(+d.y));
            console.log("Z: " + zScale(+d.z));
            

			scatter.push({
				x: xScale(+d.x), 
				y: yScale(+d.y), 
				z: zScale(+d.z), 
				id: d.Source + cnt++});


		})

        console.log(scatter);

        d3.range(-1, 11, 1).forEach(function(d){ yLine.push([-j, -d, -j]); });

        var data = [
            grid3d(xGrid),
            point3d(scatter),
            yScale3d([yLine])
        ];


        processData(data, 1000);
    }



    function dragStart(){
        mx = d3.event.x;
        my = d3.event.y;
    }

    function dragged(){
        mouseX = mouseX || 0;
        mouseY = mouseY || 0;
        beta   = (d3.event.x - mx + mouseX) * Math.PI / 230 ;
        alpha  = (d3.event.y - my + mouseY) * Math.PI / 230  * (-1);
        var data = [
            grid3d.rotateY(beta + startAngle).rotateX(alpha - startAngle)(xGrid),
            point3d.rotateY(beta + startAngle).rotateX(alpha - startAngle)(scatter),
            yScale3d.rotateY(beta + startAngle).rotateX(alpha - startAngle)([yLine]),
        ];
        processData(data, 0);
    }



    function dragEnd(){
        mouseX = d3.event.x - mx + mouseX;
        mouseY = d3.event.y - my + mouseY;
    }

    d3.selectAll('button').on('click', init);

    init();




	//update(fData);
	//General Update Pattern
	function update(data) {
		var dump = gridLr.selectAll("*").remove();

		//I create and empty selection, and use the key function with an ID string
		//in my dsv data.
		var bubs = dataLr.selectAll(".data-bub")
			.data(data, function (d, i) {
				return d.id || (d.id = ++i);
			});

		//remove any that dont need to be there.
		//The first time around there wont be any nodes in the exit, but no matter.
		bubs.exit()
			.transition(tIntro)
			.style("opacity", 0)
			.each(function (d) {
				//Log out the removed elements.
				//console.log(d.id);
			})
			.remove();

		var enter = bubs.enter() //add any new ones - a group and a circle within it.
			.append("g")
			.classed("data-bub", true)
			.attr("transform", function (d, i) {
				///console.log(i);
				var y = gHeight / 2;
				var x = gWidth / 2;
				return "translate(" + x + "," + y + ")";
			})
			.on("click", function (d) {
				console.log(d);
			})
			.on('mouseover', function (d) {
				d3.selectAll(".hoverPanel").remove();
				hoverPanel(d, svg, 14);
			})
			.each(function (d) {
				var elem = d3.select(this);
				var txt = d.id;
				toolTip2(elem, d, 16, "Source");
			});

		enter.append("circle")
			.attr("r", bubSize)
			.classed("bub", true);


		//Now I should be able to run this transition that
		//moves each group to a random location on my svg.
		//But nothing happens. Why is the update selection empty after merge?
		bubs = enter.merge(bubs);

		bubs.transition(tIntro)
			.attr("transform", function (d, i) {
				///console.log(i);
				var y = Math.random() * gHeight;
				var x = Math.random() * gWidth;
				return "translate(" + x + "," + y + ")";
			});

		var bubBub = bubs.selectAll(".bub");

		bubBub.transition()
			.attr("r", bubSize);

	}

	if (stageLr.select("#headline").empty()) {
		var headline = stageLr.append("text")
			.attr("id", "headline")
			.attr("x", 50)
			.classed("gfx-head", true)
			.attr("y", function () {
				var textNode = d3.select(this);
				var textHeight = textNode.node().getBBox().height;
				return textHeight + 50;
			})
			.call(textEdit, "headline");


		stageLr.append('rect')
			.attr("height", svgHeight)
			.attr("width", svgWidth)
			.classed("box1", true);

	}

	stageLr.select("#headline").text(function (d) {
		var recCount = fData.length;
		//console.log(recCount);
		//return recCount + " total in the list";
		return recCount + " Projects";
	});







}