//screatFilters.js takes a the results of a d3.dsv and creates filter buttons, and returns data for a viz.
import * as d3 from "d3";
import * as noUiSlider from "nouislider";

export function createSlider(data, info, divID, funRun) {

    var slide;

    //console.log(info);


    var rangeExtent = d3.extent(data, function(d) {
        //console.log(d[columns]);
        var num = d[info.col];
        //console.log(d)
        return num;
    });

    //console.log(rangeExtent);

    //CREATE THE DIV TO HOLD Bth Slider and its label.
    if (d3.select("#slider").empty()) {
        slide = d3.select(divID)
            .append("div")
            .attr("id", "slider")
            .classed("filter-holder col-md-12", true);
    } else {
        slide = d3.select("#slider");
        slide.selectAll("*").remove();
    }


    //CREATE A LABEL FOR THE SLIDER.
    slide.append("div")
        .html("FILTER BY REDUCTION RANGE")
        .classed("subhead", true);


    var rangeSlide = slide.append("div")
        .attr("id", "range-slider")
        .classed("noUiSlider", true);

    var slideDiv = rangeSlide.node();


    noUiSlider.create(slideDiv, {
            start: [info.range[0], info.range[1]],
            connect: true,
            tooltips: true,
            step: 1,
            range: {
                'min': rangeExtent[0],
                'max': rangeExtent[1]
            },
            pips: {
                mode: 'count',
                values: 5,
                density: 20
            },
            format: {
                to: function(value) {
                    return value + '%';
                },
                from: function(value) {
                    return value.replace(',-', '');
                }
            }
        })
        .on("set", funRun);



    //        .on("click", function(d) {
    //            funRun(d);
    //        });

}