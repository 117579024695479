//nA hover panel in the SVG.

import * as d3 from "d3";
import { wrap } from "./ddd";
import { cap1 } from "./ddd";
import { titleCase } from "./ddd";
import { truncate } from "./ddd";

//variables data, details (coordinates for placement), p (parent element to attach to), s (font size)

export function hoverPanel_v2(data, details, p, fs) {
    var svg = p;
    var copyLr = svg.select("#l-copy");

    var viewBox = svg.attr("viewBox");
    var ssv = d3.dsvFormat(" ");
    var vbInfo = ssv.parse(viewBox);
    var vbOffset = [+vbInfo.columns[0] + details.x, +vbInfo.columns[1] + details.y];

    
    var fields = Object.keys(data);

    var x = details.x;
    var y = details.y;
    var h = details.height;
    var w = details.width;
    var marg = 5;
    var lineX = marg;
    var leadSpace = 5;
    var lines = 2;
    var lineHeight = leadSpace + fs;
    var lineY = lineHeight;
    var totalHeight = (marg * 2) + (leadSpace * lines) + (fs * lines);

    
    var hoverGroup = copyLr.append("g")
        .attr("transform", "translate(" + vbOffset[0] + "," + vbOffset[1] + ")")
        .classed("hoverPanel", true);

    hoverGroup.append("line").attr("x1", 0).attr("x2", details.width).attr("y1", 0).attr("y2", 0).classed("leader", true);

    hoverGroup.append('rect')
        .attr("height", totalHeight)
        .attr("width", w)
        .classed("hover-box", true);



    fields.forEach(function(field){
        
        var fieldLabel = hoverGroup.append("text")
        .attr("x", lineX)
        .classed("gen", true)
        .style("font-size", fs)
        .text(function() {
            var str = "| " + field + " : "
            
            str = str + data[field];
            //console.log(name);
            if (str != "") {
                return str = truncate(str, 100)
                //nameStr.toUpperCase();
            } else {
                return "n/a"
            }



        })
        .attr("y", lineY);

        lineX = marg + fieldLabel.node().getComputedTextLength() + lineX;

        var overLine = parseInt(lineX/w);

        if (overLine >= 1) {

            lineX = marg;
            lineY = lineHeight * 2;

        }

    })


}
