import * as d3 from 'd3';


// Circle pack accepts a d3.hierarchy object.

//desl - a d3 selction to draw into, should contain a single group ro svg element.
//size  -  is a 2 item array of height and width. In this case they are circle, but I guess if they are not the same value the graphics is centered
//loc - an optional location 2 element array for an x and y.
//root - is a d3,hierarchy object - with a count attriubte specified.
export function circlePack(dsel, size, loc, root) {
   //console.log(loc);

    var width = size[0];
    var height = size[1];

    var format = d3.format(",d");

    var color = d3.scaleSequential(d3.interpolateMagma)
        .domain([-4, 4]);

    var pack = d3.pack()
        .size([width - 2, height - 2])
        .padding(1);

//I do not need to stratify becuase I already have a nest.
/*      var stratify = d3.stratify()
        .parentId(function(d) { return d.id.substring(0, d.id.lastIndexOf(".")); });

    
    var root = stratify(data)
                .sum(function(d) { return d.value; })
                .sort(function(a, b) { return b.value - a.value; });

    var root = d3.hierarchy(data, function(d) {
            //console.log("doing it");
            return d.values;
        })
        .count()*/


    pack(root);

    var node = dsel.selectAll(".packers")
        .data(root.descendants())
        .enter().append("g")
        .attr("transform", function(d) { 
          var x = d.x - (width/2);
          var y = d.y - (height/2);
          return "translate(" + x + "," + y + ")"; 
        })
        .attr("class", function(d) { return "node" + (!d.children ? " node--leaf" : d.depth ? "" : " node--root"); })
        .each(function(d) { d.node = this; })
        .on("mouseover", hovered(true))
        .on("mouseout", hovered(false));

    node.append("circle")
        .attr("id", function(d) { return "node-" + d.id; })
        .attr("r", function(d) { return d.r; })
        .style("fill", function(d) { return color(d.depth); })
        .style("fill-opacity", .25);

 /*   var leaf = node.filter(function(d) { return !d.children; });

    leaf.append("clipPath")
        .attr("id", function(d) { return "clip-" + d.id; })
        .append("use")
        .attr("xlink:href", function(d) { return "#node-" + d.id + ""; });

    leaf.append("text")
        .attr("clip-path", function(d) { return "url(#clip-" + d.id + ")"; })
        .selectAll("tspan")
        .data(function(d) { return d.id.substring(d.id.lastIndexOf(".") + 1).split(/(?=[A-Z][^A-Z])/g); })
        .enter().append("tspan")
        .attr("x", 0)
        .attr("y", function(d, i, nodes) { return 13 + (i - nodes.length / 2 - 0.5) * 10; })
        .text(function(d) { return d; });
*/
    node.append("title")
        .text(function(d) { return d.id + "\n" + format(d.value); });


    function hovered(hover) {
        return function(d) {
          console.log("um");
            d3.selectAll(d.ancestors().map(function(d) { return d.node; })).classed("node--hover", hover);
        };
    }

}