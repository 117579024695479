//make a sweetener veng diagram

import * as d3 from "d3";
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import { titleCase } from "../ddd";
import { sweetNames } from "./sweetNames";

export function sweetVeng(e, map, size) {

    var smap = map.entries();

    var snum = smap.length;

    var cr = size / 4;

    var ca = function() {
        return Math.PI * cr * cr;
    };

    var rot = 360 / snum;

    var el = d3.select(e);

    /*    var sweets = el.selectAll(".sweets")
            .data(smap).enter().append("path")
            .attr('d', d3.symbol().type(d3.symbolCircle).size(ca))
            //.attr("transform", "rotate(-90) translate(0,10)")
            .attr("transform", function(d, i){
            	return "rotate(" + (rot * i) + ") translate(0,-50)";
            })
            .classed("veng-pad", true);*/

    var sweets = el.selectAll(".sweets")
        .data(smap).enter().append("g")
        .attr("transform", function(d, i) {
            return "rotate(" + (rot * i) + ") translate(0,-50)";
        })
        .classed("veng-sweet-gp", true);

    sweets.append("path")
        .attr('d', d3.symbol().type(d3.symbolCircle).size(ca))
        .classed("sweet-pad", true)
        .style("fill", "white")
        .transition().style("fill", function(d) {
            var c = sweetNames[d.key].COLOR;
            return c;
        })
        .duration(1000)
        .delay(function(d, i) { return i * 100; });

    sweets.append("text")
        .text(function(d) {
          if (sweetNames[d.key].NICE_NAME) {
            return sweetNames[d.key].NICE_NAME;
          } else {
            return sweetNames[d.key];
          }

        })
        .attr("transform", function(d, i) {
            return "rotate(" + (-rot * i) + ")";
        })
        .attr("y", 7)
        .style("text-anchor", "middle")
        .style("dy", "0.35em")
/*        .style("fill", function(d) {
            var c = sweetNames[d.key].COLOR;
            return c;
        })
        .style("fill", "white")*/
        .classed("sweet-label up-case", true);

}
