//screatFilters.js takes a the results of a d3.dsv and creates filter buttons, and returns data for a viz.
import * as d3 from "d3";

export function group(appData) {

    console.log(appData.group);

    //currently handles only one level.
    //not sure how to suuply a varibale number of arguments...

    var groupData = d3.group(appData.fData, d => d[appData.group[0]]);
//d => d[appData.group[1]]
    return groupData;
}
