//suss.js parses tab deliminted data and return an object with the data and other useful things
import * as d3 from "d3";
//import { sweetNames } from "./coke/sweetNames";
import { cokeGeos } from "./coke/cokeGeos";


export function sussPipe(data) {
    //console.log("suss : " + data);
    var returns = {};
    //console.log(cokeGeos);

    if (!data) {
        returns.msg = "Please paste valid data in the input field above";
    } else {
        //parse TSV format
        var parsedData = d3.tsvParse(data);
        parsedData.forEach(function(d) {
            //d.PCT_BRIX = -d.PCT_BRIX;
            //d.ID = d.ID.toString();
            //d.ASMET = d.ASMET.toUpperCase();
        });
        //List the columns in the data
        var columns = parsedData.columns;
        //Find out how many columns total.
        var colCount = +columns.length;
        //Find total number of records
        var recordCount = +parsedData.length;
        returns.recordCount = recordCount;
        returns.colCount = colCount;
        //var geosJoined = joinGeos(parsedData);
        //var sweetsSussed = sussSweets(geosJoined)
        returns.data = parsedData;

        if (colCount > 3) {
            //console.log('min columns reached');
            returns.msg = recordCount + " entries | " + colCount + " columns. Now Viz It!";
            returns.success = true;
        } else {
            returns.success = false;
            returns.msg = 'Vital columns missing from the data. Please paste in the proper format';
        }

    }

    return returns;
}

//This is a script using the above variable Tobago join the ISO3 values to an arbitrary list of goe names from the company.
function joinGeos(data) {
    data.forEach(function(d) {
        var iso = d.ISO3;
        cokeGeos.forEach(function(val) {
            if (iso == val.ISO3) {
                d.BU = val.BU;
                d.REGION = val.REGION;
            }
        });
        //console.log(d.ISO3 + " : " + d.REGION + " : " + d.BU);
    });

    return data;
}

function sussSweets(data) {
    // for each element in the data:
    data.forEach(function(d) {
        //Create a map, an obect with named keys, to hold the sweetener data.
        var sweetMap = d3.map();
        //each record or element in the data is actaully key:value object so.
        var record = d3.entries(d);
        //holds the unique concatonated name of the sweetener combo.
        var sweetString = "";
        //now look through all the columns in the record.
        record.forEach(function(d) {
            // find all those that start with teh sweetener prefix and have data.
            if (d.key.startsWith("S_") && d.value != "") {
                sweetString = sweetString + d.key;
                //var sweetInfo = sweetNames[d.key];
                //console.log(sweetInfo.NICE_NAME);
                sweetMap.set(d.key, d.value);
            }
        });
        d.S_COMBO = sweetString;
        d.S_MAP = sweetMap;
    });
    return data;
}






////
