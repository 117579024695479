import * as d3 from "d3";
import * as d3ScaleChromatic from 'd3-scale-chromatic';;
import { vbSize } from "./ddd";
import { textEdit } from "./ddd";
import { parseTransform } from "./ddd";
import { toolTip2 } from "./ddd";
import { hoverPanel } from "./ddd";



import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'


export function scatter3d2(svg, data, sort, filters) {
    console.log("scatter3d2");
	var zoom = d3.zoom();
	var vizLr = svg.select("#l-viz");
	var scatterLayer = svg.select("#scatterGroup");
	scatterLayer.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	//Layers
	var gridLr = svg.select("#l-grid");
	gridLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var mapLr = svg.select("#l-map");
	mapLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var dataLr = svg.select("#l-data");
	var copyLr = svg.select("#l-copy");
	copyLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var stageLr = svg.select("#l-stage");
	var thekey = svg.select("#l-key");
	thekey.transition().duration(1000).style("opacity", 1);

	//Sizes
	var svgSize = vbSize(svg);
	var svgHeight = svgSize[0];
	var svgWidth = svgSize[1];
	var gcoords = parseTransform(vizLr.attr("transform")); //Grab the corrdinates of thedata layer which has a margin from top and left
	var gWidth = svgWidth - (gcoords[0] * 2); //50 the sides.
	var gHeight = svgHeight - (gcoords[1] + gcoords[0]); //100 - the top

    var bubSize = 26;

console.log(data.columns[0]);

var	idCol = data.columns[0];	
var xCol = data.columns[1];
var yCol = data.columns[2];
var zCol = data.columns[3];

	//Scales and extents
	var xExtent = d3.extent(data, function (d) {return d[xCol] }),
		yExtent = d3.extent(data, function (d) {return d[yCol]  }),
		zExtent = d3.extent(data, function (d) {return d[zCol]  });


	var xScale = d3.scaleLinear()
		.domain(xExtent)
		.range([0, 100]);

	var yScale = d3.scaleLinear()
		.domain(yExtent)
		.range([0, 100]);

	var zScale = d3.scaleLinear()
		.domain(zExtent)
		.range([0, 100]);


	///////////////
	//3D Stuff
	if (d3.selectAll("#three-stage").empty()) {
		//console.log($("#three-stage").length);
		//$("#3dstage").append(renderer.domElement);
		var threeCanvas = d3.select("#stage").append("div").classed("stage-3d", true).attr("id", "three-stage").append("canvas").attr("id", "three-canvas");
		
	} else {
		//var threeCanvas = d3.select("three-canvas")
	}


	const canvas = document.querySelector('#three-canvas');
	const renderer = new THREE.WebGLRenderer({
		canvas : canvas,
		alpha : true,
		antialias : true
	});


	renderer.setSize( svgWidth, svgHeight );


	//Materials
	const flatMat = new THREE.MeshBasicMaterial({color: 0x44aa88});


	const zyncMat = new THREE.MeshBasicMaterial({
		color: 0xF9CE40,
		opacity: 0.25,
		transparent: true
	});
	const emoleculeMat = new THREE.MeshBasicMaterial({
		color: 0x3D99FC,
		opacity: 0.1,
		transparent: true
	});


	const material = new THREE.MeshPhongMaterial({color: 0x44aa88});  // greenish blue
	const lineMat = new THREE.LineBasicMaterial( { color: 0x000000 } )


	// //2. A Camera - Arguments (Fieled of View, Aspect Ratio, Short Clip, and Far Clip)
	//This creates a "frustum"
	const camera = new THREE.PerspectiveCamera( 20, svgWidth / svgHeight, 0.1, 10000 );
	camera.position.z = 500;
	camera.position.y = 500;
	camera.position.x = -250;



	const controls = new OrbitControls(camera, canvas);
	//controls should focus on the statistical center of the data, vs the range...
	controls.target.set(25, 25, 25);
	controls.update();

	const scene = new THREE.Scene();

	//ok create an object
	const boxWidth = 1;
	const boxHeight = 1;
	const boxDepth = 1;
	const geometry = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth);

	{
		const color = 0xFFFFFF;
		const intensity = 1;
		const light = new THREE.DirectionalLight(color, intensity);
		light.position.set(-1, 2, 4);
		scene.add(light);
	}


	const cube100 = new THREE.BoxGeometry(100, 100, 100);
	const cube100Edges = new THREE.EdgesGeometry(cube100)
	const scatterExtentCube = new THREE.LineSegments(cube100Edges, lineMat)
	scatterExtentCube.position.set( 50, 50, 50 );

	const scatter = new THREE.Group();
	scatter.position.set( 0, 0, 0 )
	//scatter.add(scatterExtentCube);
	scene.add(scatter);


	//X, Y, and Z axis lines.
	const yAxisPoints = [new THREE.Vector3( 0, 0, 0 ), new THREE.Vector3( 0, 100, 0 )];
	const xAxisPoints = [new THREE.Vector3( 0, 0, 0 ), new THREE.Vector3( 100, 0, 0 )];
	const zAxisPoints = [new THREE.Vector3( 0, 0, 0 ), new THREE.Vector3( 0, 0, 100 )];


	const xAxisBuf = new THREE.BufferGeometry().setFromPoints( xAxisPoints );
	const yAxisBuf = new THREE.BufferGeometry().setFromPoints( yAxisPoints );
	const zAxisBuf = new THREE.BufferGeometry().setFromPoints( zAxisPoints );

	const xAxis = new THREE.Line(xAxisBuf, lineMat);
	const yAxis = new THREE.Line(yAxisBuf, lineMat);
	const zAxis = new THREE.Line(zAxisBuf, lineMat);

	scatter.add(xAxis, yAxis, zAxis);

	/////need 
	//1. Labels

	var axisLabels = {
		x : "num_heteroatoms",
		y : "num_atom_stereocenters",
		z : "molecular_weight"
	}



	//2. Arrows?

	//num_heteroatoms	num_atom_stereocenters	num_atom_stereocenters



	//A function to make the data points.

	function makeDataPoint(d) {

		var pointColor = (d.name === "eMolecules") ? emoleculeMat : zyncMat;
		var pointSize = (d.name === "eMolecules") ? .2 : .4;
		
		const sphereGeometry = new THREE.SphereGeometry(.25, 10, 10);

		const dataPoint = new THREE.Mesh(sphereGeometry, pointColor);

		dataPoint.position.set( xScale(d[xCol]), yScale(d[yCol]), zScale(d[zCol]) );
		
		scatter.add(dataPoint);

	}





	data.forEach(function(d, i) {

		//console.log(d);

		makeDataPoint(d);

	})




	//Right here is interesting.
	//this uses request animation frame
	function render(time) {
		time *= 0.001;  // convert time to seconds
	
		// cubes.forEach((cube, ndx) => {
		//     const speed = 1 + ndx * .1;
		//     const rot = time * speed;
		//     cube.rotation.x = rot;
		//     cube.rotation.y = rot;
		//   });

		const speed = .1;
		const rot = time * speed;
		scatter.rotation.X = rot;
	
		renderer.render(scene, camera);
	
		requestAnimationFrame(render);
	}
	
	
	requestAnimationFrame(render);


	//General Update Pattern
	function update(data) {
		var dump = gridLr.selectAll("*").remove();

		//I create and empty selection, and use the key function with an ID string
		//in my dsv data.
		var bubs = dataLr.selectAll(".data-bub")
			.data(data, function (d, i) {
				return d.id || (d.id = ++i);
			});

		//remove any that dont need to be there.
		//The first time around there wont be any nodes in the exit, but no matter.
		bubs.exit()
			.transition(tIntro)
			.style("opacity", 0)
			.each(function (d) {
				//Log out the removed elements.
				//console.log(d.id);
			})
			.remove();

		var enter = bubs.enter() //add any new ones - a group and a circle within it.
			.append("g")
			.classed("data-bub", true)
			.attr("transform", function (d, i) {
				///console.log(i);
				var y = gHeight / 2;
				var x = gWidth / 2;
				return "translate(" + x + "," + y + ")";
			})
			.on("click", function (d) {
				console.log(d);
			})
			.on('mouseover', function (d) {
				d3.selectAll(".hoverPanel").remove();
				hoverPanel(d, svg, 14);
			})
			.each(function (d) {
				var elem = d3.select(this);
				var txt = d.id;
				toolTip2(elem, d, 16, "Source");
			});

		enter.append("circle")
			.attr("r", bubSize)
			.classed("bub", true);


		//Now I should be able to run this transition that
		//moves each group to a random location on my svg.
		//But nothing happens. Why is the update selection empty after merge?
		bubs = enter.merge(bubs);

		bubs.transition(tIntro)
			.attr("transform", function (d, i) {
				///console.log(i);
				var y = Math.random() * gHeight;
				var x = Math.random() * gWidth;
				return "translate(" + x + "," + y + ")";
			});

		var bubBub = bubs.selectAll(".bub");

		bubBub.transition()
			.attr("r", bubSize);

	}

	if (stageLr.select("#headline").empty()) {
		var headline = stageLr.append("text")
			.attr("id", "headline")
			.attr("x", 50)
			.classed("gfx-head", true)
			.attr("y", function () {
				var textNode = d3.select(this);
				var textHeight = textNode.node().getBBox().height;
				return textHeight + 50;
			})
			.call(textEdit, "headline");


		stageLr.append('rect')
			.attr("height", svgHeight)
			.attr("width", svgWidth)
			.classed("box1", true);

	}

	stageLr.select("#headline").text(function (d) {
		var recCount = data.length;
		//console.log(recCount);
		//return recCount + " total in the list";
		return recCount + " Projects";
	});







}

