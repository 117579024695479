import * as d3 from "d3";
//Text conflict 
//Arguments:
// e  This module takes an element wich should be a text elment, byt the text element must be within a group.
// d it also takes the data
//chClass - A class to check against. 
//looks through the selection returns true if the element is touching any others from the selection.
export function textConflict(e, d, chClass) {
    var svg = d3.select(e.ownerSVGElement);

    //console.log(svg);

    var colliders = svg.selectAll("." + chClass);

    //console.log(colliders)

    var collsion = d3.forceSimulation(colliders)
        .force('charge', d3.forceManyBody())
        .force('collide', d3.forceCollide(function(d){
        	console.log(d);
        	return 25;
        }))
        .on('tick', ticked);

    var el = d3.select(e);

    //console.log(chClass);
    //console.log(e.getComputedTextLength())
    //console.log(e.getBBox());
    //console.log(e.getCTM()); //
    //console.log(e.getBoundingClientRect());
    function ticked(){
    	console.log("tick");
    }

	return true;

}