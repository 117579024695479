import * as d3 from "d3";

export function filterData(data, filterBundle, funRun) {

    //console.log(filterBundle);

    var fData = data;

    //now look through the filter bundle...
    filterBundle.forEach(function(d, k) {

        var column = k;

        //const filterSet = new Map()

        if(Array.isArray(fData[0][column])){
            console.log("Array column");
            //if this column is an array...
           //find the selection type for this array filter
            var filter_type = d3.select('#array_filter_'+column).selectAll('.array_filter_selector.btn-secondary').data()[0];

            if(filter_type == "ANY"){
                console.log('MATCH ANY')

                fData = fData.filter(function(d) {
                    var select_these = false;

                    d[column].forEach(function(item_d){
                        if(filterSet.get(item_d) == false){
                            select_these = true;
                        } 
                    });

                    return select_these
                });


            } else if(filter_type == "ALL"){
                console.log('MATCH ALL')

                fData = fData.filter(function(d) {
                    var res_array = [];
                    filterSet.each(function(value, key){
                        //console.log(key, value)
                        if(value == false){
                            if(d[column].indexOf(key) !== -1){
                                res_array.push(true);
                            } else {
                                res_array.push(false);
                            }
                        }
                    })

                    //console.log(res_array);

                    if(res_array.indexOf(false) == -1){
                        return true
                    } else {
                        return false
                    }
                });

            } else {
                //console.log('MATCH ONLY')
                fData = fData.filter(function(d) {
                    var select_these = true;
                    d[column].forEach(function(item_d){
                        if(filterSet.get(item_d) == true){
                            select_these = false;
                        } 
                    })
                    return select_these;
                });

            }

        } else {

            d.forEach(function(d, k) {



                if (d == true) {
                    fData = fData.filter(function(d) {
                        return d[column] !== k;
                    });
                }
            });
        }


    });

    return fData;
}