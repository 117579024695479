import * as d3 from "d3";

//GRAPHICS GENERATION MODULES
//basic generator
export { gen } from "./gen";


//GRAPHICS
export { nestScatter } from "./nestScatter";

export { isoMap } from "./isoMap";

export { circlePack } from "./circlePack.js";

export { sweetener } from "./sweetener";

export { org } from "./org";

export { orgMap } from "./orgMap";

export { scatter3d } from "./scatter3d";

export { scatter3d2 } from "./scatter3d2";

//Topic Modelling
export { topicPivot } from "./topicPivot";

//Databub system compliant
export { prepStage } from "./prepStage";

export { prepSVG } from "./dgfx/prepSVG";

export { pipe } from "./pipe2";

export { bubStat } from "./bubStat";

export { bubMap } from "./bubMap";

export { bubMapZoom } from "./bubMapZoom";

export { treeMap } from "./treeMap1";

//INFO INTERACTION
export { keyPanel } from "./keyPanel";

export { hoverPanel } from "./hoverPanel";

export { hoverPanel_v2 } from "./hoverPanel_v2";

export { toolTip } from "./toolTip";

export { toolTip2 } from "./toolTip2";

export { toolTip_topicModeling } from "./toolTip_topicModeling";

export { mouseControl } from "./mouseControl";

//Conflict resolutions
export { textConflict } from "./textConflict";

export { textPog } from "./textPog";

export { textEdit } from "./textEdit";

//DOWNLOAD MODULES
//get an svg ready to use
export { svgPrep } from "./svgPrep";
//download that svg
export { dlsvg } from "./dlsvg";
//convert svg to pdf with Cloud Convert
export { svg2pdf_cc } from "./svg2pdf_cc";
//convert svg to pdf with DocRaptor
export { svg2pdf_dr } from "./svg2pdf_dr";
//convert svg to png
export { svg2png_cc } from "./svg2png_cc";
//convert svg to png - sending data as url.
export { svg2png_cc_web } from "./svg2png_cc_web";
//combine multiple svgs to a pdf
//NOT COMPLETE

//Data handling / UX modules suites.
//DGFX UX FOR DATA VIZ CONTROLS
export { colorButtons } from "./ux/colorButtons";
export { color } from "./ux/color";
export { legend } from "./ux/legend";

export { filterButtons } from "./ux/filterButtons";
export { filter } from "./ux/filter";

export { groupButtons } from "./ux/groupButtons";
export { group } from "./ux/group";


//Data handling / UX modules suites.
//DGFX COMPLIANT VIZ MODES
export { basicStat } from "./dgfx/basicStat";
export { basicTree } from "./dgfx/basicTree";
export { mapus } from "./dgfx/mapus";

//Data suss functions
export { basicSuss } from "./dgfx/basicSuss";
export { sussJson } from "./sussJson";


//Utilizing the update filter bundle system.
export { uniqueValueMap } from "./uniqueValueMap";

export { createFilters } from "./createFiltersButtons";

export { filterData } from "./filterData";

export { createSorts } from "./createSorts";

export { sortData } from "./sortData";

export { stratData } from "./stratData";

export { treeData } from "./treeData";

export { createSlider } from "./createSlider";

export { rangeFilterData } from "./rangeFilterData";

export { createColorButtons } from "./createColorButtons";

export { changeBubColors } from "./changeBubColors";

export { changeColors } from "./changeColors";

//The first suss was for coke.
export { suss } from "./suss";
//Suss procedure for other projects.
export { sussPipe } from "./sussPipe";
//Suss procedure for Org Charts
export { sussOrg } from "./sussOrg";

export { sussProjects } from "./sussProjects";



/////SOME EXTRA FUNCTIONS////////////////////
////////////////////////////////////////

export function sayHi(data) {
  console.log("DDDMODS LIVE");
}

export function vbSize(svg) {
  
  var vals = svg.attr("viewBox");
  var ssv = d3.dsvFormat(" ");
  var vbInfo = ssv.parse(vals);
  var hwArray = [];
  // hwArray.push(parseInt(vbInfo.columns[3]));
  // hwArray.push(parseInt(vbInfo.columns[2]));


  hwArray.push(+vbInfo.columns[3]);
  hwArray.push(+vbInfo.columns[2]);



  return hwArray;
}

export const formats = {
  totalFmt: "$,.4s",
  rawPctFmt: ".1%",
  pctFmt: ",.0%",
  rnd1Fmt: ".1f",
  wholeFmt: ".0f"
};

//returns an array of unique values from a column, as keys in a map, with a boolean set to false
export function uniques(data, column) {
  //What happens in uniques
  //d3.map do make a list of uique values - that was a mistake!
  // So this is really only meant

  //1. First I check if the column exists in a very weird way.
  //Why do I ask a strgin if it is an array?
  if (Array.isArray(data[0][column])) {

    //and if its I create a variable called list to hold the unique values....
    var list = [];
    //add all the values for that column in the data.
    data.forEach(function(d) {
      list.push(d[column]);
    });
    //then use merge to elminate all the duplicates.
    list = d3.merge(list);
    
    //the make an map that 
    list = d3.map(list, function(d) {
        return d;
      })
      .keys();


  } else {

    var list = d3.map(data, function(d) {
        //console.log(d[column]);
        if (d[column] != "undefined" && d[column] != "") {
          //console.log("full");
          return d[column];
        }
      })
      .keys();

  }

  //list is sorted alphabetically.
  list.sort();

  var obj = {};

  list.forEach(function(d) {
    obj[d] = false;
  });

  return obj;
}


export function wrap(text, width) {
  //console.log("wrapping")
  //why does this add preceding t spans sometimes???
  text.each(function() {
    var text = d3.select(this),
      words = text
        .text()
        .split(/\s+/)
        .reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.1, // ems
      y = text.attr("y"),
      dy = parseFloat(text.attr("dy")),
      tspan = text
        .text(null)
        .append("tspan")
        .attr("x", 0)
        .attr("y", y)
        .attr("dy", dy + "em");
    //the above line replaces the orignal text with a null and then appends t-span in its place.
    // this while loop is ingenius. It takes the last value in the array words,
    // and assigns it to the variable word - which is undefined.
    // once the each value has been removed from the array, it will return false and end the while loop.
    while ((word = words.pop())) {
      //add the word to the line array.
      line.push(word);
      // add a space between words.
      tspan.text(line.join(" "));
      //console.log(tspan.node().getComputedTextLength());

      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .text(word);
      }
    }
  });
}

///STRING UTILITIES
export function cap1(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function titleCase(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function truncate(str, l, end = true) {
  if (end == true) {
    return str.length > l ? str.substr(0, l - 1) + "..." : str;
  } else {
    return str.length > l ? str.substr(0, l - 1) : str;
  }
}

export function parseTransform(coords) {
  coords = coords.split("translate(");
  coords = coords[1].split(",");
  coords[1] = coords[1].split(")");
  coords[1] = coords[1][0];
  coords[0] = parseInt(coords[0]);
  coords[1] = parseInt(coords[1]);
  //console.log(coords);
  return coords;
}

/////
