//bubMap
import * as d3 from 'd3';
import { geoArmadillo } from 'd3-geo-projection';
import * as topojson from "topojson";

//other stuff
import { parseTransform } from "./ddd";
import { vbSize } from "./ddd";
import { textEdit } from "./ddd";

import { toolTip2 } from "./ddd";
import { hoverPanel } from "./ddd";

import { cokeGeos } from "../ddd/mapdata/geos.js";
import { circlePack } from "../ddd/circlePack.js";

//require map data
const mapdata = require("../ddd/mapdata/admin0.json");
const geos = require("../ddd/mapdata/geos.json");

var isoLookup = {};
//This just finds cokes short names.
cokeGeos.forEach(function (d) {
	isoLookup[d.ISO3] = d.COKE_SPELLING;
})
//sort, filters



export function bubMapZoom(svg, sData, fData, sort, filters) {
	console.log('bubmap');
	//console.log(mapdata);

	//Layers
	var scatterLayer = svg.select("#scatterGroup");
	scatterLayer.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var vizLr = svg.select("#l-viz");
	var gridLr = svg.select("#l-grid");
	gridLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var mapLr = svg.select("#l-map");
	//mapLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var dataLr = svg.select("#l-data");
	//dataLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var copyLr = svg.select("#l-copy");
	copyLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var stageLr = svg.select("#l-stage");
	var thekey = svg.select("#l-key");
	thekey.transition().duration(1000).style("opacity", 1);
	//Sizes
	var svgSize = vbSize(svg);
	var svgHeight = svgSize[0];
	var svgWidth = svgSize[1];
	var gcoords = parseTransform(dataLr.attr("transform")); //Grab the corrdinates of thedata layer which has a margin from top and left
	var gWidth = svgWidth - (gcoords[0] * 2); //50 the sides.
	var gHeight = svgHeight - (gcoords[1] + gcoords[0]); //100 - the top

	var margin = { top: 100, right: 50, bottom: 50, left: 50 };

	//this is needed for the map zoom
	var active = d3.select(null);

	//here the actual zoom handler.
	var zoom = d3.zoom()
		// no longer in d3 v4 - zoom initialises with zoomIdentity, so it's already at origin
		// .translate([0, 0])
		// .scale(1)
		.scaleExtent([1, 6])
		.on("zoom", zoomed);


	vizLr.call(zoom);
	vizLr.on("click", stopped, true);

	var colorScale = d3.scaleOrdinal(d3.schemeCategory10);

	//sort/nest this raw data based on ISO code.
	var isoSort = d3.nest()
		.key(function (d) {
			//console.log(d);
			return d.ISO3;
		})
		.sortKeys(d3.ascending)
		.entries(fData);

	//console.log(isoSort);
	//the scale for circle radius
	var countryMax = d3.max(isoSort, function (d) {
		//console.log(d.values.length);
		return d.values.length;
	});

	var radius = d3.scaleSqrt()
		.domain([0, countryMax])
		.range([0, (gWidth * .03)]);

	var curK = d3.zoomTransform(vizLr.node()).k;

	console.log(curK);

	var bubSize = 4;

	console.log(bubSize);

	//Transitions
	var tIntro = d3.transition()
		.duration(2000)
		.ease(d3.easePolyInOut);

	//cut out entries with no ISO.
	fData = fData.filter(function (p) {
		//console.log(p.ISO != "");
		//console.log(p.ISO3 == "");
		return p.ISO3 != "";
	});



	//Access the map shapes. Switch this to require.
	//var iso = topojson.feature(admin0, admin0.objects.layer1);
	var isoMap = topojson.feature(mapdata, mapdata.objects.layer1);
	var isoPoint = topojson.feature(mapdata, mapdata.objects.lAdmin0);
	//console.log(isoPoint);

	var usaPoint = isoPoint.features.filter(function (d) {
		//console.log(d);//return p.properties.ISO3_CODE == d.ISO && p.properties.STATUS == "Member State";
		if (d.id == "USA") {
			//console.log(d);
			d.geometry.coordinates[0] = -97;
			d.geometry.coordinates[1] = 38;
			//console.log(d.geometry.coordinates);
		} else if (d.id == "RUS") {
			//console.log(d);
			d.geometry.coordinates[0] = 55;
			d.geometry.coordinates[1] = 60;
			//console.log(d.geometry.coordinates);
		}
		//return p.id == d.ISO3;
	});

	//Create a mercator propjection
	var proj = d3.geoMercator()
		.scale(200)
		.center([10, 0])
		.rotate([-10, 0, 0]) //.rotate([-10, 0, 0]) to center russia
		.translate([svgWidth / 2, svgHeight / 2]);
	/*            .clipExtent([
	                [0, 0],
	                [gWidth, 600]
	            ]);*/

	//Now create a geoPath generator that uses the projection.
	var path = d3.geoPath()
		.projection(proj);


	if (vizLr.select("#map-back").empty()) {
		vizLr.insert("rect", ":first-child")
			.attr("id", "map-back")
			.classed("map-click-background", true)
			.attr("width", gWidth)
			.attr("height", gHeight)
			.on("click", reset);
	}






	update(fData);
	//General Update Pattern
	function update(data) {

		//Now we creat the simulation with the filtered data.
		var simulation = d3.forceSimulation(data)
			//add the first force, and x – left right – force.
			.force("x", d3.forceX(function (d) {
				//console.log(d);
				var thePoint = isoPoint.features;
				thePoint = thePoint.filter(function (p) {
					return p.id == d.ISO3;
				});
				thePoint = thePoint[0];
				var pc = proj(thePoint.geometry.coordinates);
				return pc[0];
			}).strength(.5))
			.force("y", d3.forceY(function (d) {
				var thePoint = isoPoint.features;
				thePoint = thePoint.filter(function (p) {
					return p.id == d.ISO3;
				});
				thePoint = thePoint[0];
				var pc = proj(thePoint.geometry.coordinates);
				return pc[1];
			}).strength(.5))
			.force("collide", d3.forceCollide(bubSize * 1.2))
			.stop();


		//Why does he do this 120 times? Just because he assumes that is enough times?
		for (var i = 0; i < 120; ++i) simulation.tick();

		//Add graticule lines for the world map.
		var graticule = d3.geoGraticule().step([30, 30]);

		var lines = mapLr.append("path")
			.datum(graticule)
			.classed('graticule', true)
			.attr("d", path);

		//Now draw the countries


		//console.log(d3.selectAll(".countries").size());
		//this is where the zoom handler goes....
		var countries = mapLr.selectAll(".countries")
			.data(isoMap.features)
			.enter().append("path")
			.classed("feature countries", true)
			.attr("d", path)
			.style("opacity", 0)
			.on("click", clicked);



		mapLr.append("path")
			.datum(topojson.mesh(mapdata, mapdata.objects.layer1, function (a, b) { return a !== b; }))
			.attr("class", "mesh")
			.attr("d", path);

		countries.transition().duration(1000).style("opacity", 1)

		//console.log(data);


		var bubs = dataLr.selectAll(".data-bub")
			.data(data, function (d, i) {
				return d.id || (d.id = ++i);
			});


		//The first time around there wont be any nodes in the exit, but no matter.
		bubs.exit()
			.transition().duration(3000)
			.style("opacity", 0)
			.each(function (d) {
				//Log out the removed elements.
				console.log(d);
			})
			.remove();

		var enter = bubs.enter() //add any new ones - a group and a circle within it.
			.append("g")
			.classed("data-bub", true)
			.attr("transform", function (d, i) {
				///console.log(i);
				var y = gHeight / 2;
				var x = gWidth / 2;
				return "translate(" + x + "," + y + ")";
			})
			.on("click", clicked)
			.on('mouseover', function (d) {
				d3.selectAll(".hoverPanel").remove();
				hoverPanel(d, svg, 14);
			});

		enter.append("circle")
			.classed("bub", true)
			.each(function (d) {
				//Log out the new elements.
				//console.log(d.id);
			});

		//But nothing happens. Why is the update selection empty after merge?
		bubs = enter.merge(bubs);

		//console.log(bubs);

		bubs.transition(tIntro)
			.attr("transform", function (d, i) {
				//console.log(d);
				var y = d.y;
				var x = d.x;
				return "translate(" + x + "," + y + ")";
			})


		var bubBub = bubs.selectAll(".bub");

		bubBub.transition(tIntro)
			.attr("r", bubSize);

	}

	//headline et all
	if (stageLr.select("#headline").empty()) {
		var headline = stageLr.append("text")
			.attr("id", "headline")
			.attr("x", 50)
			.classed("gfx-head", true)
			.text(function (d) {
				var recCount = fData.length;
				//console.log(recCount);
				//return recCount + " total in the list";
				return recCount + " RCG STUDIES";
			})
			.attr("y", function () {
				var textNode = d3.select(this);
				var textHeight = textNode.node().getBBox().height;
				return textHeight + 14;
			})
			.call(textEdit, "headline");


		stageLr.append('rect')
			.attr("height", svgHeight)
			.attr("width", svgWidth)
			.classed("box1", true);

	}

	stageLr.select("#headline").text(function (d) {
		var recCount = fData.length;
		//console.log(recCount);
		//return recCount + " total in the list";
		return recCount + " RCG STUDIES";
	});

	////map zoom
	function clicked(d) {

		console.log(this);
		//console.log("clicked");
		console.log(d.properties.ISO3_CODE);

		if (active.node() === this) return reset();
		active.classed("active", false);
		active = d3.select(this).classed("active", true);

		if (d.properties.ISO3_CODE == "USA") {
			var bounds = [
				[190, 150],
				[250, 290]
			];
		} else if (d.properties.ISO3_CODE == "RUS") {
			var bounds = [
				[600, 50],
				[1100, 200]
			];
		} else {
			var bounds = path.bounds(d);
		}




		var dx = bounds[1][0] - bounds[0][0],
			dy = bounds[1][1] - bounds[0][1],
			x = (bounds[0][0] + bounds[1][0]) / 2,
			y = (bounds[0][1] + bounds[1][1]) / 2,
			scale = Math.max(1, Math.min(8, 0.9 / Math.max(dx / gWidth, dy / gHeight))),
			translate = [gWidth / 2 - scale * x, gHeight / 2 - scale * y];

		console.log(bounds);

		vizLr.transition()
			.duration(750)
			.call(zoom.transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale))
			.on("end", function (d) {
				//var curK = d3.zoomTransform(vizLr.node()).k;
				//bubSize = bubSize / curK;
				//simulation.restart();
				//simulation.alphaTarget(120);
				//update(fData);
			}) // updated for d3 v4

		//console.log(zoom.transform);
		//console.log(d3.zoomIdentity);
	}

	function reset() {
		console.log("reset");
		active.classed("active", false);
		active = d3.select(null);

		vizLr.transition()
			.duration(750)
			// .call( zoom.transform, d3.zoomIdentity.translate(0, 0).scale(1) ); // not in d3 v4
			.call(zoom.transform, d3.zoomIdentity.translate(50, 100).scale(1)); // updated for d3 v4
	}

	function zoomed() {
		console.log("zoomed");
		console.log(curK);

		//bubSize = bubSize / d3.event.transform.k;

		var cName = "",
			cISO = "";

		active.each(function (d) {
			cName = d.properties.name;
			cISO = d.properties.ISO_CODE;
		});

		// g.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")"); // not in d3 v4
		vizLr.attr("transform", d3.event.transform); // updated for d3 v4

		dataLr.selectAll(".bub")
			.transition()
			//.attr("r", bubSize / curK)
			.style("stroke-width", .5 / d3.event.transform.k + "px");

		mapLr.selectAll(".mesh").style("stroke-width", .5 / d3.event.transform.k + "px");

		stageLr.select("#headline").text(d => cName);

		//

		//update(fData);

	}

	// If the drag behavior prevents the default click,
	// also stop propagation so we don’t click-to-zoom.
	function stopped() {
		if (d3.event.defaultPrevented) d3.event.stopPropagation();
	}
}