export const sweetNames = {
  "S_ACE_K": { "NICE_NAME":"ACE K", "COLOR":"#5a2b84", "C_NAME":"purple"},
  "S_AGAVE_SYRUP": { "NICE_NAME":"Agave Syrup", "COLOR":"#00984a", "C_NAME":"green"},
  "S_ALLULOSE": { "NICE_NAME":"Allulose", "COLOR":"#ffdd00", "C_NAME":"yellow"},
  "S_ASPARTAME": { "NICE_NAME":"Aspartame", "COLOR":"#03499a", "C_NAME":"blue"},
  "S_ERYTHRITOL": { "NICE_NAME":"Erythritol", "COLOR":"#03499a", "C_NAME":"blue"},
  "S_FRUCTOSE": { "NICE_NAME":"Fructose", "COLOR":"#db524b", "C_NAME":"red"},
  "S_FRUIT_SUGAR": { "NICE_NAME":"Fruit Sugar", "COLOR":"#db524b", "C_NAME":"red"},
  "S_HFCS": { "NICE_NAME":"HFCS", "COLOR":"#db524b", "C_NAME":"red"},
  "S_JUICE": { "NICE_NAME":"Juice", "COLOR":"#00984a", "C_NAME":"green"},
  "S_MONK_FRUIT": { "NICE_NAME":"Monk Fruit", "COLOR":"#00984a", "C_NAME":"green"},
  "S_NEOHESPERIDIN_DIHYDROCHALCONE": { "NICE_NAME":"NHDC", "COLOR":"#ffdd00", "C_NAME":"yellow"},
  "S_NEOTAME": { "NICE_NAME":"Neotame", "COLOR":"#ff00af", "C_NAME":"pink"},
  "S_SACCHARIN": { "NICE_NAME":"Saccharin", "COLOR":"#ff00af", "C_NAME":"pink"},
  "S_SODIUM_CYCLAMATE": { "NICE_NAME":"Sodium Cyclamate", "COLOR":"#ff00af", "C_NAME":"pink"},
  "S_STEVIA-ALPHA": { "NICE_NAME":"Stevia (Alpha)", "COLOR":"#00984a", "C_NAME":"green"},
  "S_STEVIA-REB_A": { "NICE_NAME":"Stevia (Reb A)", "COLOR":"#00984a", "C_NAME":"green"},
  "S_STEVIA-REB_M": { "NICE_NAME":"Stevia (Reb B)", "COLOR":"#00984a", "C_NAME":"green"},
  "S_STEVIA-SG95_30": { "NICE_NAME":"Stevia (SG95 30)", "COLOR":"#00984a", "C_NAME":"green"},
  "S_STEVIA-SG95_80": { "NICE_NAME":"Stevia (SG95 80)", "COLOR":"#00984a", "C_NAME":"green"},
  "S_STEVIA-SG95_RA50": { "NICE_NAME":"Stevia (SG95 RA 50)", "COLOR":"#00984a", "C_NAME":"green"},
  "S_STEVIA-SG95_RA99": { "NICE_NAME":"Stevia (SG95 RA 99)", "COLOR":"#00984a", "C_NAME":"green"},
  "S_STEVIA-NSF-04": { "NICE_NAME":"Stevia (NSF-04)", "COLOR":"#00984a", "C_NAME":"green"},
  "S_STEVIA-NSF-02": { "NICE_NAME":"Stevia (NSF-02)", "COLOR":"#00984a", "C_NAME":"green"},
  "S_SUCRALOSE": { "NICE_NAME":"Sucralose", "COLOR":"#ffdd00", "C_NAME":"green"},
  "S_SUCROSE": { "NICE_NAME":"Sucrose", "COLOR":"#db524b", "C_NAME":"red"}
}