//screatFilters.js takes a the results of a d3.dsv and creates filter buttons, and returns data for a viz.
import * as d3 from "d3";
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import { keyPanel } from "./ddd";

//This is a simple selection based color change using acss classes to identify.

export function changeBubColors(colorChoice) {
	//target SVG
	var svg = d3.select("#g1");
	//Scale of 
	var colorScale = d3.scaleOrdinal(d3.schemeCategory10);
	console.log(colorChoice);
	var keyColors = {};


	var dataBubs = svg.selectAll(".data-bub");
	var bubs = dataBubs.select(".bub");
	
	
	
	
	bubs.transition().style("fill", function (d) {
			if (d.ASMET != "YES") {
				return "white";
			} else {
				var keyItem = d[colorChoice];
				var itemColor = colorScale(d[colorChoice]);
				keyColors[keyItem] = itemColor;
				//console.log(keyColors);
				return itemColor;
			}
		})
		.style("stroke", function (d) {
			return colorScale(d[colorChoice]);
		})
		.style("fill-opacity", function (d) {
			if (d.ASMET != "YES") {
				return .75;
			} else {
				return .9;
			};
		});



	var dots = svg.selectAll(".dots");
	dots.transition().style("fill", function (d) {
			if (d.ASMET != "YES") {
				return "white";
			} else {
				var keyItem = d[colorChoice];
				var itemColor = colorScale(d[colorChoice]);
				keyColors[keyItem] = itemColor;
				//console.log(keyColors);
				return itemColor;
			}
		})
		.style("stroke", function (d) {
			return colorScale(d[colorChoice]);
		})
		.style("fill-opacity", function (d) {
			//console.log(d);
			if (d.ASMET != "YES") {
				return .75;
			} else {
				return .9;
			};
		});


	//.barline

	var sticks = svg.selectAll(".barline");
	sticks.transition()
		.style("stroke", function (d) {
			return colorScale(d[colorChoice]);
		});


	var bands = svg.selectAll(".chart-bands");
	bands.transition().style("fill", function (d) {
		return colorScale(d[colorChoice]);
	});


	//Now update the key
	keyPanel(keyColors, svg, 12);


	return "";

}