import * as d3 from "d3";
import { vbSize } from "./ddd";
import { parseTransform } from "./ddd";
//ToolTip is a resuabel tooltip with a single text value.
//Must be applied to a group,
//Also the copyLr must be present in the svg....
export function toolTip2(elem, edata, size, field, txt) {
	//console.log(edata);
	var e = elem.node();
	//console.log(e.ownerSVGElement);
	var svg = d3.select(e.ownerSVGElement);
	//console.log(svg.node());
	//console.log(e);
	var gridLr = svg.select("#l-grid");
	var dataLr = svg.select("#l-data");
	var copyLr = svg.select("#l-copy");
	var stageLr = svg.select("#l-stage");
	//Sizes
	var svgSize = vbSize(svg);
	var svgHeight = svgSize[0];
	var svgWidth = svgSize[1];
	var gcoords = parseTransform(dataLr.attr("transform")); //Grab the coordinates of thedata layer which has a margin from top and left
	var gWidth = svgWidth - (gcoords[0] * 2); //50 the sides.
	var gHeight = svgHeight - (gcoords[1] + gcoords[0]); //100 - the top

	var toolText;


	//A bunch of fall back defaults for the text.
	if (txt) {
		toolText = txt;
	} else if (field) {
		//console.log(edata.data[field]);
		toolText = edata.data[field] != '' ? edata.data[field] : edata.id;
	} else {
		toolText = edata.id;
	};


	//console.log(tipText);


	elem.on('mouseenter', function (e,d) {
		console.log(d);
		//var gCoord = d3.mouse(svg.node());
		//console.log(arguments);
		
		var gCoord = d3.pointer(e);
		console.log(gCoord);
		var tipY = gCoord[1] - 3;
		var tipX = gCoord[0] + 3;

		var rollover = elem.append("g")
			.attr("transform", "translate(" + tipX + "," + tipY + ")")
			.classed("dtip", true);

		//creat the text object so you can measure it
		var tipText = rollover.append("text")
			.classed("rev-txt", true)
			.style("font-size", size)
			.attr("dy", ".35em")
			.attr("x", 15)
			.text(function (d) {
				console.log(d);
				return (toolText);
			});
		//measure it
		var txtLength = tipText.node().getComputedTextLength() + 10;
		//draw a rounded box behind.
		rollover.insert("rect", ":first-child")
			.attr("height", function () {
				return size + 10;
			})
			.attr("width", txtLength)
			.attr("rx", 4)
			.attr("ry", 4)
			.attr("y", -((size + 10) / 2))
			.attr("x", 10)
			.classed("roll-pad", true);
		//draw the triangle
		rollover.insert("path", ":first-child")
			.attr('d', d3.symbol().type(d3.symbolTriangle).size(100))
			.attr("transform", "rotate(-90) translate(0,10)")
			.classed("roll-pad", true);
		//and move it when the mouse moves.
		elem.on("mousemove", function (e, d) {
			var newCoord = d3.pointer(e);
			var nTipY = newCoord[1] - 3;
			var nTipX = newCoord[0] + 3;
			rollover.attr("transform", "translate(" + nTipX + "," + nTipY + ")");
		});
	});

	elem.on('mouseleave', function (d) {
		//console.log("out");
		d3.selectAll(".dtip").remove();
	});



	///click version
	elem.on('click', function (d) {

		var clickID = "click" + edata.id;

		console.log(this);

		if (!d3.select("#" + clickID).empty()) {

			d3.select("#" + clickID).remove();


		} else {
			var gCoord = d3.pointer(e);
			//console.log(gCoord);
			var rollSVG = d3.select(e);
			var tipY = gCoord[1] - 3;
			var tipX = gCoord[0] + 3;
			//var gCoord = d3.mouse(svg.node());
			var rollover = elem.append("g")
				.attr("transform", "translate(" + tipX + "," + tipY + ")")
				.classed("clicktip no-events", true)
				.attr("id", clickID)
				.on("click", function () {
					d3.event.stopPropagation();
					d3.select(this).remove();
				})
				.on("mouseenter", function () {
					//console.log(this);
					//d3.event.stopPropagation();
				});

			//

			var tipText = rollover.append("text")
				.classed("rev-txt", true)
				.style("font-size", size)
				.attr("dy", ".35em")
				.attr("x", 15)
				.text(toolText);

			var txtLength = tipText.node().getComputedTextLength() + 10;


			rollover.insert("rect", ":first-child")
				.attr("height", function () {
					return size + 10;
				})
				.attr("width", txtLength)
				.attr("rx", 4)
				.attr("ry", 4)
				.attr("y", -((size + 10) / 2))
				.attr("x", 10)
				.classed("roll-pad", true);

			rollover.insert("path", ":first-child")
				.attr('d', d3.symbol().type(d3.symbolTriangle).size(100))
				.attr("transform", "rotate(-90) translate(0,10)")
				.classed("roll-pad", true);

		}


		//.attr("transform", "translate(0,5)");
	});




}