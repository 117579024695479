//suss.js parses tab deliminted data and return an object with the data and other useful things
import * as d3 from "d3";
//import { sweetNames } from "./coke/sweetNames";
import { cokeGeos } from "./coke/cokeGeos";


export function sussJson(data) {

    var returns = {};

    if (!data) {
        returns.msg = "Please paste valid JSON data in the input field above";
    } else {
    
        var parsedData = JSON.parse(data);

        parsedData.forEach(function(d, i) {
            if (!d.id) {
                d.id = ++i;
            }
        });

        parsedData.columns = Object.keys(parsedData[0]);

        //List the columns in the data
        var columns = parsedData.columns;
        //Find out how many columns total.
        var colCount = +columns.length;
        //Find total number of records
        var recordCount = +parsedData.length;
        returns.recordCount = recordCount;
        returns.colCount = colCount;
        returns.data = parsedData;

///Here is where I should add returns.stats that should:
    // 1. Judge what is in each column string, number, integer, date format, money
    // 2. Find the range of each for numeric, and list of uniques for strings, unique ids.
    // 3. Create histograms, or bins for each column

        if (colCount > 3) {
            //console.log('min columns reached');
            returns.msg = recordCount + " entries | " + colCount + " columns. Now Viz It!";
            returns.success = true;
        } else {
            returns.success = false;
            returns.msg = 'Please paste valid JSON';
        }

    }

    return returns;
}

//This is a script  using the above variable Tobago join the ISO3 values to an arbitrary list of goe names from the company.
function joinGeos(data) {
    data.forEach(function(d) {
        var iso = d.ISO3;
        cokeGeos.forEach(function(val) {
            if (iso == val.ISO3) {
                d.BU = val.BU;
                d.REGION = val.REGION;
            }
        });
        //console.log(d.ISO3 + " : " + d.REGION + " : " + d.BU);
    });

    return data;
}


////
