//returns an array of unique values from a column, as keys in a map, with a boolean set to false
export function uniqueValueMap(data, column) {
    //What happens in uniques

    if (data[0][column]) {

        const uniqueColumnValueMap = new Map()  


        // This needs to handle empty strings and other bad vals./
        data.forEach(d => 
            
            uniqueColumnValueMap.set(d[column], false)
            
            
            );
      
        return uniqueColumnValueMap;


    } else {return "error"}

}