//nestScatter
// I have ot say I have the nest scatter, as I have never fully sussed the placement of
//the object without using groups that destroy the svg coordinates,
//but its a lot easier to draw to a group.

import * as d3 from "d3";
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import { vbSize } from "./ddd";
import { uniques } from "./ddd";
import { createFilters } from "./ddd";
import { createSorts } from "./ddd";
import { wrap } from "./ddd";
import { cap1 } from "./ddd";
import { titleCase } from "./ddd";
import { textEdit } from "./ddd";
import { hoverPanel } from "./ddd";
import { formats } from "./ddd";
//import { toolTip } from "./ddd";
import { toolTip2 } from "./ddd";
import { textConflict } from "./ddd";
import { textPog } from "./ddd";
import { parseTransform } from "./ddd";


export function nestScatter(svg, sData, fData, sort, filters) {
	//console.log('<--<SCATTER>--');

	fData.forEach(function (d) {
		//d.PCT_BRIX = +d.PCT_BRIX / 100;
		//d.ID = d.ID.toString();
		// console.log(d.CONTROL);
	});
	var zoom = d3.zoom();
	var vizLr = svg.select("#l-viz");
	var gridLr = svg.select("#l-grid");
	gridLr.selectAll("*").transition().duration(500).style("opacity", 0).remove();
	var mapLr = svg.select("#l-map");
	mapLr.selectAll("*").transition().duration(500).style("opacity", 0).remove();
	var dataLr = svg.select("#l-data");
	dataLr.selectAll("*").transition().duration(500).style("opacity", 0).remove();
	var copyLr = svg.select("#l-copy");
	copyLr.selectAll("*").transition().duration(500).style("opacity", 0).remove();
	var stageLr = svg.select("#l-stage");
	var keyLr = svg.select("#l-key");
	//Sizes
	var svgSize = vbSize(svg);
	var svgHeight = svgSize[0];
	var svgWidth = svgSize[1];
	var gcoords = parseTransform(vizLr.attr("transform")); //Grab the corrdinates of thedata layer which has a margin from top and left
	var gWidth = svgWidth - (gcoords[0] * 2); //50 the sides.
	var gHeight = svgHeight - (gcoords[1] + gcoords[0]); //100 - the top



	var margin = { top: 100, right: 50, bottom: 100, left: 50 };

	//upperleft point of the scatter
	var xPoint = svgWidth - margin.left;
	var yPoint = svgHeight - margin.top;
	//Set the height/width of the actual chart
	var scatterW = gWidth - 30,
		scatterH = gHeight - 40;
	//temporary locationm of static hover panel details
	var colorScale = d3.scaleOrdinal(d3.schemeCategory10);

	drawScatter(sData);

	function drawScatter(data) {
		//CLEAN THE CANVAS
		//dataLr.transition().duration(1000).style("opacity", 0);
		//mapLr.selectAll("*").transition().duration(1000).attr("opacity", 0).remove();
		//DATA SETUP
		var nData = data;
		// rangeBands is an array, used to keep all the bands - top level categories.
		var rangeBands = [];
		// this variable counts the total number of entreis, which I could have known but whatever.
		var cummulative = 0;

		//look through each entry in the data, which is nested.
		nData.forEach(function (val, i) {
			//add a key to each called cummulative, and set it to whatever the current value of cumultive is...
			//so I guess the value represent the total before this node ie the starting point.
			val.cummulative = cummulative;
			//then increase the real cumulatrive variable by the number of nodes within that top level.
			cummulative += val.values.length;

			val.values.forEach(function (values) {
				//ok now going through each item in each group, and giving it a value of whio its parent is
				values.parentKey = val.key;
				//now simply adding the number - so I guess we get an array that has the same number of values
				//as there are level 1 categories.
				rangeBands.push(i);
			})
			//console.log(rangeBands);
		});

		// does it get used - like how...
		var barPadding = 40;

		//A horizontal linear scale will determine where to put the categories.
		var x_category = d3.scaleLinear()
			.range([0, scatterW]);

		//I think Im starting to get it. x_decet scale only exists to find the bandwidth is for each bar
		var x_defect = d3.scaleBand().domain(rangeBands).rangeRound([0, scatterW]);
		//what impact does this have?
		x_defect.padding(0.1);
		//console.log(x_defect.bandwidth());
		//console.log(x_defect.domain());
		//console.log(rangeBands.length);
		//now sewe set it to the huge number...of the pizel width of the bandscale * the total number of bars
		var x_category_domain = x_defect.bandwidth() * rangeBands.length;
		//console.log(x_category_domain);

		x_category.domain([0, x_category_domain]);

		//the y scale is what it is.
		var yScale = d3.scaleLinear()
			.range([scatterH, 0]);

		yScale.domain([0, d3.max(nData, function (cat) {
			return d3.max(cat.values, function (def) {
				return def.PCT_BRIX;
			});
		})]);

		//Create the bottom axis bottom based on X_category - the linear one.
		var category_axis = d3.axisBottom()
			.scale(x_category)
			.tickSize(10)
			.tickSizeOuter(0);;

		//Create the axis ticks for the y axis/
		var yAxis = d3.axisLeft(yScale)
			.tickSize(-scatterW)
			.tickFormat(d3.format(formats.wholeFmt))
			.tickSizeOuter(1);


		//START DRAWING.

		if (svg.select("#scatterGroup").empty()) {
			//console.log("second scatter scatter");
			scatG = svg.append("g")
				.attr("transform", "translate(" + 80 + "," + margin.top + ")")
				.attr("id", "scatterGroup");
		} else {
			var scatG = d3.select("#scatterGroup");
		}

		scatG.selectAll("*").remove();

		// Add the Y axis
		scatG.append("g")
			.attr("class", "y axis")
			.attr("transform", "translate(0,0)")
			.call(customYaxis);

		//a function to style the y axis.
		function customYaxis(g) {
			var s = g.selection ? g.selection() : g;
			g.call(yAxis);
			s.select(".domain").remove();
			if (s !== g) g.selectAll(".tick text").attrTween("x", null).attrTween("dy", null);
		}

		// This is a group for each 1st level object of the nest. ANd we'd really like to get rid of it
		//nope.
		var category_g = scatG.selectAll(".category")
			.data(nData)
			.enter().append("g")
			.attr("class", function (d) {
				return 'category category-' + d.key;
			})
			.attr("transform", function (d) {
				// we use the category (linear) scale to position this group
				//d.cumulative marks the number of elements at the begginging of the group
				// then. multiply it by the bandwidth - but then why run it through the category scale?
				//this is going to determine the starting point of that group on the linear scale.
				return "translate(" + x_category((d.cummulative * x_defect.bandwidth())) + ",0)";
			});

		////A Group to add category labels
		var category_label = category_g.selectAll(".cat-label-group")
			.data(function (d) {
				return [d];
			})
			.enter().append("g")
			.attr("transform", function (d) {
				var x_label = x_category((d.values.length * x_defect.bandwidth() + barPadding) / 2);
				var y_label = scatterH + 20;
				return "translate(" + x_label + "," + y_label + ")";
			});


		//A label text object.
		category_label.append("text")
			.attr("class", function (d) {
				//console.log(d)
				return 'category-label category-label-' + d.key;
			})
			.data(function (d) {
				return [d];
			})
			.style("fill", function (d) {
				return colorScale(d.key);
				//return "white";
			})
			.attr("dy", ".35em")
			.text(function (d, i) {
				if (d.label) {
					return d.label;
				} else {
					return d.key;
				}
			})
			.attr('text-anchor', 'middle')
			.each(function (d, i) {
				var w = x_category((d.values.length * x_defect.bandwidth()) + barPadding);
				textPog(this, d, w);
				//var conflict = textConflict(this, d, "category-label");
			});


		// now a group for each bar or dataspace - within each category group.
		var defect_g = category_g.selectAll(".defect")
			.data(function (d) {
				return d.values;
			})
			.enter().append("g")
			.attr("class", function (d) {
				return 'defect defect-' + d.key;
			})
			.attr("transform", function (d, i) {
				var barSpot = x_category((i * x_defect.bandwidth()));
				return "translate(" + barSpot + ",0)";
			});

		//add rectangles for selection.
		var rects = defect_g.selectAll('.rect')
			.data(function (d) {
				return [d];
			})
			.enter().append("rect")
			.attr("class", "chart-bands")
			.attr("width", x_category(x_defect.bandwidth()))
			.attr("x", function (d) {
				return x_category(barPadding);
			})
			.attr("y", 0)
			.attr("height", function (d) {
				return scatterH;
			})
			.style("fill", function (d) {
				return colorScale(d[sort]);
			})
			.each(barPrep);

		// add vertical lines.
		var lines = defect_g.selectAll('.barline')
			.data(function (d) {
				return [d];
			})
			.enter().append("line")
			.attr("class", "barline")
			.attr("x1", function (d) {
				return x_category(barPadding) + (x_category(x_defect.bandwidth()) / 2);
			})
			.attr("x2", function (d) {
				return x_category(barPadding) + (x_category(x_defect.bandwidth()) / 2);
			})
			.attr("y1", function (d) {
				return yScale(d.PCT_BRIX);
			})
			.attr("y2", function (d) {
				return scatterH;
			})
			.style("stroke", function (d) {
				return colorScale(d[sort]);
			});

		// add the data dots themselves.
		var allDots = defect_g.selectAll(".dots")
			.data(function (d) {
				return [d];
			})
			.enter().append("g")
			.attr("transform", function (d) {
				var x = x_category(barPadding) + (x_category(x_defect.bandwidth()) / 2);
				var y = yScale(d.PCT_BRIX);
				return "translate(" + x + ", " + y + ")";
			})
			.attr("class", "dotGroup")
			.each(function (d) {
				var elem = d3.select(this);
				var fmt = d3.format(formats.wholeFmt);
				var txt = fmt(d.PCT_BRIX) + "%";
				toolTip2(elem, d, 16, "NAME");
			});


		allDots.append("circle")
			.attr("class", "dots")
			.attr("r", function () {
				var atLeast = Math.max(5, x_category(x_defect.bandwidth()) / 2);
				var butLessthan = Math.min(25, atLeast);
				return butLessthan;
			})
			.style("fill", function (d) {
				if (d.ASMET != "YES") {
					return "white";
				} else {
					return colorScale(d[sort]);

				}
			})
			.style("stroke", function (d) {
				return colorScale(d[sort]);
			});

		var headline = scatG.append("text")
			.attr("x", -25)
			.classed("chart-title", true)
			.text("PERCENT SUGAR REDUCTION")
			.attr("y", -15);

		//ADD THE LEGEND
		var legend = copyLr.append("g")
			.attr("transform", "translate(" + 200 + "," + -30 + ")");

		legend.append('rect')
			.attr("height", 20)
			.attr("width", 250)
			.classed("legend-box", true);

		legend.append('circle')
			.attr("r", 6)
			.attr("cx", 10)
			.attr("cy", 10)
			.style("fill", "grey")
			.style("stroke", "grey")
			.style("stroke-width", 1);

		legend.append("text")
			.attr("x", 25)
			.attr("y", 10)
			.attr("dy", ".35em")
			.classed("legend-label", true)
			.text("As Met");

		legend.append('circle')
			.attr("r", 6)
			.attr("cx", 85)
			.attr("cy", 10)
			.style("fill", "white")
			.style("stroke", "grey")
			.style("stroke-width", 1);

		legend.append("text")
			.attr("x", 95)
			.attr("y", 10)
			.attr("dy", ".35em")
			.classed("legend-label", true)
			.text("As Not Met");
	}


	function barPrep() {
		var elem = d3.select(this);
		elem.on('mouseover', function (d) {
			d3.selectAll(".hoverPanel").remove();
			hoverPanel(d, svg, 14);
		});
	}

	// function that updates the chart when a button is clicked (invoked right above!)
	function updateChart(dataset) {

		// updating the y-scale domain
		yScale.domain(d3.extent(dataset, function (d) {
			return d.PCT_BRIX;
		})).nice();

		//update the X scale domain.
		xScale.domain(d3.set(dataset.map(function (d) {
			return d.ID;
		})).values());

		var cSize = Math.min((xScale.bandwidth() / 2), 15);

		// selecting all the dots and binding the new dataset
		var dotUpdate = scatG.selectAll(".dotGroup")
			.data(dataset, function (d) {
				return d.ID
			});

		// updating the positions of the existing dots
		dotUpdate.transition()
			.attr("transform", function (d) {
				var x = xScale(d.ID);
				var y = yScale(-d.PCT_BRIX);
				return "translate(" + x + ", " + y + ")";
			});

		// appending the new dots
		dotUpdate.enter().append("g")
			.attr("class", "dotGroup")
			.attr("transform", function (d) {
				var x = xScale(d.ID);
				var y = yScale(-d.PCT_BRIX);
				return "translate(" + x + ", " + y + ")";
			})
			.append("circle")
			.attr("class", "dots")
			.attr("r", cSize)
			.call(dotPrep);

		dotUpdate.selectAll(".dots").transition().attr("r", cSize);

		// handles old dots that no longer have data bound to it
		dotUpdate.exit().transition().style('opacity', 0).remove();

		// updates the path, our curvy line
		/*        svg.select(".incomeLine")
		            .datum(dataset)
		            .transition()
		            .duration(2200)
		            .attr("d", incomeLine)*/

		// updates the x,y axis since its different for each country
		svg.select(".y")
			.transition()
			.call(customYaxis);

		svg.select(".x")
			.transition()
			.call(customXaxis);

	}

	//headline et all
	if (stageLr.select("#headline").empty()) {
		var headline = stageLr.append("text")
			.attr("id", "headline")
			.attr("x", 50)
			.classed("gfx-head", true)
			.attr("y", function () {
				var textNode = d3.select(this);
				var textHeight = textNode.node().getBBox().height;
				return textHeight + 50;
			})
			.call(textEdit, "headline");

		stageLr.append('rect')
			.attr("height", svgHeight)
			.attr("width", svgWidth)
			.classed("box1", true);

	}

	stageLr.select("#headline").text(function (d) {
		var recCount = fData.length;
		//console.log(recCount);
		//return recCount + " total in the list";
		return recCount + " RCG STUDIES";
	});

}