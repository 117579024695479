//screatFilter
import * as d3 from "d3";

export function rangeFilterData(data, slideInfo) {
    //console.log(data.length);

    var column = slideInfo.col;
    var range = slideInfo.range;
    //make a copy of the data
    var fData = data;

    fData = fData.filter(function(d) {
        return d[column] >= range[0] && d[column] <= range[1];
    });


    //console.log(fData.length);
    return fData;
}