import * as d3 from "d3";
import { uniques } from "./ddd";
import { uniqueValueMap } from "./ddd";
import { cokeGeos } from "../ddd/coke/cokeGeos.js";
import bootstrap from "bootstrap";
const geos = require("../ddd/mapdata/geos.json");

//Icons
// import { library, icon } from "@fortawesome/fontawesome-svg-core";
// import {
// 	faCaretDown,
// 	faRandom,
// 	faThLarge
// } from "@fortawesome/pro-solid-svg-icons";
// //import { faThLargeLight } from "@fortawesome/pro-light-svg-icons";

// library.add(faCaretDown);
// library.add(faRandom);
// library.add(faThLarge);
// //library.add(faThLargeLight);



//make tooltips work?
//console.log(Tooltip);

$("body").tooltip({
	selector: '[data-toggle="tooltip"]'
});

// This builds the filters as buttons and not radios, with a special way to hande it if we detect is is afilter containing country names.
//creatFiltersButtons.js takes
//data : the results of a d3.dsv s,
//columns : An array of filter columns, as strings. These columns must be present in the data. A flaw.
//divID : a div to build buttons into.
//funRun: A function to run when these buttons are clicked.
//When building the buttons it also create the
//filterBundle - An object that contains and object for each filtered column.
//Each column object has a map inside with 1 key for each unique value present in that columns
//and as a value it is a boolean set to false.
//The boolean represents to whether or not that filters is on.
//The module returns the filterBundle to the main script initially - so all filters begin off.
//The modue also send a revised filterBundle each time a button is clicked.
export function createFilters(data, columns, divID, funRun) {
	//a d3 selection of the div holding the filters/ create it if its not there

	var allFilters;

	if (d3.select("#filters").empty()) {
		allFilters = d3
			.select(divID)
			.append("div")
			.attr("id", "filters")
			.classed("filter-holder col-md-12", true);
	} else {
		allFilters = d3.select("#filters");
		allFilters.selectAll("*").remove();
	}
	//add a label
	allFilters
		.append("div")
		.html("Data filters ")
		.attr("id", "filterBtn")
		//.attr("type", "button")
		.attr("data-toggle", "collapse")
		.attr("data-target", "#filter-collapse")
		.attr("aria-expanded", false)
		.attr("aria-controls", "filter-collapse")
		.classed("subhead", true)
		.style("cursor", "pointer")
		.append("i")
		.classed("fas fa-caret-down", true);

	//Now add a bootstrap row to hold the filters.
	var filterRow = allFilters
		.append("div")
		.classed("row collapse", true)
		.attr("id", "filter-collapse");

	//copy the data. Why?
	var fData = data;
	
	//This map will contain a key for every column name that is listed as filterable in the mainjsx.
	//the values are another map, that has a key for unique value in that column, with a boolean as value
	//false = filter off - records with that value, true = filter on
	const filterBundle = new Map();
	

	columns.forEach(function (d) {
		var col = d;
		//variable to hold the ID of the div holding the buttons.
		var filterButtonDiv = "#filter-" + col;
		//var colVals = uniques(data, d);
		var colVals = uniqueValueMap(data, col);

		//console.log(colVals);

		//now add that to the filter bundle.
		filterBundle.set(col, colVals);

		//CREATE THE DIV TOO HOLD Buttons for each filter column.
		//its a 3 column wide div in bootstrap.
		var buttons = filterRow.append("div").classed("filter-holder", true);

		//This is some funky stuff for particular filters that  might want to take up  differnt space.
		//This should disapear with new control panel.
		if (col == "ISO3" || col == "BRAND") {
			buttons.classed("col-md-12", true);
		} else {
			buttons.classed("col-md-12", true);
		}

		//add a div first for the label of each.
		var columnLabels = buttons
			.append("div")
			.classed("gen-txt bottom-rule", true)
			.attr("for", function (d) {
				return filterButtonDiv;
			})
			.html(function () {
				var label;
				if (d == "ASMET") {
					label = "AS MET";
				} else {
					label = d;
				}
				return label;
			});

		//Do it with bootstrap toggle.
		var filterBtns = buttons
			.selectAll(".filter-btns")
			.data(colVals.keys())
			.enter()
			.append("button")
			.classed("btn btn-outline-light btn-sm active filter-btns", true)
			.attr("type", "button")
			.attr("data-toggle", "button")
			.attr("autocomplete", "off")
			.property("aria-pressed", true)
			.html(function (d) {
				var label;
				if (col == "ISO3") {
					//console.log(col);
					cokeGeos.forEach(function (val) {
						if (d == val.ISO3) {
							//console.log(val.UN_NAME);
							label = val.COKE_SPELLING;
						}
					});
				} else {
					label = d;
				}
				return label;
			})
			.on("click", function (d) {
				//flip the boolean attribute on click
				colVals[d] = !colVals[d];
				//now add the new set of booleans for this filter to the filterbundle
				filterBundle.set(col, colVals);
				funRun(filterBundle);
			});

		var selectAll = columnLabels
			.append("button")
			.classed("icon-holder btn btn-sm btn-outline-light", true)
			.attr("data-toggle", "tooltip")
			.attr("data-placement", "top")
			.attr("title", "Select All")
			.on("click", function () {
				console.log(colVals);
				buttons
					.selectAll(".filter-btns")
					.classed("active", true)
					.property("aria-pressed", true)
					.property("checked", true);

				for (var key of Object.keys(colVals)) {
					//console.log(colVals[key]);
					colVals[key] = false;
					//console.log(colVals[key]);
				}

				funRun(filterBundle);
			})
			.append("i")
			.style("color", "grey")
			.classed("fas fa-th-large", true);

		var selectNone = columnLabels
			.append("button")
			.classed("icon-holder btn btn-sm btn-outline-light", true)
			.attr("data-toggle", "tooltip")
			.attr("data-placement", "top")
			.attr("title", "Clear Selection")
			.on("click", function () {
				console.log(colVals);
				buttons
					.selectAll(".filter-btns")
					.classed("active", false)
					.property("aria-pressed", false)
					.property("checked", false);

				for (var key of Object.keys(colVals)) {
					//console.log(colVals[key]);
					colVals[key] = true;
					//console.log(colVals[key]);
				}

				funRun(filterBundle);
			})
			.append("i")
			.style("color", "grey")
			.classed("fal fa-th-large", true);

		var invert = columnLabels
			.append("button")
			.classed("icon-holder btn btn-sm btn-outline-light", true)
			.attr("data-toggle", "tooltip")
			.attr("data-placement", "top")
			.attr("title", "Invert selection")
			.on("click", function () {
				console.log(colVals);
				buttons.selectAll(".filter-btns").each(function (d) {
					//console.log(this);
					$(this).button("toggle");
				});

				for (var key of Object.keys(colVals)) {
					//console.log(colVals[key]);
					colVals[key] = !colVals[key];
					//console.log(colVals[key]);
				}

				funRun(filterBundle);
			})
			.append("i")
			.style("color", "grey")
			.classed("fas fa-random", true);

		if (Array.isArray(data[0][d])) {
			//////this is an array type of filter, so we need some filter options

			var array_filter_selector = columnLabels
				.append("div")
				.attr("data-toggle", "buttons")
				.attr("id", "array_filter_" + d)
				.attr("class", "filter-holder btn-group btn-group-toggle")
				.style("width", "100%");

			//Create the filter buttons themselves.
			array_filter_selector
				.append("label")
				.attr("class", "btn btn-secondary btn-sm array_filter_selector")
				.datum("ONLY")
				.attr("data-toggle", "tooltip")
				.attr("data-placement", "top")
				.attr(
					"title",
					"Filter to show items that include the filters shown below and none that are not shown"
				)
				.text("Items that include ONLY these filters");

			array_filter_selector
				.append("label")
				.attr("class", "btn btn-light btn-sm array_filter_selector")
				.datum("ANY")
				.attr("data-toggle", "tooltip")
				.attr("data-placement", "top")
				.attr(
					"title",
					"Filter to show items that include at least one of the filters shown below"
				)
				.text("Items that include ANY of these filters");

			array_filter_selector
				.append("label")
				.attr("class", "btn btn-light btn-sm array_filter_selector")
				.datum("ALL")
				.attr("data-toggle", "tooltip")
				.attr("data-placement", "top")
				.attr(
					"title",
					"Filter to show items that include all of the filters shown below"
				)
				.text("Items that include ALL these filters");

			array_filter_selector
				.selectAll(".array_filter_selector")
				.on("click", function (d) {
					array_filter_selector
						.selectAll(".array_filter_selector")
						.attr("class", "btn btn-light btn-sm array_filter_selector");
					d3.select(this).attr(
						"class",
						"btn btn-secondary btn-sm array_filter_selector"
					);

					funRun(filterBundle);

					//console.log(d);

					//funRun(d);
					//changeColors(d);
					//changeBubColors(d);
				});
		}

		//Add a check uncheclk all button
		/*var checkAll = buttons.insert("div", ":nth-child(2)")
		        .classed("form-check", true)
		        .append("label")
		        .classed("form-check-label", true)
		        .html("Check/Uncheck All")
		        .append("input")
		        .classed("form-check-input", true)
		        .attr("type", "checkbox")
		        .property('checked', true)
		        .on("click", function() {
		            console.log(col);
		            var checkClass = "check-" + col;
		            //find the checkbox
		            var theCheck = d3.select(this);

		            //and check that its options are toggling properly.
		            console.log(theCheck.property("checked"));

		            //get the list of filters for this column from the filter bundle
		            var theseFilters = filterBundle.get(col);

		            var allChecks = buttons.selectAll("input");
		            //select all the checks based on the

		            //This is changing the

		            if (theCheck.property("checked")) {
		                //the button has just been pressed, and its checked meaning
		                //all items should be sleceted
		                allChecks.property("checked", true);
		                //change the valuse in the data set
		                for (var key of Object.keys(theseFilters)) {
		                    theseFilters[key] = false;
		                }

		            } else {
		                allChecks.each(function(d) {
		                    //console.log(this);
		                    //console.log(theCheck.node());
		                    if (theCheck.node() !== this) {
		                        //console.log("yo");
		                    }

		                })

		                allChecks.property("checked", false)

		                //.property("checked", false);
		                for (var key of Object.keys(theseFilters)) {
		                    theseFilters[key] = true;
		                }

		            }
		            //console.log(filterBundle);
		            funRun(filterBundle);
		        });*/
	});

	return filterBundle;
}