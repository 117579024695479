import * as d3 from "d3";
import * as d3ScaleChromatic from 'd3-scale-chromatic';;
import { vbSize } from "./ddd";
import { textEdit } from "./ddd";
import { parseTransform } from "./ddd";
import { toolTip2 } from "./ddd";
import { hoverPanel } from "./ddd";



export function bubStat(svg, sData, fData, sort, filters) {
	//console.log("BubStat");
	//console.log(filters);
	var zoom = d3.zoom();
	var vizLr = svg.select("#l-viz");
	var scatterLayer = svg.select("#scatterGroup");
	scatterLayer.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	//Layers
	var gridLr = svg.select("#l-grid");
	gridLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var mapLr = svg.select("#l-map");
	mapLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var dataLr = svg.select("#l-data");
	var copyLr = svg.select("#l-copy");
	copyLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var stageLr = svg.select("#l-stage");
	var thekey = svg.select("#l-key");
	thekey.transition().duration(1000).style("opacity", 1);

	//Sizes
	var svgSize = vbSize(svg);
	var svgHeight = svgSize[0];
	var svgWidth = svgSize[1];
	var gcoords = parseTransform(vizLr.attr("transform")); //Grab the corrdinates of thedata layer which has a margin from top and left
	var gWidth = svgWidth - (gcoords[0] * 2); //50 the sides.
	var gHeight = svgHeight - (gcoords[1] + gcoords[0]); //100 - the top

	var bubSize = Math.min(12, (gWidth / fData.length) * 2);

	//Transitions
	var tIntro = d3.transition()
		.duration(2000)
		.ease(d3.easePolyInOut);


	update(fData);
	//General Update Pattern
	function update(data) {
		var dump = gridLr.selectAll("*").remove();

		//I create and empty selection, and use the key function with an ID string
		//in my dsv data.
		var bubs = dataLr.selectAll(".data-bub")
			.data(data, function (d, i) {
				return d.id || (d.id = ++i);
			});

		//remove any that dont need to be there.
		//The first time around there wont be any nodes in the exit, but no matter.
		bubs.exit()
			.transition(tIntro)
			.style("opacity", 0)
			.each(function (d) {
				//Log out the removed elements.
				//console.log(d.id);
			})
			.remove();

		var enter = bubs.enter() //add any new ones - a group and a circle within it.
			.append("g")
			.classed("data-bub", true)
			.attr("transform", function (d, i) {
				///console.log(i);
				var y = gHeight / 2;
				var x = gWidth / 2;
				return "translate(" + x + "," + y + ")";
			})
			.on("click", function (d) {
				console.log(d);
			})
			.on('mouseover', function (d) {
				d3.selectAll(".hoverPanel").remove();
				hoverPanel(d, svg, 14);
			})
			.each(function (d) {
				var elem = d3.select(this);
				var txt = d.id;
				toolTip2(elem, d, 16, "NAME");
			});

		enter.append("circle")
			.attr("r", bubSize)
			.classed("bub", true);


		//Now I should be able to run this transition that
		//moves each group to a random location on my svg.
		//But nothing happens. Why is the update selection empty after merge?
		bubs = enter.merge(bubs);

		bubs.transition(tIntro)
			.attr("transform", function (d, i) {
				///console.log(i);
				var y = Math.random() * gHeight;
				var x = Math.random() * gWidth;
				return "translate(" + x + "," + y + ")";
			});

		var bubBub = bubs.selectAll(".bub");

		bubBub.transition()
			.attr("r", bubSize);

	}

	if (stageLr.select("#headline").empty()) {
		var headline = stageLr.append("text")
			.attr("id", "headline")
			.attr("x", 50)
			.classed("gfx-head", true)
			.attr("y", function () {
				var textNode = d3.select(this);
				var textHeight = textNode.node().getBBox().height;
				return textHeight + 50;
			})
			.call(textEdit, "headline");


		stageLr.append('rect')
			.attr("height", svgHeight)
			.attr("width", svgWidth)
			.classed("box1", true);

	}

	stageLr.select("#headline").text(function (d) {
		var recCount = fData.length;
		//console.log(recCount);
		//return recCount + " total in the list";
		return recCount + " Projects";
	});







}