import * as d3 from "d3";
import { vbSize } from "./ddd";
import { textPog } from "./ddd";
import { toolTip } from "./ddd";
import { wrap } from "./ddd";


//OrgChart
export function orgMap(svg, sData, fData, sort, filters, bubs) {
    //DATA PREPARATION
    console.log("orgMap");

    //console.log(bubs);

    var margin = { top: 150, right: 50, bottom: 50, left: 50 };
    //SETUP USING CUSTOM VIEWBOX SNIFFER
    var svgSize = vbSize(svg);
    var svgHeight = svgSize[0];
    var svgWidth = svgSize[1];
    //upperleft point of the scatter
    var xPoint = svgWidth - margin.left;
    var yPoint = svgHeight - margin.top;
    //Set the height/width of the actual chart
    var width = svgWidth - margin.left - margin.right - 30,
        height = svgHeight - margin.top - margin.bottom;

    //Step 2
    /*    var orgData = d3.hierarchy(sData, function(d) {
                return d.children;
            })
            .count();*/

    var tree = d3.tree()
        .size([width, height]);


    //console.log(orgData);

    update(sData);


    function update(root) {
        console.log("updating");

        var orgData = tree(root);

        var dataLayer = svg.select("#l-data");

        // Collapse the node and all it's children
        function collapse(d) {
            if (d.children) {
                d._children = d.children
                d._children.forEach(collapse)
                d.children = null
            }
        }
        //now draw
        var link = dataLayer.selectAll(".link")
            .data(orgData.descendants().slice(1))
            .enter().append("path")
            .attr("class", "link")
            .attr("d", function(d) {
                return diagonal(d);
            });
        // adds each node as a group
        var node = dataLayer.selectAll(".node")
            .data(orgData.descendants())
            .enter().append("g")
            .attr("class", function(d) {
                return "node" +
                    (d.children ? " node--internal" : " node--leaf");
            })
            .attr("transform", function(d) {
                return "translate(" + d.x + "," + d.y + ")";
            });

        // adds the circle to the node
        node.append("circle")
            .attr("r", function(d) { return d.children ? 50 : 5; })
            .each(function(d){
                var elem = d3.select(this);
                //var fmt = d3.format(formats.wholeFmt);
                var txt = d.data.NAME;
                toolTip(elem, txt, 16);

            });

        // adds the text to the node
        var labels = node.append("text")
            .attr("dy", ".35em")
            .classed("tiny-txt", true)
            .attr("y", function(d) { return d.children ? 0 : 0; })
            //.attr("x", "50px")
            .style("text-anchor", "middle")
            .text(function(d) {
                //console.log(d);
                return d.children ? d.data.NAME : "";
                //return d.data.NAME;
            })
            .call(wrap, 100);



    }


}


function diagonal(d) {
    return "M" + d.x + "," + d.y +
        "C" + d.x + "," + (d.y + d.parent.y) / 2 +
        " " + d.parent.x + "," + (d.y + d.parent.y) / 2 +
        " " + d.parent.x + "," + d.parent.y;
}