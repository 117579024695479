//Prep an SVG to use with the bubsystem
//accepts a height, width, margion object,
import * as d3 from 'd3';

//for this particular.
export function prepSVG(h, w, targetID, parentID) {
    // console.log(arguments);
	// console.log("prep");
	var margin = { top: 50, right: 50, bottom: 50, left: 50 };
	var zeroCenterViewbox = [-w/2, -h/2];
	//console.log(zeroCenterViewbox);
	var svg, vizLr, tooltipLr, gridLr, mapLr, dataLr, copyLr, keyLr, stageLr;

	if (d3.select(targetID).empty()) {
		//console.log("fresh canvas");
		svg = d3
			.select("#" + parentID)
			.append("svg")
			.attr("id", targetID)
			.classed("stage-svg", true)
			.style("display", "block")
			//.attr("viewBox", zeroCenterViewbox[0] + " " + zeroCenterViewbox[1] + " " + w + " " + h)
			.attr("viewBox",  "0 0 " + w + " " + h)
			.attr("preserveAspectRatio", "xMidYMin meet")
			.attr("name", "viz");

		//add a viz layer to hold all viz related layers.
		vizLr = svg.append("g")
			.attr("id", "l-viz")
			.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
		
		

		//Add a grid layer for lines, scales and the like and translate according to the margin.
		gridLr = vizLr.append("g")
			.attr("id", "l-grid")
			.attr("transform", "translate(" + 0 + "," + 0 + ")");;
		//.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
		//map lr - with margin
		//Add a grid layer for lines, scales and the like and translate according to the margin.
		mapLr = svg.append("g")
			.attr("id", "l-map")
			.attr("transform", "translate(" + 0 + "," + 0 + ")");
		//.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
		//Add a data layer to the stage and translate according to the margin.
		
		
		
		dataLr = svg.append("g")
			.attr("id", "l-data")
			.attr("transform", "translate(" + 0 + "," + 0 + ")");
		//Add a copy layer above it to the stage and leave full bleed!!
		//CHANGE IN OTHER PTOJECTS.
		copyLr = svg.append("g")
			.attr("id", "l-copy");
		//add a stage group - where anything that needs to full bleed can go.
		keyLr = svg.append("g")
			.attr("id", "l-key");
		//tooltip layer
		tooltipLr = svg.append("g")
		.attr("id", "l-tooltip")
		.attr("transform", "translate(" + 0 + "," + 0 + ")");
		//add a stage group - where anything that needs to full bleed can go.
		stageLr = svg.append("g")
			.attr("id", "l-stage");

	} else {
		svg = d3.select(targetID);
	}

    //console.log(svg);

	return svg;
	//modeNow(svg, sData, fData, sort, filters);

}