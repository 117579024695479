export var cokeGeos = [{ "COKE_SPELLING": "Afganistan", "ISO3": "AFG", "UN_NAME": "Afghanistan", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Angola", "ISO3": "AGO", "UN_NAME": "Angola", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Albania", "ISO3": "ALB", "UN_NAME": "Albania", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "United Arab Emirates", "ISO3": "ARE", "UN_NAME": "United Arab Emirates", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Armenia", "ISO3": "ARM", "UN_NAME": "Armenia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Turkey, Caucasus & Central Asia" },
    { "COKE_SPELLING": "Antigua ", "ISO3": "ATG", "UN_NAME": "Antigua and Barbuda", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Australia", "ISO3": "AUS", "UN_NAME": "Australia", "REGION": "ASIA PACIFIC", "BU": "South Pacific" },
    { "COKE_SPELLING": "Austria", "ISO3": "AUT", "UN_NAME": "Austria", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Azerbaijan", "ISO3": "AZE", "UN_NAME": "Azerbaijan", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Turkey, Caucasus & Central Asia" },
    { "COKE_SPELLING": "Burundi", "ISO3": "BDI", "UN_NAME": "Burundi", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Belgium", "ISO3": "BEL", "UN_NAME": "Belgium", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Western Europe " },
    { "COKE_SPELLING": "Benin", "ISO3": "BEN", "UN_NAME": "Benin", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Burkina Faso", "ISO3": "BFA", "UN_NAME": "Burkina Faso", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Bangladesh", "ISO3": "BGD", "UN_NAME": "Bangladesh", "REGION": "ASIA PACIFIC", "BU": "India & South West Asia" },
    { "COKE_SPELLING": "Bulgaria", "ISO3": "BGR", "UN_NAME": "Bulgaria", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Bahrain", "ISO3": "BHR", "UN_NAME": "Bahrain", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Belarus", "ISO3": "BLR", "UN_NAME": "Belarus", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Brazil", "ISO3": "BRA", "UN_NAME": "Brazil", "REGION": "LATIN AMERICA  ", "BU": "Brazil " },
    { "COKE_SPELLING": "Brunei", "ISO3": "BRN", "UN_NAME": "Brunei Darussalam", "REGION": "ASIA PACIFIC", "BU": "Association of Southeast Asian Nations" },
    { "COKE_SPELLING": "Bhutan", "ISO3": "BTN", "UN_NAME": "Bhutan", "REGION": "ASIA PACIFIC", "BU": "India & South West Asia" },
    { "COKE_SPELLING": "Botswana", "ISO3": "BWA", "UN_NAME": "Botswana", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Central Africa Republic", "ISO3": "CAF", "UN_NAME": "Central African Republic", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Canada", "ISO3": "CAN", "UN_NAME": "Canada", "REGION": "NORTH AMERICA  ", "BU": "Canada" },
    { "COKE_SPELLING": "Switzerland", "ISO3": "CHE", "UN_NAME": "Switzerland", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "China", "ISO3": "CHN", "UN_NAME": "China", "REGION": "ASIA PACIFIC", "BU": "Greater China & Korea" },
    { "COKE_SPELLING": "Cote D'Ivoire", "ISO3": "CIV", "UN_NAME": "Cote D'Ivoire", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Cameroon", "ISO3": "CMR", "UN_NAME": "Cameroon", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "D.R.C.", "ISO3": "COD", "UN_NAME": "Democratic Republic of the Congo", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Comoros", "ISO3": "COM", "UN_NAME": "Comoros", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Cape Verde", "ISO3": "CPV", "UN_NAME": "Cape Verde", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Cyprus", "ISO3": "CYP", "UN_NAME": "Cyprus", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Czech Republic", "ISO3": "CZE", "UN_NAME": "Czech Republic", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Germany", "ISO3": "DEU", "UN_NAME": "Germany", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Western Europe " },
    { "COKE_SPELLING": "Djibouti", "ISO3": "DJI", "UN_NAME": "Djibouti", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Denmark", "ISO3": "DNK", "UN_NAME": "Denmark", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Western Europe " },
    { "COKE_SPELLING": "Algeria", "ISO3": "DZA", "UN_NAME": "Algeria", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Ecuador", "ISO3": "ECU", "UN_NAME": "Ecuador", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Egypt", "ISO3": "EGY", "UN_NAME": "Egypt", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Eritrea", "ISO3": "ERI", "UN_NAME": "Eritrea", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Spain", "ISO3": "ESP", "UN_NAME": "Spain", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Western Europe " },
    { "COKE_SPELLING": "Estonia", "ISO3": "EST", "UN_NAME": "Estonia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Ethiopia", "ISO3": "ETH", "UN_NAME": "Ethiopia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Finland", "ISO3": "FIN", "UN_NAME": "Finland", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Western Europe " },
    { "COKE_SPELLING": "Fiji", "ISO3": "FJI", "UN_NAME": "Fiji", "REGION": "ASIA PACIFIC", "BU": "South Pacific" },
    { "COKE_SPELLING": "France", "ISO3": "FRA", "UN_NAME": "France", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Western Europe " },
    { "COKE_SPELLING": "Gabon", "ISO3": "GAB", "UN_NAME": "Gabon", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Georgia", "ISO3": "GEO", "UN_NAME": "Georgia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Turkey, Caucasus & Central Asia" },
    { "COKE_SPELLING": "Ghana", "ISO3": "GHA", "UN_NAME": "Ghana", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Guinea", "ISO3": "GIN", "UN_NAME": "Guinea", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Gambia", "ISO3": "GMB", "UN_NAME": "Gambia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Guinea Bissau", "ISO3": "GNB", "UN_NAME": "Guinea-Bissau", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Equatorial Guinea", "ISO3": "GNQ", "UN_NAME": "Equatorial Guinea", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Greece", "ISO3": "GRC", "UN_NAME": "Greece", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Greenland", "ISO3": "GRL", "UN_NAME": "Greenland", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Western Europe " },
    { "COKE_SPELLING": "Hong Kong", "ISO3": "HKG", "UN_NAME": "Hong Kong", "REGION": "ASIA PACIFIC", "BU": "Greater China & Korea" },
    { "COKE_SPELLING": "Croatia", "ISO3": "HRV", "UN_NAME": "Croatia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Hungary", "ISO3": "HUN", "UN_NAME": "Hungary", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Indonesia", "ISO3": "IDN", "UN_NAME": "Indonesia", "REGION": "ASIA PACIFIC", "BU": "Association of Southeast Asian Nations" },
    { "COKE_SPELLING": "India", "ISO3": "IND", "UN_NAME": "India", "REGION": "ASIA PACIFIC", "BU": "India & South West Asia" },
    { "COKE_SPELLING": "Ireland", "ISO3": "IRL", "UN_NAME": "Ireland", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Western Europe " },
    { "COKE_SPELLING": "Iran", "ISO3": "IRN", "UN_NAME": "Iran (Islamic Republic of)", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Iraq", "ISO3": "IRQ", "UN_NAME": "Iraq", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Iceland", "ISO3": "ISL", "UN_NAME": "Iceland", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Western Europe " },
    { "COKE_SPELLING": "Israel", "ISO3": "ISR", "UN_NAME": "Israel", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Italy", "ISO3": "ITA", "UN_NAME": "Italy", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Jordan", "ISO3": "JOR", "UN_NAME": "Jordan", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Japan", "ISO3": "JPN", "UN_NAME": "Japan", "REGION": "ASIA PACIFIC", "BU": "Japan" },
    { "COKE_SPELLING": "Kazakhstan", "ISO3": "KAZ", "UN_NAME": "Kazakhstan", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Turkey, Caucasus & Central Asia" },
    { "COKE_SPELLING": "Kenya", "ISO3": "KEN", "UN_NAME": "Kenya", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Kyrgyzstan", "ISO3": "KGZ", "UN_NAME": "Kyrgyzstan", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Turkey, Caucasus & Central Asia" },
    { "COKE_SPELLING": "Cambodia", "ISO3": "KHM", "UN_NAME": "Cambodia", "REGION": "ASIA PACIFIC", "BU": "Association of Southeast Asian Nations" },
    { "COKE_SPELLING": "South Korea", "ISO3": "KOR", "UN_NAME": "Republic of Korea", "REGION": "ASIA PACIFIC", "BU": "Greater China & Korea" },
    { "COKE_SPELLING": "Kuwait", "ISO3": "KWT", "UN_NAME": "Kuwait", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Laos", "ISO3": "LAO", "UN_NAME": "Lao People's Democratic Republic", "REGION": "ASIA PACIFIC", "BU": "Association of Southeast Asian Nations" },
    { "COKE_SPELLING": "Lebanon", "ISO3": "LBN", "UN_NAME": "Lebanon", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Liberia", "ISO3": "LBR", "UN_NAME": "Liberia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Libya", "ISO3": "LBY", "UN_NAME": "Libya", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Sri Lanka", "ISO3": "LKA", "UN_NAME": "Sri Lanka", "REGION": "ASIA PACIFIC", "BU": "India & South West Asia" },
    { "COKE_SPELLING": "Lesotho", "ISO3": "LSO", "UN_NAME": "Lesotho", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Lithuania", "ISO3": "LTU", "UN_NAME": "Lithuania", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Latvia", "ISO3": "LVA", "UN_NAME": "Latvia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Macao", "ISO3": "MAC", "UN_NAME": "Macau", "REGION": "ASIA PACIFIC", "BU": "Greater China & Korea" },
    { "COKE_SPELLING": "Morocco", "ISO3": "MAR", "UN_NAME": "Morocco", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Madagascar", "ISO3": "MDG", "UN_NAME": "Madagascar", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Maldives", "ISO3": "MDV", "UN_NAME": "Maldives", "REGION": "ASIA PACIFIC", "BU": "India & South West Asia" },
    { "COKE_SPELLING": "Mexico", "ISO3": "MEX", "UN_NAME": "Mexico", "REGION": "LATIN AMERICA  ", "BU": "Mexico " },
    { "COKE_SPELLING": "Mali", "ISO3": "MLI", "UN_NAME": "Mali", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Malta", "ISO3": "MLT", "UN_NAME": "Malta", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Myanmar", "ISO3": "MMR", "UN_NAME": "Myanmar", "REGION": "ASIA PACIFIC", "BU": "Association of Southeast Asian Nations" },
    { "COKE_SPELLING": "Montenegro", "ISO3": "MNE", "UN_NAME": "Montenegro", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Mongolia", "ISO3": "MNG", "UN_NAME": "Mongolia", "REGION": "ASIA PACIFIC", "BU": "Greater China & Korea" },
    { "COKE_SPELLING": "Mozambique", "ISO3": "MOZ", "UN_NAME": "Mozambique", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Mauritania", "ISO3": "MRT", "UN_NAME": "Mauritania", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Mauritius", "ISO3": "MUS", "UN_NAME": "Mauritius", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Malawi", "ISO3": "MWI", "UN_NAME": "Malawi", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Malaysia", "ISO3": "MYS", "UN_NAME": "Malaysia", "REGION": "ASIA PACIFIC", "BU": "Association of Southeast Asian Nations" },
    { "COKE_SPELLING": "Mayotte", "ISO3": "MYT", "UN_NAME": "Mayotte", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Namibia", "ISO3": "NAM", "UN_NAME": "Namibia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "New Caledonia", "ISO3": "NCL", "UN_NAME": "New Caledonia", "REGION": "ASIA PACIFIC", "BU": "South Pacific" },
    { "COKE_SPELLING": "Niger", "ISO3": "NER", "UN_NAME": "Niger", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Nigeria", "ISO3": "NGA", "UN_NAME": "Nigeria", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Netherlands", "ISO3": "NLD", "UN_NAME": "Netherlands", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Western Europe " },
    { "COKE_SPELLING": "Norway", "ISO3": "NOR", "UN_NAME": "Norway", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Western Europe " },
    { "COKE_SPELLING": "Nepal", "ISO3": "NPL", "UN_NAME": "Nepal", "REGION": "ASIA PACIFIC", "BU": "India & South West Asia" },
    { "COKE_SPELLING": "Nauru", "ISO3": "NRU", "UN_NAME": "Nauru", "REGION": "ASIA PACIFIC", "BU": "South Pacific" },
    { "COKE_SPELLING": "New Zealand", "ISO3": "NZL", "UN_NAME": "New Zealand", "REGION": "ASIA PACIFIC", "BU": "South Pacific" },
    { "COKE_SPELLING": "Oman", "ISO3": "OMN", "UN_NAME": "Oman", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Pakistan", "ISO3": "PAK", "UN_NAME": "Pakistan", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Philippines", "ISO3": "PHL", "UN_NAME": "Philippines", "REGION": "ASIA PACIFIC", "BU": "Association of Southeast Asian Nations" },
    { "COKE_SPELLING": "Papua New Guinea", "ISO3": "PNG", "UN_NAME": "Papua New Guinea", "REGION": "ASIA PACIFIC", "BU": "Association of Southeast Asian Nations" },
    { "COKE_SPELLING": "Poland", "ISO3": "POL", "UN_NAME": "Poland", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Portugal", "ISO3": "PRT", "UN_NAME": "Portugal", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Western Europe " },
    { "COKE_SPELLING": "French Polynesia", "ISO3": "PYF", "UN_NAME": "French Polynesia", "REGION": "ASIA PACIFIC", "BU": "South Pacific" },
    { "COKE_SPELLING": "Qatar", "ISO3": "QAT", "UN_NAME": "Qatar", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Reunion", "ISO3": "REU", "UN_NAME": "Reunion", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Romania", "ISO3": "ROU", "UN_NAME": "Romania", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Rwanda", "ISO3": "RWA", "UN_NAME": "Rwanda", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Saudi Arabia", "ISO3": "SAU", "UN_NAME": "Saudi Arabia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Sudan", "ISO3": "SDN", "UN_NAME": "Sudan", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Senegal", "ISO3": "SEN", "UN_NAME": "Senegal", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Singapore", "ISO3": "SGP", "UN_NAME": "Singapore", "REGION": "ASIA PACIFIC", "BU": "Association of Southeast Asian Nations" },
    { "COKE_SPELLING": "Solomon Islands", "ISO3": "SLB", "UN_NAME": "Solomon Islands", "REGION": "ASIA PACIFIC", "BU": "Association of Southeast Asian Nations" },
    { "COKE_SPELLING": "Sierra Leone", "ISO3": "SLE", "UN_NAME": "Sierra Leone", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Somalia", "ISO3": "SOM", "UN_NAME": "Somalia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Sao Tome & Principe", "ISO3": "STP", "UN_NAME": "Sao Tome and Principe", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Slovenia", "ISO3": "SVN", "UN_NAME": "Slovenia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Sweden", "ISO3": "SWE", "UN_NAME": "Sweden", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Western Europe " },
    { "COKE_SPELLING": "Swaziland", "ISO3": "SWZ", "UN_NAME": "Swaziland", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Seychelles", "ISO3": "SYC", "UN_NAME": "Seychelles", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Chad", "ISO3": "TCD", "UN_NAME": "Chad", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Togo ", "ISO3": "TGO", "UN_NAME": "Togo", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "West Africa" },
    { "COKE_SPELLING": "Thailand", "ISO3": "THA", "UN_NAME": "Thailand", "REGION": "ASIA PACIFIC", "BU": "Association of Southeast Asian Nations" },
    { "COKE_SPELLING": "Tajikistan", "ISO3": "TJK", "UN_NAME": "Tajikistan", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Turkey, Caucasus & Central Asia" },
    { "COKE_SPELLING": "Turkmenistan", "ISO3": "TKM", "UN_NAME": "Turkmenistan", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Turkey, Caucasus & Central Asia" },
    { "COKE_SPELLING": "Tonga", "ISO3": "TON", "UN_NAME": "Tonga", "REGION": "ASIA PACIFIC", "BU": "South Pacific" },
    { "COKE_SPELLING": "Turkey", "ISO3": "TUR", "UN_NAME": "Turkey", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Turkey, Caucasus & Central Asia" },
    { "COKE_SPELLING": "Taiwan", "ISO3": "TWN", "UN_NAME": "Taiwan", "REGION": "ASIA PACIFIC", "BU": "Greater China & Korea" },
    { "COKE_SPELLING": "Ukraine", "ISO3": "UKR", "UN_NAME": "Ukraine", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Uruguay", "ISO3": "URY", "UN_NAME": "Uruguay", "REGION": "LATIN AMERICA  ", "BU": "South Latin " },
    { "COKE_SPELLING": "Uzbekistan", "ISO3": "UZB", "UN_NAME": "Uzbekistan", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Turkey, Caucasus & Central Asia" },
    { "COKE_SPELLING": "Vietnam", "ISO3": "VNM", "UN_NAME": "Viet Nam", "REGION": "ASIA PACIFIC", "BU": "Association of Southeast Asian Nations" },
    { "COKE_SPELLING": "Vanuatu", "ISO3": "VUT", "UN_NAME": "Vanuatu", "REGION": "ASIA PACIFIC", "BU": "South Pacific" },
    { "COKE_SPELLING": "Samoa", "ISO3": "WSM", "UN_NAME": "Samoa", "REGION": "ASIA PACIFIC", "BU": "South Pacific" },
    { "COKE_SPELLING": "Yemen", "ISO3": "YEM", "UN_NAME": "Yemen", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "South Africa", "ISO3": "ZAF", "UN_NAME": "South Africa", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Zambia", "ISO3": "ZMB", "UN_NAME": "Zambia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Syria", "ISO3": "SYR", "UN_NAME": "Syrian Arab Republic", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Tunisia ", "ISO3": "TUN", "UN_NAME": "Tunisia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "West Bank-Gaza", "ISO3": "OPT", "UN_NAME": "West Bank-Gaza", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Middle East & North Africa" },
    { "COKE_SPELLING": "Saint Helena", "ISO3": "SHN", "UN_NAME": "Saint Helena", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Tanzania", "ISO3": "TZA", "UN_NAME": "United Republic of Tanzania", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Uganda ", "ISO3": "UGA", "UN_NAME": "Uganda", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Zimbabwe ", "ISO3": "ZWE", "UN_NAME": "Zimbabwe", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "South & East Africa" },
    { "COKE_SPELLING": "Bosnia & Herzegov", "ISO3": "BIH", "UN_NAME": "Bosnia and Herzegovina", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Macedonia", "ISO3": "MKD", "UN_NAME": "The former Yugoslav Republic of Macedonia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Moldova", "ISO3": "MDA", "UN_NAME": "Moldova, Republic of", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Republic of Serbia", "ISO3": "SRB", "UN_NAME": "Serbia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Russia", "ISO3": "RUS", "UN_NAME": "Russian Federation", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Slovakia (Slovak Republic)", "ISO3": "SVK", "UN_NAME": "Slovakia", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Central & Eastern Europe" },
    { "COKE_SPELLING": "Luxemburg", "ISO3": "LUX", "UN_NAME": "Luxembourg", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Western Europe " },
    { "COKE_SPELLING": "United Kingdom", "ISO3": "GBR", "UN_NAME": "U.K. of Great Britain and Northern Ireland", "REGION": "EUROPE, MIDDLE EAST & AFRICA", "BU": "Western Europe " },
    { "COKE_SPELLING": "Aruba ", "ISO3": "ABW", "UN_NAME": "Aruba", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Bahamas, The ", "ISO3": "BHS", "UN_NAME": "Bahamas", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Barbados ", "ISO3": "BRB", "UN_NAME": "Barbados", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Belize ", "ISO3": "BLZ", "UN_NAME": "Belize", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Bermuda ", "ISO3": "BMU", "UN_NAME": "Bermuda", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Caicos Islands/Turks", "ISO3": "TCA", "UN_NAME": "Turks and Caicos Islands", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Colombia ", "ISO3": "COL", "UN_NAME": "Colombia", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Costa Rica ", "ISO3": "CRI", "UN_NAME": "Costa Rica", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Curacao ", "ISO3": "CUW", "UN_NAME": "Curacao", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Dominica ", "ISO3": "DMA", "UN_NAME": "Dominica", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Dominican Republic ", "ISO3": "DOM", "UN_NAME": "Dominican Republic", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "El Salvador ", "ISO3": "SLV", "UN_NAME": "El Salvador", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "French Guiana ", "ISO3": "GUF", "UN_NAME": "French Guiana", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Grenada ", "ISO3": "GRD", "UN_NAME": "Grenada", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Guadeloupe ", "ISO3": "GLP", "UN_NAME": "Guadeloupe", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Guatemala ", "ISO3": "GTM", "UN_NAME": "Guatemala", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Guyana ", "ISO3": "GUY", "UN_NAME": "Guyana", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Haiti ", "ISO3": "HTI", "UN_NAME": "Haiti", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Honduras ", "ISO3": "HND", "UN_NAME": "Honduras", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Jamaica ", "ISO3": "JAM", "UN_NAME": "Jamaica", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Martinique ", "ISO3": "MTQ", "UN_NAME": "Martinique", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Montserrat ", "ISO3": "MSR", "UN_NAME": "Montserrat", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Nicaragua ", "ISO3": "NIC", "UN_NAME": "Nicaragua", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Panama ", "ISO3": "PAN", "UN_NAME": "Panama", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "St. Kitts ", "ISO3": "KNA", "UN_NAME": "Saint Kitts and Nevis", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "St. Lucia ", "ISO3": "LCA", "UN_NAME": "Saint Lucia", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "St. Vincent ", "ISO3": "VCT", "UN_NAME": "Saint Vincent and the Grenadines", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Suriname ", "ISO3": "SUR", "UN_NAME": "Suriname", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Trinidad ", "ISO3": "TTO", "UN_NAME": "Trinidad and Tobago", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Venezuela ", "ISO3": "VEN", "UN_NAME": "Venezuela", "REGION": "LATIN AMERICA  ", "BU": "Latin Center" },
    { "COKE_SPELLING": "Argentina ", "ISO3": "ARG", "UN_NAME": "Argentina", "REGION": "LATIN AMERICA  ", "BU": "South Latin " },
    { "COKE_SPELLING": "Bolivia ", "ISO3": "BOL", "UN_NAME": "Bolivia", "REGION": "LATIN AMERICA  ", "BU": "South Latin " },
    { "COKE_SPELLING": "Chile ", "ISO3": "CHL", "UN_NAME": "Chile", "REGION": "LATIN AMERICA  ", "BU": "South Latin " },
    { "COKE_SPELLING": "Paraguay ", "ISO3": "PRY", "UN_NAME": "Paraguay", "REGION": "LATIN AMERICA  ", "BU": "South Latin " },
    { "COKE_SPELLING": "Peru ", "ISO3": "PER", "UN_NAME": "Peru", "REGION": "LATIN AMERICA  ", "BU": "South Latin " },
    { "COKE_SPELLING": "United States", "ISO3": "USA", "UN_NAME": "United States of America", "REGION": "NORTH AMERICA  ", "BU": "United States" }
];
