/* eslint-disable no-unused-vars */
import * as d3 from "d3";
import * as d3ScaleChromatic from 'd3-scale-chromatic';;
import {
	vbSize
} from "../ddd";
import {
	textEdit
} from "../ddd";
import {
	parseTransform
} from "../ddd";
import {
	toolTip2
} from "../ddd";
import {
	hoverPanel
} from "../ddd";


//svg, sData, fData, sort, filters
export function basicTree(appData) {
	//console.log(appData);
	//console.log(filters);
	var svg = appData.svg;
	//Layers
	var vizLr = svg.select("#l-viz");
	vizLr.selectAll('*').remove();
	var gridLr = svg.select("#l-grid");
	var mapLr = svg.select("#l-map");
	var dataLr = svg.select("#l-data");
	var copyLr = svg.select("#l-copy");
	var stageLr = svg.select("#l-stage");
	var thekey = svg.select("#l-key");

	//Sizes
	var svgSize = vbSize(svg);
	var svgHeight = svgSize[0];
	var svgWidth = svgSize[1];
	var gcoords = parseTransform(vizLr.attr("transform")); //Grab the corrdinates of thedata layer which has a margin from top and left
	var gWidth = svgWidth - (gcoords[0] * 2); //50 the sides.
	var gHeight = svgHeight - (gcoords[1] + gcoords[0]); //100 - the top

	//var bubSize = Math.min(12, (gWidth / fData.length) * 2);

	//Transitions
	var tIntro = d3.transition()
		.duration(2000)
		.ease(d3.easePolyInOut);

	update();

	//General Update Pattern
	function update() {

		//console.log(appData);


		var tree = d3.tree().size([gHeight, gWidth - 100]);

		var treeNodes = tree(appData.hierarchy);

		var nodeSize = 25;

		//
		var nodestodraw = treeNodes.descendants().filter(d => d.data.name != "end");
		//console.log(tree.descendents());
		const node = vizLr.selectAll(".node")
			.data(nodestodraw)
			.enter().append("g")
			.attr("class", d => "node" + (d.children ? " node--internal" :
				" node--leaf"))
			.attr("transform", d => "translate(" + d.y + "," +
				d.x + ")")
			.each(function (d) {
				var elem = d3.select(this);
				//var txt = d.id;
				toolTip2(elem, d, 16, "title");
			});



		node.append("circle")
			.attr("r", 10)
			.style('fill', function (d) {
				//console.log(d);
				if (d.data.link == "end") {
					return 'red';
				} else if (d.data.link == "launch") {
					return 'green';
				} else {
					return 'grey';
				}

			})
			.classed('node', true);

		node.append("text")
			.attr("dy", ".35em")
			.attr("x", d => nodeSize / 2 + 5)
			// .attr("y", d => d.children && d.depth !== 0 ?
			//     -(d.data.value + 5) : d)
			.style("text-anchor", d => d.children ? "start" : "start")
			.text(d => d.data.shortTitle);


		var icons = node.append("image").attr("xlink:href", function (d) {
			return appData.iconMap[d.data.icon];
		}).attr("x", function () {
			return -(nodeSize / 2);
		}).attr("y", function () {
			return -(nodeSize / 2);
		}).attr("width", function () {
			return nodeSize;
		}).attr("height", function () {
			return nodeSize;
		});



		const link = vizLr.selectAll(".link")
			.data(treeNodes.descendants().slice(1))
			.enter().append("path")
			.attr("class", "tree-link")
			.style("stroke", d => d.data.level)
			.attr("d", d => {
				return "M" + d.y + "," + d.x +
					"C" + (d.y + d.parent.y) / 2 + "," + d.x +
					" " + (d.y + d.parent.y) / 2 + "," + d.parent.x +
					" " + d.parent.y + "," + d.parent.x;
			});





	}





	if (stageLr.select("#headline").empty()) {
		var headline = stageLr.append("text")
			.attr("id", "headline")
			.attr("x", 50)
			.classed("gfx-head", true)
			.attr("y", function () {
				var textNode = d3.select(this);
				var textHeight = textNode.node().getBBox().height;
				return textHeight + 50;
			})
			.call(textEdit, "headline");


		stageLr.append('rect')
			.attr("height", svgHeight)
			.attr("width", svgWidth)
			.classed("box1", true);

	}


	stageLr.select("#headline").text(function (d) {
		//var recCount = fData.length;
		//console.log(recCount);
		//return recCount + " total in the list";
		return "Your Research Plan";
	});


}