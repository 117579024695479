//nA hover panel in the SVG.

import * as d3 from "d3";
import { wrap } from "./ddd";
import { cap1 } from "./ddd";
import { titleCase } from "./ddd";
import { truncate } from "./ddd";

//variables data, details (coordinates for placement), p (parent element to attach to), s (font size)

export function hoverPanel(d, p, fs) {
    var svg = p;
    var copyLr = svg.select("#l-copy");

    var details = { "x": 50, "y": 670, "height": 40, "width": 1180 };

    console.log(d);

    var x = details.x;
    var y = details.y;
    var h = details.height;
    var w = details.width;
    var marg = 5;
    var leadSpace = 5;
    var lines = 2;
    var lineHeight = leadSpace + fs;
    var totalHeight = (marg * 2) + (leadSpace * lines) + (fs * lines);
    
    var hoverGroup = copyLr.append("g")
        .attr("transform", "translate(" + details.x + "," + details.y + ")")
        .classed("hoverPanel", true);

    hoverGroup.append("line").attr("x1", 0).attr("x2", details.width).attr("y1", 0).attr("y2", 0).classed("leader", true);

    hoverGroup.append('rect')
        .attr("height", totalHeight)
        .attr("width", w)
        .classed("hover-box", true);

    //Study title
    hoverGroup.append("text")
        .attr("x", marg)
        .classed("gen-bold", true)
        .style("font-size", fs)
        .text(function() {
            var name = d.NAME;
            //console.log(name);
            if (name != "") {
                var nameStr = truncate(name, 100)
                return nameStr.toUpperCase();
            } else {
                return "UNNAMED STUDY"
            }

        })
        .attr("y", lineHeight);

    var lineX = marg;
    //console.log("add brand label");
    //Brand
    var brand = hoverGroup.append("text")
        .attr("x", lineX)
        .classed("gen-txt", true)
        .style("font-size", fs)
        .text(function() {
            return "BRAND: " + d.BRAND
        })
        .attr("y", lineHeight * 2);

    lineX = brand.node().getComputedTextLength() + 10 + lineX;

    //Flavor
    var flavor = hoverGroup.append("text")
        .attr("x", lineX)
        .classed("gen-txt", true)
        .style("font-size", fs)
        .text(function() {
            return "FLAVOR: " + d.PRIMARY_FLAVOR
        })
        .attr("y", lineHeight * 2);

    lineX = flavor.node().getComputedTextLength() + 10 + lineX;

    //Country
    var country = hoverGroup.append("text")
        .attr("x", lineX)
        .classed("gen-txt", true)
        .style("font-size", fs)
        .text(function() {
            return "COUNTRY: " + d.COUNTRY
        })
        .attr("y", lineHeight * 2);

    lineX = country.node().getComputedTextLength() + 10 + lineX;

    //Study Type
    var testType = hoverGroup.append("text")
        .attr("x", lineX)
        .classed("gen-txt", true)
        .style("font-size", fs)
        .text(function() {
            return "TYPE: " + d.TEST_TYPE
        })
        .attr("y", lineHeight * 2);

    lineX = testType.node().getComputedTextLength() + 10 + lineX;



}
