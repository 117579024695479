import * as d3 from "d3";
//ToolTip is a resuabel tooltip with a single text value.
//argumanets are this, txt (the thing to write), and a size
export function toolTip(elem, txt, size) {
    //console.log(elem);
    elem.on('mouseover', function(d) {
        //console.log(this.getBBox());

        var gCoord = d3.mouse(this.ownerSVGElement);
        var rollSVG = d3.select(this.ownerSVGElement);
        var tipY = gCoord[1];
        var tipX = gCoord[0] + 10;
        //var gCoord = d3.mouse(svg.node());
        var rollover = rollSVG.append("g")
            .attr("transform", "translate(" + tipX + "," + tipY + ")")
            .classed("dtip", true);

        var tipText = rollover.append("text")
            .classed("rev-txt", true)
            .style("font-size", size)
            .attr("dy", ".35em")
            .attr("x", 15)
            .text(txt);

        var txtLength = tipText.node().getComputedTextLength() + 10;

        rollover.insert("rect", ":first-child")
            .attr("height", function() {
                return size + 10;
            })
            .attr("width", txtLength)
            .attr("rx", 4)
            .attr("ry", 4)
            .attr("y", -((size + 10) / 2))
            .attr("x", 10)
            .classed("roll-pad", true);

        rollover.insert("path", ":first-child")
            .attr('d', d3.symbol().type(d3.symbolTriangle).size(100))
            .attr("transform", "rotate(-90) translate(0,10)")
            .classed("roll-pad", true);

        elem.on("mousemove", function(d) {
            var newCoord = d3.mouse(this.ownerSVGElement);
            var nTipY = newCoord[1];
            var nTipX = newCoord[0];
            rollover.attr("transform", "translate(" + nTipX + "," + nTipY + ")");
        });
    });
    elem.on('mouseout', function(d) {
        console.log("out");
        d3.selectAll(".dtip").remove();
    });



    ///click version
    elem.on('click', function(d) {

        var clickID = "click" + d.ID;

        //console.log(clickID);

        if (d3.select("#" + clickID).empty()) {
            console.log("not heer yet");
            var gCoord = d3.mouse(this.ownerSVGElement);
            var rollSVG = d3.select(this.ownerSVGElement);
            var tipY = gCoord[1];
            var tipX = gCoord[0];
            //var gCoord = d3.mouse(svg.node());
            var rollover = rollSVG.append("g")
                .attr("transform", "translate(" + tipX + "," + tipY + ")")
                .classed("clicktip", true)
                .attr("id", function(d) {
                    return clickID;
                })
                .on("click", function() {
                    d3.select(this).remove();
                });

            var tipText = rollover.append("text")
                .classed("rev-txt", true)
                .style("font-size", size)
                .attr("dy", ".35em")
                .attr("x", 15)
                .text(txt);

            var txtLength = tipText.node().getComputedTextLength() + 10;


            rollover.insert("rect", ":first-child")
                .attr("height", function() {
                    return size + 10;
                })
                .attr("width", txtLength)
                .attr("rx", 4)
                .attr("ry", 4)
                .attr("y", -((size + 10) / 2))
                .attr("x", 10)
                .classed("roll-pad", true);

            rollover.insert("path", ":first-child")
                .attr('d', d3.symbol().type(d3.symbolTriangle).size(100))
                .attr("transform", "rotate(-90) translate(0,10)")
                .classed("roll-pad", true);
        } else {
            d3.select("#" + clickID).remove();
        }


        //.attr("transform", "translate(0,5)");
    });




}