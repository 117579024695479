import * as d3 from "d3";
//import { toolTip } from "./ddd";
//Text conflict 
//Arguments:
// e  This module takes an element : must be a "g" with a single text element.
// d it also takes the data
//chClass - A class to check against. 
//looks through the selection returns true if the element is touching any others from the selection.
export function textPog(e, d, w) {

    var pogOn = false;
    var svg = d3.select(e.ownerSVGElement);
    var pNode = d3.select(e.parentNode);
    var thisTxt = d3.select(e);
    var full = thisTxt.text();
    var color = thisTxt.style("fill");
    var t_w = e.getComputedTextLength();
    var t_h = e.getBBox().height;
    var pogR = 7;

    var pog = pNode.insert("g", ":first-child")
        .style("visibility", "hidden")
        .attr("transform", function() {
            //var pogVert = -t_h.height;
            var pogVert = 0;
            //console.log(pogVert);
            return "translate(0, " + pogVert + ")";
        });

    //x1="5" y1="5" x2="40" y2="40"

    var line = pNode.insert("line", ":first-child")
        .attr("x1", function() {
            var x = -t_w / 2;
            return x;
        })
        .attr("x2", function() {
            var x = t_w / 2;
            return x;
        })
        .attr("y1", 0)
        .attr("y2", 0)
        .style("stroke-width", function() {
            return t_h;
        })
        .style("stroke", function() {
            return "white";
        })
        .classed("round-line", true);


    pog.append("circle")
        .attr("r", pogR)
        .style("fill", color)
        .style("fill-opacity", .5);

/*    pog.append("text")
        .classed("mini-rev-text no-select", true)
        .attr("dy", ".35em")
        .attr('text-anchor', 'middle')
        .text(thisTxt.text().charAt(0));*/


    if (t_w >= w) {
        //var pogOn = true;
        swich();
        //pog.on("mouseover", swich);
        ///log.on('mouseout', swich);
    }

    pNode.on("click", swich);



    function swich() {
        //console.log("swich");
        if (pogOn == false) {
            thisTxt.text(full.charAt(0));
            thisTxt.classed("mini-rev-text", true);
            pog.style("visibility", "visible");
            line.style("visibility", "hidden");
            pogOn = true;
        } else {
            thisTxt.text(full);
            thisTxt.classed("mini-rev-text", false);
            pog.style("visibility", "hidden");
            line.style("visibility", "visible");
            pogOn = false;
        }

    }

    return true;
}