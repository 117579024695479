//TO SEND TO CLOUD CONVERT
export function svg2pdf_cc(ready_content, filename) {

    console.log("SVG>PDF with CloudConvert: " + filename);

    var cloud_convert_api = "6JhzPgAJwv6nyB7RxyqrKumo84Q60m0hJlBCo_HNyqBQ_xnkXZXv2TYrTUggFV5fDGYriyhEzVAqWMCdWLo9cg"; ///DDD API key

    var request = $.ajax({
        url: "https://api.cloudconvert.com/process",
        method: "POST",
        data: {
            "apikey": cloud_convert_api,
            "inputformat": "raw",
            "outputformat": "pdf"
        },
        dataType: "jsonp"
    }).done(function(msg) {
        //console.log(msg);
        var request2 = $.ajax({
            url: msg.url,
            method: "POST",
            data: { 
                "wait": true,
                "input": "raw",
                "inputformat": "svg",
                "file": ready_content,
                "filename": filename,
                "outputformat": "pdf"
            }
        }).done(function(msg2) {
            //console.log(msg2);
            ////window.open(msg2.output.url);
            var link = document.createElement('a');
            link.setAttribute('href', msg2.output.url);
            link.click();
            //$('#download_screen').remove();
        }).fail(function(jqXHR, textStatus) {
            //console.log(jqXHR.responseJSON.message);
            console.log("Request failed: " + jqXHR.responseJSON.message);
        });
    }).fail(function(jqXHR, textStatus) {
        console.log("Request failed: " + textStatus);
    });

}
