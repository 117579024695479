//screatFilters.js takes a the results of a d3.dsv and creates filter buttons, and returns data for a viz.
import * as d3 from "d3";

export function stratData(data, sort, id) {

//
    console.log(data);
    console.log(id);
    console.log(sort);
    var root = d3.stratify()
    .id(function(d) { return d[id]; })
    .parentId(function(d) { return d[sort]; })
    (data);

    //console.log(root);

    return root;
}
