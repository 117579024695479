//A dependency-free way to get SVGs ready for either download or sending for conversion with a service. 
//Now supports sending to either to the corbar derived SVG download, or to CloudConvert to create PDFs, or toher things.


/*PRIORITIES

• Do this work on a clone, not the one displayed in the DOM.

• Insert entire style sheet at the top, rather than 1etting inline styles. This could be useful if it meant that we could include the link to cloud-based font service such as CC cloud or googleFonts.

*/
import * as d3 from "d3";


export function svgPrep(svgid) {

    //using cloud convert service to save the current SVG in PDF format, the URL of which gets saved in the qz_data

    var cloud_convert_api = "6JhzPgAJwv6nyB7RxyqrKumo84Q60m0hJlBCo_HNyqBQ_xnkXZXv2TYrTUggFV5fDGYriyhEzVAqWMCdWLo9cg"; ///DDD API key

    ///hmm window...will I need that?
    window.URL = (window.URL || window.webkitURL);

    // an object that holds a series of prefixes, but for what, looks like web links to various documentations. 
    var prefix = {
        xmlns: "http://www.w3.org/2000/xmlns/",
        xlink: "http://www.w3.org/1999/xlink",
        svg: "http://www.w3.org/2000/svg"
    };

    var svg = document.querySelector(svgid);
    //clone the SVG that is in the DOM to work with it.
    //var svg = gfx;

    //console.log(svg);


    //find a list of all the style sheets in the doc...
    var styleSheetList = document.styleSheets;
    //console.log(styleSheetList);

    //grab the HTML from inside it, which just contains the SVG.
    //var raw_svg = tmp_svg.html();

    //console.log(raw_svg);

    var body = document.body;
    // add empty svg element with old school javascript..
    var emptySvg = window.document.createElementNS(prefix.svg, 'svg');
    // and add it to the document, even though it contains nothing?
    window.document.body.appendChild(emptySvg);

    //now create a variable that will always represent the computed style of that empty svg.
    var emptySvgDeclarationComputed = window.getComputedStyle(emptySvg);
    //console.log(emptySvgDeclarationComputed);

    //Now a few things that Andrew is handling, but maybe different from crowbar.

    //Andrew finds and replaces the prefixes...
    //raw_svg = raw_svg.replace(/NS\d+:href/gi, 'xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href');

    // while crowbar it think cuts them out and then adds them back in to prevent duplications.
    //Now we set the version of that svg.
    svg.setAttribute("version", "1.1");

    // removing attributes so they aren't doubled up, I guess cause we are going to add them back in.
    svg.removeAttribute("xmlns");
    svg.removeAttribute("xlink");

    //More slick stuff to format the SVG.
    // These are needed for the svg
    if (!svg.hasAttributeNS(prefix.xmlns, "xmlns")) {
        svg.setAttributeNS(prefix.xmlns, "xmlns", prefix.svg);
    }

    if (!svg.hasAttributeNS(prefix.xmlns, "xmlns:xlink")) {
        svg.setAttributeNS(prefix.xmlns, "xmlns:xlink", prefix.xlink);
    }

    //Andrew's svg_prefix variable is a bit diferent from thier Doctype variable
    var svg_prefix = '<?xml version="1.0" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">'

    var styles = '<style>@page { size: 1280px 720px };@import "https://fonts.googleapis.com/css?family=Montserrat:300,400,700,900";svg {font-family: "Montserrat";}</style>';

    //Andrews includes a refernce to a stylesheet called style8.css - and crowbar does not...
    var doctype = '<?xml version="1.0" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';

    //Andrew
    //var ready_content = svg_prefix + "<svg width='1280' height='720' version='1.1' xmlns='http://www.w3.org/2000/svg'>" + raw_svg + "</svg></xml>";

    setInlineStyles(svg, emptySvgDeclarationComputed);

    //console.log(svg);

    var source = (new XMLSerializer()).serializeToString(svg);

    var svgInfo = [];

    svgInfo.push({
        class: svg.getAttribute("class"),
        id: svg.getAttribute("id"),
        name: svg.getAttribute("name"),
        childElementCount: svg.childElementCount,
        source: [svg_prefix + source]
    });

    return svgInfo[0];


    //TO SET INLINE STYLES VIA CORWBAR
    function setInlineStyles(svg, emptySvgDeclarationComputed) {
        //console.log(emptySvgDeclarationComputed);
        var fontFamily = d3.select(body).style("font-family");
        fontFamily = fontFamily.toString();
        //console.log(fontFamily.replace(/['"]+/g, ''));

        //HERES WHERE I AM MANUALLY SETTING A FONT-FAMILY STYLE FOR THIS SVG. THIS SHOULD BE BETTER. 
        d3.select(svg).style("font-family", "Gotham A, Gotham B, Gotham, Montserrat, Arial, san-serif");

        function explicitlySetStyle(element) {
            //console.log(element);
            var cSSStyleDeclarationComputed = window.getComputedStyle(element);
            //console.log(cSSStyleDeclarationComputed);
            var i, len, key, value;
            var computedStyleStr = "";
            for (i = 0, len = cSSStyleDeclarationComputed.length; i < len; i++) {
                key = cSSStyleDeclarationComputed[i];
                value = cSSStyleDeclarationComputed.getPropertyValue(key);
                if (value !== emptySvgDeclarationComputed.getPropertyValue(key)) {
                    computedStyleStr += key + ":" + value + ";";
                    //console.log(computedStyleStr);
                }
            }
            element.setAttribute('style', computedStyleStr);
        }

        function traverse(obj) {
            var tree = [];
            tree.push(obj);
            visit(obj);

            function visit(node) {
                if (node && node.hasChildNodes()) {
                    var child = node.firstChild;
                    while (child) {
                        if (child.nodeType === 1 && child.nodeName != 'SCRIPT') {
                            tree.push(child);
                            visit(child);
                        }
                        child = child.nextSibling;
                    }
                }
            }
            return tree;
        }
        // hardcode computed css styles inside svg
        var allElements = traverse(svg);
        var i = allElements.length;
        while (i--) {
            explicitlySetStyle(allElements[i]);
        }
    }


}