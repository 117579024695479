//gen.js is a basic demostration of a graphics generator in the ddd.js system.
//Creates some boxes and text, styles it with external css.

import * as d3 from "d3";
import { vbSize } from "./ddd";

export function mouseControl(svg) {
    console.log('Add Mouse control');

    var svgSize = vbSize(svg);
    var height = svgSize[0];
    var width = svgSize[1];
    var margin = 25;


    //the mat, a group where the drawing happens.
    var mat = svg.append("g");

    //add a simple borderfor that group.
    var canvas = mat.append("rect")
        .attr("height", height)
        .attr("width", width)
        .attr("class", "mat");

    //add a circle when the group is clicked

    //Create an object to hold all the things.
    //add objects to that 
    //Create ordinal scale for coloring of things
    //draw interfaces to 
    //Use the update loop to redraw.

    canvas.on("click", function() {

        var clickX = d3.mouse(this)[0];
        var clickY = d3.mouse(this)[1];
        console.log(clickX + " " + clickY);
        mat.append("circle")
            .classed("thing", true)
            .attr("cx", clickX)
            .attr("cy", clickY)
            .attr("r", 0)
            .transition()
            .duration(2000)
            .ease(d3.easeExpOut)
            .attr("r", 50);

        var things = d3.selectAll(".thing")
            .on("click", function() {
                this.remove();
            });
    });

}
