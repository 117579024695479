//screatFilters.js takes a the results of a d3.dsv and creates filter buttons, and returns data for a viz.
import * as d3 from "d3";

export function groupButtons(appData, divID, funRun) {
    


    // var sortBundle = d3.map(appData.group, function(d) {
    //     return d;
    // });

    var group;

    if (d3.select("#group").empty()) {
        group = d3.select(divID)
            .append("div")
            .attr("id", "group")
            .classed("filter-holder col-md-6", true);
    } else {
        group = d3.select("#group");
        group.selectAll("*").remove();
    }


    //CREATE A LABEL FOR THE SORT SET.
    group.append("div")
        .html("Data Groups")
        .classed("subhead", true);

    //CREATE THE DIV TO HOLD BUTTONS
    var buttons = group
        .append("div")
        .attr("id", "groups")
        .attr("data-toggle", "buttons")
        .classed("filter-holder btn-group btn-group-toggle", true);


    //Create the filter buttons themselves.
    var groupButtons = buttons.selectAll("label")
        .data(appData.group)
        .enter()
        .append("label")
        .classed("btn btn-light btn-sm", true)
        .classed("active", function(d, i) {
            if (i == 0) {
                return true;
            } else {
                return false;
            }
        })
        .text(function(d) {
            return d;
        })
        .attr("draggable", true)
        .on("ondragstart", function(e,d) {

            console.log("dragging");
        })
        .on("click", function(d) {
            //console.log("click");
            funRun(appData.group);
        });

    groupButtons.append("input")
        .attr("type", "radio")
        .attr("name", "options")
        .attr("autocomplete", "off")
        .property("checked", function(d, i) {
            if (i == 0) {
                return true;
            } else {
                return false;
            }
        })
        .attr("id", function(d) {
            return d + "-btn";
        })
        .on("ondragstart", function(e,d) {

            console.log("dragging");
        });


    //add inital sort - currently hardcoded.
    return appData.group;
}
