//nA hover panel in the SVG.

import * as d3 from "d3";
import { wrap } from "./ddd";
import { cap1 } from "./ddd";
import { titleCase } from "./ddd";
import { truncate } from "./ddd";
import { vbSize } from "./ddd";

//variables data, 
//details (coordinates for placement), 
//p (d3 selected svg element to attach to), 
//fs (font size)

export function keyPanel(keyColors, svg, fs) {
    //console.log("draw key");
    var keyLr = svg.select("#l-key");
    //console.log(copyLr.empty());
    var svgSize = vbSize(svg);
    var svgHeight = svgSize[0];
    var svgWidth = svgSize[1];

    var details = { "x": 50, "y": 670, "height": 40, "width": 1180 };
    var x = details.x;
    var y = details.y;
    var h = details.height;
    var w = details.width;
    var marg = 5;
    var leadSpace = 5;
    var lines = 2;
    var lineHeight = leadSpace + fs;
    var totalHeight = (marg * 2) + (leadSpace * lines) + (fs * lines);

    update(keyColors);

    function update(data) {
        //console.log("update")
        //console.log(data);

        //Create whatever scales you might need to got coordinates to move the bubs to
        //console.log(data)
        //1. Add or remove any other elements from the other layers
        //2. Make the selection of the data-bubs, and change the data, and be sure to get the key right.
        var keyItems = keyLr.selectAll(".key-items")
            .data(Object.entries(data));
        //3. remove any that dont need to be there with the exit selection
        var exit = keyItems.exit().remove();

        //4. Add any new nodes with the enter selection.
        var enter = keyItems.enter()
            .append("g")
            .classed("key-items", true)
            .attr("transform", function(d, i) {
                //console.log(i);
                var y = (fs * i) + 50;
                var x = svgWidth - 50;
                return "translate(" + x + "," + y + ")";
            })
            .on("click", function(d, i) {
                console.log(d);
            });

        enter.append("circle")
            .attr("r", fs / 2)
            .classed("key-dot", true)
            .style("fill", function(d) {
                return d.value;
            })
            .each(function(d) {
                //Log out the new elements.
                //console.log(d);
            })

        enter.append("text")
            .style("text-anchor", "end")
            .style("font-size", fs)
            .attr("dy", ".35em")
            .attr("x", -fs)
            .text(function(d) {
                return d.key;
            });

        enter.insert("line", ":first-child")
            .attr("x1", 0)
            .attr("x2", function() {
                var itemGroup = this.parentNode;
                var textNode = d3.select(itemGroup).select("text").node().getComputedTextLength();
                return -textNode - fs;
            })
            .attr("y1", 0)
            .attr("y2", 0)
            .style("stroke-width", function() {
                return fs + 1;
            })
            .style("stroke-opacity", .5)
            .style("stroke", function() {
                return "white";
            })
            .classed("round-line", true);

        //5. Merge the update and enter selection.
        keyItems = enter.merge(keyItems);

        //6. Then make your various changes to the bubs.
        /*        keyItems.transition()
                    .attr("transform", function(d, i) {
                        console.log(i);
                        var y = (fs * i) + 50;
                        var x = svgWidth - 50;
                        return "translate(" + x + "," + y + ")";
                    });*/

        keyItems.select(".key-dot")
            .transition()
            .style("fill-opacity", 1);

        keyItems.select("text")
            .text(function(d) {
                return d.key;
            });

        keyItems.select("line")
            .transition()
            .attr("x2", function() {
                var itemGroup = this.parentNode;
                var textNode = d3.select(itemGroup).select("text").node().getComputedTextLength();
                return -textNode - fs;
            });

    }

    /*   var hoverGroup = copyLr.append("g")
           .attr("transform", "translate(" + details.x + "," + details.y + ")")
           .classed("hoverPanel", true);

       hoverGroup.append("line").attr("x1", 0).attr("x2", details.width).attr("y1", 0).attr("y2", 0).classed("leader", true);

       hoverGroup.append('rect')
           .attr("height", totalHeight)
           .attr("width", w)
           .classed("hover-box", true);

       //Study title
       hoverGroup.append("text")
           .attr("x", marg)
           .classed("gen-bold", true)
           .style("font-size", fs)
           .text(function() {
               var name = d.NAME;
               //console.log(name);
               if (name != "") {
                   var nameStr = truncate(name, 100)
                   return nameStr.toUpperCase();
               } else {
                   return "UNNAMED STUDY"
               }

           })
           .attr("y", lineHeight);

       var lineX = marg;
       //console.log("add brand label");
       //Brand
       var brand = hoverGroup.append("text")
           .attr("x", lineX)
           .classed("gen-txt", true)
           .style("font-size", fs)
           .text(function() {
               return "BRAND: " + d.BRAND
           })
           .attr("y", lineHeight * 2);

       lineX = brand.node().getComputedTextLength() + 10 + lineX;

       //Flavor
       var flavor = hoverGroup.append("text")
           .attr("x", lineX)
           .classed("gen-txt", true)
           .style("font-size", fs)
           .text(function() {
               return "FLAVOR: " + d.PRIMARY_FLAVOR
           })
           .attr("y", lineHeight * 2);

       lineX = flavor.node().getComputedTextLength() + 10 + lineX;

       //Country
       var country = hoverGroup.append("text")
           .attr("x", lineX)
           .classed("gen-txt", true)
           .style("font-size", fs)
           .text(function() {
               return "COUNTRY: " + d.COUNTRY
           })
           .attr("y", lineHeight * 2);

       lineX = country.node().getComputedTextLength() + 10 + lineX;

       //Study Type
       var testType = hoverGroup.append("text")
           .attr("x", lineX)
           .classed("gen-txt", true)
           .style("font-size", fs)
           .text(function() {
               return "TYPE: " + d.TEST_TYPE
           })
           .attr("y", lineHeight * 2);

       lineX = testType.node().getComputedTextLength() + 10 + lineX;*/



}