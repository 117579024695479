//screatFilters.js takes a the results of a d3.dsv and creates filter buttons, and returns data for a viz.
import * as d3 from "d3";

export function createSorts(data, columns, divID, funRun) {
    //console.log('createSorts');
    //copy the data. Why?
    //var fData = data;

    var sortBundle = d3.map(columns, function(d) {
        return d;
    });

    var sort;

    if (d3.select("#sort").empty()) {
        sort = d3.select(divID)
            .append("div")
            .attr("id", "sort")
            .classed("filter-holder col-md-6", true);
    } else {
        sort = d3.select("#sort");
        sort.selectAll("*").remove();
    }


    //CREATE A LABEL FOR THE SORT SET.
    sort.append("div")
        .html("Data sort")
        .classed("subhead", true);

    //CREATE THE DIV TO HOLD BUTTONS
    var buttons = sort
        .append("div")
        .attr("id", "sorts")
        .attr("data-toggle", "buttons")
        .classed("filter-holder btn-group btn-group-toggle", true);


    //Create the filter buttons themselves.
    var sortButtons = buttons.selectAll("label")
        .data(columns)
        .enter()
        .append("label")
        .classed("btn btn-light btn-sm", true)
        .classed("active", function(d, i) {
            if (i == 0) {
                return true;
            } else {
                return false;
            }
        })
        .text(function(d) {
            return d;
        })
        .attr("draggable", true)
        .on("click", function(d) {
            //console.log("click");
            funRun(d);
        });

    sortButtons.append("input")
        .attr("type", "radio")
        .attr("name", "options")
        .attr("autocomplete", "off")
        .property("checked", function(d, i) {
            if (i == 0) {
                return true;
            } else {
                return false;
            }
        })
        .attr("id", function(d) {
            return d + "-btn";
        });


    //add inital sort - currently hardcoded.
    return columns[0];
}
