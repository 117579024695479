import * as d3 from "d3";
import * as d3ScaleChromatic from 'd3-scale-chromatic';;
import { vbSize } from "./ddd";
import { textEdit } from "./ddd";
import { parseTransform } from "./ddd";
import { toolTip_topicModeling as toolTip } from "../ddd/ddd.js";
import { hoverPanel_v2 } from "./ddd";
import { least } from "d3";
import { color } from "../ddd/ddd.js";



export function topicPivot(appData) {


	var svg = appData.svg




    //console.log("Topic Pivot Start")
	var vizLr = svg.select("#l-viz");
	var scatterLayer = svg.select("#scatterGroup");
	scatterLayer.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	//Layers
	var gridLr = svg.select("#l-grid");
	gridLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var mapLr = svg.select("#l-map");
	mapLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var dataLr = svg.select("#l-data");
	var copyLr = svg.select("#l-copy");
	copyLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var stageLr = svg.select("#l-stage");
	var thekey = svg.select("#l-key");
	thekey.transition().duration(1000).style("opacity", 1);

	//Sizes
	var svgSize = vbSize(svg);
	var svgHeight = svgSize[0];
	var svgWidth = svgSize[1];
	var gcoords = parseTransform(vizLr.attr("transform")); //Grab the corrdinates of thedata layer which has a margin from top and left
	var gWidth = svgWidth - (gcoords[0] * 2); //50 the sides.
	var gHeight = svgHeight - (gcoords[1] + gcoords[0]); //100 - the top

	const bubSize = 5;

	// center 00 in the viewbox...
	svg.attr("viewBox",  -svgWidth/2 + " " + -svgHeight/2 + " " + + svgWidth + " " + svgHeight)

	
	// Set up the force, but without specifying what its nodes and links are.
	const simulation = d3.forceSimulation()
	.force("link", d3.forceLink().distance(30).strength(.1)) //.id(d => d.index)
	.force("charge", d3.forceManyBody().strength(-50))
	.force("r", d3.forceRadial(d => d.depth * 200))
	//.force("collide", d3.forceCollide().radius(d => d.children ? 50 : 20))
	//.force("center", d3.forceCenter(svgWidth/2, svgHeight/2));
	// .force("x", d3.forceX())
	// .force("y", d3.forceY());
	.on("tick", () => {
		link
			.attr("x1", d => d.source.x)
			.attr("y1", d => d.source.y)
			.attr("x2", d => d.target.x)
			.attr("y2", d => d.target.y);
	
		node
			.attr("transform", function (d, i) {
				///console.log(i);
				return "translate(" + d.x + "," + d.y + ")";
			});
	
			// .attr("cx", d => d.x)
			// .attr("cy", d => d.y)
	
	
		});

	//Now create the selections, but do not yet bind data and add elements
	//which will be handled by .join
	let link = dataLr.selectAll(".link-line");
	let node = dataLr.selectAll(".data-bub");
	

	update(appData);
	
  
    
    //General Update Pattern
	function update(appData) {

				//Transitions
		var tIntro = d3.transition()
			.duration(2000)
			.ease(d3.easePolyInOut);

		var data = appData.fData;


		let root = appData.hierarchy;
		let links = root.links();
		let nodes = root.descendants();



		// Make a shallow copy to protect against mutation, while
		// recycling old nodes to preserve position and velocity.
		
		// const old = new Map(node.data().map(d => [d.name, d]));
		
		// nodes = nodes.map(d => Object.assign(old.get(d.name) || {}, d));
		
		// links = links.map(d => Object.assign({}, d));


		//const link = dataLr.selectAll(".link-line")
		link = 	link.data(links, d => d.source + "-" + d.target)
			.join("line")
			.classed("link-line", true) 
			.attr("stroke", "#999")
			.attr("stroke-opacity", 0.6);

		//const bubs = dataLr.selectAll(".data-bub")
		node = node.data(nodes, d => d.name)
			.join(
				function(bubnode) {
					var enter = bubnode.append("g").classed("data-bub", true);
					enter.append("circle")
					.attr("r", d => d.children ? bubSize * d.height * 2 : bubSize)
					.classed("bub", true)
					// .attr("fill", "#fff")
					// .attr("stroke", "#000")
					.on("click", function (d) {
						console.log(d);
					});
					return enter;

			},
			)
			.on("click", function (d) {
				console.log(d);
			})
			.on("mouseover", function (e, d) {
				console.log(d); //the data
				//console.log(this); //the actual SVG node
				//console.log(e); //The event
				var elem = d3.select(this);
				
				toolTip(elem, d.data, 16, "title", d.label);

				d3.selectAll(".hoverPanel").remove();
				
				var mouseoverDetails = { "x": 50, "y": svgHeight - 100, "height": 40, "width": svgWidth - 100}

				
				var hoverData = d.children ? [d.data[0]] : d.data;

				hoverPanel_v2(hoverData, mouseoverDetails, svg, 14);
			})
			.call(drag(simulation));




		// node.append("circle")
		// 	.attr("r", d => d.children ? bubSize * d.height * 2 : bubSize)
		// 	.classed("bub", true)
		// 	// .attr("fill", "#fff")
		// 	// .attr("stroke", "#000")
		// 	.on("click", function (d) {
		// 		console.log(d);
		// 	})
			// .attr("r", 3.5)

			//color(appData.colorOn);

			simulation.nodes(nodes);
			simulation.force("link").links(links);
			simulation.alpha(1).restart();



		function drag (simulation) {

			function dragstarted(event, d) {
				if (!event.active) simulation.alphaTarget(0.3).restart();
				d.fx = d.x;
				d.fy = d.y;
			}
			
			function dragged(event, d) {
				d.fx = event.x;
				d.fy = event.y;
			}
			
			function dragended(event, d) {
				console.log("drag end")
				if (!event.active) simulation.alphaTarget(0);
				d.fx = null;
				d.fy = null;
			}
			
			return d3.drag()
				.on("start", dragstarted)
				.on("drag", dragged)
				.on("end", dragended);
			}



		// now creat eht simulation.... 
		// Really wish I had a better understanding of this
		// const simulation = d3.forceSimulation(nodes)
		// 	.force("link", d3.forceLink(links).distance(30).strength(.1)) //.id(d => d.index)
		// 	.force("charge", d3.forceManyBody().strength(-50))
		// 	.force("r", d3.forceRadial(d => d.depth * 200))
		// 	//.force("collide", d3.forceCollide().radius(d => d.children ? 50 : 20))
		// 	//.force("center", d3.forceCenter(svgWidth/2, svgHeight/2));
		// 	// .force("x", d3.forceX())
		// 	// .force("y", d3.forceY());
		// 	.on("tick", () => {
		// 		link
		// 			.attr("x1", d => d.source.x)
		// 			.attr("y1", d => d.source.y)
		// 			.attr("x2", d => d.target.x)
		// 			.attr("y2", d => d.target.y);
			
		// 		bubs
		// 			.attr("transform", function (d, i) {
		// 				///console.log(i);
		// 				return "translate(" + d.x + "," + d.y + ")";
		// 			});
			
		// 			// .attr("cx", d => d.x)
		// 			// .attr("cy", d => d.y)
			
			
		// 		});









		// var bubs = dataLr.selectAll(".data-bub")
		// 	.data(data, d => d.id);


			// .attr("transform", function (d, i) {
			// 	///console.log(i);
			// 	var y = gHeight / 2; 
			// 	var x = gWidth / 2;
			// 	return "translate(" + x + "," + y + ")";
			// })


		//////			// append("g")
			// .attr("fill", "#fff")
			// .attr("stroke", "#000")
			// .attr("stroke-width", 1.5)





		// simulation.on("tick", () => {
		// 	link
		// 		.attr("x1", d => d.source.x)
		// 		.attr("y1", d => d.source.y)
		// 		.attr("x2", d => d.target.x)
		// 		.attr("y2", d => d.target.y);
		
		// 	bubs
		// 		.attr("cx", d => d.x)
		// 		.attr("cy", d => d.y);
		// 	});
		





		// //I create an empty selection, and use the key function with an ID string
		// //in my dsv data.
		// var bubs = dataLr.selectAll(".data-bub")
		// 	.data(data, d => d.id);

		// //EXIT - to remove nodes. Remove any that dont need to be there.
		// bubs.exit()
		// 	.transition(tIntro)
		// 	.style("opacity", 0)
		// 	.each(function (d) {
		// 		//Log out the removed elements.
		// 		//console.log(d.id);
		// 	})
		// 	.remove();


		// //ENTER	: To add nodes
		// var enter = bubs.enter() //add any new ones - a group and a circle within it.
		// 	.append("g")
		// 	.classed("data-bub", true)
		// 	.attr("transform", function (d, i) {
		// 		///console.log(i);
		// 		var y = gHeight / 2;
		// 		var x = gWidth / 2;
		// 		return "translate(" + x + "," + y + ")";
		// 	})
		// 	.on("click", function (d) {
		// 		console.log(d);
		// 	})
        //     .on("mouseover", function (e, d) {
        //         // console.log(d); the data
        //         // console.log(this); the actual SVG node
        //         // console.log(e); //The event
		// 		var elem = d3.select(this);
		// 		toolTip(elem, d, 16, appData.fData.columns[0]);

		// 		d3.selectAll(".hoverPanel").remove();
        //         var mouseoverDetails = { 
        //             "x": 50, 
        //             "y": svgHeight - 100, 
        //             "height": 40, 
        //             "width": svgWidth - 100
        //         }

		// 		hoverPanel_v2(d, mouseoverDetails, svg, 14);
		// 	})

		// enter.append("circle")
		// 	.attr("r", bubSize)
		// 	.classed("bub", true);


		// //Now merge the new and the old selection
		// bubs = enter.merge(bubs);

		// bubs.transition(tIntro)
		// 	.attr("transform", function (d, i) {
		// 		///console.log(i);
		// 		var y = Math.random() * gHeight;
		// 		var x = Math.random() * gWidth;
		// 		return "translate(" + x + "," + y + ")";
		// 	});

		// var bubBub = bubs.selectAll(".bub");

		// bubBub.transition()
		// 	.attr("r", bubSize);







		//Update the title.
		if (stageLr.select("#headline").empty()) {
			var headline = stageLr.append("text")
				.attr("id", "headline")
				.attr("x", 0)
				.classed("gfx-head-centered", true)
				.attr("y", function () {
					var textNode = d3.select(this);
					var textHeight = textNode.node().getBBox().height;
					return textHeight;
				})
				.call(textEdit, "Adial");
	
	
			// stageLr.append('rect')
			// 	.attr("height", svgHeight)
			// 	.attr("width", svgWidth)
			// 	.classed("box1", true);
	
		}
	
		stageLr.select("#headline").text(function (d) {
			var recCount = data.length;
			//console.log(recCount);
			//return recCount + " total in the list";
			return recCount + "Reports!";
		});




	}


	return update;

	

}