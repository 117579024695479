import * as d3 from "d3";
import { vbSize } from "./ddd";

export function gen(svg, data) {
    console.log('Generate summary');

    var svgSize = vbSize(svg);
    var height = svgSize[0];
    var width = svgSize[1];
    var margin = 25;


    svg.selectAll("*").remove();

    var stage = svg.append("g");

    stage.append('rect')
        .attr("height", height)
        .attr("width", width)
        .classed("box1", true);


    stage.append('rect')
        .attr("transform", "translate(" + margin + "," + margin + ")")
        .attr("height", function() {
            return height - (margin * 2);
        })
        .attr("width", function() {
            return width - (margin * 2);
        })
        .classed("box2", true);

    stage.append("text")
        .attr("x", margin)
        .attr("id", "headline")
        .classed("gfx-head", true)
        .text($("#head-input").val().toUpperCase())
        .attr("y", function() {
            var textHeight = this.getBBox().height;
            //console.log(textHeight);
            return margin + textHeight;
        });

}
