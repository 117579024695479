import * as d3 from "d3";
import { vbSize } from "./ddd";
import { parseTransform } from "./ddd";
//  1. ToolTip is a resuable tooltip with a single text value.
//  2. This tooltip must result from a mouse event on a group element
//  3. It operates on a copyLr, that must be presetn in the SVG copyLr must be present in the svg....

export function toolTip_topicModeling(elem, edata, size, field, txt) {
	//console.log(elem);
	var e = elem.node();
	var eCoords = parseTransform(elem.attr("transform"));
	//console.log(e.ownerSVGElement);
	var svg = d3.select(e.ownerSVGElement);
	//console.log(svg.node());
	//console.log(e);
	var gridLr = svg.select("#l-grid");
	var dataLr = svg.select("#l-data");
	var tooltipLr = svg.select("#l-tooltip");
	var stageLr = svg.select("#l-stage");
	//Sizes
	var svgSize = vbSize(svg);
	var svgHeight = svgSize[0];
	var svgWidth = svgSize[1];
	var gcoords = parseTransform(dataLr.attr("transform")); //Grab the coordinates of thedata layer which has a margin from top and left
	var gWidth = svgWidth - (gcoords[0] * 2); //50 the sides.
	var gHeight = svgHeight - (gcoords[1] + gcoords[0]); //100 - the top

	var toolText;


	//A bunch of fall back defaults for the text.
	if (txt) {
		toolText = txt;
	} else if (field) {
		toolText = edata[field];
	} else if (data.id) {
		toolText = edata.id;
	} else {
        toolText = "#id";
    };


	elem.on('mouseenter', function (event) {
        // console.log(event);
		var gCoord = d3.pointer(event);
        // console.log(gCoord);
        var tipY = event.y;
		var tipX = event.x;
        // console.log(tipX + " : " + tipY);
	    var tip = svg.node().createSVGPoint();
        tip.x = tipX;
        tip.y = tipY;

        //console.log(svg.node().getScreenCTM());

        var tipTrans = tip.matrixTransform( svg.node().getScreenCTM().inverse() );

        //console.log(tipTrans);

		var rollover = tooltipLr.append("g")
			.attr("transform", function(d) {

                return "translate(" + eCoords[0] + ", " + eCoords[1] + ")"
            })
			.classed("dtip", true);

		//creat the text object so you can measure it
		var tipText = rollover.append("text")
			.classed("rev-txt", true)
			.style("font-size", size)
			.attr("dy", ".35em")
			.attr("x", 15)
			.text(function () {
				return (toolText);
			});
		//measure it
		var txtLength = tipText.node().getComputedTextLength() + 10;
		//draw a rounded box behind.
		rollover.insert("rect", ":first-child")
			.attr("height", function () {
				return size + 10;
			})
			.attr("width", txtLength)
			.attr("rx", 4)
			.attr("ry", 4)
			.attr("y", -((size + 10) / 2))
			.attr("x", 10)
			.classed("roll-pad", true);
		//draw the triange
		rollover.insert("path", ":first-child")
			.attr('d', d3.symbol().type(d3.symbolTriangle).size(100))
			.attr("transform", "rotate(-90) translate(0,10)")
			.classed("roll-pad", true);
		//and move it when the mouse moves.
		// elem.on("mousemove", function (d) {
		// 	var newCoord = d3.pointer(d);
		// 	var nTipY = d.offsetY - 3;
		// 	var nTipX = d.offsetX + 3;
		// 	rollover.attr("transform", "translate(" + nTipX + "," + nTipY + ")");
		// });
	});

	elem.on('mouseleave', function (d) {
		//console.log("out");
		d3.selectAll(".dtip").remove();
	});

	///click version
	elem.on('click', function (d) {

		var clickID = "click" + edata.id;

		if (!d3.select("#" + clickID).empty()) {

			d3.select("#" + clickID).remove();


		} else {
			var gCoord = d3.pointer(d);
			//console.log(gCoord);
			var rollSVG = d3.select(d);
			var tipY = gCoord[1] - 3;
			var tipX = gCoord[0] + 3;
			//var gCoord = d3.mouse(svg.node());
			var rollover = elem.append("g")
				.attr("transform", "translate(" + tipX + "," + tipY + ")")
				.classed("clicktip no-events", true)
				.attr("id", clickID)
				.on("click", function () {
					d3.event.stopPropagation();
					d3.select(this).remove();
				})
				.on("mouseenter", function () {
					//console.log(this);
					//d3.event.stopPropagation();
				});

			//

			var tipText = rollover.append("text")
				.classed("rev-txt", true)
				.style("font-size", size)
				.attr("dy", ".35em")
				.attr("x", 15)
				.text(toolText);

			var txtLength = tipText.node().getComputedTextLength() + 10;


			rollover.insert("rect", ":first-child")
				.attr("height", function () {
					return size + 10;
				})
				.attr("width", txtLength)
				.attr("rx", 4)
				.attr("ry", 4)
				.attr("y", -((size + 10) / 2))
				.attr("x", 10)
				.classed("roll-pad", true);

			rollover.insert("path", ":first-child")
				.attr('d', d3.symbol().type(d3.symbolTriangle).size(100))
				.attr("transform", "rotate(-90) translate(0,10)")
				.classed("roll-pad", true);

		}


		//.attr("transform", "translate(0,5)");
	});




}