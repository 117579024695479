//SWEETENER VIEW.

import * as d3 from "d3";
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import * as topojson from "topojson";
import { vbSize } from "./ddd";
import { uniques } from "./ddd";
import { wrap } from "./ddd";
import { titleCase } from "./ddd";
import { textEdit } from "./ddd";
import { formats } from "./ddd";
import { sweetNames } from "./coke/sweetNames";
import { sweetVeng } from "./coke/sweetVeng";
import { parseTransform } from "./ddd";
import { nestScatter } from "../ddd/ddd.js";

export function sweetener(svg, sData, fData, sort, filters) {
	//console.log('--<SWEETMODE>--');
	//SETUP USING CUSTOM VIEWBOX SNIFFER

	var scatterLayer = svg.select("#scatterGroup");
	scatterLayer.selectAll("*").transition().duration(1000).style("opacity", 0).remove();

	var gridLr = svg.select("#l-grid");
	gridLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var mapLr = svg.select("#l-map");
	mapLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var dataLr = svg.select("#l-data");
	dataLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var copyLr = svg.select("#l-copy");
	copyLr.selectAll("*").transition().duration(1000).style("opacity", 0).remove();
	var thekey = svg.select("#l-key");
	thekey.transition().duration(1000).style("opacity", 0);

	var stageLr = svg.select("#l-stage");



	//Sizes
	var svgSize = vbSize(svg);
	var svgHeight = svgSize[0];
	var svgWidth = svgSize[1];
	var gcoords = parseTransform(dataLr.attr("transform")); //Grab the corrdinates of thedata layer which has a margin from top and left
	var gWidth = svgWidth - (gcoords[0] * 2); //50 the sides.
	var gHeight = svgHeight - (gcoords[1] + gcoords[0]); //100 - the top
	var margin = { top: 150, right: 50, bottom: 50, left: 50 };



	//upperleft point of the scatter
	var xPoint = svgWidth - margin.left;
	var yPoint = svgHeight - margin.top;
	//Set the height/width of the actual chart
	var width = svgWidth - margin.left - margin.right - 30,
		height = svgHeight - margin.top - margin.bottom;

	var colorScale = d3.scaleOrdinal(d3.schemeCategory10);


	var sData = sortData(fData, "S_COMBO");


	var fNoSweets = sData.filter(function (d) {
		//console.log(d.key);
		return d.key !== "";
	});


	drawSweets(fNoSweets);

	function drawSweets(data) {

		data.sort(function (a, b) {
			return b.values.length - a.values.length;
		});

		//restrict to 10 biggest.

		var data = data.filter(function (d, i) {
			return i < 10;
		});

		//var chDom = d3.range(0, data.length/2);

		var chDom = d3.range(0, 5);

		var pScale = d3.scalePoint()
			.domain(chDom)
			.rangeRound([0, svgWidth])
			.round(true)
			.padding(.8);

		//find the diameter of each combos spot
		var cd = pScale.step();

		var cr = cd / 2;

		var ca = function () {
			return Math.PI * cr * cr;
		};

		/*     var pack = d3.pack()
		         .size([scatterW, scatterH])
		         .padding(3);

		     var packData = d3.heirach

		     var node = svg.selectAll("g")
		         .data(data.descendants())
		         .enter().append("g")
		         .attr("transform", function(d) { return "translate(" + d.x + "," + d.y + ")"; })
		         .attr("class", function(d) { return "node" + (!d.children ? " node--leaf" : d.depth ? "" : " node--root"); })
		         .each(function(d) { d.node = this; })
		         .on("mouseover", hovered(true))
		         .on("mouseout", hovered(false));*/


		var combos = copyLr.selectAll(".s-combo")
			.data(data).enter().append("g")
			.attr("transform", function (d, i) {
				//console.log(i);
				var count = i;
				var yPoint = 250;
				if (i > 4) {
					count = i - 5;
					yPoint = 550;
				}
				//console.log(pScale(count));
				return "translate(" + (pScale(count)) + "," + yPoint + ")";
			})
			.on("click", function (d) {
				console.log(d.values);
				var theCombo = d.values;
				var comboData = sortData(theCombo, "S_COMBO");
				nestScatter(svg, comboData, fData, sort, filters);
			});


		/*        combos.append("path")
		            .attr('d', d3.symbol().type(d3.symbolCircle).size(ca))
		            .classed("veng-pad", true);
		        //.attr("transform", "rotate(-90) translate(0,10)");*/

		combos.each(function (d, i) {
			var smap = d.values[0].S_MAP;
			sweetVeng(this, smap, cd);

			//console.log(this);
			var delay = i * 500;
			var thethis = this;

			setTimeout(function (thethis) {
				//console.log(i);
			}, delay);


		})

		combos.append("text")
			.text(function (d) {
				return d.values.length + " formulas";
			})
			.attr("y", cr)
			.style("text-anchor", "middle")
			.style("dy", "0.35em")
			.classed("subhead no-select up-case", true);

		//asmet value
		combos.append("text")
			.text(function (d) {
				var items = d.values;
				//console.log(items);
				var asmet = items.filter(function (d, i) {
					return d.ASMET == "YES";
				});
				return asmet.length + " AS met";
			})
			.attr("y", function () {
				return cr + 16;
			})
			.style("text-anchor", "middle")
			.style("dy", "0.35em")
			.classed("tiny-txt no-select up-case", true);

		/*        //avergage sugar reduction
		        combos.append("text")
		            .text(function(d) {
		                //console.log(d.values);
		                var mean = d3.mean(d.values, function(d) { return d.PCT_BRIX / 100 })
		                //return d.values.length;
		                //console.log(mean);
		                var fmt = d3.format(formats.pctFmt);
		                var txt = fmt(mean)
		                return txt + " reduction";
		            })
		            .attr("y", cr)
		            .style("text-anchor", "middle")
		            .style("dy", "0.35em")
		            .classed("tiny-txt no-select up-case", true);*/

	}

	// function that updates the chart when a button is clicked (invoked right above!)
	function updateChart(data) {


	}

	//headline et all
	if (stageLr.select("#headline").empty()) {
		var headline = stageLr.append("text")
			.attr("id", "headline")
			.attr("x", 50)
			.classed("gfx-head", true)
			.attr("y", function () {
				var textNode = d3.select(this);
				var textHeight = textNode.node().getBBox().height;
				return textHeight + 14;
			})
			.call(textEdit, "headline");


		stageLr.append('rect')
			.attr("height", svgHeight)
			.attr("width", svgWidth)
			.classed("box1", true);

	}

	stageLr.select("#headline").text(function (d) {
		var recCount = sData.length;
		//console.log(recCount);
		if (recCount == 0) {
			return "NO COMBINATIONS FOUND BASED ON THESE FILTERS";
		} else {
			return "TOP SWEETENER COMBINATIONS";
		}
		//return recCount + " total in the list";
	});

}

function sortData(data, sort) {
	//console.log(data);
	var sweetString;
	var nested = d3.nest()
		.key(function (d) {
			//console.log(d.S_MAP);
			var sMap = d.S_MAP;

			var tempSweet = "";
			sMap.each(function (d, k) {
				//console.log(i);
				///if this sweetner isn't in the list, we add it temporarily
				if (sweetNames[k] == undefined) {

					console.log('>>> No sweetNames entry for: ' + k);
					console.log('>>> We will create a temp entry instead');

					sweetNames[k] = {
						"NICE_NAME": k.slice(2),
						"COLOR": "lime",
						"C_NAME": "lime"
					}

				}

				var thisSweet = sweetNames[k]["NICE_NAME"];
				//var thisString = thisSweet + ": " + d;
				if (tempSweet == "") {
					tempSweet = thisSweet;
				} else {
					tempSweet = tempSweet + " + " + thisSweet;
				}

			})

			sweetString = tempSweet;
			return d[sort];
		})
		.sortKeys(d3.ascending)
		.entries(data);

	nested.forEach(function (d) {
		d.label = sweetString;
	})

	return nested;
}