//screatFilters.js takes a the results of a d3.dsv and creates filter buttons, and returns data for a viz.
import * as d3 from "d3";
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import { changeColors } from "../ddd/ddd.js";

export function createColorButtons(data, columns, divID, funRun) {
    //console.log('createColor buttons');

    var colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    var svg = d3.select("#g1");

    var scatterLayer = svg.select("#scatterGroup");

    var dataLr = svg.select("#l-data");

    var copyLr = svg.select("#l-copy");

    var stageLr = svg.select("#l-stage");

    //Sizes

    var colorBy;

    if (d3.select("#color-buttons").empty()) {
        colorBy = d3.select(divID)
            .append("div")
            .attr("id", "color-buttons")
            .classed("filter-holder col-md-6", true);
    } else {
        colorBy = d3.select("#color-buttons");
        ColorBy.selectAll("*").remove();
    }


    //CREATE A LABEL FOR THE SORT SET.
    colorBy.append("div")
        .html("Color by")
        .classed("subhead", true);

    //CREATE THE DIV TO HOLD BUTTONS
    var buttons = colorBy
        .append("div")
        .attr("id", "color-options")
        .attr("data-toggle", "buttons")
        .classed("filter-holder btn-group btn-group-toggle", true);


    //Create the filter buttons themselves.
    var colorButtons = buttons.selectAll("label")
        .data(columns)
        .enter()
        .append("label")
        .classed("btn btn-light btn-sm", true)
        .classed("active", function(d, i) {
            if (i == 0) {
                return true;
            } else {
                return false;
            }
        })
        .text(function(d) {
            return d;
        })
        .on("click", function(e, d) {
            //funRun(d);
            //changeColors(d);
            changeBubColors(d);
        });

    colorButtons.append("input")
        .attr("type", "radio")
        .attr("name", "options")
        .attr("autocomplete", "off")
        .property("checked", function(d, i) {
            if (i == 0) {
                return true;
            } else {
                return false;
            }
        })
        .attr("id", function(d) {
            return d + "-btn";
        });


    //add inital sort - currently hardcoded.
    return columns[0];
}