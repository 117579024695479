import "core-js/es6";
import bootstrap from "bootstrap";
import * as d3 from "d3";

//import * as ddd from "../ddd/ddd.js";

//Graphics modes
import { bubStat } from "../ddd/ddd.js";
import { scatter3d2 } from "../ddd/ddd.js";

//prepare an svg.
import { prepStage } from "../ddd/ddd.js";

//download in various formats.
import { svgPrep } from "../ddd/ddd.js";
import { dlsvg } from "../ddd/ddd.js";
import { svg2pdf_cc } from "../ddd/ddd.js";
import { svg2png_cc } from "../ddd/ddd.js";
import { vbSize } from "../ddd/ddd.js";
import { svg2pdf_dr } from "../ddd/ddd.js";

//suss sort strat and filter
import { suss as suss } from "../ddd/ddd.js";
import { createSorts } from "../ddd/ddd.js";
import { createFilters } from "../ddd/ddd.js";
import { createSlider } from "../ddd/ddd.js";

import { filterData } from "../ddd/ddd.js";
import { sortData } from "../ddd/ddd.js";
import { treeData } from "../ddd/ddd.js";
import { rangeFilterData } from "../ddd/ddd.js";

import { createColorButtons } from "../ddd/ddd.js";
import { changeBubColors } from "../ddd/ddd.js";

import { stratData } from "../ddd/ddd.js"
import { cokeGeos } from "../ddd/coke/cokeGeos.js";

window.d3 = d3;

const appData = {
    "data" : undefined,
    "svg" : undefined,
    "colorCols" : undefined,
    "colorOn" : undefined,
    "sortCols" : undefined,
    "sortOn" : undefined,
    "filterCols" : undefined,
    "filters" : undefined,
    "range" : undefined,
    "fData" : undefined,
    "sData" : undefined,
    "modes" : undefined,
    "modeNow" : undefined
}

var data;
var svg = prepStage(1080, 1920, "g1");
var colorBy;
var sort;
var filters;
// var slideInfo = {
//     "col": "x",
//     "range": [0, 1]
// };
//Data filtered according to the current filters.
var fData;
//The data sorted according to the current sort
var sData;


var modes = [{
    "name": "A 3D Scatter Chart",
    "func": scatter3d2
},
{
    "name": "Data Stats",
    "func": bubStat
}];

//This variable holds the current mode
var modeNow = modes[0].func;

$(document).ready(function() {
    //ddd.sayHi();
    d3.select("#vizit-btn").classed("disabled", true);
    d3.select("#download-btn").classed("disabled", true);
    //$(".selectpicker").selectpicker();
});


//PROJECT UX CONFIGS
//Could these be automatically set by suss?
//create a simple array of the columns you want to be able to sort on:
var sortColumns = ["name"];
//create a simple array of the columns you want to be able to filter on:
var filterColumns = ["name"];
//create a simple array of the columns you want to be able to filter on:
var colorColumns = ["name"];

//
//var levels = ["FUNCTION", "BRAND", "NAME"];

//var rollup = "PROJECT_COUNT";
//var rollup = "COST";

//TOP LEVEL UX ELEMENTS

//DATA PASTE WINDOW
$("#data-input").on("keydown oninput onpaste", function(e) {
    var inputData = $("#data-input").val();
    sussIt(inputData);
});

//DOWNLOAD SVG
$("#svg-btn").on("click", function(e) {
    var svgP = svgPrep("#g1");
    dlsvg(svgP);
});

//DOWNLOAD PDF
$("#pdf-btn").on("click", function(e) {
    var svgP = svgPrep("#g1");
    var fileInfo = d3.select("#g1");
    var filename = fileInfo.attr("id") + ".svg";
    svg2pdf_cc(String(svgP.source), filename);
});

//DOWNLOAD PNG
$("#png-btn").on("click", function(e) {
    var svgP = svgPrep("#g1");
    var fileInfo = d3.select("#g1");
    var filename = fileInfo.attr("id") + ".svg";
    //console.log(filename);
    var filesize = vbSize(fileInfo);
    //ddd.svg2png_cc(String(svgPrep.source), filename, filesize);
    svg2png_cc(String(svgP.source), filename, filesize);
});

//VIZ MODE BUTTONS
modes.forEach(function(d, i) {
    //console.log(d);
    var name = d.name;
    var func = d.func;
    var modeBtnGroup = d3.select("#mode-btns");

    /*    if (1 == 0) {
            modeNow = func;
        }*/

    modeBtnGroup.append("label")
        .classed("btn btn-light btn-sm", true)
        .classed("active", function(d) {
            if (i == 0) {
                return true;
            } else {
                return false;
            }
        })
        .html(function(d) {
            return name;
        })
        .on("click", function() {
            //console.log("clicked");
            vizIt(func);
        })
        .append("input")
        .attr("type", "radio")
        .attr("name", "options")
        .attr("autocomplete", "off")
        .property("checked", function(d) {
            if (i == 0) {
                return true;
            } else {
                return false;
            }
        })
        .attr("id", function() {
            return func.name + "-btn";
        });
});

//THE DATA PREP WORKFLOW
//1. When someone pastes any data into the input we SussIt
function sussIt(tsvData) {
    var sussed = suss(tsvData);
    if (sussed.success) {
        $("#data-feedback").show().html(sussed.msg);
        d3.select("#vizit-btn").classed("disabled", false);
        data = sussed.data;
        $("#data-input-wrapper").collapse("hide");
        $("#mode-btn-holder").collapse("show");
        $('html, body').animate({
            scrollTop: 0
        }, 500);
        filterSort();
    } else {
        $("#data-feedback").show().html(sussed.msg);
        //return error;
    }
}

//2. If the data passes any tests we have in suss,
// then we run it through filter sort
//which create the actual user buttons to filter and sort.
function filterSort() {

    //Create a slider for a single column based on the slide info variable above.
    //var sliders = createSlider(data, slideInfo, "#controls", updateSlide);
    //now run createSorts which builds buttons for those filters
    sort = createSorts(data, sortColumns, "#controls", updateSort);
    //Now run createColorBy
    colorBy = createColorButtons(data, colorColumns, "#controls", updateColor);
    //now run createFilters which builds buttons for those filters
    filters = createFilters(data, filterColumns, "#controls", updateFilterBundle);
    //now run prepData which is the one function which uses filter bundle and the sort 
    //to produce fData and sData for visualization.
    //console.log(data.length);
    prepData(data);
}


//Prep data is the single function that prepares all derivitive datasets.
function prepData(data) {
    fData = filterData(data, filters);
    //fData = rangeFilterData(data, slideInfo);
    //sData = sortData(fData, sort);
    //sData = stratData(fData, sort, "NAME");
    //sData = treeData(fData, levels, rollup);
    //modeNow(svg, sData, fData, sort, filters);
    vizIt(modeNow)
}

//Switching between modes.
function vizIt(mode) {
    //var svg = d3.select("#g1");
    modeNow = mode;
    d3.select("#download-btn").classed("disabled", false);
    //console.log(fData);
    modeNow(svg, fData, sort, filters);
    //changeBubColors(colorBy);
}


//Functions that handle ux adjustments
function updateFilterBundle(filterBundle) {
    console.log("Update filter bundle");
    filters = filterBundle;
    prepData(data);
}

function updateSlide(values, handle, unencoded) {
    console.log("Update Slider Range");
    console.log(unencoded);
    slideInfo.range = unencoded;
    prepData(data);
}

function updateSort(newSort) {
    console.log("Update sort");
    sort = newSort;
    prepData(data);
}

function updateColor(newColor) {
    console.log(newColor);
    colorBy = newColor;
    //prepData(data);
}
