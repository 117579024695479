//screatFilters.js takes a the results of a d3.dsv and creates filter buttons, and returns data for a viz.
import * as d3 from "d3";

export function treeData(data, levels, rollup) {
    console.log("make tree data");
    //console.log(data);
    var nest = d3.nest()
        .key(function(d) { return d[levels[0]]; })
        .key(function(d) { return d[levels[1]]; })
        .key(function(d) { return d[levels[2]]; })
        .rollup(function(d) {
            //console.log(d[0]["ACTIVE"]);
            return d3.sum(d, function(d) {
                if (d[rollup] == "n/a" || d[rollup] == "") {
                    //this doesnt need to bee here.
                    d[rollup] = 0;
                }
                return d[rollup];
            })
        })
        .entries(data);


    //console.log(nest);

    var root = d3.hierarchy({ values: nest }, function(d) { return d.values; })
        .sum(function(d) {
            //console.log(d.value);
            return d.value;
        })
        .sort(function(a, b) { return b.value - a.value; });

    console.log(root);

    return root;








}